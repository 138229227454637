export type locale = 'nb' | 'en';

export enum ConsentStateEnum {
	ACCEPTED = 'ACCEPTED',
	DECLINED = 'DECLINED',
	UNANSWERED = 'UNANSWERED',
	PENDING = 'PENDING',
}

export type ConsentState = keyof typeof ConsentStateEnum;

export enum MarketingConsentType {
	PersonalizedMarketing = 'PersonalizedMarketing',
	GeneralMarketing = 'GeneralMarketing',
	DisableMarketing = 'DisableMarketing',
	Legacy1 = 'Legacy1',
}

export type EpiPageType =
	| 'HeadlessAboutEnergyAgreementPageType'
	| 'HeadlessActiveEnergyAgreementPageType'
	| 'HeadlessConsumptionCostPageType'
	| 'HeadlessConsumptionUsePageType'
	| 'HeadlessMovePageType'
	| 'HeadlessInvoicePageType'
	| 'HeadlessInvoiceEditDueDatePageType';

export enum Brand {
	'brand-fjordkraft-chart',
	'brand-fjordkraft',
	'brand-steddi',
	'brand-strømforsikring',
	'brand-trondelagkraft',
	'brand-trumf',
	'brand-vannkraft',
	'brand-nges',
}

export type StatusType = 'positive' | 'neutral' | 'negative';
