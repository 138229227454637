import {
    getCounterTheme,
    IComponent,
    IText,
} from '@fjordkraft/fjordkraft.component.library';
import { getBrand } from '../../services';

export const h3TextPrefab = (props?: IComponent) => {
    return {
        theme: getCounterTheme(props?.theme ?? 'Light'),
        brand: props?.brand ?? getBrand(),
        weight: 700,
        size: 'large',
        family: 'main',
        type: 'h3',
    } as IText;
};
