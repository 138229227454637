import {
    BrandColors,
    IComponentTemplate,
    IComponentTransform,
    IThemedColors,
    Theme,
} from '@fjordkraft/fjordkraft.component.library';

// ************************************
// Transform
// ************************************

const _getMinHeight = (hasImage: boolean) => {
    return (hasImage ? '19rem' : '13rem');
}

const _transform = (
    desktopView: boolean,
    type: 'column' | 'row',
    count: number,
    hasImage: boolean,
) => {
    let minHeight: string;
    let width: string;

    if (desktopView) {
        minHeight = type === 'row' ? 'auto' : _getMinHeight(hasImage);
        width = type === 'row' ? '100%' : `${100 / count}%`;
    } else {
        minHeight = 'auto';
        width = '100%';
    }

    return {
        height: 'auto',
        minHeight,
        width,
        border: {
            radiusType: 'curved',
        },
        padding: '1.5rem 1rem',
        grid: {
            direction: type,
            alignment: type === 'row' ? 'top-left' : 'top-center',
            gap: 2,
            scaling: 1,
            tagType: 'article',
            boxSizing: 'border-box',
            wrap: false,
            spaceBetween: true,
        },
    } as IComponentTransform;
};
// ************************************
// Palette
// ************************************

const _palette = {
    Light: {
        background: { color: BrandColors['background-shade-light-1'] },
        text: { color: BrandColors['text-shade-dark-3'] },
        border: { color: BrandColors['none'] },
        icon: { color: BrandColors['none'] },
    },
    Dark: {
        background: { color: BrandColors['background-shade-dark-3'] },
        text: { color: BrandColors['text-shade-light-1'] },
        border: { color: BrandColors['none'] },
        icon: { color: BrandColors['none'] },
    },
} as IThemedColors;

// ************************************
// Export
// ************************************

export const ServicePitchCardTemplate = (
    count: number,
    theme: Theme = 'Light',
    desktopView: boolean = true,
    type: 'row' | 'column' = 'column',
    hasImage: boolean = false
) => {
    return {
        dontUpdateChildren: true,
        palette: _palette[theme],
        transform: _transform(desktopView, type, count, hasImage),
    } as IComponentTemplate;
};
