import { IDetailCheckboxCollectionBlockData } from "../../../blocks";
import { IDetailCheckboxItemData } from "../../../blocks/DetailCheckboxCollectionBlock/DetailCheckboxItem/DetailCheckboxItem";
import { Constants } from "../../../data";
import {ConsentStateEnum, IConsent, IDefaultProps, MarketingConsentType} from "../../../models";
import { getText, getTextV2 } from "../../../services";

export interface IReservationPageData extends IDefaultProps {
    consents: IConsent[];
}

export const getPageContent = async (config: IReservationPageData) => {
    const { translations} = config;

    return({
        ...config,
        sub: {
            title: getText('pageTitle', translations),
            subTitle: getTextV2({
                key: 'pageSubTitle',
                translations,
                includeMissing: false
            }),
            back: {
                text: getText('back', translations),
                link: Constants.paths.userPage
            }
        },
        description: getTextV2({
            key: 'description',
            translations,
            includeMissing: false
        }),
        detailCheckboxCollection: _getConsentCollectionData(config)
    });
}

const _getConsentCollectionData = (config: IReservationPageData) : IDetailCheckboxCollectionBlockData[] => {
    const { translations } = config;

    let collection : IDetailCheckboxCollectionBlockData[] = [];

    collection.push({
        title: getTextV2({
            key: 'reservationMarketingTitle',
            translations,
            includeMissing: false
        }),
        items: _getConsentCollectionItems(config),
        description: getTextV2({
            key: 'reservationMarketingDescription',
            translations,
            includeMissing: false
        })
    });

    return collection;
}

const _getConsentCollectionItems = (config: IReservationPageData) : IDetailCheckboxItemData[] => {
    const { translations, consents } = config;
    const reservation = consents?.find(e => e.consentName === MarketingConsentType.DisableMarketing)


    let items : IDetailCheckboxItemData[] = [];

    items.push({
        title: getTextV2({
            key: 'itemReservationMarketingTitle',
            translations,
            includeMissing: false
        }),
        description: getTextV2({
            key: 'itemReservationMarketingDescription',
            translations,
            includeMissing: false
        }),
        location: 'single',
        state: reservation?.value ?? ConsentStateEnum.UNANSWERED
    });


    return items;
}