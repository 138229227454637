import {
    BrandColors,
    IComponentTemplate,
    IComponentTransform,
    IThemedColors,
    Theme,
} from '@fjordkraft/fjordkraft.component.library';

// ************************************
// Transform
// ************************************

const _transform = (desktopView: boolean) => {
    return {
        width: '100%',
        height: desktopView ? '30.313rem' : 'auto',
        grid: {
            tagType: 'div',
            boxSizing: 'border-box',
            direction: 'row',
            alignment: 'center',
        },
    } as IComponentTransform;
};

// ************************************
// Palette
// ************************************

const _palette = {
    Light: {
        background: { color: BrandColors['primary-shade-light-1'] },
        text: { color: BrandColors['text-shade-dark-3'] },
        icon: { color: BrandColors['icon-shade-light-1'] },
        extra: [
            {
                key: 'loaderFill',
                value: { color: BrandColors['action-shade-light-1'] },
            },
            {
                key: 'loaderBackground',
                value: { color: BrandColors['action-shade-light-1'] },
            },
        ],
    },
    Dark: {
        background: { color: BrandColors['background-shade-dark-1'] },
        text: { color: BrandColors['text-shade-light-1'] },
        border: { color: BrandColors['none'] },
        icon: { color: BrandColors['none'] },
    },
} as IThemedColors;

export const SectionBlockTemplate = (theme: Theme, desktopView: boolean) => {
    return {
        palette: _palette[theme],
        transform: _transform(desktopView),
    } as IComponentTemplate;
};
