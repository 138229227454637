import {Button, Icon, IconType, IPalette} from "@fjordkraft/fjordkraft.component.library";
import React from "react";

export const ArrowButton = ({isVisible, direction, onClick, activeBrand, palette, classPrefix=""}: {
    isVisible: boolean,
    direction: "right" | "left",
    onClick: () => void
    classPrefix?: string
    activeBrand?: string
    palette?: IPalette
}) => {
    return (
        <Button
            style={{visibility: isVisible ? "visible" : "hidden"}}
            className={`${classPrefix}__arrow`}
            action={{
                onClick: onClick
            }}
        >
            <Icon
                className={`${classPrefix}__arrow`}
                brand={activeBrand}
                palette={palette}
                type={direction === "left" ? IconType.ChevronLeft : IconType.ChevronRight}
                width={1.5}
                height={1.5}
            />
        </Button>
    )
}