import {
    BrandColors,
    IComponentTemplate,
    IComponentTransform,
    IThemedColors,
    Theme,
} from '@fjordkraft/fjordkraft.component.library';

// ************************************
// Transform
// ************************************

const _transform = {
    height: 'auto',
    border: {
        radiusType: 'curved',
    },
    padding: '2rem',
    margin: undefined,
    grid: {
        direction: 'column',
        alignment: 'top-center',
        gap: 2,
        tagType: 'article',
        boxSizing: 'border-box',
    },
} as IComponentTransform;

// ************************************
// Palette
// ************************************

const _palette = {
    Light: {
        background: { color: BrandColors['background-shade-light-1'] },
        text: { color: BrandColors['text-shade-dark-3'] },
        border: { color: BrandColors['none'] },
        icon: { color: BrandColors['none'] },
    },
    Dark: {
        background: { color: BrandColors['background-shade-dark-3'] },
        text: { color: BrandColors['text-shade-light-1'] },
        border: { color: BrandColors['none'] },
        icon: { color: BrandColors['none'] },
    },
} as IThemedColors;

// ************************************
// Export
// ************************************

export const CardTemplate = (theme: Theme = 'Light') => {
    return {
        dontUpdateChildren: true,
        palette: _palette[theme],
        transform: _transform,
    } as IComponentTemplate;
};
