import React from 'react';
import {
    IComponent,
    ContentGrid,
    Text,
    getCounterTheme,
    IconType,
    BaseComponent,
} from '@fjordkraft/fjordkraft.component.library';
import classnames from 'classnames';
import { ActionButton } from '../../components';
import { FooterLinkItemGridBlock, IFooterLinkItem } from '../index';
import {
    useApplicationContext,
} from '../../contexts';
import { MS_ButtonTemplate, paragraphTextPrefab } from '../../Prefabs';
import { FooterTemplate } from './FooterTemplate';
import { IHomePage } from '../../models';
import { isExternalUrl } from '../../services';
import './Footer.scss';

export interface IFooter extends IComponent {
    rootPageData: IHomePage;
}

export const Footer = (props: IFooter) => {
    // ************************************
    // Properties
    // ************************************

    const { id, className, rootPageData } = props;
    const classPrefix = 'footer';
    const { activeTheme, activeBrand, desktopView } = useApplicationContext();

    // ************************************
    // Helper Functionality
    // ************************************

    const _getGridContent = () => {
        return rootPageData.footerItems.map((item: any) => {
            item.actionButton.link = item.actionButton.url;
            return item as IFooterLinkItem;
        });
    }

    // ************************************
    // Render
    // ************************************

    return (
        <BaseComponent
            id={id}
            brand={activeBrand}
            theme={activeTheme}
            className={classnames(classPrefix, {
                [`${activeBrand}`]: activeBrand,
                [`${className}`]: className,
                [`${classPrefix}--${activeTheme}`]: activeTheme,
            })}
            template={FooterTemplate(activeTheme, desktopView, activeBrand)}
        >
            {rootPageData.footerItems &&
                <FooterLinkItemGridBlock
                    theme={activeTheme}
                    brand={activeBrand}
                    alignment={'center'}
                    items={_getGridContent()}
                />
            }
            <ContentGrid
                className={`${classPrefix}__proxy-form`}
                tagType="section"
                alignment="center"
                direction={desktopView ? 'row' : 'column'}
                gap={2}
            >
                <Text
                    {...paragraphTextPrefab()}
                    brand={activeBrand}
                    theme={getCounterTheme(activeTheme)}
                    faded
                    align="align-center"
                >
                    {rootPageData.proxyFormDescription}
                </Text>
                {rootPageData.proxyFormAction &&
                    <ActionButton
                        brand={activeBrand}
                        action={{
                            text: rootPageData.proxyFormAction.text,
                            icon: rootPageData.proxyFormAction.iconType as IconType ?? IconType.PdfIcon,
                            link: rootPageData.proxyFormAction.url ?? '',
                            useRouterLink: !isExternalUrl(rootPageData.proxyFormAction.url)
                        }}
                        template={MS_ButtonTemplate(activeTheme, 'link')}
                    />
                }
            </ContentGrid>
        </BaseComponent>
    );
};
