import React from 'react';
import {
    IComponent,
    FlexGridAligner,
    Text,
    getCounterTheme,
} from '@fjordkraft/fjordkraft.component.library';
import { useApplicationContext } from '../../contexts';
import './ListItemCategories.scss';
import { v4 as uuid4 } from 'uuid';

export interface IListItemCategories extends IComponent {
    categories: string[];
}

/**
 *    The ListItemCategories is used to show categories
 *    @type {string[]} categories
 */
export const ListItemCategories = (props: IListItemCategories) => {
    // ************************************
    // Properties
    // ************************************
    const { activeBrand, activeTheme, desktopView } = useApplicationContext();
    const { categories } = props;
    const classPrefix = 'list-item-categories';

    // ************************************
    // Render
    // ************************************
    return (
        <FlexGridAligner
            className={`${classPrefix}`}
            alignment={'center-left'}
            direction={'row'}
            gap={1}
        >
            {categories &&
                categories.map((category, i) => (
                    <Text
                        key={uuid4()}
                        type={'p'}
                        size={'small'}
                        weight={600}
                        brand={activeBrand}
                        theme={
                            desktopView
                                ? getCounterTheme(activeTheme)
                                : activeTheme
                        }
                        faded
                    >
                        {category.toUpperCase() +
                            (i < categories.length - 1 ? ',' : '')}
                    </Text>
                ))}
        </FlexGridAligner>
    );
};
