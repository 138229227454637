import React, { useEffect } from 'react';
import {
    IComponent,
    Text,
    ITextCustomization,
    StyleLoopLimit,
    ContentGrid,
    StyleGrid,
} from '@fjordkraft/fjordkraft.component.library';
import { v4 as uuid4 } from 'uuid';
import { IMSPlankWall, PlankWall } from '../PlankWallBlock';
import { h3TextPrefab } from '../../Prefabs';
import classNames from 'classnames';
import './PlankHouseBlock.scss';

export interface IPlankHouse extends IComponent {
    title?: string;
    titleCustomization?: ITextCustomization;
    plankWalls: IMSPlankWall[];
    titleGap?: StyleLoopLimit;
    wallGap?: StyleLoopLimit;
    wallGapType?: 'rem' | 'px';
    onReRender?: () => void;
}

export const PlankHouseBlock = (props: IPlankHouse) => {
    // ************************************
    // Properties
    // ************************************

    const {
        id,
        className,
        theme,
        title,
        titleCustomization,
        plankWalls,
        titleGap = 2,
        wallGap = 4,
        wallGapType = 'rem',
        render = true,
        disabled,
        onReRender,
    } = props;
    const classPrefix = 'ms-plank-house-block';

    // ************************************
    // Lifecycle
    // ************************************

    useEffect(() => {
        if (onReRender) {
            onReRender();
        }
    }, [plankWalls]);

    // ************************************
    // Render Functionality
    // ************************************

    const buildHouse = () => {
        return plankWalls.map((plankWall: IMSPlankWall) => {
            let idRef: string = uuid4();

            return (
                <PlankWall
                    {...plankWall}
                    disabled={disabled}
                    key={uuid4()}
                    id={plankWall.id ? plankWall.id : idRef}
                    theme={theme}
                />
            );
        });
    };

    // ************************************
    // Render
    // ************************************

    return (
        <StyleGrid
            id={id}
            className={classNames(classPrefix, {
                [`${className}`]: className,
            })}
            direction="column"
            alignment="top-left"
            render={render}
            gap={titleGap}
            boxSizing="border-box"
        >
            {title && (
                <ContentGrid
                    className={`${classPrefix}__header`}
                    direction="row"
                    alignment="center-left"
                    tagType="section"
                    boxSizing="border-box"
                >
                    <Text
                        {...h3TextPrefab()}
                        className={`${classPrefix}__title`}
                        {...titleCustomization}
                    >
                        {title}
                    </Text>
                </ContentGrid>
            )}
            <StyleGrid
                className={`${classPrefix}__content`}
                direction="column"
                alignment="top-left"
                gap={wallGap}
                gapType={wallGapType}
                boxSizing="border-box"
            >
                {plankWalls && buildHouse()}
            </StyleGrid>
        </StyleGrid>
    );
};
