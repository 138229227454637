import React from 'react';
import {
    useApplicationServicehandlerContext,
    useDefaultPageContext,
    useSubPageLayoutContext,
} from '../../../contexts';
import {
    ITransaction,
    getPageContent,
} from './TransactionsPageData';
import { Constants } from '../../../data';
import { KickbackTransactionsBlock } from '../../../blocks';
import { IDefaultViewProps, PageV2 } from '../../PageV2';

export interface ITransactionsPage extends IDefaultViewProps {
    id: string;
    transactions: any[];
    expiringTransactions?: ITransaction[];
}

export const TransactionsPage = () => {
    // ************************************
    // Properties
    // ************************************

    const { setInfoBoxContent } = useSubPageLayoutContext();
    const { setMainContentClamping } = useDefaultPageContext();

    // ************************************
    // Render
    // ************************************

    return (
        PageV2({
            setup: {
                pageType: Constants.epiServerPageNames.benefits.type,
                usesSubPage: true
            },
            startedRender: () => {
                setMainContentClamping('clamp');
            },
            handleData: getPageContent,
            onDataChange: (config: ITransactionsPage) => {
                setInfoBoxContent({ hasInfo: false });
            },
            render: (config: ITransactionsPage) => {
                const {
                    activeBrand,
                    activeTheme,
                    transactions,
                    expiringTransactions,
                    translations
                } = config;

                return (
                    <KickbackTransactionsBlock
                        theme={activeTheme}
                        brand={activeBrand}
                        transactions={transactions}
                        expiringTransactions={expiringTransactions}
                        translations={translations}
                    />
                )
            }
        })
    );
};
