import _ from 'lodash';

export const convertObjectToMap = (obj: any) => {
	return new Map(Object.entries(obj));
};

export const convertObjectArrayToMap = (deps: any[]): Map<string, any> => {
	let map: Map<string, any> = new Map<string, any>();

	for (const obj of deps) {
		if (obj !== undefined && obj !== null) {
			for (const [key, value] of Object.entries(obj)) {
				map.set(key, value);
			}
		}
	}

	return map;
};

export const convertMapToObject = (dep: Map<string, any>) => {
	let obj: any = {};

	for (let [key, value] of dep) {
		obj[key] = value;
	}

	return obj;
};

export const assignedAllDepndencies = (deps: any[], ref: Map<string, any>): boolean => {
	if (deps.length === 0 || ref.size < deps.length) {
		return false;
	}

	for (const obj of deps) {
		if (obj !== undefined && obj !== null) {
			for (const [key, value] of Object.entries(obj)) {
				for (let [refKey, refValue] of ref) {
					if (key === refKey) {
						if (!_.isEqual(value, refValue) || refValue === undefined || refValue === null) {
							return false;
						}
					}
				}
			}
		} else {
			return false;
		}
	}

	return true;
};
