import React from 'react';
import { BrandColors, ContentGrid, IAction, IconType, Text, getCounterTheme, Icon } from "@fjordkraft/fjordkraft.component.library";
import { getPageContent } from './AccessDeniedPageData';
import { MS_ButtonTemplate, h1TextPrefab } from '../../Prefabs';
import { ActionButton, MSRichText } from '../../components';
import { Constants } from '../../data';
import { IDefaultViewProps, PageV2 } from '../PageV2';
import './AccessDeniedPage.scss';

export interface IAccessDeniedPageProps {
    accessTo?: string;
}

export interface IAccessDeniedPage extends IDefaultViewProps {
    title: string;
    description: string;
    action: IAction;
}

export const AccessDeniedPage = (props: IAccessDeniedPageProps) => {
    const { accessTo } = props;
    const classPrefix = 'access-denied-page';

    return (
        PageV2({
            setup: {
                pageType: Constants.epiServerPageNames.accessDeniedPage.type,
            },
            dependencies: [{ accessTo }],
            handleData: getPageContent,
            render: (config: IAccessDeniedPage) => {
                const { title, description, action, activeBrand, activeTheme } = config;

                return (
                    <ContentGrid
                        className={classPrefix}
                        direction='column'
                        alignment='center'
                        gap={4}
                        boxSizing='border-box'
                        tagType='section'
                    >
                        <Text
                            {...h1TextPrefab()}
                            brand={activeBrand}
                            color={BrandColors["primary-shade-light-2"]}
                        >
                            {title}
                        </Text>
                        <Icon
                            type={IconType.ErrorFace}
                            width={8}
                            height={8}
                            color={BrandColors["primary-shade-light-1"]}
                        />
                        <MSRichText
                            brand={activeBrand}
                            theme={getCounterTheme(activeTheme)}
                            alignment='lawful'
                            text={description}
                            customization={{
                                type: 'p',
                                align: 'align-center'
                            }}
                        />
                        <ActionButton
                            template={MS_ButtonTemplate(activeTheme, 'primary')}
                            action={action}
                            iconPlacement='Right'
                            padding='medium'
                        />
                    </ContentGrid>
                );
            }
        })
    );
}