import { useContext, createContext } from 'react';
import { IConsent } from '../../models';

interface IConsentContext {
    consents?: IConsent[];
    PUT_CONSENTS: (consents: IConsent[], translations: any) => void;
    updateConsents: () => Promise<IConsent[] | null>;
    useNewConsents?: boolean;
    showConsentPopup?: boolean;
    isReservedAgainstMarketing?: boolean
    hasAnsweredNewConsent?: boolean
}

export const ConsentContext =
    createContext<IConsentContext>({
        consents: undefined,
        PUT_CONSENTS: () => {},
        updateConsents: () => new Promise(() => {}),
        useNewConsents: false,
        showConsentPopup: false,
        isReservedAgainstMarketing: false,
        hasAnsweredNewConsent: false
    });

export const useConsentContext = () => useContext(ConsentContext);