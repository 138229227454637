import React from 'react';
import {
	Text,
	IconType,
	BrandColors,
	NoticeBubble,
	IMasterPlank,
	Icon,
} from '@fjordkraft/fjordkraft.component.library';
import classNames from 'classnames';
import { ICustomerInvoice, IInvoicePage } from '../../../models';
import { format } from 'date-fns';
import { nb } from 'date-fns/locale';
import {
	capitalizeFirstLetter,
	getCorrectInvoicePeriodDate,
	getText,
	tNumber,
	translateInvoiceStatus,
} from '../../../services';
import { paragraphTextPrefab, InvoiceIconBubbleTemplate, h4TextPrefab } from '../../';
import { v4 as uuid4 } from 'uuid';
import './InvoicePlankPrefab.scss';

export interface IInvoicePlankPrefab extends IMasterPlank {
	invoice: ICustomerInvoice;
	translation: IInvoicePage;
	desktopView: boolean;
}

export const InvoicePlankPrefab = (props: IInvoicePlankPrefab) => {
	// ************************************
	// Properties
	// ************************************

	const { id, className, theme = 'Light', brand, invoice, translation, action, desktopView } = props;
	const classPrefix = 'ms-invoice-plank';

	// ************************************
	// Helper Functionality
	// ************************************

	const getTypeTranslation = () => {
		switch (invoice.type) {
			case 'Electricity':
				return getText('electricity', translation);
			case 'Mobile':
				return getText('mobile', translation);
			default:
				return '';
		}
	};

	const getCostString = () => {
		return `${tNumber(invoice.totalAmount ?? 0, 'no-NO', 2, 2)} ${getText('currency', translation)}`;
	};

	// ************************************
	// Render Functionality
	// ************************************

	const _renderLeftIcon = () => {
		return [
			<NoticeBubble
				key={uuid4()}
				bubbleIcon={IconType.Lightning}
				className={`${classPrefix}__notice-bubble`}
				palette={{ icon: { color: BrandColors['primary-shade-light-2'] } }}
				template={InvoiceIconBubbleTemplate(theme)}
				theme={theme}
				brand={brand}
			/>,
		];
	};

	const _renderLeftTitle = () => {
		return (
			<Text
				{...h4TextPrefab()}
				brand={brand}
				align={'align-left'}
			>
				{getTypeTranslation()}
			</Text>
		);
	};

	const _renderLeftDescription = () => {
		return (
			<Text
				{...paragraphTextPrefab()}
				brand={brand}
				faded
				align={'align-left'}
			>
				{capitalizeFirstLetter(
					format(getCorrectInvoicePeriodDate(invoice.invoicePeriod), 'MMMM', {
						locale: nb,
					})
				)}
			</Text>
		);
	};

	const _renderRightIcon = () => {
		return [
			<Icon
				key={uuid4()}
				brand={brand}
				type={IconType.ChevronRight}
				width={1.5}
				height={1.5}
			/>,
		];
	};

	const _renderRightTitle = () => {
		return (
			<Text
				brand={brand}
				{...h4TextPrefab()}
				align={'align-right'}
			>
				{getCostString()}
			</Text>
		);
	};

	const _renderRightDescription = () => {
		let status = translateInvoiceStatus({ invoice, translation });

		return (
			<Text
				{...paragraphTextPrefab()}
				color={status.color}
			>
				{status.text}
			</Text>
		);
	};

	const _renderLeft = () => {
		return [_renderLeftTitle(), _renderLeftDescription()];
	};

	const _renderRight = () => {
		return [_renderRightTitle(), _renderRightDescription()];
	};

	// ************************************
	// Render
	// ************************************

	return {
		id,
		className: classNames(classPrefix, {
			[`${className}`]: className,
		}),
		action,
		leftColumn1: desktopView ? _renderLeftIcon() : undefined,
		leftColumn2: _renderLeft(),
		rightColumn1: _renderRight(),
		rightColumn2: _renderRightIcon(),
	} as IMasterPlank;
};
