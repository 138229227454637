import React, { useState, useMemo, useEffect } from 'react';
import {
    IComponent,
    ITabFilterState,
    ITabSelectDropdown,
    ITabSelectFilterItem,
    StyleGrid,
    TabSelectFilter,
} from '@fjordkraft/fjordkraft.component.library';
import classNames from 'classnames';
import { useApplicationContext } from '../../contexts';
import './GenericDateSelectFilter.scss';

export interface IGenericDateSelectFilter extends IComponent {
    initialDate?: Date;
    yearsFilter?: ITabSelectFilterItem[];
    monthsFilter?: ITabSelectFilterItem[];
    daysFilter?: ITabSelectFilterItem[];
    onChange: (value: any) => void;
}

export const GenericDateSelectFilter = (props: IGenericDateSelectFilter) => {
    // ************************************
    // Properties
    // ************************************

    const { desktopView } = useApplicationContext();
    const {
        id,
        className,
        brand,
        theme = 'Light',
        disabled,
        yearsFilter,
        monthsFilter,
        daysFilter,
        initialDate,
        onChange,
    } = props;
    const classPrefix = 'generic-date-select-filter';

    // ************************************
    // Lifecycle
    // ************************************

    const [selectedDate, setSelectedDate] = useState<Date>();

    useEffect(() => {
        if (initialDate && !selectedDate) {
            setSelectedDate(initialDate);
        }
    }, [initialDate]);

    // ************************************
    // Functionality
    // ************************************

    const _getDateFilters = () => {
        let filterSelection: ITabSelectDropdown[] = [];

        if (yearsFilter) {
            let years: ITabSelectDropdown = {
                tabScaling: {
                    type: 'percentage',
                    width: _calculateSize().years,
                },
                items: yearsFilter,
                highlighted: false,
                includeActive: false,
            };

            filterSelection.push(years);
        }

        if (monthsFilter) {
            let months: ITabSelectDropdown = {
                tabScaling: {
                    type: 'percentage',
                    width: _calculateSize().months,
                },
                items: monthsFilter,
                highlighted: false,
                includeActive: false,
            };

            filterSelection.push(months);
        }

        if (daysFilter) {
            let days: ITabSelectDropdown = {
                tabScaling: {
                    type: 'percentage',
                    width: _calculateSize().days,
                },
                items: daysFilter,
                highlighted: false,
                includeActive: false,
            };

            filterSelection.push(days);
        }

        return filterSelection;
    };

    const _calculateSize = () => {
        let months: number = 100;
        let years: number = 100;
        let days: number = 100;

        if (monthsFilter && daysFilter && yearsFilter) {
            years = 20;
            months = 60;
            days = 20;
        }

        return { months, years, days };
    };

    const _filterChangeHandler = (filterSettings: ITabFilterState) => {
        onChange(filterSettings.selected?.value);
        setSelectedDate(filterSettings.selected?.value);
    };

    // ************************************
    // Render Functionality
    // ************************************

    const _renderFilter = useMemo(() => {
        if (selectedDate) {
            return (
                <TabSelectFilter
                    className={`${classPrefix}__filter`}
                    brand={brand}
                    theme={theme}
                    curvy={desktopView}
                    desktopView={desktopView}
                    filters={_getDateFilters()}
                    onChange={(filterSettings: ITabFilterState) => {
                        _filterChangeHandler(filterSettings);
                    }}
                />
            );
        }
    }, [selectedDate, theme, brand, desktopView]);

    // ************************************
    // Render
    // ************************************

    return (
        <StyleGrid
            id={id}
            className={classNames(`${classPrefix}`, {
                [`${className}`]: className,
            })}
            direction="column"
            alignment="center"
            boxSizing="border-box"
        >
            {_renderFilter}
            {disabled && (
                <span
                    className={classNames(`${classPrefix}__loading-container`, {
                        [`${classPrefix}__loading-container--${theme}`]: theme,
                    })}
                />
            )}
        </StyleGrid>
    );
};
