import { useContext, createContext } from 'react';
import { CallState, ICustomer, ICustomerInstallation, IPage } from '../../models';

interface IApplicationExternalData {
	epiChildren?: any;
	userData?: ICustomer;
	installation?: ICustomerInstallation;
	setInstallation: (installation?: ICustomerInstallation) => void;
	pageId: string;
	updateCustomerData: (newSetup?: boolean) => Promise<{ callState: CallState; data: ICustomer }>;
	setPageId: (id: string) => void;
	translation: any;
	setTranslation: (translation: any) => void;
}

export const ApplicationCoreDataContext = createContext<IApplicationExternalData>({
	epiChildren: undefined,
	userData: undefined,
	pageId: 'basic',
	installation: undefined,
	updateCustomerData: () => new Promise(() => {}),
	setInstallation: () => {},
	setPageId: () => {},
	translation: undefined,
	setTranslation: () => {},
});

export const useApplicationCoreDataContext = () => useContext(ApplicationCoreDataContext);
