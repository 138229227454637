import React, { useEffect, useMemo, useState } from 'react';
import {
	IComponent,
	Text,
	getCounterTheme,
	BrandColors,
	DatePicker,
	StyleGrid,
} from '@fjordkraft/fjordkraft.component.library';
import {
	useApplicationContext,
	useApplicationCoreDataContext,
	useApplicationServicehandlerContext,
	useMoveContext,
	useDefaultPageContext,
} from '../../../contexts';
import { ActionButton, ArrowButton, Card } from '../../../components';
import _ from 'lodash';
import Holidays from 'date-holidays';
import './MoveFormPages.scss';
import { useAuth } from 'react-oidc-context';
import { MS_ButtonTemplate } from '../../../Prefabs';
import { fetchMeterType } from '../../../services/collection/InstallationService';

export interface IMoveFormPageDate extends IComponent {
	nextPage: () => void;
	prevPage: () => void;
	setPage: (page: number) => void;
}

/**
 *    The MoveFormPageDate is used to -FILL IN-
 *    @type {string} text
 */
export const MoveFormPageDate = (props: IMoveFormPageDate) => {
	// ************************************
	// Properties
	// ************************************
	const { activeBrand, activeTheme } = useApplicationContext();
	const { translation } = useApplicationCoreDataContext();
	const { customerServiceFeature } = useApplicationServicehandlerContext();
	const { address, setAddress, terminationDate, setTerminationDate } = useMoveContext();
	const { setContentLoading } = useDefaultPageContext();
	const { GET } = useApplicationServicehandlerContext();
	const { user } = useAuth();
	const { id, nextPage, prevPage, setPage } = props;
	const classPrefix = 'move-form-page-date';

	// ************************************
	// Lifecycle
	// ************************************
	const [enableNext, setEnableNext] = useState<boolean>(false);
	const [firstInteractableDate, setFirstInteractableDate] = useState<Date>();

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		if (firstInteractableDate && terminationDate && terminationDate >= firstInteractableDate) {
			setEnableNext(true);
		} else {
			setEnableNext(false);
		}
	}, [terminationDate, firstInteractableDate]);

	useEffect(() => {
		if (_.isEmpty(address)) setPage(1);
	}, [address]);

	// ************************************
	// Async functionality
	// ************************************

	const getData = async () => {
		if (!firstInteractableDate) {
			setContentLoading(true);

			let meteringPointType;
			// fetch metertype
			if (user && address.meterId) {
				let result = await fetchMeterType(GET, address.meterId);
				meteringPointType = result ?? 'HOURLY';
				setAddress({ ...address, meteringPointType });
			}
			let date = new Date();
			date.setHours(0);
			date.setMinutes(0);
			date.setSeconds(0);
			date.setMilliseconds(0);

			if (meteringPointType == 'PROFILE') {
				let hd = new Holidays();
				hd.init('NO');
				let workingDay = 0;
				while (workingDay < 3) {
					date.setDate(date.getDate() + 1);
					if (date.getDay() != 6 && date.getDay() != 7 && !hd.isHoliday(date)) {
						workingDay++;
					}
				}
				date.setDate(date.getDate() + 1);
			} else {
				date.setDate(date.getDate() + 2);
			}

			setFirstInteractableDate(date);

			setContentLoading(false);
		}
	};

	// ************************************
	// Render Functionality
	// ************************************

	const renderDatePicker = useMemo(() => {
		if (firstInteractableDate) {
			return (
				<DatePicker
					id={`${classPrefix}__date-picker`}
					className={`${classPrefix}__date-picker`}
					theme={activeTheme}
					brand={activeBrand}
					interactableStartDate={firstInteractableDate}
					selected={terminationDate ?? null}
					onSelect={(date: Date) => {
						setTerminationDate(date);
					}}
					placeholderText={translation.datePage.datePickerPlaceholder}
				/>
			);
		}
	}, [firstInteractableDate, activeBrand, activeTheme, terminationDate]);

	// ************************************
	// Render
	// ************************************
	return (
		<>
			{firstInteractableDate && (
				<StyleGrid
					id={id}
					className={`${classPrefix}`}
					brand={activeBrand}
					theme={activeTheme}
					alignment={'center-left'}
					direction={'row'}
					boxSizing="border-box"
				>
					<Card
						className={`${classPrefix}__card`}
						theme={activeTheme}
						brand={activeBrand}
						alignment={'center'}
						gap={3}
					>
						<Text
							type={'h3'}
							weight={700}
							color={BrandColors['primary-shade-light-2']}
							brand={activeBrand}
							theme={getCounterTheme(activeTheme)}
						>
							{address?.address}
						</Text>
						<Text
							type={'h4'}
							weight={600}
							brand={activeBrand}
							theme={getCounterTheme(activeTheme)}
						>
							{customerServiceFeature
								? translation.datePage.titleCustomerService
								: translation.datePage.title}
						</Text>

						{renderDatePicker}

						<Text
							type={'p'}
							brand={activeBrand}
							theme={getCounterTheme(activeTheme)}
							align={'align-center'}
						>
							{translation.datePage.moreInfo}
						</Text>
						<ActionButton
							template={MS_ButtonTemplate(activeTheme, 'primary')}
							brand={activeBrand}
							padding="default"
							action={{
								text: translation.datePage.nextPageButtonTitle,
								onClick: nextPage,
								disabled: !enableNext,
							}}
						/>
						<ArrowButton
							action={{
								onClick: prevPage,
								text: translation.datePage.backButtonTitle,
							}}
							iconLocation="left"
						/>
					</Card>
				</StyleGrid>
			)}
		</>
	);
};
