import {
	BrandColors,
	IComponentColors,
	IMasterPlankTemplate,
	IPalette,
	IThemedColors,
	LocationType,
	MasterPlankTemplate,
	Theme,
} from '@fjordkraft/fjordkraft.component.library';

export const MS_MasterPlankTemplate = (theme: Theme, location: LocationType) => {
	let base: IMasterPlankTemplate = MasterPlankTemplate(theme, location);

	if (base?.leftColumn2?.transform?.grid) {
		base.leftColumn2.transform.grid.scaling = 4;
	}

	if (base?.rightColumn1?.transform?.grid) {
		base.rightColumn1.transform.grid.scaling = 2;
	}

	if (base.bottom?.transform?.border) {
		if (location === 'bottom' || location === 'single') {
			base.bottom.transform.border.radiusLocation = 'bottom';
			base.bottom.transform.border.radiusType = 'curved';
		}
	}

	return base;
};

let componentColorsLight: IComponentColors = {
	background: { color: BrandColors['tertiary-shade-light-2'] },
	text: { color: BrandColors['tertiary-shade-light-3'] },
	border: { color: BrandColors['none'] },
	icon: { color: BrandColors['tertiary-shade-light-3'] },
	active: {
		background: {
			color: BrandColors['tertiary-shade-light-2'],
			opacity: 0.8,
		},
		text: { color: BrandColors['tertiary-shade-light-3'] },
		icon: { color: BrandColors['tertiary-shade-light-3'], opacity: 0.8 },
		border: { color: BrandColors['none'] },
	},
	hover: {
		background: {
			color: BrandColors['tertiary-shade-light-2'],
			opacity: 0.8,
		},
		text: { color: BrandColors['tertiary-shade-light-3'], opacity: 1 },
		icon: { color: BrandColors['tertiary-shade-light-3'], opacity: 1 },
	},
	focus: {
		background: {
			color: BrandColors['tertiary-shade-light-2'],
			opacity: 0.8,
		},
		text: { color: BrandColors['tertiary-shade-light-3'], opacity: 1 },
		icon: { color: BrandColors['tertiary-shade-light-3'], opacity: 1 },
		outline: { color: BrandColors['primary-shade-light-2'] },
	},
	disabled: {
		background: {
			color: BrandColors['tertiary-shade-light-2'],
			opacity: 0.7,
		},
		text: { color: BrandColors['tertiary-shade-light-3'], opacity: 0.5 },
		icon: { color: BrandColors['tertiary-shade-light-3'], opacity: 0.5 },
	},
};

const _highlightPalette = {
	Light: componentColorsLight,
	Dark: componentColorsLight,
} as IThemedColors;

export const MS_MasterPlankHighlightedTemplate = (theme: Theme, location: LocationType) => {
	let base: IMasterPlankTemplate = MS_MasterPlankTemplate(theme, location);

	base.palette = _highlightPalette[theme];

	return base;
};
