import {sanityClient} from "./SanityClient";
import {MarketingConsent} from "../../../models/interfaces/sanity/MarketingConsent";
import {getBrandShorthand} from "../HelperService";

export async function getMarketingConsentText() : Promise<MarketingConsent[]> {
    let query = `*[_type == "consent" && brand->.systemName == '${getBrandShorthand()}'] {
      'consentId': systemName,
      title,
      description,
      partners[]->{
        name,
        description
      }
}`;

    return await sanityClient.fetch<MarketingConsent[]>(query);
}