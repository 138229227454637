import React, { useEffect, useState } from 'react';
import {
	BrandColors,
	ContentGrid,
	IconType,
	Text,
	Icon,
	StyleGrid,
	getCounterTheme,
} from '@fjordkraft/fjordkraft.component.library';
import { getPageContent } from './InvoiceFixedDueDatePageData';
import { MS_ButtonTemplate, h1TextPrefab, paragraphTextPrefab } from '../../../Prefabs';
import { ActionButton, MSRichText } from '../../../components';
import { useParams } from 'react-router-dom';
import { Constants } from '../../../data';
import { PopupCard } from '../../../modals';
import { setFixedDueDate, getText } from '../../../services';
import classNames from 'classnames';
import { IDefaultViewProps, PageV2 } from '../../PageV2';
import './InvoiceFixedDueDatePage.scss';

interface IStatusGraphic {
	icon: IconType;
	title: string;
	description: string;
}

interface IFixedDueDatePage extends IDefaultViewProps {
	content: {
		title: string;
		subTitle: string;
		desc: string;
		action: string;
	};
	popups: {
		success: {
			sub: {
				icon: IconType;
				title: string;
				description: string;
			};
			cancel: {
				icon: IconType;
				title: string;
				description: string;
			};
		};
		error: {
			sub: {
				icon: IconType;
				title: string;
				description: string;
			};
			cancel: {
				icon: IconType;
				title: string;
				description: string;
			};
		};
	};
}

export const InvoiceFixedDueDatePage = () => {
	// ************************************
	// Properties
	// ************************************

	const classPrefix = 'invoice-fixed-due-date-page';

	let { fixedDueDateStatus, accountId } = useParams<any>();

	// ************************************
	// Lifecycle
	// ************************************

	const [statusGraphic, setStatusGraphic] = useState<IStatusGraphic>();

	// ************************************
	// Async handling
	// ************************************

	const _handleDueDateUpdate = async (config: IFixedDueDatePage): Promise<any> => {
		const { popups, services } = config;

		let accId = accountId ?? '';
		let accAction: 'Order' | 'Cancel' = fixedDueDateStatus === '1' ? 'Cancel' : 'Order';
		let resp = await setFixedDueDate(accId, accAction, services.PUT);
		let isSubbed: boolean = accAction === 'Cancel';

		if (resp) {
			switch (resp.callState) {
				case 'success':
					setStatusGraphic(isSubbed ? popups.success.cancel : popups.success.sub);
					break;
				case 'error':
					setStatusGraphic(isSubbed ? popups.error.cancel : popups.error.sub);
					break;
			}
		}
	};

	// ************************************
	// Render Functionality
	// ************************************

	const _renderStatusModal = (status: IStatusGraphic, config: IFixedDueDatePage) => {
		const { activeBrand, activeTheme, desktopView, translations } = config;

		return (
			<PopupCard
				onClose={() => {
					setStatusGraphic(undefined);
				}}
				desktopView={desktopView}
				theme={activeTheme}
				brand={activeBrand}
				secondaryAction={{
					link: Constants.paths.invoicePage,
					text: getText('back', translations),
				}}
			>
				<StyleGrid
					className={`${classPrefix}__status__content`}
					alignment="center"
					direction="column"
					gap={3}
				>
					<StyleGrid
						className={`${classPrefix}__status__content__icon`}
						alignment="center"
						direction="row"
					>
						<Icon
							type={status.icon}
							width={desktopView ? 7 : 5}
							height={desktopView ? 7 : 5}
							brand={activeBrand}
							color={BrandColors['background-shade-light-1']}
						/>
					</StyleGrid>
					<Text
						{...h1TextPrefab()}
						color={BrandColors['primary-shade-light-2']}
						className={`${classPrefix}__status__content__title`}
						align={'align-center'}
					>
						{status.title}
					</Text>
					<MSRichText
						className={`${classPrefix}__status__content__desc`}
						text={status.description}
						theme={getCounterTheme(activeTheme)}
					/>
				</StyleGrid>
			</PopupCard>
		);
	};

	// ************************************
	// Render
	// ************************************

	return PageV2({
		setup: {
			pageType: Constants.epiServerPageNames.invoiceFixedDueDate.type,
			usesSubPage: true,
		},
		dependencies: [{ fixedDueDateStatus }, { accountId }],
		handleData: getPageContent,
		render: (config: IFixedDueDatePage) => {
			const { activeBrand, activeTheme, desktopView, services, content } = config;
			const { token } = services;

			return (
				<>
					<StyleGrid
						className={classPrefix}
						brand={activeBrand}
						theme={activeTheme}
						gap={4}
						alignment={'top-center'}
						direction={'column'}
					>
						<ContentGrid
							className={`${classPrefix}__content`}
							brand={activeBrand}
							theme={activeTheme}
							tagType={'article'}
							gap={4}
							alignment={desktopView ? 'top-center' : 'top-left'}
							direction={'column'}
						>
							<MSRichText
								className={classNames(`${classPrefix}__content__desc`, {
									[`${classPrefix}__content__desc--desktop`]: desktopView,
								})}
								{...paragraphTextPrefab()}
								text={content.desc}
								theme={getCounterTheme(activeTheme)}
							/>
							<ContentGrid
								className={`${classPrefix}__content_nav`}
								tagType="nav"
								alignment={desktopView ? 'top-center' : 'top-left'}
								direction="column"
							>
								<ActionButton
									template={MS_ButtonTemplate(activeTheme, 'primary')}
									padding="default"
									action={{
										text: content.action,
										onClick: () => {
											_handleDueDateUpdate(config);
										},
									}}
								/>
							</ContentGrid>
						</ContentGrid>
					</StyleGrid>
					{statusGraphic && _renderStatusModal(statusGraphic, config)}
				</>
			);
		},
	});
};
