import React, { ReactElement } from 'react';
import {
	Accordion,
	BaseComponent,
	ContentGrid,
	IAccordionItem,
	IComponent,
	Text,
	getCounterTheme,
} from '@fjordkraft/fjordkraft.component.library';
import { AccordionItemTemplate, h2TextPrefab, paragraphTextPrefab } from '../../Prefabs';
import { MSRichText } from '../MSRichText/MSRichText';
import classNames from 'classnames';
import './FAQ.scss';

export interface IQuestion {
	question: string;
	answer?: string;
	answerElement?: ReactElement;
}

export interface IFAQ extends IComponent {
	title?: string;
	questions: IQuestion[];
}

export const FAQ = (props: IFAQ) => {
	// ************************************
	// Properties
	// ************************************

	const { id, className, brand, theme = 'Light', title, questions } = props;
	const classPrefix = 'ms-faq';

	// ************************************
	// Render Content
	// ************************************

	const _renderContent = (content?: string, contentElement?: ReactElement) => {
		if (content) {
			return (
				<BaseComponent
					template={AccordionItemTemplate(theme)}
					className={`${classPrefix}__answer-container`}
				>
					<MSRichText
						text={content}
						theme={getCounterTheme(theme)}
					/>
				</BaseComponent>
			);
		} else if (contentElement) {
			return (
				<BaseComponent
					template={AccordionItemTemplate(theme)}
					className={`${classPrefix}__answer-container`}
				>
					{contentElement}
				</BaseComponent>
			);
		}
	};

	const _renderAccordionItem = () => {
		return questions.map((q: IQuestion) => {
			return {
				theme,
				brand,
				leftTitleContent: (
					<Text
						{...paragraphTextPrefab()}
						theme={getCounterTheme(theme)}
						brand={brand}
					>
						{q.question}
					</Text>
				),
				content: _renderContent(q.answer, q.answerElement),
			} as IAccordionItem;
		});
	};

	// ************************************
	// Render
	// ************************************

	return (
		<ContentGrid
			id={id}
			className={classNames(classPrefix, {
				[`${className}`]: className,
			})}
			direction="column"
			alignment="top-center"
			tagType="nav"
			gap={2}
		>
			{title && <Text {...h2TextPrefab()}>{title}</Text>}
			{questions && (
				<Accordion
					theme={theme}
					brand={brand}
					elements={_renderAccordionItem()}
				/>
			)}
		</ContentGrid>
	);
};
