import React, { useState, useEffect } from 'react';
import {
	Alignment,
	BaseComponent,
	BrandColors,
	IAction,
	IComponent,
	IComponentTemplate,
	IText,
	Icon,
	IconType,
	StyleGrid,
	StyleLoopLimit,
	Text,
	getPlankLocation,
} from '@fjordkraft/fjordkraft.component.library';
import { StatePlank } from '../StatePlank/StatePlank';
import { IBareDropdownTemplate } from '../BareDropdown/BareDropdown';
import { MS_MasterPlankTemplate } from '../../Prefabs/Templates/planks/MS_MasterPlankTemplate';
import { v4 as uuid4 } from 'uuid';
import { ITextPlankPrefab, MS_PlankDropdownTemplate, getPlankPrefab, paragraphTextPrefab } from '../../Prefabs';
import { useApplicationContext, useDefaultPageContext } from '../../contexts';
import { MsButton } from '../Buttons/Button';
import classNames from 'classnames';
import _ from 'lodash';
import './PlankDropdown.scss';
import { useLocation, useNavigate } from 'react-router';
import { isExternalUrl } from '../../services';

export interface IPlankDropdownItem extends ITextPlankPrefab {
	value: any;
	uniqueId?: string;
	activeTitle?: string;
}

export interface IPlankDropdown extends Omit<IComponent, 'palette' | 'template' | 'id'> {
	id: string;
	gridPlacement: Alignment;
	template?: IBareDropdownTemplate;
	genericDropdownStyle?: boolean;
	icon: IconType;
	customization?: {
		text?: IText;
		gap?: StyleLoopLimit;
		gapType?: 'px' | 'rem';
		iconColor?: BrandColors;
		iconBoxed?: boolean;
	};
	preActiveItem?: IPlankDropdownItem;
	items: IPlankDropdownItem[];
	includeActiveText?: boolean;
	includeActiveItem?: boolean;
	onSelectedChange?: (selected: ITextPlankPrefab) => void;
}

export const PlankDropdown = (props: IPlankDropdown) => {
	// ************************************
	// Properties
	// ************************************

	const { desktopView } = useApplicationContext();
	const { setContentLoading } = useDefaultPageContext();
	const {
		id,
		theme = 'Light',
		brand,
		className,
		template = MS_PlankDropdownTemplate(theme, desktopView),
		gridPlacement,
		genericDropdownStyle = false,
		icon,
		preActiveItem,
		customization = {
			text: paragraphTextPrefab(),
			gap: 2,
			gapType: 'px',
			iconColor: BrandColors['background-shade-light-2'],
			iconBoxed: false,
		},
		items,
		includeActiveText = true,
		includeActiveItem = false,
		onSelectedChange,
	} = props;
	const classPrefix = 'plank-dropdown';
	const navigate = useNavigate();
	const { pathname } = useLocation();

	// ************************************
	// Lifecycle
	// ************************************

	const [activeItem, setActiveItem] = useState<IPlankDropdownItem>();
	const [toggled, setTogggled] = useState<boolean>(false);

	useEffect(() => {
		if (preActiveItem || !activeItem) {
			setActiveItem(preActiveItem ?? items[0]);
		}
	}, []);

	useEffect(() => {
		document.addEventListener('mousedown', _handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', _handleClickOutside);
		};
	}, []);

	// ************************************
	// Helper Functionality
	// ************************************

	const _handleClickOutside = (event: any) => {
		if (event?.target?.id !== id) {
			setTogggled(false);
		}
	};

	const _onClickPlank = (plank: IPlankDropdownItem, useLink: boolean = false) => {
		if (onSelectedChange) {
			onSelectedChange(plank.value);
		}

		setTogggled(false);
		setActiveItem(plank);

		if (useLink) {
			let link: string = plank?.action?.link ?? '/';

			if(pathname !== link) {
				setContentLoading(true);
				navigate(link);
			} else {
				window.location.reload();
			}
		}
	};

	const _getParsedAction = (plank: IPlankDropdownItem): IAction | undefined => {
		if (plank?.action?.link && !isExternalUrl(plank.action.link)) {
			return {
				text: plank.action.text,
				icon: plank.action.icon,
				link: undefined,
				onClick: () => {
					_onClickPlank(plank, true);
				},
			};
		} else {
			return {
				...plank.action,
				...{
					onClick: (e) => {
						_onClickPlank(plank);
					},
				},
			};
		}
	};

	// ************************************
	// Template handling
	// ************************************

	const _handleContainerTemplate = (): IComponentTemplate => {
		let base: IComponentTemplate = template.container;

		if (base?.transform?.grid?.gapType && customization.gapType) {
			base.transform.grid.gapType = customization.gapType;
		}

		if (base?.transform?.grid?.gap && customization.gap) {
			base.transform.grid.gap = customization.gap;
		}

		return base;
	};

	// ************************************
	// Render Functionality
	// ************************************

	const _renderIcon = () => {
		let iconToUse: IconType | undefined;

		if (icon) {
			iconToUse = icon;
		} else if (genericDropdownStyle) {
			iconToUse = toggled ? IconType.ChevronUp : IconType.ChevronDown;
		}

		if (iconToUse) {
			return (
				<Icon
					className={classNames(`${classPrefix}__main-button__icon`, {
						[`${classPrefix}__main-button__icon--boxed`]: customization.iconBoxed,
					})}
					type={iconToUse}
					width={1.438}
					height={1.438}
					color={customization.iconColor}
				/>
			);
		}
	};

	const _renderItems = () => {
		if (items && activeItem) {
			let filteredItems: IPlankDropdownItem[] = _filterOutActiveItems(items, activeItem);

			return filteredItems.map((item: IPlankDropdownItem, i: number) => {
				let location = getPlankLocation(i, filteredItems.length, item);

				return (
					<StatePlank
						{...getPlankPrefab('Text', item)}
						id={id}
						key={uuid4()}
						action={_getParsedAction(item)}
						disabled={item.action?.disabled}
						location={location}
						template={item.template ?? MS_MasterPlankTemplate(theme, location)}
					/>
				);
			});
		}
	};

	const _filterOutActiveItems = (arr: IPlankDropdownItem[], active: IPlankDropdownItem) => {
		let elements: any[] = [];

		for (let item of arr) {
			if (activeItem && !includeActiveItem) {
				if (!_.isEqual(item.value, activeItem.value)) {
					elements.push(item);
				}
			} else {
				elements.push(item);
			}
		}

		return elements;
	};

	// ************************************
	// Render
	// ************************************

	return (
		<StyleGrid
			className={classNames(`${classPrefix}`, {
				[`${className}`]: className,
			})}
			alignment="center"
			direction="column"
			boxSizing="border-box"
			brand={brand}
		>
			<MsButton
				id={id}
				className={`${classPrefix}__main-button`}
				template={template.dropdown}
				action={{
					onClick: () => {
						setTogggled(!toggled);
					},
				}}
			>
				{includeActiveText && activeItem?.activeTitle && (
					<Text
						id={id}
						className={`${classPrefix}__main-button__text`}
						{...paragraphTextPrefab()}
						{...customization.text}
					>
						{activeItem?.activeTitle}
					</Text>
				)}
				{_renderIcon()}
			</MsButton>
			{toggled && (
				<BaseComponent
					id={id}
					className={classNames(`${classPrefix}__grid`, {
						[`${classPrefix}__grid--${gridPlacement}`]: gridPlacement,
					})}
					template={_handleContainerTemplate()}
					brand={brand}
				>
					{_renderItems()}
				</BaseComponent>
			)}
		</StyleGrid>
	);
};
