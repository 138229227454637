import React from 'react';
import {
    IComponent,
    IconType,
    NotificationBox,
    StyleGrid,
} from '@fjordkraft/fjordkraft.component.library';
import classnames from 'classnames';
import { v4 as uuid4 } from 'uuid';
import { MS_NotificationBoxTemplate } from '../../Prefabs';
import './NotificationBlock.scss';

type NotificationNoteType = 'Warning' | 'Note' | 'Error';

export interface INotificationNote {
    type: NotificationNoteType;
    title?: string;
    children?: React.ReactNode | string;
}

export interface INotificationBlock extends IComponent {
    notifications: INotificationNote[];
}

export const NotificationBlock = (props: INotificationBlock) => {
    // ************************************
    // Properties
    // ************************************

    const { id, className, theme = 'Light', brand, notifications } = props;
    const classPrefix = 'ms-notification-box';

    // ************************************
    // Render Functionality
    // ************************************

    const renderNotifications = () => {
        return notifications.map((item: INotificationNote) => {
            return (
                <NotificationBox
                    key={uuid4()}
                    className={`${classPrefix}__note`}
                    brand={brand}
                    theme={theme}
                    title={item.title}
                    template={MS_NotificationBoxTemplate(theme, item.type)}
                    icon={IconType.InfoBubble}
                    contentAlignment={'top-left'}
                    contentDirection={'column'}
                >
                    {item.children && (
                        <StyleGrid
                            brand={brand}
                            className={`${classPrefix}__note__container`}
                            alignment={'top-left'}
                            direction={'column'}
                        >
                            {item.children}
                        </StyleGrid>
                    )}
                </NotificationBox>
            );
        });
    };

    // ************************************
    // Render
    // ************************************

    return (
        <StyleGrid
            id={id}
            brand={brand}
            className={classnames(classPrefix, {
                [`${className}`]: className,
            })}
            direction="column"
            alignment="top-center"
            gap={1}
        >
            {renderNotifications()}
        </StyleGrid>
    );
};
