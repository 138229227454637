import React from 'react';
import {
    ContentGrid,
    IComponent,
    Text,
    getCounterTheme,
} from '@fjordkraft/fjordkraft.component.library';
import {
    DataPointCardTemplate,
    h3TextPrefab,
    paragraphTextPrefab,
} from '../../../Prefabs';
import { useApplicationContext } from '../../../contexts';
import { Card } from '../Card';
import { v4 as uuid4 } from 'uuid';
import classNames from 'classnames';
import { MSTooltip } from '../../../components';
import "./ServicePointsCard.scss";

export interface IDescriptionPoint {
    key: string;
    name: string;
    amount: number;
    amountSuffix: string;
    tooltip?: string;
}

export interface IServicePointsCard extends IComponent {
    points: IDescriptionPoint[];
}

export const ServicePointsCard = (props: IServicePointsCard) => {
    // ************************************
    // Properties
    // ************************************

    const { id, className, theme, brand, points } = props;
    const { desktopView } = useApplicationContext();
    const classPrefix = 'points-card';

    // ************************************
    // Render Functionality
    // ************************************

    const _renderPoints = () => {
        return points.map((data: IDescriptionPoint) => {
            let valueText =
                data.key === 'text'
                    ? `${data.amountSuffix ?? ''}`
                    : `${data.amount} ${data.amountSuffix ?? ''}`;

            return (
                <ContentGrid
                    className={`${classPrefix}__point`}
                    key={uuid4()}
                    direction="column"
                    alignment="top-left"
                    tagType="section"
                    gap={2}
                    brand={brand}
                    spaceBetween
                >
                    {data.key === 'title' && (
                        <Text
                            {...h3TextPrefab()}
                            brand={brand}
                            className={`${classPrefix}__point`}
                        >
                            {`${data.name}`}
                        </Text>
                    )}
                    {data.key !== 'title' && (
                        <ContentGrid
                            className={`${classPrefix}__point`}
                            direction="row"
                            alignment="top-left"
                            tagType="section"
                            gap={2}
                            brand={brand}
                            spaceBetween
                        >
                            <Text {...paragraphTextPrefab()} brand={brand}>
                                {`${data.name}`}
                            </Text>
                            <ContentGrid
                                tagType='section'
                                alignment='center-right'
                                boxSizing='border-box'
                                direction='row'
                                gap={1}
                            >
                                <Text
                                    {...paragraphTextPrefab()}
                                    brand={brand}
                                    weight={500}
                                >
                                    {valueText}
                                </Text>
                                {data.tooltip &&
                                    <MSTooltip
                                        className={`${classPrefix}__tooltip`}
                                        brand={brand}
                                        theme={getCounterTheme(theme)}
                                        text={data.tooltip}
                                        alignment={
                                            desktopView
                                                ? 'bottom-center'
                                                : 'bottom-left'
                                        }
                                    />
                                }
                            </ContentGrid>
                        </ContentGrid>
                    )}
                </ContentGrid>
            );
        });
    };

    // ************************************
    // Render
    // ************************************

    return (
        <Card
            id={id}
            className={classNames(classPrefix, {
                [`${className}`]: className,
            })}
            template={DataPointCardTemplate({ theme, desktopView })}
            brand={brand}
            gap={desktopView ? 2 : 0}
        >
            {_renderPoints()}
        </Card>
    );
};
