import { getRequest } from '../../contexts';
import { ICustomerInstallation } from '../../models';

export const fetchSpotPrice = async (
	GET: getRequest,
	installation: ICustomerInstallation,
	detail: 'current' | 'today' | 'tomorrow'
) => {
	let resp = await GET(`SpotPrice/${installation.address.priceArea}/${installation.address.postalCode}/${detail}`);

	return resp;
};

export const fetchSpotPriceHistory = async (GET: getRequest, installation: ICustomerInstallation) => {
	let toDate = new Date();
	let fromDate = new Date();
	fromDate.setMonth(toDate.getMonth() - 12);

	let resp = await GET(
		`SpotPrice/${installation.meterId}/history?from=${fromDate.toDateString()}&to=${toDate.toDateString()}`
	);

	return resp.data;
};

export const fetchSpotPriceManagedComparison = async (GET: getRequest, installation: ICustomerInstallation) => {
	let toDate = new Date();
	let fromDate = new Date();
	fromDate.setMonth(toDate.getMonth() - 12);

	let resp = await GET(
		`SpotPrice/${
			installation.meterId
		}/managedcomparison?from=${fromDate.toDateString()}&to=${toDate.toDateString()}`
	);

	return resp.data;
};
