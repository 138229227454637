import {
    BrandColors,
    CheckInputTemplate,
    IComponentTemplate,
    IComponentTransform,
    IThemedColors,
    Theme,
} from '@fjordkraft/fjordkraft.component.library';
import { Constants } from '../../../data';

// ************************************
// Palette
// ************************************

const _transform = (type: 'checkbox' | 'radio') => {
    return {
        width: '1.5rem',
        height: '1.5rem',
        border: {
            radiusType: type === 'checkbox' ? 'curved' : 'round',
            thickness: 2,
            type: 'solid',
        },
        grid: {
            direction: 'column',
            alignment: 'center',
            boxSizing: 'border-box',
            tagType: 'div',
        },
    } as IComponentTransform;
};

// ************************************
// Palette
// ************************************

const _palette = {
    Light: {
        background: { color: BrandColors['background-shade-light-1'] },
        text: { color: BrandColors['text-shade-dark-3'] },
        border: { color: BrandColors['border-shade-light-3'] },
        icon: { color: BrandColors['icon-shade-light-1'] },
        focus: {
            background: { color: BrandColors['background-shade-light-1'] },
            text: { color: BrandColors['text-shade-dark-3'] },
            border: { color: BrandColors['border-shade-light-1'] },
            icon: { color: BrandColors['icon-shade-light-1'] },
            outline: { color: BrandColors['primary-shade-light-2'] },
        },
        active: {
            background: { color: BrandColors['secondary-shade-light-1'] },
            text: { color: BrandColors['text-shade-dark-3'] },
            border: { color: BrandColors['secondary-shade-light-1'] },
            icon: { color: BrandColors['icon-shade-light-1'] },
        },
        activeHover: {
            background: {
                color: BrandColors['secondary-shade-light-1'],
                opacity: 0.8,
            },
            text: { color: BrandColors['text-shade-dark-3'], opacity: 0.8 },
            border: {
                color: BrandColors['secondary-shade-light-1'],
                opacity: 0.8,
            },
            icon: {
                color: BrandColors['icon-shade-light-1'],
                opacity: 0.8,
            },
        },
        hover: {
            background: {
                color: BrandColors['background-shade-light-1'],
                opacity: 0.8,
            },
            text: { color: BrandColors['text-shade-dark-3'], opacity: 0.8 },
            border: {
                color: BrandColors['border-shade-light-1'],
                opacity: 0.8,
            },
            icon: {
                color: BrandColors['icon-shade-light-1'],
                opacity: 0.8,
            },
        },
        disabled: {
            background: {
                color: BrandColors['background-shade-light-1'],
                opacity: 0.3,
            },
            text: {
                color: BrandColors['text-shade-dark-3'],
                opacity: 0.25,
            },
            border: {
                color: BrandColors['border-shade-light-1'],
                opacity: 0.25,
            },
            icon: {
                color: BrandColors['icon-shade-light-1'],
                opacity: 0.25,
            },
        },
    },
    Dark: {},
} as IThemedColors;

export const MSCheckInputTemplate = (
    theme: Theme,
    type: 'radio' | 'checkbox',
    brand: string = Constants.uiBrand
) => {
    let palette = _palette[theme];

    if(brand === 'brand-trondelagkraft') {
        if (palette.icon && palette.active?.icon?.color) {
            palette.icon.color = BrandColors['icon-shade-dark-1'];
            palette.active.icon.color = BrandColors['icon-shade-dark-1'];
        }
    } else if(brand === 'brand-fjordkraft') {
        if (palette.icon && palette.active?.icon?.color) {
            palette.icon.color = BrandColors['icon-shade-light-3'];
            palette.active.icon.color = BrandColors['icon-shade-light-3'];
        }
    }

    return {
        transform: _transform(type),
        palette
    } as IComponentTemplate;
};
