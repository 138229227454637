
import React from "react";
import { IBlock } from "../../models";
import {
    BrandColor,
    BrandColors,
    ContentGrid,
    getColorCode,
    IAction,
    IOption,
    SelectDropdown,
    Text
} from "@fjordkraft/fjordkraft.component.library";
import classNames from "classnames";
import { h3TextPrefab, MS_ButtonTemplate, paragraphTextPrefab } from "../../Prefabs";
import { ActionButton } from "../../components";
import { Constants } from "../../data";
import "./HighlightedDropdownBlock.scss";
import { useApplicationContext } from "../../contexts";

export interface IHighlightedDropdownBlock extends IBlock {
    title?: string;
    description?: string;
    action?: IAction;
    options: IOption[];
    dropDownPlaceholder?: string;
    customization?: {
        color?: {
            background: BrandColor;
        }
    },
    onChange: (selectedOption: IOption) => void;
}

export const HighlightedDropdownBlock = (props: IHighlightedDropdownBlock) => {
    const {
        id,
        className,
        theme = 'Light',
        brand = Constants.uiBrand,
        title,
        description,
        action,
        options,
        dropDownPlaceholder,
        customization = {
            color: {
                background: { color: BrandColors["action-shade-light-3"] }
            }
        },
        onChange
    } = props;
    const classPrefix = 'highlighted-dropdown-block';
    const dropdownId = `${classPrefix}-dropdown`;
    const { desktopView } = useApplicationContext();

    return (
        <ContentGrid
            id={id}
            className={classNames(classPrefix, {
                [`${className}`]: className
            })}
            tagType="article"
            direction="column"
            alignment={desktopView ? "top-left" : "center"}
            gap={2}
            boxSizing='border-box'
            style={{
                backgroundColor: getColorCode({
                    color: customization.color?.background.color ?? BrandColors["background-shade-light-1"],
                    opacity: customization.color?.background.opacity
                })
            }}
        >
            {title &&
                <Text {...h3TextPrefab()}>
                    {title}
                </Text>
            }
            {description &&
                <Text 
                    {...paragraphTextPrefab()} 
                    align={desktopView ? 'align-left' : 'align-center'}
                >
                    {description}
                </Text>
            }
            <SelectDropdown
                id={dropdownId}
                options={options}
                theme={theme}
                brand={brand}
                placeholder={dropDownPlaceholder}
                value={options[0]}
                onChange={(option) => {
                    onChange(option);
                }}
            />
            {action &&
                <ActionButton
                    action={action}
                    template={MS_ButtonTemplate(theme, 'primary')}
                    brand={brand}
                    padding="medium"
                    iconPlacement="Right"
                />
            }
        </ContentGrid>
    );
}