import {
    BrandColors,
    IComponentTemplate,
    IComponentTransform,
    IThemedColors,
    Theme,
} from '@fjordkraft/fjordkraft.component.library';

// ************************************
// Transform
// ************************************

const _transform = {
    width: '2.5rem',
    height: '3rem',
    padding: '0.5rem',
    border: {
        radiusType: 'curved',
        thickness: 2,
        type: 'solid',
    },
    outline: {
        thickness: 2,
        type: 'dashed',
    },
    grid: {
        direction: 'column',
        alignment: 'center',
        boxSizing: 'border-box',
    },
} as IComponentTransform;

// ************************************
// Palette
// ************************************

const _palette = {
    Light: {
        background: { color: BrandColors['background-shade-light-1'] },
        text: { color: BrandColors['text-shade-dark-3'] },
        icon: { color: BrandColors['text-shade-dark-3'] },
        border: { color: BrandColors['border-shade-light-1'] },
        outline: { color: BrandColors['background-shade-light-2'] },
        hover: {
            background: { color: BrandColors['background-shade-light-1'] },
            text: { color: BrandColors['text-shade-dark-3'] },
            icon: { color: BrandColors['text-shade-dark-3'] },
            border: { color: BrandColors['primary-shade-light-2'] },
            outline: { color: BrandColors['background-shade-light-2'] },
        },
        focus: {
            background: { color: BrandColors['background-shade-light-1'] },
            text: { color: BrandColors['text-shade-dark-3'] },
            icon: { color: BrandColors['text-shade-dark-3'] },
            border: { color: BrandColors['border-shade-light-1'] },
            outline: { color: BrandColors['primary-shade-light-2'] },
        },
    },
    Dark: {
        background: { color: BrandColors['background-shade-light-1'] },
        text: { color: BrandColors['text-shade-dark-3'] },
        icon: { color: BrandColors['text-shade-dark-3'] },
        border: { color: BrandColors['border-shade-light-1'] },
    },
} as IThemedColors;

export const ClampedDigitInputFieldTemplate = (theme: Theme) => {
    return {
        palette: _palette[theme],
        transform: _transform,
    } as IComponentTemplate;
};
