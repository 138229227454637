import { getCounterTheme, IComponent, IText } from '@fjordkraft/fjordkraft.component.library';
import { getBrand } from '../../services';

export const paragraphTextPrefab = (props?: IComponent) => {
	return {
		theme: getCounterTheme(props?.theme ?? 'Light'),
		brand: props?.brand ?? getBrand(),
		weight: 400,
		size: 'regular',
		applyLineHeight: true,
		family: 'main',
		type: 'p',
	} as IText;
};
