import React from 'react';
import {
    BrandColors,
    IMasterPlank,
    PlaceholderCube,
    PlaceholderLine,
    StyleGrid,
} from '@fjordkraft/fjordkraft.component.library';
import classNames from 'classnames';
import { v4 as uuid4 } from 'uuid';
import './PlaceholderPlankPrefab.scss';

export interface IPlaceholderPlankPrefab extends IMasterPlank {
    desktopView?: boolean;
}

export const PlaceholderPlankPrefab = (props: IPlaceholderPlankPrefab) => {
    // ************************************
    // Properties
    // ************************************

    const {
        id,
        className,
        theme = 'Light',
        brand,
        template,
    } = props;
    const classPrefix = 'ms-placeholder-plank';

    // ************************************
    // Render
    // ************************************

    return {
        id,
        className: classNames(classPrefix, {
            [`${className}`]: className
        }),
        brand,
        template,
        leftColumn1: [
            <StyleGrid
                key={uuid4()}
                className={`${classPrefix}__column`}
                direction='column'
                alignment='center-left'
            >
                <PlaceholderCube
                    intensity='weak'
                    graphicSize={2}
                    color={BrandColors["primary-shade-dark-2"]}
                    theme={theme}
                    brand={brand}
                />
            </StyleGrid>
        ],
        leftColumn2: [
            <StyleGrid
                key={uuid4()}
                className={`${classPrefix}__column`}
                direction='column'
                alignment='center-left'
                wrap={false}
                gap={1}
            >
                <PlaceholderLine
                    graphicWidth={4}
                    graphicHeight={1}
                    color={BrandColors['text-shade-dark-2']}
                    theme={theme}
                    brand={brand}
                    intensity='weak'
                />
                <PlaceholderLine
                    color={BrandColors['text-shade-light-3']}
                    graphicWidth={6}
                    graphicHeight={1}
                    theme={theme}
                    brand={brand}
                    intensity='weak'
                />
            </StyleGrid>
        ],
        rightColumn1: [
            <StyleGrid
                key={uuid4()}
                className={`${classPrefix}__column`}
                direction='column'
                alignment='center-right'
                wrap={false}
                gap={1}
            >
                <PlaceholderLine
                    graphicWidth={4}
                    graphicHeight={1}
                    color={BrandColors['text-shade-dark-2']}
                    theme={theme}
                    brand={brand}
                    intensity='weak'
                />
                <PlaceholderLine
                    color={BrandColors['text-shade-light-3']}
                    graphicWidth={6}
                    graphicHeight={1}
                    theme={theme}
                    brand={brand}
                    intensity='weak'
                />
            </StyleGrid>
        ],
        rightColumn2: [
            <StyleGrid
                key={uuid4()}
                className={`${classPrefix}__column`}
                direction='column'
                alignment='center-left'
            >
                <PlaceholderCube
                    intensity='weak'
                    graphicSize={2}
                    color={BrandColors["icon-shade-light-1"]}
                    theme={theme}
                    brand={brand}
                />
            </StyleGrid>
        ]
    } as IMasterPlank;
};
