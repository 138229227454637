import React from 'react';
import {
    Text,
    LoaderGraphic,
    IconType,
    BaseComponent,
    IBaseComponent,
} from '@fjordkraft/fjordkraft.component.library';
import classnames from 'classnames';
import { LoadingModalTemplate } from './LoadingModalTemplate';
import { h2TextPrefab, h3TextPrefab } from '../../Prefabs';
import './LoadingModal.scss';

export interface ILoadingModal extends IBaseComponent {
    title?: string;
    subTitle?: string;
    icon?: IconType;
    mode?: 'window' | 'fit' | 'fit-absolute';
    delayedText?: boolean;
}

/**
 *   The loading modal is a basic screen covering modal used to
 *   display a loading graphic with various options.
 *   @type {title} title (optional)
 *   @type {string} subTitle (optional)
 *   @type {IconType} icon (optional)
 *   @type {string} mode (optional)
 *   @type {boolean} delayedText (optional)
 */
export const LoadingModal = (props: ILoadingModal) => {
    // ************************************
    // Properties
    // ************************************

    const {
        id,
        className,
        title,
        subTitle,
        mode = 'window',
        theme = 'Light',
        brand = import.meta.env.REACT_APP_UI_BRAND,
        template = LoadingModalTemplate(mode, theme),
    } = props;

    const classPrefix = 'ms-loading-modal';

    // ************************************
    // Render
    // ************************************

    return (
        <BaseComponent
            id={id}
            className={classnames(classPrefix, {
                [`${brand}`]: brand,
                [`${classPrefix}--${theme}`]: theme,
                [`${classPrefix}--${mode}`]: mode,
                [`${className}`]: className,
            })}
            template={template}
        >
            <LoaderGraphic
                className={`${classPrefix}__graphic`}
                theme={theme}
                brand={brand}
                scaleThickness={mode === 'window' ? 1.25 : 1}
                size={mode === 'window' ? 8 : 6}
            />
            {title && <Text {...h2TextPrefab()}>{title}</Text>}
            {subTitle && <Text {...h3TextPrefab()}>{subTitle}</Text>}
        </BaseComponent>
    );
};
