import { useContext, createContext } from 'react';
import { IDefaultProps, PageType } from '../../models';
import { Constants } from '../../data';

export interface IApplicationDefaultSetup {
	page: PageType;
	subPage?: string;
}

interface IApplicationDefaultContext {
	defaultProps: IDefaultProps;
}

export const ApplicationDefaultContext = createContext<IApplicationDefaultContext>({
	defaultProps: {
		activeBrand: Constants.uiBrand,
		activeTheme: Constants.uiTheme,
	} as any,
});

export const useApplicationDefaultContext = () => useContext(ApplicationDefaultContext);
