import { BrandColors } from "@fjordkraft/fjordkraft.component.library";

export const BUTTON_TEMPLATE_CTA_URGENT = {
    "brand-fjordkraft": {
        Light: {
            background: { color: BrandColors['status-shade-light-3'] },
            text: { color: BrandColors['text-shade-light-1'] },
            border: { color: BrandColors['status-shade-light-3'] },
            icon: { color: BrandColors['text-shade-light-1'] },
            hover: {
                background: {
                    color: BrandColors['status-shade-light-3'],
                    opacity: 0.8,
                },
                text: { color: BrandColors['text-shade-light-1'], opacity: 0.8 },
                border: {
                    color: BrandColors['status-shade-light-3'],
                    opacity: 0.8,
                },
                icon: { color: BrandColors['text-shade-light-1'], opacity: 0.8 },
            },
            focus: {
                background: {
                    color: BrandColors['status-shade-light-3'],
                    opacity: 0.8,
                },
                text: { color: BrandColors['text-shade-light-1'], opacity: 0.8 },
                border: {
                    color: BrandColors['status-shade-light-3'],
                    opacity: 0.8,
                },
                icon: { color: BrandColors['text-shade-light-1'], opacity: 0.8 },
                outline: { color: BrandColors['primary-shade-light-2'] },
            },
            disabled: {
                background: {
                    color: BrandColors['status-shade-light-3'],
                    opacity: 0.3,
                },
                text: { color: BrandColors['text-shade-light-1'], opacity: 0.3 },
                border: {
                    color: BrandColors['status-shade-light-3'],
                    opacity: 0.3,
                },
                icon: { color: BrandColors['text-shade-light-1'], opacity: 0.3 },
            },
        },
        Dark: {
            background: { color: BrandColors['status-shade-light-3'] },
            text: { color: BrandColors['text-shade-light-1'] },
            border: { color: BrandColors['status-shade-light-3'] },
            icon: { color: BrandColors['text-shade-light-1'] },
            hover: {
                background: {
                    color: BrandColors['status-shade-light-3'],
                    opacity: 0.8,
                },
                text: { color: BrandColors['text-shade-light-1'], opacity: 0.8 },
                border: {
                    color: BrandColors['status-shade-light-3'],
                    opacity: 0.8,
                },
                icon: { color: BrandColors['text-shade-light-1'], opacity: 0.8 },
            },
            focus: {
                background: {
                    color: BrandColors['status-shade-light-3'],
                    opacity: 0.8,
                },
                text: { color: BrandColors['text-shade-light-1'], opacity: 0.8 },
                border: {
                    color: BrandColors['status-shade-light-3'],
                    opacity: 0.8,
                },
                icon: { color: BrandColors['text-shade-light-1'], opacity: 0.8 },
                outline: { color: BrandColors['primary-shade-light-2'] },
            },
            disabled: {
                background: {
                    color: BrandColors['status-shade-light-3'],
                    opacity: 0.3,
                },
                text: { color: BrandColors['text-shade-light-1'], opacity: 0.3 },
                border: {
                    color: BrandColors['status-shade-light-3'],
                    opacity: 0.3,
                },
                icon: { color: BrandColors['text-shade-light-1'], opacity: 0.3 },
            },
        }
    }
};