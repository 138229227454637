import { BrandColors, NoticeBubbleTemplate, Theme } from '@fjordkraft/fjordkraft.component.library';


export const InvoiceIconBubbleTemplate = (theme: Theme) => {
	let base = NoticeBubbleTemplate(theme);

	base.palette.icon = { color: BrandColors["primary-shade-light-2"]}

	return base;
};
