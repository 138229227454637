import React from 'react';
import { ContentGrid, Text, Theme, getCounterTheme } from '@fjordkraft/fjordkraft.component.library';
import { IPointCard, MSRichText, PointCard } from '../../components';
import { IBlock } from '../../models';
import { Constants } from '../../data';
import classNames from 'classnames';
import { h3TextPrefab } from '../../Prefabs';
import { useApplicationContext } from '../../contexts';
import './DetailsDescriptionBlock.scss';

export interface IDetailsDescriptionBlock extends IBlock {
	title?: string;
	description?: string;
	points: IPointCard;
	cardTheme?: Theme;
}

export const DetailsDescriptionBlock = (props: IDetailsDescriptionBlock) => {
	// ************************************
	// Lifecycle
	// ************************************

	const {
		id,
		className,
		theme = 'Light',
		brand = Constants.uiBrand,
		title,
		description,
		points,
		cardTheme = theme,
	} = props;
	const classPrefix = 'details-description-block';
	const { desktopView } = useApplicationContext();

	// ************************************
	// Render
	// ************************************

	return (
		<ContentGrid
			id={id}
			className={classNames(classPrefix, {
				[`${className}`]: className,
			})}
			direction="row"
			alignment="top-left"
			boxSizing="border-box"
			gap={4}
			tagType="article"
			spaceBetween
		>
			<ContentGrid
				className={classNames(`${classPrefix}__content`, {
					[`${className}__content`]: className,
				})}
				direction="column"
				alignment="top-left"
				gap={2}
				tagType="section"
				boxSizing="border-box"
				scaling={desktopView ? 3 : undefined}
			>
				{title && (
					<Text
						{...h3TextPrefab()}
						theme={getCounterTheme(theme)}
						brand={brand}
						align="align-left"
					>
						{title}
					</Text>
				)}
				{description && (
					<MSRichText
						text={description}
						theme={getCounterTheme(theme)}
						brand={brand}
						alignment="lawful"
					/>
				)}
			</ContentGrid>
			<PointCard
				{...points}
				className={classNames(`${classPrefix}__points`, {
					[`${className}__points`]: className,
				})}
				theme={cardTheme}
				brand={brand}
				scaling={desktopView ? 2 : undefined}
			/>
		</ContentGrid>
	);
};
