import React from 'react';
import {
	Alignment,
	BaseComponent,
	BrandColors,
	ContentGrid,
	IAction,
	IComponent,
	IModalTemplate,
	IconType,
	StyleGrid,
	StyleLoopLimit,
	Text,
	getCounterTheme,
} from '@fjordkraft/fjordkraft.component.library';
import { ActionButton } from '../../components';
import classNames from 'classnames';
import {
	ButtonTypes,
	MS_ButtonTemplate,
	PopupModalTemplate,
	h1TextPrefab,
	h3TextPrefab
} from '../../Prefabs';
import { useApplicationContext } from '../../contexts';
import './PopupCard.scss';

export interface IPopupCard extends IComponent {
	contentTitle?: string;
	contentSubTitle?: string;
	contentSubTitleAlignment?: 'align-left' | 'align-center' | 'align-right';
	contentTitleAlignment?: 'align-left' | 'align-center' | 'align-right';
	contentAlignment?: Alignment;
	contentGap?: StyleLoopLimit;
	template?: IModalTemplate;
	primaryActionStyle?: ButtonTypes;
	secondaryActionStyle?: ButtonTypes;
	onClose?: () => void;
	showCloseButton?: boolean;
	primaryAction?: IAction;
	secondaryAction?: IAction;
	desktopView?: boolean;
	navigationContent?: any;
}

export const PopupCard = (props: IPopupCard) => {
	// ************************************
	// Properties
	// ************************************

	const { desktopView } = useApplicationContext();
	const {
		theme = 'Light',
		brand,
		onClose,
		showCloseButton = true,
		children,
		className,
		navigationContent,
		contentTitle,
		contentTitleAlignment = 'align-left',
		contentSubTitle,
		contentSubTitleAlignment = 'align-left',
		contentAlignment,
		contentGap = 0,
		primaryActionStyle = 'primary',
		secondaryActionStyle = 'secondary',
		primaryAction,
		secondaryAction,
		template = PopupModalTemplate(theme, desktopView),
	} = props;
	const classPrefix = 'ms-popup-card';

	// ************************************
	// Render Functionality
	// ************************************

	const _renderCoreNav = () => {
		if (secondaryAction || primaryAction) {
			return (
				<StyleGrid
					className={`${classPrefix}__card__navigation-wrapper`}
					direction='column'
					alignment='top-left'
					boxSizing='border-box'
					gap={2}
				>
					{navigationContent}
					<ContentGrid
						className={`${classPrefix}__card__navigation`}
						tagType="nav"
						direction="row"
						alignment="center"
						wrap={false}
						gap={4}
					>
						{secondaryAction && (
							<ActionButton
								brand={brand}
								template={MS_ButtonTemplate(theme, secondaryActionStyle)}
								padding={desktopView ? 'default' : 'medium'}
								action={secondaryAction}
							/>
						)}
						{primaryAction && (
							<ActionButton
								brand={brand}
								template={MS_ButtonTemplate(theme, primaryActionStyle)}
								padding={desktopView ? 'default' : 'medium'}
								action={primaryAction}
							/>
						)}
					</ContentGrid>
				</StyleGrid>
			);
		}
	}

	const _renderHeader = () => {
		if (onClose && showCloseButton) {
			return (
				<ContentGrid
					className={`${classPrefix}__card__nav`}
					tagType="nav"
					alignment="center-right"
					direction="row"
					boxSizing="border-box"
				>
					<ActionButton
						brand={brand}
						action={{
							onClick: () => {
								onClose();
							},
							icon: IconType.Close,
						}}
						template={MS_ButtonTemplate(theme, 'icon')}
						iconPlacement="Right"
					/>
				</ContentGrid>
			);
		}
	}

	const _renderContentHeader = () => {
		if (contentTitle || contentSubTitle) {
			return (
				<ContentGrid
					className={`${classPrefix}__card__header`}
					direction="column"
					alignment={contentTitle ? 'top-left' : 'top-center'}
					tagType="section"
					boxSizing="border-box"
				>
					{contentTitle && (
						<Text
							{...h1TextPrefab()}
							className={`${classPrefix}__content-title`}
							color={BrandColors['primary-shade-light-2']}
							align={contentTitleAlignment}
						>
							{contentTitle}
						</Text>
					)}
					{contentSubTitle && (
						<Text
							{...h3TextPrefab()}
							className={`${classPrefix}__content-sub-title`}
							theme={getCounterTheme(theme)}
							align={contentSubTitleAlignment}
							weight={500}
							faded
						>
							{contentSubTitle}
						</Text>
					)}
				</ContentGrid>
			);
		}
	}

	// ************************************
	// Render 
	// ************************************

	return (
		<StyleGrid
			className={classNames(`${classPrefix}`, {
				[`${classPrefix}--${theme}`]: theme,
				[`${className}`]: className,
			})}
			direction='column'
			alignment={desktopView ? 'center' : 'bottom-center'}
			boxSizing='border-box'
		>
			<BaseComponent
				className={`${classPrefix}__card`}
				brand={brand}
				theme={theme}
				template={template}
			>
				{_renderHeader()}
				<StyleGrid
					id={`${classPrefix}__card__content-wrapper`}
					className={`${classPrefix}__card__content-wrapper`}
					direction="column"
					alignment={contentTitle ? 'top-left' : 'top-center'}
					gap={contentTitle ? 2 : 0}
					boxSizing='border-box'
					wrap={false}
				>
					{_renderContentHeader()}
					<ContentGrid
						className={`${classPrefix}__card__content`}
						direction="column"
						alignment={contentAlignment ?? contentTitle ? 'top-left' : 'top-center'}
						gap={contentGap}
						boxSizing='border-box'
						tagType='section'
					>
						{children}
					</ContentGrid>
					{!desktopView && _renderCoreNav()}
				</StyleGrid>
				{desktopView && _renderCoreNav()}
			</BaseComponent>
		</StyleGrid>
	);
};
