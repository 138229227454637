import React, { useState, useEffect } from 'react';
import { IPopupCard, PopupCard } from '../PopupCard/PopupCard';
import { IResponse, getText } from '../../services';
import { Text } from '@fjordkraft/fjordkraft.component.library';
import { paragraphTextPrefab } from '../../Prefabs';
import { useApplicationContext, useApplicationServicehandlerContext } from '../../contexts';
import { ICustomerAccountInformation, IPage } from '../../models';

export interface IDirectDebitModal extends IPopupCard {
	translations: IPage;
	account: ICustomerAccountInformation;
}

export const DirectDebitModal = (props: IDirectDebitModal) => {
	// ************************************
	// Properties
	// ************************************

	const { activeTheme, activeBrand, desktopView } = useApplicationContext();
	const { FORMPOST, GET } = useApplicationServicehandlerContext();
	const [directDebitForm, setDirectDebitForm] = useState<any>();
	const { translations, account, onClose } = props;

	useEffect(() => {
		_fetchFormAttributes();
	}, []);
	// ************************************
	// Async
	// ************************************

	const _fetchFormAttributes = async () => {
		let resp: IResponse = await GET(`Account/${account.accountId}/avtaleGiro/form`);
		setDirectDebitForm(resp.data);
	};

	// ************************************
	// Helpers
	// ************************************

	const _handleOnSubmitDirectDebit = () => {
		if (directDebitForm) {
			FORMPOST(directDebitForm.url, [
				{
					name: 'companyAccountNo',
					value: directDebitForm.fields.companyAccountNo,
				},
				{
					name: 'companyName',
					value: directDebitForm.fields.companyName,
				},
				{
					name: 'kid',
					value: directDebitForm.fields.kid,
				},
				{
					name: 'notificationDisabled',
					value: directDebitForm.fields.notificationDisabled,
				},
				{
					name: 'returnUrl',
					value: directDebitForm.fields.returnUrl,
				},
			]);
		}
	};

	// ************************************
	// Render
	// ************************************

	return (
		<PopupCard
			{...props}
			contentTitle={getText('directDebitConfirmationPopupTitle', translations)}
			contentGap={2}
			brand={activeBrand}
			theme={activeTheme}
			primaryAction={{
				text: getText('directDebitConfirmationPopupSubmit', translations),
				onClick: () => {
					_handleOnSubmitDirectDebit();
				},
			}}
			secondaryAction={{
				text: getText('directDebitConfirmationPopupCancel', translations),
				onClick: () => {
					if (onClose) {
						onClose();
					}
				},
			}}
			desktopView={desktopView}
		>
			<Text {...paragraphTextPrefab()}>{getText('directDebitConfirmationPopupDesc', translations)}</Text>
		</PopupCard>
	);
};
