import {
	BrandColors,
	IComponentTemplate,
	IComponentTransform,
	IThemedColors,
	Theme,
} from '@fjordkraft/fjordkraft.component.library';

export interface IDataPointCardTemplate {
	theme?: Theme;
	desktopView?: boolean;
	maxWidth?: boolean;
	hasPlanks?: boolean;
}

// ************************************
// Transform
// ************************************

const _transform = (config: IDataPointCardTemplate) => {
	const { desktopView = true, maxWidth = false, hasPlanks = false } = config;

	return {
		height: 'auto',
		width: desktopView && !maxWidth ? 'auto' : '100%',
		border: {
			radiusType: 'curved',
			radiusLocation: hasPlanks ? 'top' : undefined,
		},
		padding: '2rem',
		grid: {
			direction: 'column',
			alignment: 'top-left',
			gap: 2,
			tagType: 'article',
			boxSizing: 'border-box',
		},
	} as IComponentTransform;
};
// ************************************
// Palette
// ************************************

const _palette = {
	Light: {
		background: { color: BrandColors['action-shade-light-3'] },
		text: { color: BrandColors['text-shade-dark-3'] },
		border: { color: BrandColors['none'] },
		icon: { color: BrandColors['none'] },
	},
	Dark: {
		background: { color: BrandColors['action-shade-dark-2'] },
		text: { color: BrandColors['text-shade-light-1'] },
		border: { color: BrandColors['none'] },
		icon: { color: BrandColors['none'] },
	},
} as IThemedColors;

// ************************************
// Export
// ************************************

export const DataPointCardTemplate = (config: IDataPointCardTemplate) => {
	const { theme = 'Light' } = config;

	return {
		dontUpdateChildren: true,
		palette: _palette[theme],
		transform: _transform(config),
	} as IComponentTemplate;
};
