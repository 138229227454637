import React, { useState } from 'react';
import { IPlankHouse, PlankHouseBlock } from '../../../blocks';
import { getPageContent } from './GuestAdminPageData';
import { Constants } from '../../../data';
import { GuestAdminPopup, IGuestAdminPopupData } from '../../../modals';
import { IDefaultViewProps, PageV2 } from '../../PageV2';
import { useSearchParams } from 'react-router-dom';

export interface IGuestAdminPage extends IDefaultViewProps {
    house: IPlankHouse;
}

export const GuestAdminPage = () => {

    // ************************************
    // Lifecycle
    // ************************************

    const [popupData, setPopupData] = useState<IGuestAdminPopupData>();
    const [queryParams, setQueryParams] = useSearchParams();

    // ************************************
    // Render
    // ************************************

    return PageV2({
        setup: {
            pageType: Constants.epiServerPageNames.guests.type,
            usesSubPage: true,
            pageClamp: 'clamp'
        },
        dependenciesOptional: [
            { popupData },
            { queryParams }
        ],
        dependencies: [
            { setPopupData },
        ],
        handleData: getPageContent,
        render: (config: IGuestAdminPage) => {
            const { house, activeBrand, activeTheme } = config;

            return (
                <>
                    <PlankHouseBlock
                        {...house}
                        theme={activeTheme}
                        brand={activeBrand}
                    />
                    {popupData &&
                        <GuestAdminPopup
                            {...popupData}
                            onClose={() => {
                                setQueryParams(undefined);
                                setPopupData(undefined);
                            }}
                            theme={activeTheme}
                            brand={activeBrand}
                        />
                    }
                </>
            );
        }
    })
}