import React from 'react';
import classnames from 'classnames';
import {
    IComponent,
    Text,
    getCounterTheme,
    IChartText,
    StyleGrid,
    AsyncStateButton,
    CallState,
    ContentGrid,
} from '@fjordkraft/fjordkraft.component.library';
import { useApplicationContext } from '../../../contexts';
import { ILegend } from '../../Legend/Legend';
import { LegendBlock } from '../../../blocks/LegendsBlock/LegendsBlock';
import { ChartCardTemplate } from './ChartCardTemplate';
import { Card } from '../Card';
import { AsyncStateButtonTemplates, h2TextPrefab, h4TextPrefab, paragraphTextPrefab } from '../../../Prefabs';
import './ChartCard.scss';

export interface IChartCard extends IComponent {
    cardHeader?: IChartText;
    cardTopLeftText?: IChartText;
    cardTopRightText?: IChartText;
    description?: string;
    legends?: ILegend[];
    fallback?: {
        content: React.ReactNode;
        show: boolean;
    };
    csvData?: {
        fetchCsvFunc: () => Promise<CallState>;
        buttonTitle: string;
    };
    showEstimatedView?: boolean;
}

export const ChartCard = (props: IChartCard) => {
    // ************************************
    // Properties
    // ************************************

    const {
        id,
        className,
        theme,
        brand,
        cardHeader,
        cardTopLeftText,
        cardTopRightText,
        children,
        description,
        legends,
        fallback,
        csvData,
        showEstimatedView = false,
    } = props;
    const classPrefix = 'ms-chart-card';
    const { desktopView } = useApplicationContext();

    // ************************************
    // Render Functionality
    // ************************************

    const _renderHeader = () => {
        if (cardHeader && (!fallback || fallback?.show === false)) {
            return (
                <ContentGrid
                    className={`${classPrefix}__header`}
                    direction="column"
                    alignment="center-left"
                    gap={1}
                    tagType='section'
                >
                    <Text
                        {...h4TextPrefab()}
                        theme={getCounterTheme(theme)}
                        brand={brand}
                    >
                        {cardHeader.title}
                    </Text>
                    <Text
                        {...paragraphTextPrefab()}
                        theme={getCounterTheme(theme)}
                        brand={brand}
                    >
                        {cardHeader.subTitle}
                    </Text>
                </ContentGrid>
            );
        }
    };

    const _renderSubHeader = () => {
        if (fallback?.show === false) {
            return (
                <StyleGrid
                    className={`${classPrefix}__sub-header`}
                    direction={desktopView ? 'row' : 'column'}
                    alignment={desktopView ? 'center' : 'top-left'}
                    gap={desktopView ? 0 : 3}
                    spaceBetween
                >
                    {cardTopLeftText && (
                        <ContentGrid
                            className={`${classPrefix}__sub-header__left`}
                            direction="column"
                            alignment="center-left"
                            gap={1}
                            gapType={desktopView ? 'rem' : 'px'}
                            tagType='section'
                        >
                            <ContentGrid
                                alignment="bottom-left"
                                direction="row"
                                gap={4}
                                gapType={'px'}
                                tagType={'section'}
                            >
                                <Text
                                    {...h2TextPrefab()}
                                    weight={700}
                                    brand={brand}
                                    theme={getCounterTheme(theme)}
                                >
                                    {cardTopLeftText.title}
                                </Text>
                                <Text
                                    {...h4TextPrefab()}
                                    className={`${classPrefix}__sub-header__left__suffix`}
                                    brand={brand}
                                    theme={getCounterTheme(theme)}
                                >
                                    {cardTopLeftText.titleSuffix}
                                </Text>
                            </ContentGrid>
                            <Text
                                {...paragraphTextPrefab()}
                                brand={brand}
                                theme={getCounterTheme(theme)}
                            >
                                {cardTopLeftText.subTitle}
                            </Text>
                        </ContentGrid>
                    )}
                    {cardTopRightText && (
                        <ContentGrid
                            className={`${classPrefix}__sub-header__right`}
                            direction="column"
                            alignment="center-right"
                            gap={1}
                            gapType={desktopView ? 'rem' : 'px'}
                            tagType='section'
                        >
                            <ContentGrid
                                alignment="bottom-right"
                                direction="row"
                                gap={1}
                                tagType='section'
                            >
                                <Text
                                    {...h2TextPrefab()}
                                    brand={brand}
                                    theme={getCounterTheme(theme)}
                                    faded
                                >
                                    {cardTopRightText.title}
                                </Text>
                                <Text
                                    {...h4TextPrefab()}
                                    className={`${classPrefix}__sub-header__right__suffix`}
                                    brand={brand}
                                    theme={getCounterTheme(theme)}
                                    faded
                                >
                                    {cardTopRightText.titleSuffix}
                                </Text>
                            </ContentGrid>
                            <Text
                                size={desktopView ? 'regular' : 'small'}
                                type="p"
                                family="main"
                                weight={400}
                                brand={brand}
                                theme={getCounterTheme(theme)}
                                faded
                            >
                                {cardTopRightText.subTitle}
                            </Text>
                        </ContentGrid>
                    )}
                </StyleGrid>
            );
        }
    };

    const _renderChart = () => {
        if (!fallback || fallback?.show === false) {
            return (
                <StyleGrid
                    className={`${classPrefix}__content`}
                    direction="column"
                    alignment="center-left"
                >
                    {children}
                    {legends && (
                        <LegendBlock
                            className={`${classPrefix}__legends`}
                            direction="row"
                            alignment={'center'}
                            theme={theme}
                            brand={brand}
                            legends={legends}
                        />
                    )}
                    {csvData && !showEstimatedView && (
                        <AsyncStateButton
                            className={`${classPrefix}__download-button`}
                            theme={theme}
                            brand={brand}
                            asyncOnClick={csvData.fetchCsvFunc}
                            templates={AsyncStateButtonTemplates()}
                            action={{
                                text: csvData.buttonTitle,
                            }}
                        />
                    )}
                </StyleGrid>
            );
        }
    };

    const _renderFallback = () => {
        if (fallback?.content && fallback.show) {
            return <>{fallback.content}</>;
        }
    };

    // ************************************
    // Render
    // ************************************

    return (
        <Card
            id={id}
            className={classnames(classPrefix, {
                [`${className}`]: className,
            })}
            brand={brand}
            theme={theme}
            template={ChartCardTemplate(theme)}
        >
            {_renderHeader()}
            {_renderSubHeader()}
            {fallback && _renderFallback()}
            {_renderChart()}
            {description && description.length > 0 && (
                <Text
                    {...paragraphTextPrefab()}
                    className={`${classPrefix}__description`}
                    brand={brand}
                    theme={getCounterTheme(theme)}
                    faded
                >
                    {description}
                </Text>
            )}
        </Card>
    );
};
