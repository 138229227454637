import { Constants } from '../../../../data';
import { IBenefitSummary, IDefaultProps } from '../../../../models';
import { getText } from '../../../../services';
import { fetchKickbackSummary } from '../../../../services/collection/BenefitService';

export interface IUsePointsInvoicePageData extends IDefaultProps {
}

export const getPageContent = async (config: IUsePointsInvoicePageData) => {
	const { translations, services } = config;
	const { GET } = services;

	if (translations && GET !== undefined) {
		let summary: IBenefitSummary = await fetchKickbackSummary(GET);

		return {
			...config,
			sub: {
				title: getText('onInvoiceTitle', translations),
				back: {
					text: getText('usePoints', translations),
					link: Constants.paths.usePointsPage,
				},
			},
			availablePoints: summary.amount,
		};
	}
};
