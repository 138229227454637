import React from 'react';
import { IMsButton, MsButton } from '../Buttons/Button';

export interface ISliderItemData extends Omit<IMsButton, | 'action' | 'stateChange'> {
    value: any;
    onClick: () => void;
}

export interface ISliderItem extends IMsButton {
    slideToSelect: boolean;
    width?: number;
}

export const SliderItem = (props: ISliderItem) => {
    const { action, theme = 'Light', width, slideToSelect, children } = props;

    const _getWidth = () => {
        return width ? `${width}px` : undefined;
    };

    return (
        <MsButton
            {...props}
            style={{
                width: _getWidth(),
                flex: slideToSelect ? `0 0 ${_getWidth()}` : undefined,
            }}
            action={action}
        >
            {children}
        </MsButton>
    );
};
