import React from 'react';
import {
    IComponent,
    FlexGridAligner,
    Text,
    getCounterTheme,
    BrandColors,
} from '@fjordkraft/fjordkraft.component.library';
import classnames from 'classnames';
import { useApplicationContext } from '../../contexts';
import './ListItemSponsorTag.scss';

export interface IListItemSponsorTag extends IComponent {
    text: string;
    rightAlign?: boolean;
    backgroundColor: BrandColors;
}

export const ListItemSponsorTag = (props: IListItemSponsorTag) => {
    // ************************************
    // Properties
    // ************************************
    const { activeBrand, activeTheme } = useApplicationContext();
    const { id, className, text, rightAlign = false, backgroundColor } = props;
    const classPrefix = 'list-item-sponsor-tag';

    // ************************************
    // Render
    // ************************************
    return (
        <FlexGridAligner
            id={id}
            className={classnames(`${classPrefix}`, {
                [`${classPrefix}--right`]: rightAlign,
                [`${className}`]: className,
            })}
            brand={activeBrand}
            theme={activeTheme}
            alignment={rightAlign ? 'top-right' : 'top-left'}
            direction={'row'}
            style={{ backgroundColor: `rgb(var(--${backgroundColor}))` }}
        >
            <Text
                className={classnames(`${classPrefix}__text`, {
                    [`${classPrefix}--${activeTheme}`]:
                        getCounterTheme(activeTheme),
                })}
                type={'p'}
                size={'small'}
                color={BrandColors['text-shade-light-1']}
                weight={400}
                brand={activeBrand}
                theme={getCounterTheme(activeTheme)}
            >
                {text}
            </Text>
        </FlexGridAligner>
    );
};
