import { IconType } from '@fjordkraft/fjordkraft.component.library';
import { _getTextPlankPrefabTemplate } from '.';
import { Constants } from '../../../data';
import { AllowFeature, IsActiveCustomer, getText } from '../../../services';
import { IHomePageData } from '../HomePageData';
import { IStatePlank } from '../../../components';

export const MovingPlank = (config: IHomePageData): IStatePlank | undefined => {
	const { translations, user, services } = config;
	const { installation } = user;
	const { customerServiceFeature } = services;

	if (
		AllowFeature(Constants.features.move, translations, services.user, customerServiceFeature) &&
		IsActiveCustomer(installation)
	) {
		return {
			promise: fetchMovePlank(config),
		};
	}
};

const fetchMovePlank = async (props: IHomePageData) => {
	let { activeTheme, activeBrand, translations } = props;

	return _getTextPlankPrefabTemplate({
		theme: activeTheme,
		brand: activeBrand,
		title: getText('plankMovingTitle', translations),
		description: getText('plankMovingDesc', translations),
		link: Constants.paths.movePage,
		iconRight: IconType.ChevronRight,
	});
};
