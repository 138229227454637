import React from 'react';
import {
    FlexGridAligner,
    Text,
    Button,
    getCounterTheme,
    IAction,
    ContentGrid,
} from '@fjordkraft/fjordkraft.component.library';
import { useApplicationContext } from '../../contexts';
import classnames from 'classnames';
import './ActionDescriptionBlock.scss';
import { IBlock } from '../../models';
import { ActionButton } from '../../components';
import {
    MS_ButtonTemplate,
    h3TextPrefab,
    smallParagraphTextPrefab,
} from '../../Prefabs';

export interface IActionDescriptionBlock extends IBlock {
    title: string;
    description: string;
    action: IAction;
}

export const ActionDescriptionBlock = (props: IActionDescriptionBlock) => {
    // ************************************
    // Properties
    // ************************************

    const { activeBrand, activeTheme } = useApplicationContext();
    const { title, description, action, id, className } = props;
    const classPrefix = 'action-description-block';

    // ************************************
    // Render
    // ************************************

    return (
        <ContentGrid
            id={id}
            direction="column"
            alignment="top-center"
            tagType="section"
            className={classnames(classPrefix, {
                [`${className}`]: className,
            })}
            gap={2}
        >
            <Text {...h3TextPrefab()} align={'align-center'}>
                {title}
            </Text>
            <Text {...smallParagraphTextPrefab()} align={'align-center'}>
                {description}
            </Text>
            <ActionButton
                template={MS_ButtonTemplate(activeTheme, 'primary')}
                brand={activeBrand}
                action={action}
                padding="default"
            />
        </ContentGrid>
    );
};
