import React from 'react';
import {
    BaseComponent,
    ContentGrid,
    IAction,
    IComponent,
    IComponentTemplate,
    IImage,
    Text,
    getCounterTheme,
} from '@fjordkraft/fjordkraft.component.library';
import {
    LinkButtonTemplate,
    SectionBlockTemplate,
    h2TextPrefab,
    h3TextPrefab,
} from '../../Prefabs';
import { ActionButton, MSRichText } from '../../components';
import classNames from 'classnames';
import { useApplicationContext } from '../../contexts';
import './SectionBlock.scss';

export interface ISectionBlock extends IComponent {
    image: IImage;
    imagePlacement?: 'left' | 'right';
    roundImage?: boolean;
    template?: IComponentTemplate;
    action?: IAction;
    content: {
        logo?: IImage;
        title?: string;
        subTitle?: string;
        description?: string;
    };
}

export const SectionBlock = (props: ISectionBlock) => {
    // ************************************
    // Properties
    // ************************************
    const { desktopView } = useApplicationContext();
    const {
        id,
        className,
        imagePlacement = 'left',
        image,
        roundImage = true,
        content,
        brand,
        theme = 'Light',
        template = SectionBlockTemplate(theme, desktopView),
        action,
    } = props;
    const classPrefix = 'section-block';

    // ************************************
    // Render Functionality
    // ************************************

    const _renderImage = () => {
        return (
            <ContentGrid
                className={`${classPrefix}__image`}
                tagType="figure"
                alignment="center"
                direction="column"
                boxSizing="border-box"
                scaling={2}
            >
                <img
                    className={classNames(`${classPrefix}__image__graphic`, {
                        [`${classPrefix}__image__graphic__roundImage`]: roundImage
                    })}
                    src={image.src}
                    alt={image.alt}
                />
            </ContentGrid>
        );
    };

    const _renderContent = () => {
        return (
            <ContentGrid
                className={`${classPrefix}__content`}
                tagType="section"
                alignment="center-left"
                direction="column"
                boxSizing="border-box"
                gap={2}
                scaling={2}
            >
                {content?.logo?.src && (
                    <ContentGrid
                        className={`${classPrefix}__content__image`}
                        alignment="center-left"
                        direction="row"
                        tagType="figure"
                        boxSizing="border-box"
                    >
                        <img
                            className={`${classPrefix}__content__image__graphic`}
                            src={content.logo?.src}
                            alt={content.logo?.alt}
                        />
                    </ContentGrid>
                )}
                {content.title && (
                    <Text
                        {...h2TextPrefab()}
                        className={`${classPrefix}__content__title`}
                        palette={template.palette}
                    >
                        {content.title}
                    </Text>
                )}
                {content.subTitle && (
                    <Text
                        {...h3TextPrefab()}
                        className={`${classPrefix}__content__subTitle`}
                        palette={template.palette}
                    >
                        {content.subTitle}
                    </Text>
                )}
                {content.description && (
                    <MSRichText
                        className={`${classPrefix}__content__description`}
                        text={content.description}
                        theme={getCounterTheme(theme)}
                    />
                )}
                {action && (
                    <ActionButton
                        template={LinkButtonTemplate(theme)}
                        action={action}
                        customization={{
                            text: {
                                underline: true,
                            }
                        }}
                    />
                )}
            </ContentGrid>
        );
    };

    // ************************************
    // Render
    // ************************************

    return (
        <BaseComponent
            id={id}
            className={classNames(`${classPrefix}`, {
                [`${className}`]: className,
            })}
            brand={brand}
            template={template}
        >
            <ContentGrid
                className={`${classPrefix}__content-wrapper`}
                tagType="article"
                direction={desktopView ? 'row' : 'column'}
                alignment="center"
                boxSizing="border-box"
                wrap={desktopView}
            >
                {imagePlacement === 'left' && desktopView && _renderImage()}
                {_renderContent()}
                {imagePlacement === 'right' && desktopView && _renderImage()}
                {!desktopView && _renderImage()}
            </ContentGrid>
        </BaseComponent>
    );
};
