import {
	BrandColors,
	IComponentTransform,
} from '@fjordkraft/fjordkraft.component.library';

// ************************************
// Transform
// ************************************

const _transform = {
	width: 'auto',
	height: 'auto',
	outline: {
		type: 'dashed',
		thickness: 2,
	},
	grid: {
		boxSizing: 'border-box',
		direction: 'column',
		alignment: 'center',
	},
} as IComponentTransform;

// ************************************
// Palette
// ************************************

const _palette = {
	"brand-fjordkraft": {
		Light: {
			background: { color: BrandColors['transparent'] },
			icon: { color: BrandColors['primary-shade-light-2'] },
			hover: {
				background: { color: BrandColors['transparent'] },
				icon: { color: BrandColors['primary-shade-light-3'], opacity: 0.8 },
			},
			focus: {
				background: { color: BrandColors['transparent'] },
				icon: { color: BrandColors['primary-shade-light-3'], opacity: 0.8 },
				outline: { color: BrandColors['primary-shade-light-2'] },
			},
			disabled: {
				background: { color: BrandColors['transparent'] },
				icon: { color: BrandColors['primary-shade-light-2'], opacity: 0.3 },
			},
		},
		Dark: {
			background: { color: BrandColors['transparent'] },
			icon: { color: BrandColors['primary-shade-light-2'] },
			hover: {
				background: { color: BrandColors['transparent'] },
				icon: { color: BrandColors['primary-shade-light-3'], opacity: 0.8 },
			},
			focus: {
				background: { color: BrandColors['transparent'] },
				icon: { color: BrandColors['primary-shade-light-3'], opacity: 0.8 },
				outline: { color: BrandColors['primary-shade-light-2'] },
			},
			disabled: {
				background: { color: BrandColors['transparent'] },
				icon: { color: BrandColors['primary-shade-light-2'], opacity: 0.3 },
			},
		},
	}
};

// ************************************
// Export
// ************************************

export const BUTTON_TEMPLATE_MENU_ICON = {
	themedPalette: _palette,
	transform: _transform
}