import React, { useEffect, useState } from 'react';
import {
	IComponent,
	ContentGrid,
	Text,
	IconType,
	getCounterTheme,
	StyleGrid,
	BrandColors,
} from '@fjordkraft/fjordkraft.component.library';
import { MS_ButtonTemplate, h4TextPrefab, paragraphTextPrefab } from '../../Prefabs';
import { Constants } from '../../data';
import { MSRichText, Card, ActionButton, MsButton } from '../../components';
import { useApplicationCoreDataContext, useApplicationServicehandlerContext } from '../../contexts';
import { useAuth } from 'react-oidc-context';
import { ICustomer, IHomePage } from '../../models';
import { HomePageData, IBackendServiceException, currentElectricityCustomerNumber, getText } from '../../services';
import classNames from 'classnames';
import './CustomerServiceInformationBlock.scss';
import { useNavigate } from 'react-router';
import { useApplicationGuestsAndHostsContext } from '../../contexts/variations/ApplicationGuestsAndHostsContext';

interface ICustomerServiceInformationBlock extends IComponent {
	closeText: string;
	title: string;
	description: string;
	customerNumberLabel: string;
	customerNumber: string;
	nameLabel: string;
	name: string;
	logOutText: string;
	errorMessages?: IBackendServiceException[];
}

interface IBlockDataSetup {
	user: any;
	userData: ICustomer;
	translations: IHomePage;
	errorMessages: IBackendServiceException[];
}

export const CustomerServiceInformationBlock = (props: IComponent) => {
	// ************************************
	// Properties
	// ************************************
	const { id, className, theme = 'Light', brand = Constants.uiBrand } = props;
	const classPrefix = 'userData-service-information-block';
	const { epiChildren } = useApplicationCoreDataContext();
	const { customerServiceFeature, setCustomerServiceFeature, error } = useApplicationServicehandlerContext();
	const { mainUser } = useApplicationGuestsAndHostsContext();
	const { user, signoutRedirect } = useAuth();
	const navigate = useNavigate();

	// ************************************
	// Lifecycle
	// ************************************

	const [blockData, setBlockData] = useState<ICustomerServiceInformationBlock>();
	const [errorMessages, setErrorMessages] = useState<IBackendServiceException[]>([]);
	const [showError, setShowError] = useState(true);

	useEffect(() => {
		if (epiChildren && customerServiceFeature && mainUser) {
			_setupBlockData({
				user,
				userData: mainUser,
				translations: HomePageData(epiChildren),
				errorMessages,
			});
		}
	}, [user, epiChildren, mainUser, customerServiceFeature, errorMessages]);

	useEffect(() => {
		// Add to errorMessages if error is present, but not if its already there
		if (error && !errorMessages.some((e) => e.error === error.error)) {
			setErrorMessages([...errorMessages, error]);
		}
	}, [error]);

	// ************************************
	// Handlers
	// ************************************

	const _setupBlockData = (config: IBlockDataSetup) => {
		const { user, translations, userData, errorMessages } = config;

		setBlockData({
			theme: theme,
			brand: brand,
			closeText: getText('customerServiceBlockCloseButton', translations),
			title: getText('customerServiceBlockTitle', translations),
			description: getText('customerServiceBlockDesc', translations),
			customerNumber: currentElectricityCustomerNumber(user) as string,
			customerNumberLabel: getText('customerServiceBlockNumber', translations),
			nameLabel: getText('customerServiceBlockName', translations),
			name: `${userData?.firstName} ${userData?.lastName}`,
			logOutText: getText('customerServiceBlockLogout', translations),
			errorMessages: errorMessages.length ? errorMessages : undefined,
		});
	};

	// ************************************
	// Render
	// ************************************
	return (
		<>
			{blockData && (
				<StyleGrid
					alignment="center"
					direction="column"
					className={classNames(`${classPrefix}`, {
						[`${className}`]: className,
					})}
				>
					<StyleGrid
						className={`${classPrefix}__content__close-button`}
						alignment="center-right"
						direction="row"
						gap={2}
					>
						<MsButton
							className={`${classPrefix}__content__close-button__button`}
							action={{
								onClick: () => {
									setCustomerServiceFeature(false);
									navigate('/');
								},
								onHoverEnter: () => {},
							}}
						>
							<Text
								{...paragraphTextPrefab(props)}
								type={'p'}
								style={'italic'}
								size={'small'}
							>
								{blockData.closeText}
							</Text>
							<Text
								{...paragraphTextPrefab(props)}
								size={'large'}
							>
								✖
							</Text>
						</MsButton>
					</StyleGrid>
					<StyleGrid
						alignment="top-left"
						direction="row"
						gap={2}
					>
						<Card
							id={id}
							alignment={'top-left'}
							direction={'column'}
							brand={blockData.brand}
							theme={blockData.theme}
						>
							<ContentGrid
								alignment={'center'}
								direction={'column'}
								tagType={'section'}
								gap={2}
							>
								<Text
									{...h4TextPrefab(props)}
									brand={brand}
									theme={getCounterTheme(theme)}
								>
									{blockData.title}
								</Text>
								<MSRichText
									className={`${classPrefix}__content__description`}
									text={blockData.description}
									brand={brand}
									theme={getCounterTheme(theme)}
								/>
								<Text
									{...paragraphTextPrefab(props)}
									brand={brand}
									theme={getCounterTheme(theme)}
								>
									<strong>{`${blockData.customerNumberLabel}:`}</strong>{' '}
									{`${blockData.customerNumber}`}
								</Text>
								<Text
									{...paragraphTextPrefab(props)}
									brand={brand}
									theme={getCounterTheme(theme)}
								>
									<strong>{`${blockData.nameLabel}:`}</strong> {`${blockData.name}`}
								</Text>
								<ActionButton
									template={MS_ButtonTemplate(theme, 'secondary')}
									brand={brand}
									padding="medium"
									action={{
										icon: IconType.LogoutIcon,
										text: blockData.logOutText,
										onClick: () => {
											signoutRedirect();
										},
									}}
								/>
							</ContentGrid>
						</Card>
						{showError && blockData.errorMessages && (
							<StyleGrid
								className={`${classPrefix}__error`}
								alignment="center-right"
								direction="column"
							>
								<MsButton
									className={`${classPrefix}__error__close-button`}
									action={{
										onClick: () => setShowError(false),
									}}
								>
									<Text
										type={'p'}
										size={'large'}
									>
										✖
									</Text>
								</MsButton>
								<Card className={`${classPrefix}__error`}>
									<StyleGrid
										alignment="top-left"
										direction="column"
										gap={2}
									>
										{blockData.errorMessages.map((e) => (
											<ContentGrid
												tagType={'section'}
												alignment="top-left"
												direction="column"
												gap={0}
												key={e.query + e.error}
											>
												<Text
													className={`${classPrefix}__error__text`}
													{...paragraphTextPrefab(props)}
													brand={brand}
													theme={getCounterTheme(theme)}
													key={e.query + e.error + '_url'}
												>
													{e.query ? `Url: ${e.query}` : ''}
												</Text>
												<Text
													className={`${classPrefix}__error__text__error`}
													{...paragraphTextPrefab(props)}
													brand={brand}
													theme={getCounterTheme(theme)}
													color={BrandColors['status-shade-dark-3']}
													key={e.query + e.error + '_error'}
												>
													{e.error ? `Error: ${e.error}` : ''}
												</Text>
											</ContentGrid>
										))}
									</StyleGrid>
								</Card>
							</StyleGrid>
						)}
					</StyleGrid>
				</StyleGrid>
			)}
		</>
	);
};
