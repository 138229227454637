import { BrandColors, IComponentTemplate, Theme, ToastTemplate } from '@fjordkraft/fjordkraft.component.library';
import { logger } from '../../../services';

interface IMSStatusToastTemplateConfiguration {
	theme: Theme;
	status: 'positive' | 'negative' | 'neutral';
	desktopView: boolean;
	allowBorder: boolean;
}

export const MSStatusToastTemplate = (config: IMSStatusToastTemplateConfiguration) => {
	const { theme = 'Light', status = 'positive', desktopView = true, allowBorder = true } = config;
	let base: IComponentTemplate = ToastTemplate(theme, status);

	if (theme) {
		if (status === 'neutral') {
			base.palette.background = { color: BrandColors['action-shade-dark-2'], opacity: 0.05 };
			base.palette.border = { color: BrandColors['action-shade-dark-2'] };
			base.palette.icon = { color: BrandColors['action-shade-dark-2'] };
		}

		if (base.transform.grid) {
			base.transform.grid.alignment = 'top-left';
		}

		base.transform.height = 'auto';

		if (!allowBorder) {
			base.transform.border = undefined;
		}

		if (!desktopView) {
			base.transform.width = '100%';
			base.transform.padding = '1.25rem 1rem';
		} else {
			base.transform.border = {
				radiusType: 'curved',
				type: 'solid',
				thickness: 3,
			};
		}
	} else {
		logger('Theme not defined for MSStatusToastTemplate', 'error');
	}

	return base;
};

export const MSStaticStatusToastTemplate = (
	theme: Theme,
	status: 'positive' | 'negative' | 'neutral',
	desktopView: boolean = true
) => {
	let base: IComponentTemplate = MSStatusToastTemplate({
		theme,
		status,
		desktopView,
		allowBorder: false,
	});

	if (theme) {
		base.transform.width = '100%';
		base.transform.padding = '1rem 2rem';
	} else {
		logger('Theme not defined for MSStaticStatusToastTemplate', 'error');
	}

	return base;
};
