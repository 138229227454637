export const Constants = {
	uiBrand: import.meta.env.REACT_APP_UI_BRAND,
	uiTheme: 'Light',
	appUrl: import.meta.env.REACT_APP_URL,
	appEnv: import.meta.env.REACT_APP_ENV,
	isLocalHost: import.meta.env.REACT_APP_IS_LOCAL_HOST === 'true',
	locales: import.meta.env.REACT_APP_LOCALES,
	mobileBreak: 1040,
	popupFrequencyDays: 90,
	customerEffortScore: {
		minimumTimeOnPage: 5,
		globalMinimumTimeOnPage: 7.5,
		timeBetweenQuestions: 60,
	},
	api: {
		url: import.meta.env.REACT_APP_API_URL,
		version: 'v1',
		brand: import.meta.env.REACT_APP_API_BRAND,
	},
	sanity: {
		projectId: import.meta.env.REACT_APP_SANITY_PROJECT_ID,
		dataSet: import.meta.env.REACT_APP_SANITY_DATASET,
		useCdn: import.meta.env.NODE_ENV === 'production',
	},
	episerver: {
		pageId: import.meta.env.REACT_APP_EPISERVER_ROOT_PAGE_ID,
	},
	paths: {
		energyPage: import.meta.env.REACT_APP_ENERGY_PAGE,
		oldFrontPage: import.meta.env.REACT_APP_OLD_FRONT_PAGE,
		invoicePage: import.meta.env.REACT_APP_INVOICE_PAGE,
		invoiceHistoryPage: import.meta.env.REACT_APP_INVOICE_HISTORY_PAGE,
		messagesPage: import.meta.env.REACT_APP_MESSAGES_PAGE,
		userPage: import.meta.env.REACT_APP_USER_PAGE,
		powerAgreementPage: import.meta.env.REACT_APP_POWER_AGREEMENT_PAGE,
		movePage: import.meta.env.REACT_APP_MOVE_PAGE,
		moveOutPage: import.meta.env.REACT_APP_MOVE_OUT_PAGE,
		benefitsPage: import.meta.env.REACT_APP_BENEFITS_PAGE,
		benefitsIntroPage: import.meta.env.REACT_APP_BENEFITS_INTRO_PAGE,
		kickbackTransactionsPage: import.meta.env.REACT_APP_KICKBACK_TRANSACTIONS_PAGE,
		usePointsPage: import.meta.env.REACT_APP_USE_POINTS_PAGE,
		usePointsInvoicePage: import.meta.env.REACT_APP_USE_POINTS_INVOICE_PAGE,
		usePointsWebshopPage: import.meta.env.REACT_APP_USE_POINTS_WEBSHOP_PAGE,
		myBenefitsPage: import.meta.env.REACT_APP_MY_BENEFITS_PAGE,
		powerPage: import.meta.env.REACT_APP_POWER_PAGE,
		powerCostPage: import.meta.env.REACT_APP_POWER_COST_PAGE,
		powerUsagePage: import.meta.env.REACT_APP_POWER_USAGE_PAGE,
		spotPricePage: import.meta.env.REACT_APP_SPOTPRICE_PAGE,
		invoiceSettings: import.meta.env.REACT_APP_INVOICE_SETTINGS,
		fixedDueDate: import.meta.env.REACT_APP_INVOICE_FIXED_DUE_DATE_PAGE,
		servicesPage: import.meta.env.REACT_APP_SERVICES_PAGE,
		predictablePayment: import.meta.env.REACT_APP_PREDICTABLE_PAYMENT_PAGE,
		predictablePaymentTransactions: import.meta.env.REACT_APP_PREDICTABLE_PAYMENT_TRANSACTIONS,
		userCommunication: import.meta.env.REACT_APP_USER_COMMUNICATIONS,
		userResidences: import.meta.env.REACT_APP_USER_RESIDENCES,
		onboardingPage: import.meta.env.REACT_APP_ONBOARDING_PAGE,
		guests: import.meta.env.REACT_APP_GUESTS,
		consentPage: import.meta.env.REACT_APP_CONSENT_PAGE,
		reservationPage: import.meta.env.REACT_APP_RESERVED_PAGE,
	},
	whitelisted: import.meta.env.REACT_APP_CLOSEDFEATURE_WHITELIST
		? import.meta.env.REACT_APP_CLOSEDFEATURE_WHITELIST.split(',')
		: [],

	epiServerPageNames: {
		consentPage: {
			type: 'HeadlessConsentPageType',
			url: import.meta.env.REACT_APP_CONSENT_PAGE,
			id: 'Samtykke',
		},
		reservationPage: {
			type: 'ReservationPage',
			url: import.meta.env.REACT_APP_CONSENT_PAGE,
			id: 'Reservasjon',
		},
		benefits: {
			type: 'BenefitPages',
			url: import.meta.env.REACT_APP_BENEFITS_PAGE,
			id: 'Fordel',
		},
		energyAgreement: {
			type: 'HeadlessActiveEnergyAgreementPageType',
			url: import.meta.env.REACT_APP_POWER_AGREEMENT_PAGE,
			id: 'Strømavtale',
		},
		consumptionCost: {
			type: 'HeadlessConsumptionCostPageType',
			url: import.meta.env.REACT_APP_POWER_COST_PAGE,
			id: 'Strømkostnad',
		},
		consumptionUse: {
			type: 'HeadlessConsumptionUsePageType',
			url: import.meta.env.REACT_APP_POWER_USAGE_PAGE,
			id: 'Strømforbruk',
		},
		home: {
			type: 'HeadlessHomePageType',
			url: import.meta.env.REACT_APP_ENERGY_PAGE,
			id: 'Forside',
		},
		inbox: {
			type: 'HeadlessInboxPageType',
			url: import.meta.env.REACT_APP_MESSAGES_PAGE,
			id: 'Meldinger',
		},
		invoice: {
			type: 'HeadlessInvoicePageType',
			url: import.meta.env.REACT_APP_INVOICE_PAGE,
			id: 'Faktura',
		},
		move: {
			type: 'HeadlessMovePageType',
			url: import.meta.env.REACT_APP_MOVE_PAGE,
			id: 'Flytting',
		},
		services: {
			type: 'HeadlessServicesPage',
			url: import.meta.env.REACT_APP_SERVICES_PAGE,
			id: 'Tjenester',
		},
		spotPrice: {
			type: 'HeadlessSpotPricePageType',
			url: import.meta.env.REACT_APP_SPOTPRICE_PAGE,
			id: 'Spotpris nå',
		},
		invoiceHistory: {
			type: 'InvoiceHistory',
			url: import.meta.env.REACT_APP_INVOICE_HISTORY_PAGE,
			id: 'Faktura',
		},
		invoiceSelected: {
			type: 'SelectedInvoice',
			url: import.meta.env.REACT_APP_INVOICE_HISTORY_PAGE,
			id: 'Faktura',
		},
		invoiceSettings: {
			type: 'InvoiceSettingsPage',
			url: import.meta.env.REACT_APP_INVOICE_SETTINGS,
			id: 'Faktura: Fakturainnstillinger',
		},
		invoiceFixedDueDate: {
			type: 'HeadlessInvoiceEditDueDatePageType',
			url: import.meta.env.REACT_APP_INVOICE_FIXED_DUE_DATE_PAGE,
			id: 'Faktura: Fastforfall',
		},
		me: {
			type: 'MePage',
			url: import.meta.env.REACT_APP_USER_PAGE,
			id: 'Meg',
		},
		communication: {
			type: 'MyCommunicationSettingsPage',
			url: import.meta.env.REACT_APP_USER_COMMUNICATIONS,
			id: 'Meg: Kommunikasjon',
		},
		residences: {
			type: 'MyResidencesPage',
			url: import.meta.env.REACT_APP_USER_RESIDENCES,
			id: 'Meg: Boliger',
		},
		guests: {
			type: 'GuestAdminPage',
			url: import.meta.env.REACT_APP_GUESTS,
			id: 'Meg: Gjester',
		},
		notFound: {
			type: 'PageNotFound',
			url: '',
			id: 'Fant ingen side',
		},
		onboardingPage: {
			type: 'HeadlessTutorialPageType',
			url: import.meta.env.REACT_APP_ONBOARDING_PAGE,
			id: 'Veileder',
		},
		accessDeniedPage: {
			type: 'AccessDeniedPage',
			url: '',
			id: 'IngenTilgang',
		},
	},
	features: {
		consumptionUse: 'ConsumptionUse',
		consumptionCost: 'ConsumptionCost',
		spotPrice: 'Spotprice',
		services: 'Services',
		invoices: 'Invoices',
		benefits: 'Benefits',
		move: 'Moving',
		inbox: 'Inbox',
		mySettings: 'MySettings',
		agreement: 'Agreement',
		onboarding: 'Onboarding',
		steddi: 'Steddi',
		trumf: 'Trumf',
		installationStatus: 'installationStatus',
		guestAdmin: 'GuestAdmin',
		consentPage: 'ConsentPage',
		reservationPage: 'ReservedPage',
		steddiPaymentFreeMonth: 'SteddiPaymentFreeMonth',
		chatBot: 'Chatbot',
	},
	services: {
		dynamicInfoDetailsId: 'dynamicDetails',
	},
	meterNumberValidation: {
		minDigits: import.meta.env.REACT_APP_METER_NUMBER_VALIDATION_MIN_DIGITS,
		maxDigits: import.meta.env.REACT_APP_METER_NUMBER_VALIDATION_MAX_DIGITS,
		isMeterIdIfFirstDigitsEqual: import.meta.env.REACT_APP_METER_NUMBER_VALIDATION_IS_METER_ID_IF_FIRST_DIGITS_EQUALS,
	},
	minimumBindingPeriodGiftcardProductId: 1001920,
	keys: {
		consentPopupClosed: 'consentPopupClosed',
	},
} as any;