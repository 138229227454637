import { BrandColors, IComponentTemplate, IComponentTransform, IThemedColors, Theme } from "@fjordkraft/fjordkraft.component.library";

// ************************************
// Interfaces
// ************************************

export interface IDetailCheckboxItemTemplate extends IComponentTemplate {
    list: IComponentTemplate;
}

// ************************************
// Transforms
// ************************************

const _transform = (loc?: 'top' | 'bottom' | 'single') => {
    return {
        width: '100%',
        height: 'auto',
        padding: '1rem',
        border: loc ? {
            radiusType: 'curved',
            radiusLocation: loc === 'single' ? undefined : loc
        } : undefined,
        grid: {
            direction: 'column',
            alignment: 'top-left',
            boxSizing: 'border-box',
            gap: 2,
        }
    } as IComponentTransform;
}

const _listTransform = {
    width: '100%',
    height: 'auto',
    padding: '1rem',
    border: {
        type: 'dashed',
        thickness: 1
    },
    grid: {
        alignment: 'top-left',
        direction: 'column',
        boxSizing: 'border-box',
        gap: 2
    }
} as IComponentTransform;

// ************************************
// Palettes
// ************************************

const _palette = {
    Light: {
        background: { color: BrandColors["background-shade-light-1"] },
        disabled: { 
            background: { color: BrandColors["background-shade-light-1"], opacity: 0.8 },
        },
    },
    Dark: {
        background: { color: BrandColors["background-shade-dark-3"] }
    }
} as IThemedColors;

const _listPalette = {
    Light: {
        border: { color: BrandColors["primary-shade-light-1"] }
    },
    Dark: {

    }
} as IThemedColors;

// ************************************
// Export
// ************************************

export const DetailCheckboxItemTemplate = (theme: Theme, location?: 'top' | 'bottom' | 'single') : IDetailCheckboxItemTemplate => {
    return {
        transform: _transform(location),
        palette: _palette[theme],
        list: {
            transform: _listTransform,
            palette: _listPalette[theme]
        }
    }
}