import React from 'react';
import {
    useApplicationAccountSelectContext,
} from '../../../contexts';
import {PlankHouseBlock, IMSPlankWall, PlankWall, CommercialBlock} from '../../../blocks';
import { Constants } from '../../../data';
import { getPageContent } from './InvoiceHistoryPageData';
import { getPlankPrefab } from '../../../Prefabs';
import { IDefaultViewProps, PageV2 } from '../../PageV2';
import "./InvoiceHistoryPage.scss";

export interface IInvoiceHistoryPage extends IDefaultViewProps {
    invoiceHistory?: IMSPlankWall[];
}

export const InvoiceHistoryPage = () => {
    // ************************************
    // Properties
    // ************************************

    const { setUseAccountSelectMenu, activeAccount } = useApplicationAccountSelectContext();
    const classPrefix = 'invoice-history-page';

    // ************************************
    // Render
    // ************************************

    return (
        PageV2({
            setup: {
                pageType: Constants.epiServerPageNames.invoice.type,
                usesSubPage: true
            },
            dependenciesOptional: [
                { activeAccount }
            ],
            startedRender: () => {
                setUseAccountSelectMenu(true);
            },
            handleData: getPageContent,
            render: (config: IInvoiceHistoryPage) => {
                const { activeBrand, activeTheme, invoiceHistory } = config;

                return (
                    <>
                        {invoiceHistory && invoiceHistory.length > 0 &&
                            <PlankHouseBlock
                                className={`${classPrefix}__house`}
                                plankWalls={invoiceHistory}
                                theme={activeTheme}
                                brand={activeBrand}
                            />
                        }
                        <CommercialBlock className={`${classPrefix}__commercial`}/>
                    </>
                )
            },
            fallbackRender: () => {
                return (
                    <PlankWall
                        className={`${classPrefix}__placeholder`}
                        planks={[
                            getPlankPrefab('Placeholder', {})
                        ]}
                    />
                )
            }
        })
    );
};