import { IToast } from '@fjordkraft/fjordkraft.component.library';
import { GenericToastPrefab, IGenericToastPrefab } from './GenericToastPrefab';
import { MessageToastPrefab, IMessageToastPrefab } from './MessageToastPrefab';
import { StatusToastPrefab, IStatusToastPrefab } from './StatusToastPrefab';
import { CallStateToastPrefab, ICallStateToastPrefab } from './CallStateToastPrefab';

// ************************************
// Interfaces and types
// ************************************

export enum ToastPrefabsType {
	Generic,
	MessageToast,
	StatusToast,
	CallStateToast,
}
export type ToastPrefabInterface =
	| IGenericToastPrefab
	| IMessageToastPrefab
	| IStatusToastPrefab
	| ICallStateToastPrefab;

// ************************************
// Getter
// ************************************

export const GetToastPrefab = (type: ToastPrefabsType, data: ToastPrefabInterface): IToast => {
	switch (type) {
		case ToastPrefabsType.CallStateToast:
			return CallStateToastPrefab(data as ICallStateToastPrefab);
		case ToastPrefabsType.Generic:
			return GenericToastPrefab(data as IGenericToastPrefab);
		case ToastPrefabsType.MessageToast:
			return MessageToastPrefab(data as IMessageToastPrefab);
		case ToastPrefabsType.StatusToast:
			return StatusToastPrefab(data as IStatusToastPrefab);
	}
};
