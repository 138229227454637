import { IconType } from '@fjordkraft/fjordkraft.component.library';
import { _getTextPlankPrefabTemplate } from '.';
import { Constants } from '../../../data';
import { AllowFeature, IsActiveCustomer, getText, tNumber } from '../../../services';
import { IHomePageData } from '../HomePageData';
import { IStatePlank } from '../../../components';

export const SpotpricePlank = (props: IHomePageData): IStatePlank | undefined => {
	const { translations, user, services } = props;
	const { installation } = user;
	const { customerServiceFeature } = services;

	if (
		AllowFeature(Constants.features.spotPrice, translations, services.user, customerServiceFeature) &&
		IsActiveCustomer(installation)
	) {
		return {
			promise: fetchSpotPricePlank(props),
		};
	}
};

const fetchSpotPricePlank = async (props: IHomePageData) => {
	const { activeTheme, activeBrand, translations, user, services } = props;
	const { installation } = user;
	const { GET } = services;

	let spotpriceAsString = '';

	if (installation) {
		let resp = await GET(`SpotPrice/${installation.address.priceArea}/${installation.address.postalCode}/current`);

		if (resp?.callState === 'success' && resp?.data) {
			spotpriceAsString = `${tNumber(resp.data.price ?? 0, 'no-NO', 2)} ${resp.data.unit}`;
		}
	}

	return _getTextPlankPrefabTemplate({
		theme: activeTheme,
		brand: activeBrand,
		title: getText('plankSpotpriceTitle', translations),
		description: getText('plankSpotpriceDesc', translations),
		rightTitle: `${spotpriceAsString}`,
		link: Constants.paths.spotPricePage,
		iconRight: IconType.ChevronRight,
	});
};
