import React, { useState } from 'react';
import {
	IAction,
	Icon,
	IconType,
	StyleGrid,
	Text,
	getCounterTheme,
	useQueryParams,
} from '@fjordkraft/fjordkraft.component.library';
import {
	useDefaultPageContext,
	useSubPageLayoutContext,
} from '../../../contexts';
import { getPageContent } from './MyBenefitsPageData';
import { v4 as uuid4 } from 'uuid';
import { ActionButton, BenefitList } from '../../../components';
import { MS_ButtonTemplate, h4TextPrefab, paragraphTextPrefab } from '../../../Prefabs';
import { SliderSelector } from '../../../components/SliderSelector/SliderSelector';
import { getText } from '../../../services';
import { IBenefit, ILocationNavigationInfoBoxContent } from '../../../models';
import { Constants } from '../../../data';
import { IDefaultViewProps, PageV2 } from '../../PageV2';
import './MyBenefitsPage.scss';
import { ISliderItemData } from '../../../components/SliderSelector/SliderItem';

export interface IMyBenefitsPage extends IDefaultViewProps {
	info: ILocationNavigationInfoBoxContent;
	webshopLink: string;
	recruitLink: string;
	categories: IBenefit[];
	benefits: IBenefit[];
}

export const MyBenefitsPage = () => {
	// ************************************
	// Properties
	// ************************************

	const { setInfoBoxContent } = useSubPageLayoutContext();
	const { setMainContentClamping } = useDefaultPageContext();
	const { get: getInclude } = useQueryParams('include', true);
	const { get: getExclude } = useQueryParams('exclude', true);
	const classPrefix = 'my-benefits-page';

	// ************************************
	// Lifecycle
	// ************************************

	const [selectedCategories, setSelectedCategories] = useState<string[]>();
	const queryIncludeCategories = getInclude()?.replaceAll('_', ' ').split(',');
	const queryExcludeCategories = getExclude()?.replaceAll('_', ' ').split(',');

	// ************************************
	// Helper Functionality
	// ************************************

	const _toggleCategory = (category: string) => {
		let selected = [...(selectedCategories ?? [])];
		if (selected.includes(category)) {
			selected = selected.filter((x) => x != category);
		} else {
			selected.push(category);
		}
		setSelectedCategories(selected);
	};

	// ************************************
	// Render Functionality
	// ************************************

	const _renderCategorySelection = (config: IMyBenefitsPage) => {
		const { activeTheme, activeBrand, categories } = config;
		let selection: any[] = [];

		if (categories && categories?.length > 0) {
			categories.forEach((category: any) => {
				let selected = selectedCategories?.includes(category);

				selection.push({
					className: `${classPrefix}__categories__button`,
					active: selected,
					onClick: () => {
						_toggleCategory(category);
					},
					template: MS_ButtonTemplate(activeTheme, selected ? 'highlighted' : 'secondary', activeBrand),
					children: (
						<>
							<Text 
								{...paragraphTextPrefab()} 
								wrap='nowrap'
								weight={500}
							>
								{category}
							</Text>
							{selected &&
								<Icon
									type={IconType.Close}
								/>
							}
						</>
					),
					value: category,
				} as ISliderItemData);
			});
		}

		return selection;
	};

	// ************************************
	// Render
	// ************************************

	return PageV2({
		setup: {
			pageType: Constants.epiServerPageNames.benefits.type,
			usesSubPage: true
		},
		dependenciesOptional: [
			{ selectedCategories },
			{ queryIncludeCategories },
			{ queryExcludeCategories }
		],
		startedRender: () => {
			setMainContentClamping('clamp');
		},
		handleData: getPageContent,
		onDataChange: (config: IMyBenefitsPage) => {
			const { info, categories, desktopView } = config;

			setMainContentClamping(desktopView ? 'clamp' : 'stretch');

			if (!selectedCategories) {
				// set "include" query parameter categories
				if (queryIncludeCategories?.some((category: any) => categories.includes(category))) {
					setSelectedCategories(queryIncludeCategories);
				}
				// set "exclude" query parameter categories, this takes priority over "include", only one of which will be used at a time
				if (queryExcludeCategories?.some((category: any) => categories.includes(category))) {
					setSelectedCategories(
						categories.filter((category: any) => !queryExcludeCategories.includes(category)) as any
					);
				}
			}

			if (setInfoBoxContent) {
				setInfoBoxContent(info);
			}
		},
		render: (config: IMyBenefitsPage) => {
			const {
				translations,
				activeBrand,
				activeTheme,
				recruitLink,
				benefits,
				categories,
				desktopView,
			} = config;

			return (
				<>
					{translations && (
						<StyleGrid
							className={`${classPrefix}`}
							direction={'column'}
							alignment={desktopView ? 'top-left' : 'top-center'}
							boxSizing="border-box"
							gap={2}
						>
							{translations && (
								<Text
									{...h4TextPrefab()}
									className={`${classPrefix}__title`}
									brand={activeBrand}
									theme={getCounterTheme(activeTheme)}
									weight={500}
								>
									{`${getText('benefitCategoriesTitle', translations)}`}
								</Text>
							)}
							{categories && (
								<SliderSelector
									className={`${classPrefix}__slider-selector`}
									uniqueId={`${classPrefix}__slider-selector`}
									data={_renderCategorySelection(config)}
									slideToSelect={false}
									width={550}
									mobileWidth={document.body.clientWidth}
									sliderItemGap={1}
									onValueChange={(value) => {
										_toggleCategory(value);
									}}
								/>
							)}
							{benefits && (
								<BenefitList
									className={`${classPrefix}__benefits`}
									theme={activeTheme}
									brand={activeBrand}
									recruitLink={recruitLink}
									excludeParamCategories={true}
									selectedCategories={selectedCategories}
									benefits={benefits}
									showMoreText={getText('readMore', translations)}
								/>
							)}
						</StyleGrid>
					)}
				</>
			);
		},
	});
};
