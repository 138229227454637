import { BrandColors, IComponentTemplate, IComponentTransform, IThemedColors, Theme } from "@fjordkraft/fjordkraft.component.library";
import { Constants } from "../../data";

const _transform = (desktopView: boolean) => {
    return {
        width: '100%',
        height: 'auto',
        minHeight: '19.563rem',
        padding: '3rem 0',
        margin: desktopView ? '12.375rem 0 0 0' : '5rem 0 0 0',
        grid: {
            tagType: 'footer',
            direction: 'column',
            alignment: 'center',
            gap: 2,
            boxSizing: 'border-box'
        }
    } as IComponentTransform;
}

const _palette = {
    Light: {
        background: { color: BrandColors["background-shade-light-1"] }
    },
    Dark: {
        background: { color: BrandColors["background-shade-dark-3"] }
    }
} as IThemedColors;

export const FooterTemplate = (theme: Theme, desktopView: boolean, brand: string = Constants.uiBrand) => {
    return {
        transform: _transform(desktopView),
        palette: _palette[theme]
    } as IComponentTemplate;
}