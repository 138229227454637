import React, { useMemo, useState } from 'react';
import {
	CallState,
	CheckInput,
	ContentGrid,
	IAction,
	IComponent,
	IMasterPlank,
	Image,
	LoaderGraphic,
	StyleGrid,
	getCounterTheme,
} from '@fjordkraft/fjordkraft.component.library';
import { IMSPlankWall, IPlankHouse, PlankHouseBlock } from '../../blocks';
import { PopupCard } from '../PopupCard/PopupCard';
import classNames from 'classnames';
import { MSRichText, ProgressBar, ProgressBarTemplate } from '../../components';
import { ButtonTypes, ITextPlankPrefab, MSCheckInputTemplate, getPlankPrefab } from '../../Prefabs';
import { Constants } from '../../data';
import {
	AgreementMinimumTermTypeDefinitionType,
	IAgreementCancellationStep,
	IAgreementMinimumTermTypeDefinition,
	ICustomerElectricityAgreement,
	IEpiserverPlank,
} from '../../models';
import { createString, getPlankIcon, getPlankText, getText, getTextV2 } from '../../services';
import { format } from 'date-fns';
import SuccessGraphic from '../../assets/art/service-order-failure.svg';
import ErrorGraphic from '../../assets/art/service-order-success.svg';
import _ from 'lodash';
import './CancelManagedModal.scss';
import { useApplicationContext } from '../../contexts';

export interface ICancelSpotManagedStage {
	title: string;
	agreement: ICustomerElectricityAgreement;
	loading?: boolean;
	topDescription?: string;
	house?: IPlankHouse;
	bottomDescription?: string;
	checkBoxDescription?: string;
	checkBoxState?: boolean;
	secondaryAction?: IAction;
	primaryAction?: IAction;
}

export interface ICancelManagedModal extends IComponent {
	agreement: ICustomerElectricityAgreement;
	translations: any;
	onClose: () => void;
	onOrderComplete: (callState: CallState) => void;
}

enum StageTypes {
	'start',
	'confirm',
	'confirmWithExtra',
	'loading',
	'error',
	'success',
}

export const CancelManagedModal = (props: ICancelManagedModal) => {
	// ************************************
	// Properties
	// ************************************

	const {
		id,
		className,
		theme = 'Light',
		brand = Constants.uiBrand,
		agreement,
		translations,
		onClose,
		onOrderComplete,
	} = props;
	const { desktopView } = useApplicationContext();
	const classPrefix = 'cancel-managed-modal';
	const totalStages = 2;

	// ************************************
	// Lifecycle
	// ************************************

	const [activeStage, setActiveStage] = useState<StageTypes>(StageTypes.start);
	const [canProceed, setCanProceed] = useState<boolean>(false);

	// ************************************
	// OnClick handling
	// ************************************

	const _handleStageSelect = () => {
		let additionaBindingTime: IAgreementMinimumTermTypeDefinition | undefined = _getTermDefinitionItem('START');

		if (additionaBindingTime) {
			setActiveStage(StageTypes.confirmWithExtra);
		} else {
			setActiveStage(StageTypes.confirm);
		}
	};

	const _onClickStageHandling = () => {
		switch (activeStage) {
			case StageTypes.start:
				_handleStageSelect();
				break;
			case StageTypes.confirm:
			case StageTypes.confirmWithExtra:
				setActiveStage(StageTypes.loading);
				break;
		}
	};

	const _onClickSecondayHandling = () => {
		switch (activeStage) {
			case StageTypes.start:
			case StageTypes.loading:
			case StageTypes.error:
			case StageTypes.success:
				onClose();
				break;
			case StageTypes.confirm:
			case StageTypes.confirmWithExtra:
				setActiveStage(activeStage - 1);
				break;
		}
	};

	// ************************************
	// GETTERS
	// ************************************

	const _getTermDefinitionPriority = () => {
		let item: IAgreementMinimumTermTypeDefinition | undefined = _getTermDefinitionItem('START');
		return item ?? _getTermDefinitionItem('TERMINATION');
	};

	const _getTermDefinitionItem = (type: AgreementMinimumTermTypeDefinitionType) => {
		let additionaBindingTimeItem: IAgreementMinimumTermTypeDefinition | undefined = undefined;

		if (agreement.minimumTermDefinitions && agreement.minimumTermDefinitions.length > 0) {
			additionaBindingTimeItem = _.find(
				agreement.minimumTermDefinitions,
				(def: IAgreementMinimumTermTypeDefinition) => {
					return def.type === type;
				}
			);
		}

		return additionaBindingTimeItem;
	};

	const _getContentTitleAlignment = () => {
		switch (activeStage) {
			case StageTypes.loading:
			case StageTypes.error:
			case StageTypes.success:
				return 'align-center';
		}

		return 'align-left';
	};

	const _getTextRow = (stageData: IAgreementCancellationStep, row: 'bottomText' | 'topText' | 'checkboxText') => {
		let def: IAgreementMinimumTermTypeDefinition | undefined = _getTermDefinitionPriority();
		let earliestCancelDate: Date = _getCancellationEndDate();
		let cost: number = def?.breachFee ?? 0;

		let bottomText: string | undefined = createString(
			getTextV2({
				key: row,
				translations: stageData,
				textsKey: 'texts',
				includeMissing: false,
			}) ?? '',
			{
				date: format(earliestCancelDate, 'dd.MM.yyyy'),
				agreementName: agreement.name,
				amount: cost,
			}
		);

		return bottomText;
	};

	const _getCancellationEndDate = () => {
		let earliestCancelDate: Date = new Date();
		let def: IAgreementMinimumTermTypeDefinition | undefined = _getTermDefinitionPriority();

		if (def) {
			if (def.type === 'START') {
				earliestCancelDate = new Date(def.expiresAt);
			} else if (def.type === 'TERMINATION') {
				earliestCancelDate.setDate(earliestCancelDate.getDate() + def.termDays);
			}
		}

		return earliestCancelDate;
	};

	const _getStageAsString = (stage: StageTypes) => {
		let stageTypeKeys: string[] = Object.keys(StageTypes);
		let stageString: string = stageTypeKeys[stage + stageTypeKeys.length / 2];
		return stageString;
	};

	const _getTitle = (stage: StageTypes) => {
		let cancellationStage: IAgreementCancellationStep = _getStageFromTranslations(stage);
		return getTextV2({
			key: 'title',
			translations: cancellationStage,
			textsKey: 'texts',
		});
	};

	const _getPrimaryActionData = () => {
		switch (activeStage) {
			case StageTypes.start:
			case StageTypes.confirm:
			case StageTypes.confirmWithExtra:
				return {
					text: getText(activeStage === StageTypes.start ? 'next' : 'confirm', translations),
					disabled: !canProceed,
					onClick: _onClickStageHandling,
				};
			case StageTypes.loading:
			case StageTypes.error:
			case StageTypes.success:
				return undefined;
		}
	};

	const _getSecondaryActionData = () => {
		switch (activeStage) {
			case StageTypes.start:
				return {
					text: getText('cancel', translations),
					style: 'secondary' as ButtonTypes,
				};
			case StageTypes.confirm:
			case StageTypes.confirmWithExtra:
				return {
					text: getText('goBack', translations),
					style: 'secondary' as ButtonTypes,
				};
			case StageTypes.loading:
				return {
					text: getText('cancel', translations),
					style: 'end' as ButtonTypes,
				};
			case StageTypes.error:
			case StageTypes.success:
				return {
					text: getText('close', translations),
					style: 'end' as ButtonTypes,
				};
		}
	};

	const _getPlankPrefabConfigured = (
		plank: IEpiserverPlank,
		title?: string,
		description?: string,
		value?: string
	) => {
		return getPlankPrefab('Text', {
			left: {
				title: title,
				description: description,
				icon: getPlankIcon({
					key: 'leftIcon',
					plank: plank,
					includeMissing: false,
				}),
			},
			right: {
				title: getPlankText({
					key: 'rightTitle',
					plank: plank,
					includeMissing: false,
				}),
				description: value,
				icon: getPlankIcon({
					key: 'rightIcon',
					plank: plank,
					includeMissing: false,
				}),
			},
		} as ITextPlankPrefab);
	};

	const _getPlankStageTitle = (stage: StageTypes, plank: IEpiserverPlank) => {
		let plankString: string =
			getPlankText({
				plank: plank,
				key: 'title',
				includeMissing: false,
			}) ?? '';

		let item: IAgreementMinimumTermTypeDefinition | undefined = _getTermDefinitionPriority();

		switch (stage) {
			case StageTypes.start:
				return createString(plankString, { amount: item?.termDays ?? 0 });
			case StageTypes.confirm:
				return plankString;
			case StageTypes.confirmWithExtra:
				return plankString;
		}
	};

	const _getPlankStageDescription = (plank: IEpiserverPlank) => {
		return getPlankText({
			plank: plank,
			key: 'description',
			includeMissing: false,
		});
	};

	const _getPlankStageValue = (stage: StageTypes, plank: IEpiserverPlank) => {
		let plankString: string =
			getPlankText({
				plank: plank,
				key: 'value',
				includeMissing: false,
			}) ?? '';
		let earliestCancelDate: Date = _getCancellationEndDate();

		switch (stage) {
			case StageTypes.start:
				return plankString;
			case StageTypes.confirm:
				return createString(plankString, {
					date: format(earliestCancelDate, 'dd.MM.yyyy'),
					agreementName: agreement.name,
				});
			case StageTypes.confirmWithExtra:
				return createString(plankString, { date: format(earliestCancelDate, 'dd.MM.yyyy') });
		}
	};

	// ************************************
	// Data Handling
	// ************************************

	const _getStagePlanks = (stage: StageTypes) => {
		let details: any = _energyAgreementDetails(translations, agreement);
		let cancellationStage: IAgreementCancellationStep = _getStageFromTranslations(stage);

		if (details && cancellationStage) {
			if (cancellationStage.planks && cancellationStage.planks.length > 0) {
				let planks: IMasterPlank[] = [];

				cancellationStage.planks.forEach((epiPlank: IEpiserverPlank) => {
					planks.push(
						_getPlankPrefabConfigured(
							epiPlank,
							_getPlankStageTitle(stage, epiPlank),
							_getPlankStageDescription(epiPlank),
							_getPlankStageValue(stage, epiPlank)
						)
					);
				});

				return planks;
			}
		}

		return undefined;
	};

	const _energyAgreementDetails = (translations: any, agreement: ICustomerElectricityAgreement) => {
		switch (agreement.agreementCategory) {
			case 'SpotPrice':
				return translations.benefitPlanksSpotprice;
			case 'Managed':
				return translations.benefitPlanksManaged;
			case 'VariableRate':
				return translations.benefitPlanksVariableRate;
			default:
				return translations.benefitPlanksDefault;
		}
	};

	const _getStageFromTranslations = (stageId: StageTypes) => {
		let details: any = _energyAgreementDetails(translations, agreement);
		let foundStep: IAgreementCancellationStep = {};

		if (details.cancellationSteppes?.length > 0) {
			details.cancellationSteppes.forEach((step: IAgreementCancellationStep, index: number) => {
				if (step.stageId === _getStageAsString(stageId)) {
					foundStep = step;
				}
			});
		}

		return foundStep;
	};

	// ************************************
	// Render Functionality
	// ************************************

	const _renderText = (text: any, align: 'align-left' | 'align-center' = 'align-left') => {
		return (
			<MSRichText
				className={`${classPrefix}__description`}
				theme={getCounterTheme(theme)}
				brand={brand}
				alignment={'lawful'}
				text={text}
				customization={{
					type: 'p',
					align: align,
				}}
			/>
		);
	};

	const _renderStatehandling = (stage: StageTypes) => {
		let stageData: IAgreementCancellationStep = _getStageFromTranslations(stage);
		let graphic: any = undefined;
		let topText: string | undefined = _getTextRow(stageData, 'topText');
		let bottomText: string | undefined = _getTextRow(stageData, 'bottomText');

		switch (stage) {
			case StageTypes.loading:
				graphic = (
					<LoaderGraphic
						className={`${classPrefix}__graphic`}
						theme={theme}
						brand={brand}
						scaleThickness={1}
						size={6}
					/>
				);
				break;
			case StageTypes.error:
				graphic = (
					<Image
						alt={'Error graphic'}
						src={ErrorGraphic}
					/>
				);
				break;
			case StageTypes.success:
				graphic = (
					<Image
						alt={'Success graphic'}
						src={SuccessGraphic}
					/>
				);
				break;
		}

		if (graphic) {
			return (
				<StyleGrid
					className={`${classPrefix}__state-graphic`}
					direction={'column'}
					alignment={'center'}
					gap={5}
				>
					{topText && _renderText(topText, 'align-center')}
					{graphic}
					{bottomText && _renderText(bottomText, 'align-center')}
				</StyleGrid>
			);
		}
	};

	const _renderStage = (stage: StageTypes) => {
		let cancellationStage: IAgreementCancellationStep = _getStageFromTranslations(stage);
		let plankWall: IMSPlankWall = {
			planks: _getStagePlanks(stage) ?? [],
		};
		let topText: string | undefined = _getTextRow(cancellationStage, 'topText');
		let checkboxDescription: string = _getTextRow(cancellationStage, 'checkboxText');
		let bottomText: string | undefined = _getTextRow(cancellationStage, 'bottomText');

		setCanProceed(!(stage === StageTypes.confirm || stage === StageTypes.confirmWithExtra));

		return (
			<>
				{topText && _renderText(topText)}
				<PlankHouseBlock
					plankWalls={[plankWall]}
					className={`${classPrefix}__house`}
					theme={theme}
					brand={brand}
				/>
				{checkboxDescription && (
					<ContentGrid
						tagType="section"
						direction="row"
						alignment="top-left"
						gap={2}
						boxSizing="border-box"
						wrap={false}
					>
						<CheckInput
							theme={theme}
							brand={brand}
							template={MSCheckInputTemplate(theme, 'checkbox', brand)}
							onChange={(value, state) => {
								setCanProceed(!!state);
							}}
						/>
						{_renderText(checkboxDescription)}
					</ContentGrid>
				)}
				{bottomText && _renderText(bottomText)}
				<ProgressBar
					className={`${classPrefix}__progress`}
					brand={brand}
					theme={theme}
					current={activeStage}
					max={totalStages}
					template={ProgressBarTemplate(theme)}
				/>
			</>
		);
	};

	const _getStage = useMemo(() => {
		switch (activeStage) {
			case StageTypes.loading:
			case StageTypes.error:
				return _renderStatehandling(activeStage);
			default:
				return _renderStage(activeStage);
		}
	}, [activeStage]);

	// ************************************
	// Render
	// ************************************

	return (
		<PopupCard
			id={id}
			className={classNames(classPrefix, {
				className: className,
			})}
			theme={theme}
			brand={brand}
			contentTitle={_getTitle(activeStage)}
			contentTitleAlignment={_getContentTitleAlignment()}
			onClose={() => {
				onClose();
			}}
			primaryAction={_getPrimaryActionData()}
			secondaryAction={{
				text: _getSecondaryActionData().text,
				onClick: () => {
					_onClickSecondayHandling();
				},
			}}
			secondaryActionStyle={_getSecondaryActionData().style}
			contentGap={4}
			desktopView={desktopView}
		>
			{_getStage}
		</PopupCard>
	);
};
