import { IServiceStatusDescriptions, ServiceStatus } from "../../../../models";
import { IServiceBasePageData } from "../ServiceBasePageData";
import { getServiceEpiDataBasedOnStatus } from "./ServicePageDataDescriptions";

// ************************************
// PUBLIC
// ************************************

export const getServicePageDataPitchCards = (config: IServiceBasePageData, status: ServiceStatus) => {
    const { translations } = config;

    let relevantDescription : IServiceStatusDescriptions | undefined = getServiceEpiDataBasedOnStatus(translations, status);

    return {
        items: relevantDescription?.pitches,    
        title: relevantDescription?.pitchesTitle,
        description: relevantDescription?.pitchesDescription
    };
}