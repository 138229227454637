import React from 'react';
import classNames from 'classnames';
import { ActionButton, Card, MSRichText } from '../../../components';
import { v4 as uuid4 } from 'uuid';
import {
	ContentGrid,
	Direction,
	IAction,
	IComponent,
	Image,
	Text,
	getCounterTheme,
} from '@fjordkraft/fjordkraft.component.library';
import { MS_ButtonTemplate, ServicePitchCardTemplate, h4TextPrefab } from '../../../Prefabs';
import { useApplicationContext } from '../../../contexts';
import { Constants } from '../../../data';
import './FlexiblePitchCard.scss';

export interface IFlexiblePitchCard extends IComponent {
	image: string;
	imageAlt?: string;
	imageStyle?: 'default' | 'rounded' | 'curved';
	title: string;
	description: string;
	totalCount?: 1 | 2 | 3 | 4;
	type?: Direction;
	action?: IAction;
	alignment?: 'align-left' | 'align-center' | 'align-right';
}

export const FlexiblePitchCard = (props: IFlexiblePitchCard) => {
	// ************************************
	// Properties
	// ************************************

	const classPrefix = 'flexible-pitch-card';
	const { desktopView } = useApplicationContext();
	const {
		theme = 'Light',
		brand = Constants.uiBrand,
		image,
		imageAlt,
		imageStyle = 'default',
		title,
		description,
		totalCount = 1,
		type = 'column',
		alignment = 'align-left',
		action,
	} = props;

	// ************************************
	// Render
	// ************************************

	return (
		<Card
			className={classPrefix}
			key={uuid4()}
			template={ServicePitchCardTemplate(totalCount, theme, desktopView, type, !!image)}
			brand={brand}
			scaling={1}
			alignment="center"
		>
			{image && (
				<Image
					className={classNames(`${classPrefix}__image`, {
						[`${classPrefix}__image--clamped`]: type === 'row',
						[`${classPrefix}__image--clamped__style--${imageStyle}`]: imageStyle && type === 'row',
						[`${classPrefix}__image__style--${imageStyle}`]: imageStyle,
					})}
					src={image}
					alt={imageAlt ?? `${title} bilde`}
					scalingBy={'height'}
					fit={'cover'}
				/>
			)}
			<ContentGrid
				className={classNames(`${classPrefix}__description`, {
					[`${classPrefix}__description--clamped`]: type === 'row' && image,
				})}
				direction={'column'}
				alignment={'top-left'}
				boxSizing="border-box"
				tagType="section"
				wrap={false}
				gap={1}
			>
				<Text
					{...h4TextPrefab()}
					className={classNames(`${classPrefix}__description__title`, {
						[`${classPrefix}__stretch-element`]: true,
					})}
					align={desktopView ? alignment : 'align-left'}
					theme={getCounterTheme(theme)}
					brand={brand}
				>
					{title}
				</Text>
				<ContentGrid
					className={`${classPrefix}__bottom`}
					direction="column"
					alignment="top-center"
					tagType="section"
					boxSizing="border-box"
				>
					<MSRichText
						className={`${classPrefix}__bottom__text`}
						brand={brand}
						theme={getCounterTheme(theme)}
						text={description}
						alignment="lawful"
						customization={{
							type: 'p',
							align: desktopView ? alignment : 'align-left',
						}}
					/>
				</ContentGrid>
				{action && (
					<ContentGrid
						className={`${classPrefix}__bottom__nav`}
						direction="column"
						alignment={desktopView ? 'center' : 'center-right'}
						gap={1}
						tagType="nav"
					>
						{action && (
							<ActionButton
								iconPlacement={'Right'}
								action={action}
								padding="medium"
								brand={brand}
								template={MS_ButtonTemplate(theme, 'primary')}
							/>
						)}
					</ContentGrid>
				)}
			</ContentGrid>
		</Card>
	);
};
