import React, { useEffect, useState } from 'react';
import { IComponent, ContentGrid, StyleGrid } from '@fjordkraft/fjordkraft.component.library';
import { v4 as uuid4 } from 'uuid';
import { useApplicationContext } from '../../contexts';
import { BenefitListItemModal } from '../../modals';
import { IBenefit } from '../../models';
import { ArticleItem } from '../ArticleItem/ArticleItem';
import './BenefitList.scss';

export interface IBenefitList extends IComponent {
	benefits?: IBenefit[];
	excludeParamCategories?: boolean;
	recruitLink?: string;
	selectedCategories?: string[];
	showMoreText?: string;
}
export const BenefitList = (props: IBenefitList) => {
	// ************************************
	// Properties
	// ************************************
	const {
		benefits,
		recruitLink,
		theme = 'Light',
		brand,
		showMoreText,
		selectedCategories
	} = props;
	const classPrefix = 'benefit-list';

	// ************************************
	// Lifecycle
	// ************************************

	const [benefitModal, setBenefitModal] = useState<IBenefit | null>(null);
	const [showBenefitModal, setShowBenefitModal] = useState<boolean>(false);
	const { desktopView } = useApplicationContext();

	useEffect(() => {
		// when showing modal we remove scrolling on the entire page, we activate it again 10 ms after we remove modal, to contain scrolling
		let body = document.querySelector('body');
		if (showBenefitModal) {
			if (body) {
				body.style.overflow = 'hidden';
			}
		} else {
			setTimeout(() => {
				let body = document.querySelector('body');
				if (body) {
					body.style.overflow = 'auto';
				}
			}, 10);
		}

		return () => {
			// this sets it back if we leave the page
			if (body) {
				body.style.overflow = 'auto';
			}
		};
	}, [showBenefitModal]);

	// ************************************
	// Helper functions
	// ************************************

	const _filterBenefits = (benefits: IBenefit[], selectedCategories?: string[]) => {
		if (selectedCategories && selectedCategories.length > 0) {
			const filtered = benefits?.filter((benefit) => {
				return selectedCategories.some((category) => benefit.categories.includes(category));
			});
			return filtered;
		} else {
			return benefits;
		}
	};

	// ************************************
	// Render functionality
	// ************************************

	const _renderBenefitList = () => {
		if (benefits) {
			let filtered = _filterBenefits(benefits, selectedCategories);

			return filtered.map((benefit: IBenefit) => {
				return (
					<ArticleItem
						key={uuid4()}
						theme={theme}
						brand={brand}
						image={{
							src: benefit.imageUrl,
							alt: 'inspiring benefit image',
						}}
						pitchHeader={{
							image: {
								src: benefit.sponsorLogoUrl,
								alt: 'Benefit sponsor logo',
							},
							title: benefit.sponsorName,
							text: benefit.sponsorText,
						}}
						title={benefit.heading}
						label={benefit.sponsorTag}
						description={benefit.description}
						additions={benefit.categories}
						action={{
							text: showMoreText,
							onClick: () => {
								setBenefitModal(benefit);
								setShowBenefitModal(true);
							},
						}}
					/>
				);
			});
		}
	};

	// ************************************
	// Render
	// ************************************

	return (
		<ContentGrid
			className={classPrefix}
			alignment={'center-left'}
			direction={'column'}
			tagType="section"
		>
			<StyleGrid
				className={`${classPrefix}__benefits`}
				alignment={'center'}
				direction={'row'}
				gap={desktopView ? 10 : 4}
			>
				{benefits && _renderBenefitList()}
			</StyleGrid>

			<BenefitListItemModal
				data={benefitModal}
				recruitLink={recruitLink}
				open={showBenefitModal}
				close={() => setShowBenefitModal(false)}
			/>
		</ContentGrid>
	);
};
