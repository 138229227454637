import React from 'react';
import { ICustomerMessage } from '../../models';
import {
    Button,
    Text,
    StyleGrid,
    ContentGrid,
    IComponent,
    getCounterTheme,
    LocationType,
    BrandColors,
} from '@fjordkraft/fjordkraft.component.library';
import { format } from 'date-fns';
import classnames from 'classnames';
import { h4TextPrefab, paragraphTextPrefab } from '../../Prefabs';
import './MessageItem.scss';

export interface IMessageItem extends IComponent {
    item: ICustomerMessage;
    placement: LocationType;
    onClick: (item: ICustomerMessage) => void;
    deletedText?: string;
}

export const MessageItem = (props: IMessageItem) => {
    // ************************************
    // Properties
    // ************************************

    const classPrefix = 'message-item';
    const { theme, brand, item, active, placement, onClick, deletedText } =
        props;

    // ************************************
    // Helper Functionality
    // ************************************

    const getMsgStatus = () => {
        if (item.opened) {
            return 'read';
        } else {
            return 'unread';
        }
    };

    // ************************************
    // Render
    // ************************************

    return (
        <Button
            className={classnames(classPrefix, {
                [`${classPrefix}--${placement}`]: placement,
                [`${classPrefix}--${theme}--${placement}`]: placement && theme,
                [`${classPrefix}--${theme}--active`]: active,
                [`${classPrefix}--deleted`]: item.deleted,
            })}
            theme={theme}
            brand={brand}
            action={{
                onClick: () => {
                    onClick(item);
                },
            }}
        >
            <StyleGrid
                className={`${classPrefix}__content`}
                theme={theme}
                brand={brand}
                direction={'row'}
                alignment={'center-left'}
            >
                <span
                    className={classnames(
                        `${classPrefix}__content__active-graphic`,
                        {
                            [`${classPrefix}__content__active-graphic--${theme}`]:
                                theme,
                            [`${classPrefix}__content__active-graphic--${theme}--${getMsgStatus()}`]:
                                item,
                        }
                    )}
                />
                <ContentGrid
                    className={`${classPrefix}__content__details`}
                    theme={theme}
                    brand={brand}
                    tagType={'section'}
                    direction={'column'}
                    alignment={'center-left'}
                    gap={4}
                    gapType={'px'}
                >
                    {item?.content?.subject && (
                        <Text
                            {...h4TextPrefab()}
                            className={`${classPrefix}__content__details__title`}
                            weight={item.opened ? 400 : 700}
                            theme={getCounterTheme(theme)}
                            brand={brand}
                            align={'align-left'}
                            wrap={'nowrap'}
                        >
                            {item.content.subject}
                        </Text>
                    )}
                    {item?.content?.dateCreated && (
                        <Text
                            {...paragraphTextPrefab()}
                            theme={getCounterTheme(theme)}
                            brand={brand}
                            faded
                            align="align-left"
                        >
                            {format(
                                new Date(item.content.dateCreated),
                                'dd.MM.yyyy'
                            )}
                        </Text>
                    )}
                </ContentGrid>
                {item.deleted && deletedText && (
                    <ContentGrid
                        className={`${classPrefix}__content__deleted-note`}
                        alignment={'center-left'}
                        direction={'row'}
                        tagType={'section'}
                    >
                        <Text
                            {...paragraphTextPrefab()}
                            color={BrandColors['status-shade-light-3']}
                        >
                            {deletedText}
                        </Text>
                    </ContentGrid>
                )}
            </StyleGrid>
        </Button>
    );
};
