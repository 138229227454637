import {
	BrandColors,
	IComponentTemplate,
	IComponentTransform,
	IThemedColors,
	Theme,
} from '@fjordkraft/fjordkraft.component.library';

const _transform = (mode: 'window' | 'fit' | 'fit-absolute') => {
	let width: string = '';
	let height: string = '';

	switch (mode) {
		case 'window':
			width = '100vw';
			height = '100vh';
			break;
		case 'fit':
		case 'fit-absolute':
			width = '100%';
			height = '100%';
			break;
	}

	return {
		width,
		height,
		grid: {
			tagType: 'section',
			direction: 'column',
			alignment: 'center',
			gap: 1,
			boxSizing: 'border-box',
		},
	} as IComponentTransform;
};

const _palette = (mode: 'window' | 'fit' | 'fit-absolute') => {
	return {
		Light: {
			background: {
				color: BrandColors['background-shade-light-2'],
				opacity: mode === 'fit-absolute' ? 0.5 : 1,
			},
			text: { color: BrandColors['text-shade-dark-3'] },
			icon: { color: BrandColors['primary-shade-light-2'] },
		},
		Dark: {
			background: {
				color: BrandColors['background-shade-dark-3'],
				opacity: mode === 'fit-absolute' ? 0.3 : 1,
			},
			text: { color: BrandColors['text-shade-light-1'] },
			icon: { color: BrandColors['primary-shade-light-2'] },
		},
	} as IThemedColors;
};

export const LoadingModalTemplate = (mode: 'window' | 'fit' | 'fit-absolute', theme: Theme = 'Light') => {
	return {
		transform: _transform(mode),
		palette: _palette(mode)[theme],
	} as IComponentTemplate;
};
