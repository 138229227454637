import { Constants } from '../../../../data';
import { IDefaultProps } from '../../../../models';
import { getText } from '../../../../services';

export interface IUsePointsWebshopPageData extends IDefaultProps {}

export const getPageContent = async (config: IUsePointsWebshopPageData) => {
	const { translations } = config;

	if (translations) {
		return {
			...config,
			sub: {
				title: getText('onWebshopTitle', translations),
				back: {
					text: getText('usePoints', translations),
					link: Constants.paths.usePointsPage,
				},
			}
		};
	}
};
