import React, {useEffect, useMemo, useState} from "react";
import {
    ConsentContext, useApplicationCoreDataContext,
    useApplicationDefaultContext,
    useApplicationOverlayWrapperContext,
    useApplicationServicehandlerContext
} from "../../../contexts";
import {ConsentStateEnum, IConsent, MarketingConsentType} from "../../../models";
import {GetToastPrefab, ToastPrefabsType} from "../../../Prefabs";
import {getMarketingConsentText, getText} from "../../../services";
import {IWebToast} from "../../../blocks";
import {CallState} from "@fjordkraft/fjordkraft.component.library";
import {Partner} from "../../../models/interfaces/sanity/MarketingConsent";
import {useApplicationGuestsAndHostsContext} from "../../../contexts/variations/ApplicationGuestsAndHostsContext";

export const ApplicationConsentWrapper = (props: any) => {
    // ************************************
	// Properties
	// ************************************

    const {  GETTYPED, PUT } = useApplicationServicehandlerContext();
    const {defaultProps} = useApplicationDefaultContext();
    const {setTempToasts} = useApplicationOverlayWrapperContext();
    const {userData} = useApplicationCoreDataContext()
    const { isGuest } = useApplicationGuestsAndHostsContext();


    // ************************************
	// Handling
	// ************************************

    const PUT_CONSENTS = async (consents: IConsent[], translations: any) => {
        if(consents?.length && !isGuest) {
            const updatedConsents = await Promise.all(consents.map(async (consent: IConsent) => {
                return await PUT('Customers/v2/marketingConsent', consent);
            }));

            const hasFailedCall = updatedConsents.some(resp => resp.callState !== "success")
            const callState = hasFailedCall ? 'error' : 'success' as CallState
            setTempToasts([GetToastPrefab(ToastPrefabsType.CallStateToast, {
                text: getText(`consent_toast_${callState}`, translations),
                status: callState,
                desktopView: defaultProps.desktopView
            } ) as IWebToast])

            await updateConsents();
        }
    }

    const _shouldUseNewConsents = (marketingConsentData: IConsent[], newConsentsTypes: MarketingConsentType[]) =>
         marketingConsentData.some((consent) =>
            newConsentsTypes.includes(consent.consentName) &&
            [ConsentStateEnum.ACCEPTED, ConsentStateEnum.PENDING, ConsentStateEnum.DECLINED]
                .includes(consent.value as ConsentStateEnum))

    const _shouldShowConsentPopup = (marketingConsentsData: IConsent[], newConsentsTypes: MarketingConsentType[]) =>
        marketingConsentsData.some((consent) =>
            newConsentsTypes.includes(consent.consentName) &&
            consent.value === ConsentStateEnum.PENDING)


    const getPartners = async (marketingConsents: IConsent[] | null) => {
        const sanityConsentResponse = await getMarketingConsentText();
        const sanityPartners  = sanityConsentResponse.reduce((acc: {[key: string]: Partner[] }, curr) => {
            if(curr?.partners) acc[curr.consentId] = curr.partners
            return acc
        }, {})

        return marketingConsents?.map((consent: IConsent) => {
            if(Object.keys(sanityPartners).includes(consent.consentName)) return {...consent, partners: sanityPartners[consent.consentName]}
            return consent
        }) ?? []
    }
    const _hasAnsweredNewConsents = (marketingConsents: IConsent[], newConsentTypes: MarketingConsentType[]) => {
        return !marketingConsents?.some((consent: IConsent) => newConsentTypes.includes(consent.consentName) && consent.value === ConsentStateEnum.PENDING)

    }

    const setConsentValues = async (marketingConsents: IConsent[] ) => {
        const consentsWithPartners = await getPartners(marketingConsents)
        setConsents(consentsWithPartners);
        const newConsentsTypes = [MarketingConsentType.PersonalizedMarketing, MarketingConsentType.GeneralMarketing]
        const newConsentsAndReservationTypes = [...newConsentsTypes, MarketingConsentType.DisableMarketing]
        setHasAnsweredNewConsent(_hasAnsweredNewConsents(marketingConsents,  newConsentsAndReservationTypes))
        setUseNewConsents(_shouldUseNewConsents(consentsWithPartners, newConsentsAndReservationTypes))
        setShowConsentPopup(_shouldShowConsentPopup(consentsWithPartners, newConsentsTypes))

        const disableMarketingConsent = findConsent(consentsWithPartners, MarketingConsentType.DisableMarketing)
        setIsReservedAgainstMarketing(disableMarketingConsent?.value === ConsentStateEnum.ACCEPTED);
    }

    const findConsent = (consents: IConsent[] | undefined, consentName: MarketingConsentType) => consents?.find(e => e.consentName === consentName);



    const updateConsents = async () : Promise<IConsent[] | null> => {
        let marketingConsents = await GETTYPED<IConsent[]>('Customers/v2/marketingConsent', true);
        if(marketingConsents?.data?.length) setConsentValues(marketingConsents.data)

        return marketingConsents.data;
    }

    // ************************************
	// Lifecycle
	// ************************************
    
    const [consents, setConsents] = useState<IConsent[]>();
    const [useNewConsents, setUseNewConsents] = useState<boolean>();
    const [showConsentPopup, setShowConsentPopup] = useState<boolean>()
    const [isReservedAgainstMarketing, setIsReservedAgainstMarketing] = useState<boolean>();
    const [hasAnsweredNewConsent, setHasAnsweredNewConsent] = useState<boolean>();

    const _context = useMemo(() => {
        return {
            consents,
            PUT_CONSENTS,
            updateConsents,
            useNewConsents,
            showConsentPopup,
            isReservedAgainstMarketing,
            hasAnsweredNewConsent
        }
    }, [ consents, useNewConsents, showConsentPopup, isReservedAgainstMarketing, hasAnsweredNewConsent]);

    useEffect(() => {
            updateConsents();
    }, [userData]);

    // ************************************
	// Render
	// ************************************

    return(
        <ConsentContext.Provider value={_context}>
            {props.children}
        </ConsentContext.Provider>
    );
}