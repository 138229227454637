import React, { useEffect } from 'react';
import {
	IComponent,
	Text,
	getCounterTheme,
	BrandColors,
	Icon,
	IconType,
	StyleGrid,
	ContentGrid,
} from '@fjordkraft/fjordkraft.component.library';
import classnames from 'classnames';
import {
	useApplicationContext,
	useApplicationCoreDataContext,
	useApplicationServicehandlerContext,
	useMoveContext,
} from '../../../contexts';
import './MoveFormPages.scss';
import { format } from 'date-fns';
import { Constants } from '../../../data';
import _ from 'lodash';
import { createString } from '../../../services';
import { ActionButton, MSRichText } from '../../../components';
import { MS_ButtonTemplate } from '../../../Prefabs';

export interface IMoveFormPageResult extends IComponent {
	setPage: (page: number) => void;
}

export const MoveFormPageResult = (props: IMoveFormPageResult) => {
	// ************************************
	// Properties
	// ************************************
	const { activeBrand, activeTheme } = useApplicationContext();
	const { translation } = useApplicationCoreDataContext();
	const { customerServiceFeature } = useApplicationServicehandlerContext();
	const { address, terminationDate, requestResult, responseContent } = useMoveContext();
	const { id, setPage } = props;
	const classPrefix = 'move-form-page-result';

	const formattetDate = terminationDate ? format(new Date(terminationDate), `dd.MM.yyyy`) : '';

	// ************************************
	// Lifecycle
	// ************************************
	useEffect(() => {
		if (_.isEmpty(address) || terminationDate == null) setPage(1);
	}, [address, terminationDate]);

	// ************************************
	// Render
	// ************************************
	return (
		<StyleGrid
			id={id}
			className={`${classPrefix}`}
			brand={activeBrand}
			theme={activeTheme}
			alignment={'center'}
			direction={'column'}
			gap={5}
		>
			{address && formattetDate && translation && (
				<>
					<ContentGrid
						className={`${classPrefix}__icon-container`}
						direction={'row'}
						alignment={'center'}
						brand={activeBrand}
						theme={activeTheme}
						tagType={'figure'}
					>
						<Icon
							theme={activeTheme}
							brand={activeBrand}
							type={requestResult == 'success' ? IconType.HelloFace : IconType.ErrorFace}
							color={BrandColors['icon-shade-light-3']}
							width={7}
							height={7}
						/>
					</ContentGrid>
					<Text
						className={`${classPrefix}__title`}
						type={'h1'}
						brand={activeBrand}
						theme={activeTheme}
						color={BrandColors['primary-shade-light-2']}
						size={'extra-large'}
						weight={700}
					>
						{requestResult == 'success'
							? translation.resultPage?.successTitle
							: translation.resultPage?.failureTitle}
					</Text>

					{requestResult == 'success' ? (
						<>
							<Text
								className={`${classPrefix}__subTitle`}
								type={'h2'}
								brand={activeBrand}
								theme={getCounterTheme(activeTheme)}
								size={'regular'}
								weight={400}
								align={'align-center'}
							>
								{customerServiceFeature ? (
									<MSRichText
										brand={activeBrand}
										theme={getCounterTheme(activeTheme)}
										text={createString(translation.resultPage?.successDescriptionCustomerService, {
											address: address?.address,
											date: formattetDate,
											orderId: responseContent?.moveOutId ?? 'N/A',
										})}
									/>
								) : (
									<MSRichText
										brand={activeBrand}
										theme={getCounterTheme(activeTheme)}
										text={createString(translation.resultPage?.successDescription, {
											address: address?.address,
											date: formattetDate,
										})}
									/>
								)}
							</Text>
							<ActionButton
								template={MS_ButtonTemplate(activeTheme, 'primary')}
								className={classnames(`${classPrefix}__button`, {
									[`${classPrefix}__button--${activeTheme}`]: activeTheme,
								})}
								padding="default"
								brand={activeBrand}
								action={{
									link: customerServiceFeature
										? translation.resultPage?.orderForAddressButtonLinkCustomerService
										: translation.resultPage?.orderForAddressButtonLink,
									text: customerServiceFeature
										? translation.resultPage?.orderForAddressButtonTitleCustomerService
										: translation.resultPage?.orderForAddressButtonTitle,
									icon: IconType.ExternalLinkThick,
								}}
							/>
						</>
					) : (
						<>
							<Text
								className={`${classPrefix}__subTitle`}
								type={'h2'}
								brand={activeBrand}
								theme={getCounterTheme(activeTheme)}
								size={'regular'}
								weight={400}
								align={'align-center'}
							>
								<MSRichText
									text={translation?.resultPage?.failureDescription}
									brand={activeBrand}
									theme={getCounterTheme(activeTheme)}
								/>
							</Text>
							<ActionButton
								template={MS_ButtonTemplate(activeTheme, 'primary')}
								className={classnames(`${classPrefix}__button`, {
									[`${classPrefix}__button--${activeTheme}`]: activeTheme,
								})}
								padding="default"
								brand={activeBrand}
								action={{
									link: Constants.paths.movePage,
									text: translation.resultPage?.backButtonTitle,
								}}
							/>
						</>
					)}
				</>
			)}
		</StyleGrid>
	);
};
