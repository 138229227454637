import _, { isEmpty } from 'lodash';
import { Constants } from '../../../data';
import { getText } from '../../../services';
import { fetchKickbackTransactions } from '../../../services/collection/BenefitService';
import { format } from 'date-fns';
import { nb } from 'date-fns/locale';
import { getRequest } from '../../../contexts';
import { IDefaultProps } from '../../../models';

export interface IKickbackTransactions {
	allTransactions: ITransaction[];
}

export interface ITransaction {
	amount: number;
	date: string;
	dateTime: string;
	message: string;
	typeId: string;
	typeStatus?: string;
	expiryDate?: string;
}
export interface IMonthlyTransaction {
	month: number;
	monthStr: string;
	year: number;
	transactions: ITransaction[];
	monthTotal: number;
}

export interface IYearlyTransaction {
	year: number;
	months: IMonthlyTransaction[];
	yearTotal: number;
	allTotal: number;
}

export interface IBenefitsTransactionsPageData extends IDefaultProps {}

// ************************************
// PUBLIC
// ************************************

export const getPageContent = async (config: IBenefitsTransactionsPageData) => {
	const { services, translations } = config;
	const { GET } = services;

	if (GET !== undefined && translations) {
		let data = await fetchKickbackTransactionsData(GET);
		let yearlyTransactions = getYearlyTransactions(data.allTransactions, translations);

		return {
			...config,
			sub: {
				title: getText('pageTitle', translations),
				back: {
					text: getText('transactionsBack', translations),
					link: Constants.paths.benefitsPage,
				},
			},
			transactions: yearlyTransactions,
			expiringTransactions: data.expiringTransactions,
		};
	}
};

// ************************************
// PRIVATE
// ************************************

const getYearlyTransactions = (transactions: ITransaction[], translations: any) => {
	let yearlyTransactions: IYearlyTransaction[] = [];
	let allTotal = 0;

	if (transactions && transactions.length > 0) {
		transactions.forEach((transaction) => {
			let year = new Date(transaction.dateTime).getFullYear();
			if (year) {
				allTotal += transaction.amount > 0 ? transaction.amount : 0;
				let foundYear = _.find(yearlyTransactions, (y) => {
					return y.year === year;
				});
				if (!foundYear) {
					let months = getMonthlyTransactions(transactions, year, translations);
					let total = 0;
					months.forEach((m) => {
						total += m.monthTotal;
					});
					yearlyTransactions.push({
						year: year,
						months: months,
						yearTotal: total,
						allTotal: 0,
					});
				}
			}
		});
	}

	yearlyTransactions.forEach((year) => {
		year.allTotal = allTotal;
	});

	return yearlyTransactions;
};

const getMonthlyTransactions = (transactions: ITransaction[], forYear: number, translations: any) => {
	let monthlyTransactions: IMonthlyTransaction[] = [];

	if (transactions && transactions.length > 0) {
		transactions.forEach((transaction) => {
			if (transaction.dateTime && transaction.date) {
				_handleMonthTransaction(monthlyTransactions, transaction, forYear, translations);
			}
		});
	}

	return monthlyTransactions;
};

const _handleMonthTransaction = (
	monthlyTransactions: IMonthlyTransaction[],
	transaction: ITransaction,
	forYear: number,
	translations: any
) => {
	let transactionDate = new Date(transaction.dateTime);
	let year = transactionDate.getFullYear();

	if (transaction.typeId) {
		transaction.typeStatus = parseStatus(transaction.typeId, translations);
	}

	if (year === forYear) {
		let monthStr = format(transactionDate, 'MMMM', {
			locale: nb,
		});
		let month = transactionDate.getMonth() + 1;
		let foundMonth = _.find(monthlyTransactions, (m) => {
			return m.month === month;
		});

		if (monthStr && month) {
			if (foundMonth) {
				foundMonth.transactions.push(transaction);
				foundMonth.monthTotal += transaction.amount;
			} else {
				monthlyTransactions.push({
					month: month,
					monthStr: monthStr,
					year: year,
					transactions: [transaction],
					monthTotal: transaction.amount,
				});
			}
		}
	}

	return monthlyTransactions;
};

const parseStatus = (typeId: string, translations: any) => {
	if (typeId) {
		return getText(`transaction${typeId}`, translations);
	} else {
		return getText('transactionConfirmed', translations);
	}
};

// ************************************
// Fetch latest kickback transaction
// ************************************

export const fetchKickbackTransactionsData = async (GET: getRequest) => {
	let resp = await fetchKickbackTransactions(GET);
	if (!isEmpty(resp)) {
		return resp;
	} else {
		return {};
	}
};
