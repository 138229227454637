import { useContext, createContext } from 'react';

export type IDefaultPageContentClampType = 'clamp' | 'scale' | 'stretch';

interface IDefaultPageContext {
    setContentLoading: (contentLoading: boolean) => void;
    contentLoading: boolean;
    mainContentClamping: IDefaultPageContentClampType;
    setMainContentClamping: (value: IDefaultPageContentClampType) => void;
    ScrollIntoAppView: () => void;
    ScrollIntoMainView: () => void;
}

export const DefaultPageContext =
    createContext<IDefaultPageContext>({
        contentLoading: true,
        setContentLoading: () => { },
        mainContentClamping: 'clamp',
        setMainContentClamping: () => { },
        ScrollIntoAppView: () => { },
        ScrollIntoMainView: () => { },
    });

export const useDefaultPageContext = () => useContext(DefaultPageContext);
