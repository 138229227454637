import React, { useState } from 'react';
import { ContentGrid, StyleGrid, Text } from '@fjordkraft/fjordkraft.component.library';
import { IBlock } from '../../models';
import classNames from 'classnames';
import { v4 as uuid4 } from 'uuid';
import { FlexiblePitchCard, IFlexiblePitchCard } from './FlexiblePitchCard';
import { useApplicationContext } from '../../contexts';
import './PitchCardsBlock.scss';
import { h3TextPrefab, paragraphTextPrefab } from '../../Prefabs';

export interface IPitchCardsBlock extends IBlock {
	title?: string;
	description?: string;
	items: IFlexiblePitchCard[];
	overrideScaling?: boolean;
}

type maxScaling = 'default' | 'medium' | 'large' | 'none';

export const PitchCardsBlock = (props: IPitchCardsBlock) => {
	// ************************************
	// Properties
	// ************************************

	const { id, className, theme, brand, items, overrideScaling, title, description } = props;
	const { desktopView } = useApplicationContext();
	const classPrefix = 'pitch-cards-block';
	const wordCountClamp = 25;

	// ************************************
	// Lifecycle
	// ************************************

	const [maxScaling, setMaxScaling] = useState<maxScaling>('default');

	// ************************************
	// Helpers
	// ************************************

	const _handleoverallScaling = (rowCount: number) => {
		let scaling: maxScaling = 'default';

		if (overrideScaling) {
			scaling = 'none';
		} else {
			if (rowCount > 4) {
				scaling = 'large';
			} else if (rowCount === 3) {
				scaling = 'default';
			} else if (rowCount === 4) {
				scaling = 'medium';
			}
		}

		if (scaling !== maxScaling) {
			setMaxScaling(scaling);
		}
	};

	// ************************************
	// Render Functionality
	// ************************************

	const _renderItem = (item: IFlexiblePitchCard) => {
		return (
			<FlexiblePitchCard
				theme={theme}
				brand={brand}
				key={uuid4()}
				totalCount={4}
				{...item}
			/>
		);
	};

	const _renderPitchCards = () => {
		let rows: any[] = [];
		let columns: any[] = [];

		items.forEach((item: IFlexiblePitchCard) => {
			let wordCount: number = item.description.split(' ').length;

			if (wordCount > wordCountClamp || !desktopView) {
				item.type = 'row';
				columns.push(_renderItem(item));
			} else {
				rows.push(_renderItem(item));
				item.type = 'column';
			}
		});

		_handleoverallScaling(rows.length);

		return {
			columns,
			rows:
				rows.length > 0 ? (
					<StyleGrid
						className={`${classPrefix}__row-container`}
						direction="row"
						alignment="center"
						boxSizing="border-box"
						gap={2}
						spaceBetween
					>
						{rows}
					</StyleGrid>
				) : undefined,
		};
	};

	// ************************************
	// Render
	// ************************************

	return (
		<ContentGrid
			direction={'column'}
			alignment="top-center"
			boxSizing="border-box"
			gap={1}
			tagType="section"
		>
			{title && <Text {...h3TextPrefab()}>{title}</Text>}
			{description && <Text {...paragraphTextPrefab()}>{description}</Text>}
			<StyleGrid
				id={id}
				className={classNames(`${classPrefix}`, {
					[`${classPrefix}--${maxScaling}`]: maxScaling,
					[`${classPrefix}--distance`]: title || description,
					[`${className}`]: className,
				})}
				direction={'column'}
				alignment="top-center"
				boxSizing="border-box"
				gap={2}
			>
				{_renderPitchCards().rows}
				{_renderPitchCards().columns}
			</StyleGrid>
		</ContentGrid>
	);
};
