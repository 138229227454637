import React from 'react';
import { getCounterTheme, StyleGrid } from '@fjordkraft/fjordkraft.component.library';
import { DetailCheckboxCollectionBlock, IDetailCheckboxCollectionBlockData } from '../../../blocks';
import { MSRichText } from '../../../components';
import { Constants } from '../../../data';
import { IDefaultViewProps, PageV2 } from '../../PageV2';
import { getPageContent } from './ReservationPageData';
import { v4 as uuid4 } from 'uuid';
import './ReservationPage.scss';
import { useConsentContext } from '../../../contexts';
import {ConsentStateEnum, MarketingConsentType} from '../../../models';
import { translateCheckboxValue } from '../../../services';
import { useApplicationGuestsAndHostsContext } from '../../../contexts/variations/ApplicationGuestsAndHostsContext';

export interface IReservationPage extends IDefaultViewProps {
	detailCheckboxCollection: IDetailCheckboxCollectionBlockData[];
	description: string;
	isGuest: boolean;
}

export const ReservationPage = () => {
	// ************************************
	// Properties
	// ************************************

	const classPrefix = 'reservation-page';
	const { consents, PUT_CONSENTS } = useConsentContext();
	const { isGuest } = useApplicationGuestsAndHostsContext();

	const _handleReservation = async (state: boolean, translations: any) => {
		const consentsToUpdate = [
			{ consentName: MarketingConsentType.DisableMarketing, value: translateCheckboxValue(state) },
		];

		if(!state){
			const newConsentsTypes = [MarketingConsentType.PersonalizedMarketing, MarketingConsentType.GeneralMarketing]
			consents?.forEach(consent => {
				if(newConsentsTypes.includes(consent.consentName) &&
					(consent.value === ConsentStateEnum.PENDING || consent.value === ConsentStateEnum.DECLINED)){
					  consentsToUpdate.push({consentName: consent.consentName, value: ConsentStateEnum.UNANSWERED as ConsentStateEnum})
				}
			})
		}
		if (state)
			consentsToUpdate.push(
				{ consentName: MarketingConsentType.GeneralMarketing, value: translateCheckboxValue(false) },
				{ consentName: MarketingConsentType.PersonalizedMarketing, value: translateCheckboxValue(false) }
			);
		PUT_CONSENTS(consentsToUpdate, translations);
	};

	// ************************************
	// Render Functionality
	// ************************************

	const _renderDetailCheckboxCollections = (config: IReservationPage) => {
		const { detailCheckboxCollection, activeBrand, activeTheme, isGuest } = config;

		return detailCheckboxCollection.map((item: IDetailCheckboxCollectionBlockData) => {
			return (
				<DetailCheckboxCollectionBlock
					{...item}
					key={uuid4()}
					className={classPrefix}
					theme={activeTheme}
					brand={activeBrand}
					disabled={isGuest}
					onChange={(value, state) => _handleReservation(state, config.translations)}
				/>
			);
		});
	};

	// ************************************
	// Properties
	// ************************************

	return PageV2({
		setup: {
			pageType: Constants.epiServerPageNames.reservationPage.type,
			usesSubPage: true,
			pageClamp: 'clamp',
		},
		dependencies: [{ consents }, { isGuest }],
		handleData: getPageContent,
		render: (config: IReservationPage) => {
			const { activeBrand, activeTheme, description, desktopView} = config;

			return (
				<StyleGrid
					className={`${classPrefix}`}
					direction="column"
					alignment="top-left"
					gap={4}
					boxSizing="border-box"
				>
					<MSRichText
						className={`${classPrefix}__description`}
						brand={activeBrand}
						theme={getCounterTheme(activeTheme)}
						text={description}
						alignment="lawful"
						customization={{
							type: 'p',
							align: desktopView ? 'align-center' : 'align-left',
						}}
					/>
					{_renderDetailCheckboxCollections(config)}
				</StyleGrid>
			);
		},
	});
};