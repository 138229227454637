import { IconType, getCounterTheme } from '@fjordkraft/fjordkraft.component.library';
import { ITextPlankPrefab, MS_ButtonTemplate, getPlankPrefab } from '../../../Prefabs';
import { IActionPlankPrefab } from '../../../Prefabs/Planks/ActionPlankPrefab/ActionPlankPrefab';
import { createString, getText } from '../../../services';
import { IHomePageData } from '../HomePageData';
import { IStatePlank } from '../../../components';
import { format } from 'date-fns';
import { nb } from 'date-fns/locale';

export const NoInstallationMeterPlank = (config: IHomePageData): IStatePlank | undefined => {
	const { installation } = config.user;

	let orderInprogress = false;
	if (
		// Short cached situation, 5 minutes max.
		installation?.missingMeterNumberOrderStatus === 'SUCCESS' ||
		// Little longer progress, usually gets fixed during a day.
		installation?.missingMeterNumberOrderStatus === 'IN_PROGRESS' ||
		// Rejected in elhub, usually takes less than 7 days.
		installation?.missingMeterNumberOrderStatus === 'AWAITING_MANUAL_REVIEW'
	) {
		orderInprogress = true;
	}

	return {
		promise: orderInprogress ? _getMeterNumberInProgressPlank(config) : _getNoInstallationMeterPlank(config),
	} as IStatePlank;
};

const _getInProgressTexts = (config: IHomePageData): { description: string; right?: string } => {
	const { installation } = config.user;

	switch (installation?.missingMeterNumberOrderStatus) {
		case 'SUCCESS':
			return {
				description: createString(getText('plankCachedInstallationMeterNumberSent', config.translations), {
					meterNumber: installation?.missingMeterNumberOrderCachedMeterNumber,
				}),
			};
		case 'IN_PROGRESS':
			return {
				description: getText('plankCachedInstallationMeterDesc', config.translations),
			};
		case 'AWAITING_MANUAL_REVIEW':
			const date = installation.missingMeterNumberOrderLastUpdatedDate;

			return {
				description: date
					? createString(getText('plankCachedInstallationMeterDescAwaitingReview', config.translations), {
							lastUpdatedDate: format(new Date(date), 'dd. MMMM', { locale: nb }),
					  })
					: getText('plankCachedInstallationMeterDesc', config.translations),
			};
		default:
			throw new Error(`${installation?.missingMeterNumberOrderStatus} not included in status switch.`);
	}
};

const _getMeterNumberInProgressPlank = (config: IHomePageData) => {
	const { translations, desktopView, activeTheme } = config;

	const { description, right } = _getInProgressTexts(config);

	return getPlankPrefab('Text', {
		theme: getCounterTheme(activeTheme),
		left: {
			icon: desktopView ? IconType.MoreInfo : undefined,
			title: getText('plankCachedInstallationMeterTitle', translations),
			description: description,
		},
		right: {
			description: right,
		},
	} as ITextPlankPrefab);
};

const _getNoInstallationMeterPlank = (config: IHomePageData) => {
	const { translations, setShowAddInstallationMeter, desktopView, activeTheme } = config;

	return getPlankPrefab('Action', {
		theme: getCounterTheme(activeTheme),
		left: {
			icon: desktopView ? IconType.MoreInfo : undefined,
			title: getText('plankNoInstallationMeterTitle', translations),
			description: getText('plankNoInstallationMeterDesc', translations),
		},
		right: {
			template: MS_ButtonTemplate(activeTheme, 'secondary'),
		},
		action: {
			text: getText('plankNoInstallationMeterValue', translations),
			onClick: () => {
				setShowAddInstallationMeter(true);
			},
		},
	} as IActionPlankPrefab);
};
