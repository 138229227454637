import { IComponentTransform, IThemedColors, TextInputTemplate, Theme, BrandColors } from "@fjordkraft/fjordkraft.component.library"

const _invalidTransform = {
    padding: '0.25rem',
    grid: {
        tag: 'div',
        direction: 'row',
        alignment: 'center-left',
        boxSizing: 'border-box'
    }
} as IComponentTransform;

const _invalidPalette = {
    Light: {
        text: { color: BrandColors["status-shade-light-3"] }
    },
    Dark: {
        text: { color: BrandColors["status-shade-light-3"] }
    }
} as IThemedColors;

export const CustomerEditorInputTemplate = (theme: Theme = 'Light') => {
    let base = TextInputTemplate(theme);

    base.invalidatedText.transform = _invalidTransform;
    base.invalidatedText.palette = _invalidPalette[theme];

    return base;
}