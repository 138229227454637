import React from 'react';
import {
    Text,
    Icon,
    IconType,
    BrandColors,
    IAction,
    ContentGrid,
    getCounterTheme,
} from '@fjordkraft/fjordkraft.component.library';
import classnames from 'classnames';
import { IBlock } from '../../../models';
import { Card, MSRichText, ActionButton } from '../..';
import { TipCardTemplate } from './TipCardTemplate';
import { MS_ButtonTemplate, h3TextPrefab } from '../../../Prefabs';
import { useApplicationContext } from '../../../contexts';
import { Constants } from '../../../data';
import './TipCard.scss';

export interface ITipCard extends IBlock {
    title: string;
    content: string;
    action?: IAction;
    iconType?: IconType | "None";
    iconColor?: BrandColors;
    iconSize?: number;
}

/**
 *   The TipCard is used to display tips and short informational tidbits
 *   @type {string} title (required)
 *   @type {string} content (required)
 *   @type {IAction} action (optional) (Default: undefined)
 *   @type {icon} IconType (optional) (Default: lightbulb)
 *   @type {BrandColors} iconColor (optional) (Default: primary-shade-light-2)
 *   @type {number} iconSize (optional) (Default: 3)
 */
export const TipCard = (props: ITipCard) => {
    // ************************************
    // Properties
    // ************************************

    const {
        id,
        title,
        content,
        action,
        iconType = IconType.LightBulb,
        iconColor = BrandColors['primary-shade-light-2'],
        iconSize = 3,
        className,
        brand = Constants.uiBrand,
        theme = 'Light',
    } = props;
    const classPrefix = 'ms_tip-card';
    const { desktopView } = useApplicationContext();

    // ************************************
    // Render
    // ************************************

    return (
        <Card
            id={id}
            brand={brand}
            theme={theme}
            className={classnames(classPrefix, {
                [`${classPrefix}--${theme}`]: theme,
                [`${className}`]: className,
            })}
            template={TipCardTemplate(theme)}
        >
            {iconType && iconType !== "None" && desktopView && (
                <Icon
                    className={`${classPrefix}__left`}
                    type={iconType}
                    width={iconSize}
                    height={iconSize}
                    color={iconColor}
                    brand={brand}
                />
            )}
            <ContentGrid
                className={`${classPrefix}__right`}
                alignment="top-left"
                direction="column"
                tagType="section"
                boxSizing="border-box"
                gap={1}
            >
                {title && title.length > 0 && (
                    <Text
                        {...h3TextPrefab()}
                        className={`${classPrefix}__right__title`}
                        brand={brand}
                        theme={getCounterTheme(theme)}
                    >
                        {title}
                    </Text>
                )}
                <MSRichText
                    className={`${classPrefix}__right__text`}
                    text={content}
                    brand={brand}
                    theme={getCounterTheme(theme)}
                    alignment='lawful'
                />
                {action && (
                    <ContentGrid
                        className={`${classPrefix}__right__nav`}
                        direction='row'
                        alignment='center-left'
                        gap={2}
                        tagType='nav'
                    >
                        <ActionButton
                            template={MS_ButtonTemplate(theme, 'primary')}
                            brand={brand}
                            action={action}
                            padding="medium"
                        />
                    </ContentGrid>
                )}
            </ContentGrid>
        </Card>
    );
};
