import React, { useEffect, useMemo, useState } from 'react';
import { BrandColors, StyleLoopLimit, getPlankLocation } from "@fjordkraft/fjordkraft.component.library";
import { IStatePlank, StatePlank } from "../../../components";
import { IOptimizedPlankWallBase, OptimizedPlankWallBase } from "../OptimizedPlankWallBase";
import { v4 as uuid4 } from 'uuid';
import { MS_MasterPlankTemplate } from "../../../Prefabs/Templates/planks/MS_MasterPlankTemplate";

export interface IMSPlankWall extends Omit<IOptimizedPlankWallBase, 'customization'> {
    planks: IStatePlank[];
    customization?: {
        titleGap?: StyleLoopLimit;
        blendWithTop?: boolean;
        backgroundColor: {
            Light: BrandColors;
            Dark: BrandColors;
        }
    }
}

interface IPlankItemData {
    plank: IStatePlank;
    promiseId: string;
    show: boolean;
}

export const PlankWall = (props: IMSPlankWall) => {
    // ************************************
    // Properties
    // ************************************

    const {
        theme = 'Light',
        planks,
        disabled,
        customization = {
            titleGap: 2,
            blendWithTop: false,
            backgroundColor: {
                Light: BrandColors["background-shade-light-2"],
                Dark: BrandColors["background-shade-dark-3"]
            }
        },
    } = props;

    // ************************************
    // Lifecycle
    // ************************************

    const [planksToRender, setPlanksToRender] = useState<IPlankItemData[]>();

    useEffect(() => {
        if (planks && planks.length > 0) {
            let plankData = planks.map((plank: IStatePlank) => {
                return {
                    plank,
                    promiseId: uuid4(),
                    show: true
                };
            });
            setPlanksToRender(plankData);
        }
    }, [planks])

    // ************************************
    // Helper Functionality
    // ************************************

    const _getExtendedLocation = (i: number, plank: IStatePlank, length: number): 'middle' | 'top' | 'bottom' | 'single' => {
        let location = getPlankLocation(i, planks.length, plank);

        if (customization?.blendWithTop && i === 0) {
            if (length > 1) {
                location = 'middle';
            } else {
                location = 'bottom';
            }
        }

        return location;
    }

    // ************************************
    // Render Functionality
    // ************************************

    const _handleFailedPlank = (failedPlank: IPlankItemData) => {
        if (planksToRender && planksToRender.length > 0) {
            let planks: IPlankItemData[] = planksToRender.map((plankData: IPlankItemData) => {
                if (failedPlank.promiseId === plankData.promiseId) {
                    plankData.show = false;
                }
                return plankData;
            })
            setPlanksToRender(planks);
        }
    }

    const _renderPlanks = useMemo(() => {
        if (planksToRender && planksToRender.length > 0) {
            let planks: any[] = [];

            planksToRender.forEach((data: IPlankItemData, i: number) => {
                let plank: IStatePlank = data.plank;
                let idRef: string = uuid4();
                let location = _getExtendedLocation(i, plank, planksToRender.length);

                if (data.show) {
                    planks.push(
                        <StatePlank
                            {...plank}
                            promiseId={data.promiseId}
                            disabled={disabled}
                            key={idRef}
                            location={location}
                            template={plank.template ?? MS_MasterPlankTemplate(plank.theme ?? theme, location)}
                            stateFailed={() => {
                                _handleFailedPlank(data);
                            }}
                        />
                    );
                }
            });

            if (planks.length > 0) {
                return planks;
            }
        }
    }, [planksToRender, theme, disabled]);

    // ************************************
    // Render
    // ************************************

    return (
        <>
            {_renderPlanks &&
                <OptimizedPlankWallBase
                    {...props}
                    optimizationSettings={{
                        plankAverageHeightInRem: 4,
                        plankCount: planks.length
                    }}
                >
                    {_renderPlanks}
                </OptimizedPlankWallBase>
            }
        </>
    );
}