import React from 'react';
import {
    ContentGrid,
    IAccordionItem,
    IconType,
    StyleGrid,
    Text,
    Icon,
    getCounterTheme,
    Theme,
    BrandColors,
    IText,
    MasterPlank,
} from '@fjordkraft/fjordkraft.component.library';
import { v4 as uuid4 } from 'uuid';
import { getPlankPrefab, TypedPlank } from '../Planks';
import './Commode.scss';

export interface IAccordionItemPlankPrefab {
    theme: Theme;
    brand: string;
    title?: string;
    description?: string;
    icon?: IconType;
    rightTitle?: string;
    rightDescription?: string;
    rightIcon?: IconType;
    content: TypedPlank[];
    leftTitleCustomization?: IText;
    leftDescriptionCustomization?: IText;
    rightTitleCustomization?: IText;
    rightDescriptionCustomization?: IText;
}

export const Commode = (props: IAccordionItemPlankPrefab) => {
    // ************************************
    // Properties
    // ************************************

    const {
        title,
        description,
        icon,
        content,
        rightTitle,
        rightDescription,
        rightIcon,
        brand,
        theme,
        leftTitleCustomization,
        leftDescriptionCustomization,
        rightTitleCustomization,
        rightDescriptionCustomization
    } = props;
    const classPrefix = 'commode';

    // ************************************
    // Render Functionality
    // ************************************

    const _renderContent = () => {
        return content.map((contentData: TypedPlank) => {
            return (
                <MasterPlank
                    key={uuid4()}
                    {...getPlankPrefab(contentData.type, contentData.data)}
                />
            );
        });
    };

    const _renderLeftContent = () => {
        if (icon || title || description) {
            return (
                <StyleGrid
                    direction="row"
                    alignment="center-left"
                    gap={1}
                    boxSizing='border-box'
                    wrap={false}
                    scaling={3}
                >
                    {icon && (
                        <Icon
                            brand={brand}
                            type={icon}
                            color={BrandColors['primary-shade-light-2']}
                            width={1.5}
                            height={1.5}
                        />
                    )}
                    <ContentGrid
                        alignment="center-left"
                        direction="column"
                        tagType={'section'}
                        boxSizing='border-box'
                    >
                        {title && (
                            <Text
                                brand={brand}
                                weight={500}
                                size={'regular'}
                                family={'main'}
                                type={'h4'}
                                theme={getCounterTheme(theme)}
                                {...leftTitleCustomization}
                            >
                                {title}
                            </Text>
                        )}
                        {description && (
                            <Text
                                brand={brand}
                                weight={500}
                                size={'small'}
                                family={'main'}
                                type={'p'}
                                theme={getCounterTheme(theme)}
                                faded
                                {...leftDescriptionCustomization}
                            >
                                {description}
                            </Text>
                        )}
                    </ContentGrid>
                </StyleGrid>
            );
        } else {
            return undefined;
        }
    }

    const _renderRightContent = () => {
        if (rightIcon || rightTitle || rightDescription) {
            return (
                <StyleGrid
                    direction="row"
                    alignment="center-right"
                    gap={1}
                    boxSizing='border-box'
                    scaling={2}
                >
                    {rightIcon && (
                        <Icon
                            brand={brand}
                            type={rightIcon}
                            color={BrandColors['primary-shade-light-2']}
                            width={1.5}
                            height={1.5}
                        />
                    )}
                    <ContentGrid
                        alignment="center-right"
                        direction="column"
                        tagType={'section'}
                        boxSizing='border-box'
                    >
                        {rightTitle && (
                            <Text
                                brand={brand}
                                weight={500}
                                size={'regular'}
                                family={'main'}
                                type={'h4'}
                                theme={getCounterTheme(theme)}
                                {...rightTitleCustomization}
                            >
                                {rightTitle}
                            </Text>
                        )}
                        {rightDescription && (
                            <Text
                                brand={brand}
                                weight={500}
                                size={'small'}
                                family={'main'}
                                type={'p'}
                                theme={getCounterTheme(theme)}
                                faded
                                {...rightDescriptionCustomization}
                            >
                                {rightDescription}
                            </Text>
                        )}
                    </ContentGrid>
                </StyleGrid>
            );
        } else {
            return undefined;
        }
    }

    return {
        leftTitleContent: _renderLeftContent(),
        rightTitleContent: _renderRightContent(),
        content: (
            <ContentGrid
                className={`${classPrefix}__grid`}
                tagType="nav"
                alignment="center"
                direction="column"
                wrap={false}
                boxSizing='border-box'
            >
                {_renderContent()}
            </ContentGrid>
        ),
    } as IAccordionItem;
};
