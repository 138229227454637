import { BrandColors, IComponentTemplate, IComponentTransform, IThemedColors, Theme } from '@fjordkraft/fjordkraft.component.library';

const _transform = (index: number, length: number) => {
	let allowBorder: boolean = true;

	if(index === length - 1) {
		allowBorder = false;
	}

	return {
		width: '100%',
		height: 'auto',
		padding: '1rem 2rem',
		margin: '0',
		outline: {
			type: 'dashed',
			thickness: 2,
		},
		border: allowBorder ? {
			type: 'solid',
			sides: 'bottom',
			thickness: 1,
		} : undefined,
		grid: {
			direction: 'row',
			alignment: 'center-left',
			gap: 2,
			boxSizing: 'border-box',
			wrap: false,
		},
	} as IComponentTransform;
}

const _palette = {
	Light: {
		background: { color: BrandColors['transparent'] },
		text: { color: BrandColors['text-shade-dark-3'] },
		border: { color: BrandColors['text-shade-dark-3'], opacity: 0.1 },
		icon: { color: BrandColors['primary-shade-light-2'] },
		hover: {
			background: { color: BrandColors['transparent'], opacity: 0.8 },
			text: { color: BrandColors['text-shade-dark-3'], opacity: 0.8 },
			border: { color: BrandColors['text-shade-dark-3'], opacity: 0.8 },
			icon: { color: BrandColors['primary-shade-light-2'], opacity: 0.8 },
		},
		focus: {
			background: { color: BrandColors['transparent'], opacity: 0.8 },
			text: { color: BrandColors['text-shade-dark-3'], opacity: 0.8 },
			border: { color: BrandColors['text-shade-dark-3'], opacity: 0.8 },
			icon: { color: BrandColors['primary-shade-light-2'], opacity: 0.8 },
			outline: { color: BrandColors['primary-shade-light-2'] },
		},
		disabled: {
			background: { color: BrandColors['transparent'], opacity: 0.3 },
			text: { color: BrandColors['text-shade-dark-3'], opacity: 0.3 },
			border: { color: BrandColors['text-shade-dark-3'], opacity: 0.3 },
			icon: { color: BrandColors['primary-shade-light-2'], opacity: 0.3 },
		},
	},
	Dark: {},
} as IThemedColors;

const _paletteHighlighted = {
	Light: {
		background: { color: BrandColors['transparent'] },
		text: { color: BrandColors['text-shade-dark-2'] },
		border: { color: BrandColors['text-shade-dark-3'], opacity: 0.1 },
		icon: { color: BrandColors['text-shade-dark-2'] },
		hover: {
			background: { color: BrandColors['transparent'], opacity: 0.8 },
			text: { color: BrandColors['text-shade-dark-2'], opacity: 0.8 },
			border: { color: BrandColors['text-shade-dark-3'], opacity: 0.8 },
			icon: { color: BrandColors['text-shade-dark-2'], opacity: 0.8 },
		},
		focus: {
			background: { color: BrandColors['transparent'], opacity: 0.8 },
			text: { color: BrandColors['text-shade-dark-2'], opacity: 0.8 },
			border: { color: BrandColors['text-shade-dark-3'], opacity: 0.8 },
			icon: { color: BrandColors['primary-shade-light-2'], opacity: 0.8 },
			outline: { color: BrandColors['text-shade-dark-2'] },
		},
		disabled: {
			background: { color: BrandColors['transparent'], opacity: 0.3 },
			text: { color: BrandColors['text-shade-dark-2'], opacity: 0.3 },
			border: { color: BrandColors['text-shade-dark-3'], opacity: 0.3 },
			icon: { color: BrandColors['text-shade-dark-2'], opacity: 0.3 },
		},
	},
	Dark: {},
} as IThemedColors;

export const ListedButtonTemplate = (theme: Theme, index: number, length: number) => {
	return {
		transform: _transform(index, length),
		palette: _palette[theme]
	} as IComponentTemplate;
};

export const ListedButtonHighlightedTemplate = (theme: Theme, index: number, length: number) => {
	return {
		transform: _transform(index, length),
		palette: _paletteHighlighted[theme]
	} as IComponentTemplate;
};