import React from 'react';
import {
    Alignment,
    BaseComponent,
    BrandColors,
    ContentGrid,
    Direction,
    IAction,
    IComponent,
    IconType,
    StyleGrid,
    StyleLoopLimit,
    Text
} from "@fjordkraft/fjordkraft.component.library";
import classNames from 'classnames';
import { h2TextPrefab, MS_ButtonTemplate, SidePopupCardTemplate } from '../../Prefabs';
import { ActionButton } from '../../components';
import './SidePopupCard.scss';

export interface ISidePopupCard extends IComponent {
    title?: string;
    secondaryAction?: IAction;
    primaryAction?: IAction;
    desktopView?: boolean;
    onClose: () => void;
    customization?: {
        content?: {
            direction: Direction;
            alignment: Alignment;
            gap: StyleLoopLimit;
        }
    }
}

export const SidePopupCard = (props: ISidePopupCard) => {
    // ************************************
    // Properties
    // ************************************

    const {
        id,
        className,
        theme = 'Light',
        brand,
        title,
        secondaryAction,
        primaryAction,
        children,
        desktopView = true,
        onClose,
        customization = {
            content: {
                direction: 'column',
                alignment: 'top-left',
                gap: 2,
            }
        }
    } = props;
    const classPrefix = 'side-popup-card';

    // ************************************
    // Render Functionality
    // ************************************

    const _renderHeader = () => {
        return (
            <ContentGrid
                className={`${classPrefix}__nav`}
                direction='row'
                alignment='top-right'
                tagType='nav'
                boxSizing='border-box'
            >
                <ActionButton
                    template={MS_ButtonTemplate(theme, 'icon')}
                    action={{
                        icon: IconType.Close,
                        onClick: onClose
                    }}
                />
            </ContentGrid>
        );
    }

    const _renderCoreNav = () => {
        if (secondaryAction || primaryAction) {
            return (
                <ContentGrid
                    className={`${classPrefix}__nav__bottom`}
                    direction='row'
                    alignment='center'
                    tagType='nav'
                    boxSizing='border-box'
                    spaceBetween={!!(secondaryAction && primaryAction)}
                >
                    {secondaryAction &&
                        <ActionButton
                            template={MS_ButtonTemplate(theme, 'secondary')}
                            action={{
                                text: secondaryAction.text,
                                onClick: () => {
                                    onClose();
                                }
                            }}
                            padding='medium'
                        />
                    }
                    {primaryAction &&
                        <ActionButton
                            template={MS_ButtonTemplate(theme, 'primary')}
                            action={primaryAction}
                            padding='medium'
                        />
                    }
                </ContentGrid>
            );
        }
    }

    // ************************************
    // Render
    // ************************************

    return (
        <BaseComponent
            id={id}
            className={classNames(classPrefix, {
                [`${className}`]: className
            })}
            brand={brand}
            template={SidePopupCardTemplate(theme, desktopView)}
        >
            {_renderHeader()}
            {title &&
                <Text
                    {...h2TextPrefab()}
                    color={BrandColors["primary-shade-dark-2"]}
                    className={`${classPrefix}__text`}
                >
                    {title}
                </Text>
            }
            <StyleGrid
                className={`${classPrefix}__view`}
                direction='column'
                alignment={'top-left'}
                boxSizing='border-box'
            >
                <ContentGrid
                    className={`${classPrefix}__view__content`}
                    direction={customization.content?.direction ?? 'column'}
                    alignment={customization.content?.alignment ?? 'top-left'}
                    gap={customization.content?.gap ?? 2}
                    tagType='section'
                    boxSizing='border-box'
                >
                    {children}
                </ContentGrid>
            </StyleGrid>
            {_renderCoreNav()}
        </BaseComponent>
    );
}