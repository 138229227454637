import { getRequest } from '../../contexts';
import { Constants } from '../../data';
import { ICustomerMessage, IDefaultProps } from '../../models';
import { IResponse, getText } from '../../services';

export interface IInboxPageData extends IDefaultProps {}

export const getPageContent = async (config: IInboxPageData) => {
	const { services, translations } = config;
	const { GET } = services;

	if (GET !== undefined) {
		let messages: ICustomerMessage[] = await _getMessages(GET);

		return {
			...config,
			sub: {
				title: getText('pageTitle', translations),
				back: {
					text: getText('back', translations),
					link: Constants.paths.energyPage,
				},
			},
			messages,
		};
	}
};

const _getMessages = async (GET: getRequest) => {
	let resp: IResponse = await GET('Messages');
	let msg: ICustomerMessage[] = [];

	if (resp.data && resp.callState === 'success') {
		msg = resp.data;
	}

	return msg;
};
