import React, { useEffect, useMemo, useState } from 'react';
import {
	CallState,
	CheckInput,
	ContentGrid,
	IAction,
	IComponent,
	IOption,
	LinkButtonTemplate,
	LoaderGraphic,
	Text,
	TextType,
	getCounterTheme,
} from '@fjordkraft/fjordkraft.component.library';
import {
	IAdditonalAddonService,
	IAddonData,
	ICustomer,
	ICustomerAccountInformation,
	ICustomerInstallation,
	IServicePage,
	IServicePopupBlock,
	IServicePopupCheckbox,
	IServiceStatusDescriptions,
	OnClickServiceOrderAction,
	ServiceStatus,
	ServiceStatusDescription,
} from '../../models';
import { PopupCard } from '../PopupCard/PopupCard';
import {
	capitalizeFirstLetter,
	fetchTrumfOrderHandling,
	getText,
	handleServiceOrderAndCancel,
	hasText,
	redirectToSteddiOrderAndCancelUrl,
} from '../../services';
import SuccessSVG from '../../assets/art/service-order-success.svg?react';
import FailureSVG from '../../assets/art/service-order-failure.svg?react';
import WaitingSVG from '../../assets/art/service-order-waiting.svg?react';
import { MSCheckInputTemplate, getPlankPrefab, h3TextPrefab, paragraphTextPrefab } from '../../Prefabs';
import { v4 as uuid4 } from 'uuid';
import { ActionButton, ICardPoint, MSRichText, ServicePointsCard } from '../../components';
import { IMSPlankWall, IPlankHouse, PlankHouseBlock, RadioPlankWall } from '../../blocks';
import { ICheckInputPlankPrefab } from '../../Prefabs/Planks/CheckInputPlankPrefab/CheckInputPlankPrefab';
import { useAddonServicesContext, useApplicationContext, useApplicationServicehandlerContext } from '../../contexts';
import _ from 'lodash';
import './ServiceManagementPopup.scss';
import { Constants } from '../../data';
import {
	getAdditionalAddonServicesPoints,
	getAddonActiveFutureFromDate,
	getCoreDetailPoints,
	pointOutStateDescription,
} from '../../pages/ServicesPagesWrapper/ServicePage/Datahandling';
import { IServiceBasePageData } from '../../pages/ServicesPagesWrapper/ServicePage/ServiceBasePageData';

export interface IServiceOrderOrCancel extends Omit<IServiceManagementPopup, 'onClose'> {}

export interface IServiceManagementPopup extends IComponent {
	installation?: ICustomerInstallation;
	userData?: ICustomer;
	account?: ICustomerAccountInformation;
	status: ServiceStatus;
	page: IServicePage;
	addonStateResponse?: any;
	predefinedStatusStep?: ServiceOrderAndCancelSteppes;
	selectedSubService?: IOption;
	onClose?: () => void;
}

export type ServiceOrderAndCancelSteppes =
	| 'SELECT'
	| 'DEFAULT'
	| 'SUCCESS'
	| 'REDIRECT_SUCCESS'
	| 'ERROR'
	| 'FAILURE'
	| 'IN_PROGRESS'
	| 'LOADING';

export const ServiceManagementPopup = (props: IServiceManagementPopup) => {
	// ************************************
	// Properties
	// ************************************

	const {
		page,
		theme = 'Light',
		brand,
		status,
		account,
		predefinedStatusStep,
		installation,
		selectedSubService,
		onClose,
	} = props;
	const classPrefix = 'service-stage-popup';
	const { GET, GETTYPED, POST } = useApplicationServicehandlerContext();
	const { addonStates, updateAddonStates } = useAddonServicesContext();
	const { desktopView } = useApplicationContext();

	// ************************************
	// Initial setup
	// ************************************

	const _getInitialStep = (): ServiceOrderAndCancelSteppes => {
		if (predefinedStatusStep) {
			return predefinedStatusStep;
		} else {
			return page.servicePageId === 'trumf' ? 'SELECT' : 'DEFAULT';
		}
	};

	const _getInitalSelectedOption = (): OnClickServiceOrderAction => {
		switch (status) {
			case 'ACTIVE':
			case 'TERMINATING':
			case 'ACTIVE_FUTURE':
			case 'ACTIVATING':
			case 'ORDER_IN_PROGRESS':
			case 'ORDER_WAITING_FOR_CUSTOMER':
				return 'CANCEL';
			case 'INACTIVE':
			case 'TERMINATED':
			case 'ORDER_CANCELLED_BY_CUSTOMER':
			case 'ORDER_FAILED':
				return 'ORDER';
		}
	};

	const _getInitialClickAllowState = (step: ServiceOrderAndCancelSteppes): boolean => {
		if (step === 'SELECT' || !checkboxStates) {
			return true;
		}

		return false;
	};

	// ************************************
	// Lifecycle
	// ************************************

	const [currentStep, setCurrentStep] = useState<ServiceOrderAndCancelSteppes>(_getInitialStep());
	const [selectedOption, setSelectedOption] = useState<OnClickServiceOrderAction>(_getInitalSelectedOption());
	const [popupStatusData, setPopupStatusData] = useState<IServicePopupBlock>();
	const [checkboxStates, setCheckboxStates] = useState<IServicePopupCheckbox[]>();
	const [allowOrderOrCancelClick, setAllowOrderOrCancelClick] = useState<boolean>(
		_getInitialClickAllowState(currentStep)
	);

	useEffect(() => {
		if (page && status) {
			_handlePopupData(page, status);
		}
	}, [page, status]);

	// Checks if all checkboxes are active before enabling onclick
	useEffect(() => {
		if (checkboxStates && checkboxStates.length > 0 && currentStep === 'DEFAULT') {
			let allActive: boolean = false;
			checkboxStates.forEach((checkbox: IServicePopupCheckbox) => {
				allActive = checkbox.alreadyChecked;
			});
			setAllowOrderOrCancelClick(allActive);
		} else if (currentStep === 'SELECT') {
			setAllowOrderOrCancelClick(true);
		}
	}, [checkboxStates, currentStep]);

	const _handlePopupData = async (page: IServicePage, status: ServiceStatus) => {
		if (page && status) {
			let data: IServiceStatusDescriptions | undefined = await _getPopupData(status);

			if (data?.servicePopupBlock) {
				let isSubbed: boolean = _getServiceStatusDescriptionFromStatus(status) === 'subscribed';
				let house: IPlankHouse = await _getDetailData();

				if (house) {
					data.servicePopupBlock.house = house;
				}

				if (selectedSubService) {
					let relevantExtraDesc = _getRelevantExtraService(selectedSubService.value);

					if (relevantExtraDesc) {
						data.servicePopupBlock.selectedExtraProductDesc = isSubbed
							? relevantExtraDesc.textSubbed
							: relevantExtraDesc.textUnSubbed;
					}
				}

				setPopupStatusData(data?.servicePopupBlock);

				if (data?.servicePopupBlock?.checkboxes && !checkboxStates) {
					// The data should rather be reset on close of component instead of on open, but choosing easy fix for now
					const notAcceptedCheckboxes = data.servicePopupBlock.checkboxes.map((e) => ({
						...e,
						alreadyChecked: false,
					}));
					setCheckboxStates(notAcceptedCheckboxes);
				}
			}
		}
	};

	// ************************************
	// Getters
	// ************************************

	const _getRelevantExtraService = (id: string): IAdditonalAddonService | undefined => {
		return _.find(page.serviceAdditionalAddonBlocks, (serv: IAdditonalAddonService) => {
			return serv.serviceId === id;
		});
	};

	const _getPopupData = async (status: ServiceStatus): Promise<IServiceStatusDescriptions | undefined> => {
		let data: IServiceStatusDescriptions | undefined = undefined;

		if (page.serviceDescriptions && page.serviceDescriptions.length > 0) {
			page.serviceDescriptions.forEach((desc: IServiceStatusDescriptions) => {
				let epiState: ServiceStatusDescription = _getServiceStatusDescriptionFromStatus(status);
				if (desc.status === epiState || epiState === 'default') {
					data = desc;
				}
			});
		}

		return data;
	};

	const _getServiceStatusDescriptionFromStatus = (status: ServiceStatus): ServiceStatusDescription => {
		switch (status) {
			case 'ACTIVE':
			case 'TERMINATING':
			case 'ACTIVE_FUTURE':
				return 'subscribed';
			case 'INACTIVE':
			case 'TERMINATED':
			case 'ORDER_CANCELLED_BY_CUSTOMER':
			case 'ORDER_FAILED':
				return 'unsubscribed';
			case 'ACTIVATING':
			case 'ORDER_IN_PROGRESS':
			case 'ORDER_WAITING_FOR_CUSTOMER':
				return 'pending';
			default:
				return 'default';
		}
	};

	const _getTitle = useMemo(() => {
		if (popupStatusData) {
			switch (currentStep) {
				case 'FAILURE':
				case 'ERROR':
					if (page.servicePageId === 'trumf') {
						return getText(
							`failureResultTitle${capitalizeFirstLetter(selectedOption)}`,
							popupStatusData,
							'texts'
						);
					} else {
						return getText(`failureResultTitle`, popupStatusData, 'texts');
					}
				case 'IN_PROGRESS':
					return getText('waitingResultTitle', popupStatusData, 'texts');
				case 'SUCCESS':
					return getText(`successResultTitle`, popupStatusData, 'texts');
				case 'REDIRECT_SUCCESS':
					// We force the "order" title for (Trumf) redirects:
					return getText(`successResultTitleOrder`, popupStatusData, 'texts');
				case 'SELECT':
					return getText('confirmTitle', popupStatusData, 'texts');
				default:
					if (page.servicePageId === 'trumf') {
						return getText(`title${capitalizeFirstLetter(selectedOption)}`, popupStatusData, 'texts');
					} else {
						return getText(`title`, popupStatusData, 'texts');
					}
			}
		}

		return '';
	}, [popupStatusData, currentStep]);

	const _canOrderOrCancelBasedOnInstallations = () => {
		let foundAddon: IAddonData | undefined = _.find(addonStates, (state: IAddonData) => {
			return state.id === page.productDefinitionId;
		});

		let canOrder: boolean = true;
		let canCancel: boolean = false;

		if (foundAddon) {
			if (!canCancel) {
				canCancel = foundAddon.hasActiveInstallation;
			}

			if (canOrder && foundAddon.allInstallationsActive) {
				canOrder = !foundAddon.allInstallationsActive;
			}
		}

		return { canOrder, canCancel };
	};

	const _getOrderOrCancelPlanks = () => {
		let planks: ICheckInputPlankPrefab[] = [];
		let { canOrder, canCancel } = _canOrderOrCancelBasedOnInstallations();

		if (popupStatusData) {
			planks.push(
				getPlankPrefab('CheckInput', {
					left: {
						title: getText('confirmOrderPlankTitle', popupStatusData, 'texts'),
						description: getText('confirmOrderPlankDesc', popupStatusData, 'texts'),
					},
					value: 'ORDER',
					id: '0',
					disabled: !canOrder,
					radioTemplate: MSCheckInputTemplate(theme, 'radio'),
				} as ICheckInputPlankPrefab) as ICheckInputPlankPrefab
			);

			planks.push(
				getPlankPrefab('CheckInput', {
					left: {
						title: getText('confirmCancelPlankTitle', popupStatusData, 'texts'),
						description: getText('confirmCancelPlankDesc', popupStatusData, 'texts'),
					},
					value: 'CANCEL',
					id: '1',
					disabled: !canCancel,
					radioTemplate: MSCheckInputTemplate(theme, 'radio'),
				} as ICheckInputPlankPrefab) as ICheckInputPlankPrefab
			);
		}

		return planks;
	};

	const _getPrimaryButton = useMemo(() => {
		if ((currentStep === 'DEFAULT' || currentStep === 'SELECT') && popupStatusData) {
			return {
				disabled: !allowOrderOrCancelClick,
				text: getText('continueButton', popupStatusData, 'texts'),
				onClick: () => {
					if (currentStep === 'SELECT') {
						setCurrentStep('DEFAULT');
					} else if (currentStep === 'DEFAULT') {
						_onConfirmClick();
					}
				},
			};
		}
	}, [allowOrderOrCancelClick, currentStep, popupStatusData]);

	const _getSecondaryButton = () => {
		if (currentStep !== 'LOADING') {
			if (page.servicePageId === 'trumf' && currentStep === 'DEFAULT') {
				return {
					text: getText('backButton', popupStatusData, 'texts'),
					onClick: () => {
						setCurrentStep('SELECT');
					},
				} as IAction;
			} else {
				return {
					text: getText('closePopupButton', popupStatusData, 'texts'),
					onClick: () => {
						if (onClose) {
							onClose();
						}
					},
				} as IAction;
			}
		}
	};

	const _getTitleAlignment = () => {
		if (currentStep === 'SELECT' || currentStep === 'DEFAULT') {
			return 'align-left';
		} else {
			return 'align-center';
		}
	};

	// ************************************
	// Order Handling
	// ************************************

	const _onConfirmClick = async () => {
		setCurrentStep('LOADING');

		let result: ServiceOrderAndCancelSteppes = 'DEFAULT';

		if (page.servicePageId === 'forutsigbar' && account) {
			const steddiResponse = await redirectToSteddiOrderAndCancelUrl(account, selectedOption, GET);
			result = steddiResponse;
		} else if (page.servicePageId === 'trumf') {
			result = await fetchTrumfOrderHandling(selectedOption, GET, POST);
		} else {
			result = await handleServiceOrderAndCancel(props, GET, POST);
		}

		setCurrentStep(result);
		updateAddonStates();
	};

	// ************************************
	// Helpers

	const _getDetailData = async (): Promise<IPlankHouse> => {
		let house: IPlankHouse = { plankWalls: [], wallGap: 1 };
		let walls;
		let state: string = _getServiceStatusDescriptionFromStatus(status);

		if (state === 'unsubscribed') {
			walls = await _handleSubscribedPlankData();
		} else {
			walls = await _handleUnsubscribedPlankData();
		}

		if (walls?.wallTop && walls.wallTop.planks.length > 0) {
			house.plankWalls.push(walls.wallTop);
		}

		if (walls?.wallMiddle && walls.wallMiddle.planks.length > 0) {
			house.plankWalls.push(walls.wallMiddle);
		}

		if (walls?.wallBottom && walls.wallBottom.planks.length > 0) {
			house.plankWalls.push(walls.wallBottom);
		}

		return house;
	};

	const _handleUnsubscribedPlankData = async () => {
		let pointList: ICardPoint[] = [];
		let additionalAddonPoints = await getAdditionalAddonServicesPoints(
			{
				services: {
					GETTYPED,
				},
				user: {
					installation,
				},
				translations: page,
				addonStates,
			} as IServiceBasePageData,
			status
		);

		if (page.orderMethodType === 'installation' && page.servicePageId !== 'trumf') {
			pointList.push({
				id: 'installationAddressPlank',
				leftText: getText('address', page),
				rightText: installation?.address?.streetAddress ?? '',
			});
		}

		if (installation) {
			const activeFromDate = getAddonActiveFutureFromDate(installation, addonStates, page.productDefinitionId);
			if (activeFromDate) {
				pointList.push({
					leftText: getText('activeFrom', page),
					rightText: activeFromDate,
				});
			}
		}

		if (additionalAddonPoints && selectedSubService?.value !== 'none') {
			pointList = pointList.concat(additionalAddonPoints);
		}

		return _filterPointsIntoWalls(pointList);
	};

	const _handleSubscribedPlankData = async () => {
		let pointList: ICardPoint[] = [];
		let blockData: any = _getRelatedDynamicInfoBlock();
		let { points } = await getCoreDetailPoints(
			{
				services: {
					GETTYPED,
				},
				translations: page,
				user: {
					installation,
				},
				addonStates,
			},
			blockData
		);
		let additionalAddonPoints = await getAdditionalAddonServicesPoints(
			{
				services: {
					GETTYPED,
				},
				translations: page,
				user: {
					installation,
				},
				addonStates,
			} as IServiceBasePageData,
			status
		);

		if (additionalAddonPoints && selectedSubService?.value !== 'none') {
			pointList = points.concat(additionalAddonPoints);
		} else {
			pointList = points;
		}

		return _filterPointsIntoWalls(pointList);
	};

	const _filterPointsIntoWalls = (pointList: ICardPoint[]) => {
		let wallTop: IMSPlankWall = { planks: [] };
		let wallMiddle: IMSPlankWall = { planks: [] };
		let wallBottom: IMSPlankWall = { planks: [] };

		if (pointList && pointList.length > 0) {
			for (let point of pointList) {
				if (
					page.orderMethodType === 'installation' &&
					installation &&
					point.id === 'installationAddressPlank'
				) {
					wallTop.planks.push(_getPointPlank(point));
				} else if (point.subDetail) {
					wallBottom.planks.push(_getPointPlank(point));
				} else {
					wallMiddle.planks.push(_getPointPlank(point));
				}
			}
		}

		return { wallTop, wallMiddle, wallBottom };
	};

	const _getRelatedDynamicInfoBlock = () => {
		let dynamicInfoBlock: any = undefined;

		if (page?.serviceDescriptions && page.serviceDescriptions.length > 0) {
			page.serviceDescriptions.forEach((statusDesc: IServiceStatusDescriptions) => {
				let desc: IServiceStatusDescriptions | undefined = pointOutStateDescription(statusDesc, status);

				if (desc?.infoBlocks && desc.infoBlocks.length > 0) {
					for (let info of desc.infoBlocks) {
						if (info.blockId === Constants.services.dynamicInfoDetailsId) {
							dynamicInfoBlock = info;
							break;
						}
					}
				}
			});
		}

		return dynamicInfoBlock;
	};

	const _getPointPlank = (point: ICardPoint) => {
		return getPlankPrefab('Text', {
			left: {
				title: point.leftText,
				description: point.extraLeftText,
			},
			right: {
				title: point.rightText,
				description: point.extraRightText,
			},
		});
	};

	// ************************************
	// Render Functionality
	// ************************************

	const _renderText = (textKey: string, tag: TextType = 'p') => {
		let prefab = tag === 'p' ? paragraphTextPrefab() : h3TextPrefab();

		return <Text {...prefab}>{getText(textKey, popupStatusData, 'texts')}</Text>;
	};

	const _renderDetailsHouse = (houseData: IPlankHouse | undefined) => {
		if (houseData) {
			return (
				<PlankHouseBlock
					{...houseData}
					theme={theme}
					brand={brand}
				/>
			);
		}
	};

	const _renderStepContent = (element: any, textKey: string) => {
		return (
			<ContentGrid
				className={`${classPrefix}__step-content`}
				direction="column"
				alignment="center"
				boxSizing="border-box"
				tagType="section"
				gap={2}
			>
				{element}
				{_renderText(textKey)}
			</ContentGrid>
		);
	};

	const _renderLoading = () => {
		let useSelectStatus: boolean = page.servicePageId === 'trumf';

		return _renderStepContent(
			<LoaderGraphic
				theme={theme}
				brand={brand}
				thickness={'regular'}
				scaleThickness={0.5}
				size={4}
			/>,
			useSelectStatus ? `loadingText${capitalizeFirstLetter(selectedOption)}` : 'loadingText'
		);
	};

	const _renderSuccess = () => {
		let useSelectStatus: boolean = page.servicePageId === 'trumf';
		return _renderStepContent(
			<SuccessSVG />,
			useSelectStatus ? `successConclusion${capitalizeFirstLetter(selectedOption)}` : 'successConclusion'
		);
	};

	const _renderRedirectSuccess = () => {
		let useSelectStatus: boolean = page.servicePageId === 'trumf';
		return _renderStepContent(<SuccessSVG />, useSelectStatus ? `successConclusionOrder` : 'successConclusion');
	};

	const _renderError = () => {
		return _renderStepContent(<FailureSVG />, 'failureConclusion');
	};

	const _renderInProgress = () => {
		let useSelectStatus: boolean = page.servicePageId === 'trumf';
		return _renderStepContent(<WaitingSVG />, useSelectStatus ? '' : 'waitingConclusion');
	};

	const _renderLinks = useMemo(() => {
		if (popupStatusData?.links && popupStatusData.links.length > 0) {
			return (
				<ContentGrid
					className={`${classPrefix}__links`}
					tagType="nav"
					alignment="top-left"
					gap={2}
					direction="column"
				>
					{popupStatusData.links.map((action: any) => {
						return (
							<ActionButton
								key={uuid4()}
								action={{
									...action,
									...{ link: action.url },
								}}
								template={LinkButtonTemplate(theme)}
								brand={brand}
							/>
						);
					})}
				</ContentGrid>
			);
		}
	}, [popupStatusData]);

	const _renderCheckboxes = useMemo(() => {
		if (checkboxStates && checkboxStates.length > 0) {
			return checkboxStates.map((checkbox: IServicePopupCheckbox, index: number) => {
				if (checkbox.useCheckbox) {
					return (
						<ContentGrid
							className={`${classPrefix}__content__checkbox-row`}
							key={uuid4()}
							direction="row"
							alignment="top-left"
							tagType="section"
							gap={2}
							wrap={false}
						>
							<CheckInput
								brand={brand}
								theme={theme}
								active={checkbox.alreadyChecked}
								value={checkbox.alreadyChecked}
								template={MSCheckInputTemplate(theme, 'checkbox')}
								onChange={(value) => {
									let arr: IServicePopupCheckbox[] = checkboxStates.slice();
									arr[index].alreadyChecked = !checkbox.alreadyChecked;
									setCheckboxStates(arr);
								}}
							/>
							<MSRichText
								className={`${classPrefix}__content__checkbox-row__description`}
								brand={brand}
								theme={getCounterTheme(theme)}
								text={checkbox.checkboxDescription}
								alignment="lawful"
							/>
						</ContentGrid>
					);
				}
			});
		}
	}, [checkboxStates]);

	const _renderOrderOrCancelConfirmationStep = useMemo(() => {
		if (popupStatusData && selectedOption) {
			return (
				<ContentGrid
					className={`${classPrefix}__content`}
					alignment="top-left"
					direction="column"
					gap={2}
					tagType="section"
				>
					{_renderText('confirmDescription')}
					<RadioPlankWall
						theme={theme}
						brand={brand}
						planks={_getOrderOrCancelPlanks()}
						initialActiveIndex={selectedOption === 'ORDER' ? 0 : 1}
						usePlankDisabled={true}
						onSelectItem={(option) => {
							setSelectedOption(option.value);
						}}
					/>
				</ContentGrid>
			);
		}
	}, [selectedOption, popupStatusData, theme, brand]);

	const _renderInformationStep = useMemo(() => {
		if (popupStatusData) {
			return (
				<ContentGrid
					className={`${classPrefix}__content`}
					tagType="section"
					direction="column"
					alignment="top-left"
					gap={2}
					boxSizing="border-box"
				>
					{hasText('subTitle', popupStatusData.texts) && _renderText('subTitle', 'h3')}
					{hasText('introduction', popupStatusData.texts) && _renderText('introduction')}
					{hasText('main', popupStatusData.texts) && _renderText('main')}
					{popupStatusData.dataPoints && popupStatusData.dataPoints.length > 0 && (
						<ServicePointsCard
							className={`${classPrefix}__content__points`}
							points={popupStatusData.dataPoints}
							theme={theme}
							brand={brand}
						/>
					)}
					{_renderDetailsHouse(popupStatusData.house)}
					{popupStatusData.selectedExtraProductDesc && (
						<MSRichText
							className={`${classPrefix}__content__desc`}
							brand={brand}
							theme={getCounterTheme(theme)}
							text={popupStatusData.selectedExtraProductDesc}
							alignment="lawful"
						/>
					)}
					{popupStatusData.description && (
						<MSRichText
							className={`${classPrefix}__content__desc`}
							brand={brand}
							theme={getCounterTheme(theme)}
							text={popupStatusData.description}
							alignment="lawful"
						/>
					)}
					{_renderCheckboxes}
					{_renderLinks}
				</ContentGrid>
			);
		}
	}, [popupStatusData]);

	const _renderStateResult = useMemo(() => {
		if (popupStatusData) {
			switch (currentStep) {
				case 'FAILURE':
				case 'ERROR':
					return _renderError();
				case 'IN_PROGRESS':
					return _renderInProgress();
				case 'SUCCESS':
					return _renderSuccess();
				case 'REDIRECT_SUCCESS':
					return _renderRedirectSuccess();
				case 'LOADING':
					return _renderLoading();
				case 'SELECT':
					return _renderOrderOrCancelConfirmationStep;
				default:
					return _renderInformationStep;
			}
		}
	}, [popupStatusData, currentStep]);

	// ************************************
	// Render
	// ************************************

	return (
		<>
			{popupStatusData && (
				<PopupCard
					theme={theme}
					brand={brand}
					contentTitle={_getTitle}
					contentTitleAlignment={_getTitleAlignment()}
					contentGap={4}
					onClose={onClose}
					primaryAction={_getPrimaryButton}
					secondaryAction={_getSecondaryButton()}
					desktopView={desktopView}
				>
					{_renderStateResult}
				</PopupCard>
			)}
		</>
	);
};
