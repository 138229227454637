import React from 'react';
import { IAction } from '@fjordkraft/fjordkraft.component.library';
import {
    useSubPageLayoutContext,
} from '../../../contexts';
import { getPageContent } from './UsePointsPageData';
import { IMSPlankWall, PlankHouseBlock } from '../../../blocks';
import { useNavigate } from 'react-router-dom';
import { IPageData } from '../../Page';
import { Constants } from '../../../data';
import { PageV2 } from '../../PageV2';
import './UsePointsPage.scss';

export interface IUsePointsPage extends IPageData {
    title: string;
    back: IAction;
    listedOptions: IMSPlankWall;
}

export const UsePointsPage = () => {
    // ************************************
    // Properties
    // ************************************

    const { setInfoBoxContent } = useSubPageLayoutContext();
    const navigateFunc = useNavigate();
    const classPrefix = 'use-points-page';

    // ************************************
    // Render
    // ************************************

    return (
        PageV2({
            setup: {
                pageType: Constants.epiServerPageNames.benefits.type,
                usesSubPage: true
            },
            dependencies: [
                { navigateFunc }
            ],
            handleData: getPageContent,
            onDataChange: (config: IUsePointsPage) => {
                if (setInfoBoxContent) {
                    setInfoBoxContent({ hasInfo: false });
                }
            },
            render: (config: IUsePointsPage) => {
                const { activeBrand, activeTheme, listedOptions } = config;

                return (
                    <PlankHouseBlock
                        className={`${classPrefix}`}
                        theme={activeTheme}
                        brand={activeBrand}
                        plankWalls={[
                            listedOptions
                        ]}
                    />
                )
            }
        })
    );
};
