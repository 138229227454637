import React from 'react';
import {
    Text,
    getCounterTheme,
    ITextCustomization,
    IMasterPlank,
    IAccordionPlank,
} from '@fjordkraft/fjordkraft.component.library';
import classNames from 'classnames';
import { paragraphTextPrefab } from '../../Texts/ParagraphTextPrefab';
import { h4TextPrefab } from '../../Texts/Header4TextPrefab';

export interface IAccordionPlankPrefabCustomization {
    title?: ITextCustomization;
    description?: ITextCustomization;
}

export interface IAccordionPlankPrefab extends IMasterPlank {
    left?: {
        title?: string;
        description?: string;
        customization?: IAccordionPlankPrefabCustomization;
    };
    right?: {
        title?: string;
        description?: string;
        customization?: IAccordionPlankPrefabCustomization;
    };
    content: any;
    desktopView?: boolean;
}

export const AccordionPlankPrefab = (props: IAccordionPlankPrefab) => {
    // ************************************
    // Properties
    // ************************************

    const {
        id,
        className,
        theme = 'Light',
        brand,
        left,
        right,
        template,
        content
    } = props;
    const classPrefix = 'ms-text-plank';

    // ************************************
    // Render Functionality
    // ************************************

    const _renderContent = () => {
        if (content) {
            return (content);
        }
    }

    const _renderLeftTitle = () => {
        if (left?.title) {
            return (
                <Text
                    {...h4TextPrefab()}
                    brand={brand}
                    align={'align-left'}
                    {...left.customization?.title}
                >
                    {left.title}
                </Text>
            );
        }
    }

    const _renderLeftDescription = () => {
        if (left?.description) {
            return (
                <Text
                    {...paragraphTextPrefab()}
                    brand={brand}
                    faded
                    align={'align-left'}
                    {...left.customization?.description}
                >
                    {left.description}
                </Text>
            );
        }
    }

    const _renderRightTitle = () => {
        if (right?.title) {
            return (
                <Text
                    {...h4TextPrefab()}
                    brand={brand}
                    align={'align-right'}
                    {...right.customization?.title}
                >
                    {right.title}
                </Text>
            );
        }
    }

    const _renderRightDescription = () => {
        if (right?.description) {
            return (
                <Text
                    {...paragraphTextPrefab()}
                    brand={brand}
                    align={'align-right'}
                    theme={getCounterTheme(theme)}
                    {...right.customization?.description}
                >
                    {right.description}
                </Text>
            );
        }
    }

    const _renderLeft = () => {
        if (left?.title || left?.description) {
            return ([
                _renderLeftTitle(),
                _renderLeftDescription(),
            ]);
        }
    }

    const _renderRight = () => {
        if (right?.title || right?.description) {
            return ([
                _renderRightTitle(),
                _renderRightDescription(),
            ]);
        }
    }

    // ************************************
    // Render
    // ************************************

    return {
        id,
        className: classNames(classPrefix, {
            [`${className}`]: className
        }),
        brand,
        template,
        leftColumn2: _renderLeft(),
        rightColumn1: _renderRight(),
        children: _renderContent(),
    } as IAccordionPlank;
};
