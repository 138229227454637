import { IconType } from '@fjordkraft/fjordkraft.component.library';
import { Constants } from '../../../data';
import { getText } from '../../../services';
import { IPage, IDefaultProps } from '../../../models';

export interface IInvoiceFixedDueDatePageData extends IDefaultProps {
	fixedDueDateStatus: string;
}

// ************************************
// Main (public)
// ************************************

export const getPageContent = async (config: IInvoiceFixedDueDatePageData) => {
	const { translations, fixedDueDateStatus } = config;

	let isSubbed: boolean = fixedDueDateStatus === '1';
	
	return {
		...config,
		sub: {
			title: _getContent(isSubbed, translations).title,
			subTitle: _getContent(isSubbed, translations).subTitle,
			statusToast: undefined,
			back: {
				text: getText('back', translations),
				link: Constants.paths.invoicePage,
			},
		},
		popups: _getStatusesContent(translations),
		content: _getContent(isSubbed, translations),
	};
};

// ************************************
// Helpers (Private)
// ************************************

const _getContent = (fixed: boolean, translation: IPage) => {
	return fixed
		? {
				title: getText('pageTitleCancel', translation),
				subTitle: getText('pageSubTitleCancel', translation),
				desc: getText('pageDescCancel', translation),
				action: getText('pageActionCancel', translation),
		  }
		: {
				title: getText('pageTitleSub', translation),
				subTitle: getText('pageSubTitleSub', translation),
				desc: getText('pageDescSub', translation),
				action: getText('pageActionSub', translation),
		  };
};

const _getStatusesContent = (translation: IPage) => {
	return {
		success: {
			sub: {
				icon: IconType.AccomplishedFace3,
				title: getText('subSuccessTitle', translation),
				description: getText('subSuccessDescription', translation),
			},
			cancel: {
				icon: IconType.HelloFace,
				title: getText('cancelSuccessTitle', translation),
				description: getText('cancelSuccessDescription', translation),
			},
		},
		error: {
			sub: {
				icon: IconType.ErrorFace,
				title: getText('subErrorTitle', translation),
				description: getText('subErrorDescription', translation),
			},
			cancel: {
				icon: IconType.ErrorFace,
				title: getText('cancelErrorTitle', translation),
				description: getText('cancelErrorDescription', translation),
			},
		},
	};
};