import { IconType, Theme } from '@fjordkraft/fjordkraft.component.library';
import { IPlankHouse } from '../../../blocks';
import { getText } from '../../../services';
import { MS_ButtonTemplate, getPlankPrefab } from '../../../Prefabs';
import { IActionPlankPrefab } from '../../../Prefabs/Planks/ActionPlankPrefab/ActionPlankPrefab';
import { Constants } from '../../../data';
import { IMovePage, IDefaultProps } from '../../../models';

export interface IMoveOverviewPageData extends IDefaultProps {
	house: IPlankHouse;
}

export const getPageContent = async (config: IMoveOverviewPageData) => {
	const { translations, activeTheme, relationship, services } = config;
	const { customerServiceFeature } = services;
	const { isGuest } = relationship;

	if (translations) {
		return {
			...config,
			sub: {
				title: getText('pageOverviewTitle', translations),
				back: {
					text: getText('back', translations),
					link: Constants.paths.energyPage,
				},
			},
			house: _introductionPlankHouse(translations, activeTheme, customerServiceFeature, isGuest),
		};
	}
};

// ************************************
// PRIVATE
// ************************************

const _introductionPlankHouse = (
	translations: IMovePage,
	activeTheme: Theme,
	customerServiceFeature: boolean,
	isGuest: boolean
) => {
	const _plankHouse: IPlankHouse = {
		plankWalls: [
			{
				planks: [
					_getIntroductionMoveInPlank(translations, activeTheme, customerServiceFeature, isGuest),
					_getIntroductionMoveOutPlank(translations, activeTheme, isGuest),
				],
				title: getText('introductionHouseTitle', translations),
			},
		],
	};

	return _plankHouse;
};

const _getIntroductionMoveInPlank = (
	translations: IMovePage,
	activeTheme: Theme,
	customerServiceFeature: boolean,
	isGuest: boolean
) => {
	return getPlankPrefab('Action', {
		left: {
			description: getText('plankIntroductionMoveInDesc', translations),
		},
		right: {
			template: MS_ButtonTemplate(activeTheme, 'primary'),
		},
		action: {
			text: getText('plankIntroductionMoveInValue', translations),
			icon: IconType.ExternalLinkThick,
			disabled: isGuest,
			link: customerServiceFeature
				? translations.resultPage?.orderForAddressButtonLinkCustomerService
				: translations.resultPage?.orderForAddressButtonLink,
		},
		disabled: isGuest,
		actionIconPlacement: 'Right',
		actionButtonPadding: 'medium',
	} as IActionPlankPrefab);
};

const _getIntroductionMoveOutPlank = (translations: any, activeTheme: Theme, isGuest: boolean) => {
	return getPlankPrefab('Action', {
		left: {
			description: getText('plankIntroductionMoveOutDesc', translations),
		},
		right: {
			template: MS_ButtonTemplate(activeTheme, 'primary'),
		},
		action: {
			text: getText('plankIntroductionMoveOutValue', translations),
			icon: IconType.ArrowRight,
			disabled: isGuest,
			link: `${Constants.paths.moveOutPage}`,
		},
		actionIconPlacement: 'Right',
		actionButtonPadding: 'medium',
	} as IActionPlankPrefab);
};
