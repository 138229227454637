import { useContext, createContext } from 'react';
import { IAddonData } from '../../models';

interface IAddonServicesContext {
    addonStates?: IAddonData[];
    updateAddonStates: () => void;
}

export const AddonServicesContext =
    createContext<IAddonServicesContext>({
        addonStates: undefined,
        updateAddonStates: () => { }
    });

export const useAddonServicesContext = () =>
    useContext(AddonServicesContext);
