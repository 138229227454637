import { IconType } from '@fjordkraft/fjordkraft.component.library';
import { _getTextPlankPrefabTemplate } from '.';
import { Constants } from '../../../data';
import { AllowFeature, IsActiveCustomer, getText } from '../../../services';
import { IHomePageData } from '../HomePageData';
import { IStatePlank } from '../../../components';

export const OnboardingPlank = (props: IHomePageData) => {
	const { user, translations, services } = props;
	const { installation, userData } = user;
	const { customerServiceFeature } = services;

	if (
		AllowFeature(Constants.features.onboarding, translations, services.user, customerServiceFeature) &&
		IsActiveCustomer(installation)
	) {
		if (userData?.hasOnboarded) {
			return {
				promise: fetchOnboardingPlank(props),
			} as IStatePlank;
		}
	}
};

const fetchOnboardingPlank = async (props: IHomePageData) => {
	let { activeTheme, activeBrand, translations, desktopView } = props;

	return _getTextPlankPrefabTemplate({
		theme: activeTheme,
		brand: activeBrand,
		title: getText('plankOnboardingTitle', translations),
		description: getText('plankOnboardingDesc', translations),
		link: Constants.paths.onboardingPage,
		iconLeft: desktopView ? IconType.MoreInfo : undefined,
		iconRight: IconType.ChevronRight,
	});
};
