import React, {useState} from 'react';
import {
	BrandColors,
	CallState,
	CheckInput,
	ContentGrid,
	getCounterTheme,
	Icon,
	IconType,
	IRadioPlank,
	StyleGrid,
	Text,
} from '@fjordkraft/fjordkraft.component.library';
import {
	useApplicationAccountSelectContext,
	useApplicationOverlayWrapperContext,
	useConsentContext,
} from '../../../contexts';
import {getPageContent} from './MyCommunicationSettingsPageData';
import {
	GetToastPrefab,
	h2TextPrefab,
	MSCheckInputTemplate,
	paragraphTextPrefab,
	ToastPrefabsType,
} from '../../../Prefabs';
import {capitalizeFirstLetter, getText, IResponse} from '../../../services';
import {LoadingModal} from '../../../modals';
import {MSRichText, TipCard} from '../../../components';
import {Constants} from '../../../data';
import {v4 as uuid4} from 'uuid';
import {IWebToast, RadioPlankWall} from '../../../blocks';
import {IDefaultViewProps, PageV2} from '../../PageV2';
import './MyCommunicationSettingsPage.scss';
import {ConsentStateEnum, MarketingConsentType} from "../../../models";

interface IDescription {
	title: string;
	description: string;
	active?: boolean;
}

export interface IMyCommunicationSettingsPage extends IDefaultViewProps {
	noticesSettings: IDescription;
	consentSettings: IDescription;
	noticesSettingOptions: IRadioPlank[];
}

export const MyCommunicationSettingsPage = () => {
	// ************************************
	// Properties
	// ************************************

	const classPrefix = 'my-communication-settings-page';
	const { setTempToasts } = useApplicationOverlayWrapperContext();
	const { setUseAccountSelectMenu } = useApplicationAccountSelectContext();
	const radioSelectId = uuid4();
	const { useNewConsents, hasAnsweredNewConsent, consents, PUT_CONSENTS} = useConsentContext()

	// ************************************
	// Handle page data
	// ************************************

	const [notification, setNotification] = useState<'email' | 'sms'>('email');
	const [commercialStatus, setCommercialStatus] = useState<CallState>('pending');
	const [activeNotificationOptionStatus, setActiveNotificationOptionStatus] = useState<CallState>('pending');

	// ************************************
	// Async handling
	// ************************************

	const _updateNotificationSettings = async (type: string, config: IMyCommunicationSettingsPage) => {
		const { PUT } = config.services;

		setActiveNotificationOptionStatus('pending');

		try {
			let resp = await PUT('Customers/notificationSettings', type);

			if (resp.callState === 'success') {
				setNotification(type as 'sms' | 'email');
			}

			_handleToasts('notificationOptionUpdate', resp.callState, config);
			setActiveNotificationOptionStatus(resp.callState);
			return resp;
		} catch {
			setActiveNotificationOptionStatus('idle');
			return { callState: 'error', data: null } as IResponse;
		}
	};

	const _handleToasts = (prefix: string, callState: CallState, config: IMyCommunicationSettingsPage) => {
		const { desktopView} = config;

		setTempToasts([
			GetToastPrefab(ToastPrefabsType.CallStateToast, {
				text: getText(`${prefix}${capitalizeFirstLetter(callState)}`, config.translations),
				status: callState,
				desktopView
			}) as IWebToast
		]);
	};

	// ************************************
	// Render
	// ************************************

	return PageV2({
		setup: {
			pageType: Constants.epiServerPageNames.communication.type,
			usesSubPage: true
		},
		dependencies: [
			{ consents },
			{ notification }, 
			{ commercialStatus }, 
			{ activeNotificationOptionStatus }
		],
		renderUpdate: () => {
			setUseAccountSelectMenu(false);
		},
		handleData: getPageContent,
		onDataChange: (config: IMyCommunicationSettingsPage) => {
			if (config) {
				setCommercialStatus('idle');
				setActiveNotificationOptionStatus('idle');
			}
		},
		render: (config: IMyCommunicationSettingsPage) => {
			const { 
				activeBrand,
				activeTheme, 
				consentSettings, 
				noticesSettingOptions, 
				noticesSettings,
				relationship,
				translations
			} = config;

			return (
				<StyleGrid
					className={classPrefix}
					brand={activeBrand}
					theme={activeTheme}
					direction="column"
					alignment="top-left"
					gap={3}
				>
					{useNewConsents && !hasAnsweredNewConsent && (
						<TipCard
							brand={activeBrand}
							iconType={IconType.LightBulb}
							theme={activeTheme}
							title={getText('newConsentTipBoxTitle', translations)}
							content={getText('newConsentTipBoxDescription', translations)}
						/>
						)
					}
					<Text
						{...h2TextPrefab()}
						theme={getCounterTheme(activeTheme)}
						brand={activeBrand}
					>
						{noticesSettings.title}
					</Text>
					<Text
						{...paragraphTextPrefab()}
						theme={getCounterTheme(activeTheme)}
						brand={activeBrand}
					>
						{noticesSettings.description}
					</Text>
					<RadioPlankWall
						id={radioSelectId}
						theme={activeTheme}
						brand={activeBrand}
						planks={noticesSettingOptions}
						initialActiveIndex={notification === 'email' ? 0 : 1}
						disabled={activeNotificationOptionStatus === 'pending' || relationship.isGuest}
						onAsyncSelectItem={async (item) => {
							return _updateNotificationSettings(item.value, config);
						}}
					/>
					{consentSettings && !useNewConsents && (
						<ContentGrid
							className={`${classPrefix}__consent-container`}
							direction="column"
							alignment="top-left"
							tagType="section"
							gap={2}
						>
							<Text
								{...h2TextPrefab()}
								theme={getCounterTheme(activeTheme)}
								brand={activeBrand}
							>
								{consentSettings.title}
							</Text>
							<ContentGrid
								direction="row"
								alignment="top-left"
								boxSizing="border-box"
								gap={2}
								wrap={false}
								tagType="section"
							>
								{relationship.isGuest &&
									<Icon
										type={consentSettings.active ? IconType.CheckmarkThick : IconType.Close}
										color={consentSettings.active ? BrandColors["status-shade-light-1"] : BrandColors["status-shade-light-3"]}
										width={1.5}
										height={1.5}
									/>
								}
								{!relationship.isGuest &&
									<CheckInput
										className={`${classPrefix}__consent-container__checkbox`}
										template={MSCheckInputTemplate(activeTheme, 'checkbox')}
										theme={activeTheme}
										brand={activeBrand}
										type={'checkbox'}
										active={consentSettings.active}
										disabled={commercialStatus === 'pending'}
										onChange={(value, state) => {
											PUT_CONSENTS([{value: state ? ConsentStateEnum.ACCEPTED : ConsentStateEnum.DECLINED , consentName: MarketingConsentType.Legacy1 }], translations)
										}}
									/>
								}
								<MSRichText
									className={`${classPrefix}__consent-container__description`}
									brand={activeBrand}
									theme={getCounterTheme(activeTheme)}
									text={consentSettings.description}
									alignment="lawful"
								/>
							</ContentGrid>
							{commercialStatus === 'pending' && (
								<StyleGrid
									className={`${classPrefix}__consent-container__loader`}
									direction="column"
									alignment="center"
								>
									<LoadingModal mode="fit-absolute" />
								</StyleGrid>
							)}
						</ContentGrid>
					)}
				</StyleGrid>
			);
		},
	});
};