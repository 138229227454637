import {
    BrandColors,
    IComponentColors,
    IComponentTemplate,
    IComponentTransform,
    IconType,
} from '@fjordkraft/fjordkraft.component.library';

export interface IASyncStateButtonIcons {
    error: IconType;
    aborted: IconType;
    success: IconType;
    idle: IconType;
    pending: IconType;
}

export interface IASyncStateButtonTemplate extends IComponentTemplate {
    icon: IASyncStateButtonIcons;
}

// ************************************
// Transform
// ************************************

const _transform = {
    size: {
        width: 'auto',
        height: 'auto',
    },
    border: undefined,
    spacing: 0,
    grid: {
        direction: 'row',
        alignment: 'center',
        gap: 1,
    },
} as IComponentTransform;

// ************************************
// Icon
// ************************************

const _icons: IASyncStateButtonIcons = {
    error: IconType.ErrorIcon,
    aborted: IconType.ErrorIcon,
    success: IconType.CheckmarkThick,
    pending: IconType.PdfIcon,
    idle: IconType.PdfIcon,
};

// ************************************
// Palette
// ************************************

// Default
const _palette: IComponentColors = {
    background: { color: BrandColors['transparent'] },
    text: { color: BrandColors['action-shade-light-1'] },
    icon: { color: BrandColors['action-shade-light-1'] },
    outline: { color: BrandColors['none'] },
    border: { color: BrandColors['none'] },
    extra: [
        {
            key: 'loaderFill',
            value: { color: BrandColors['action-shade-light-1'] },
        },
        {
            key: 'loaderBackground',
            value: { color: BrandColors['action-shade-light-1'] },
        },
    ],
    hover: {
        background: { color: BrandColors['transparent'] },
        text: { color: BrandColors['action-shade-light-1'], opacity: 0.6 },
        icon: { color: BrandColors['action-shade-light-1'], opacity: 0.6 },
        border: { color: BrandColors['none'] },
        outline: { color: BrandColors['none'] },
    },
};

// Error / Abort
const _paletteAbort: IComponentColors = {
    background: { color: BrandColors['transparent'] },
    text: { color: BrandColors['status-shade-light-3'] },
    icon: { color: BrandColors['status-shade-light-3'] },
    outline: { color: BrandColors['none'] },
    border: { color: BrandColors['none'] },
    extra: [
        {
            key: 'loaderFill',
            value: { color: BrandColors['status-shade-light-3'] },
        },
        {
            key: 'loaderBackground',
            value: { color: BrandColors['status-shade-light-3'] },
        },
    ],
};

// Success
const _paletteSuccess: IComponentColors = {
    background: { color: BrandColors['transparent'] },
    text: { color: BrandColors['status-shade-light-1'] },
    icon: { color: BrandColors['status-shade-light-1'] },
    outline: { color: BrandColors['none'] },
    border: { color: BrandColors['none'] },
    extra: [
        {
            key: 'loaderFill',
            value: { color: BrandColors['status-shade-light-1'] },
        },
        {
            key: 'loaderBackground',
            value: { color: BrandColors['status-shade-light-1'] },
        },
    ],
};

// Pending
const _palettePending: IComponentColors = {
    background: { color: BrandColors['transparent'] },
    text: { color: BrandColors['icon-shade-light-2'] },
    icon: { color: BrandColors['icon-shade-light-2'] },
    outline: { color: BrandColors['none'] },
    border: { color: BrandColors['none'] },
    extra: [
        {
            key: 'loaderFill',
            value: { color: BrandColors['action-shade-light-1'] },
        },
        {
            key: 'loaderBackground',
            value: { color: BrandColors['icon-shade-light-2'] },
        },
    ],
};

// ************************************
// Export
// ************************************

export const AsyncStateButtonTemplates = () => {
    return {
        error: {
            transform: _transform,
            icon: _icons,
            palette: _paletteAbort,
        } as IASyncStateButtonTemplate,
        aborted: {
            transform: _transform,
            icon: _icons,
            palette: _paletteAbort,
        } as IASyncStateButtonTemplate,
        success: {
            transform: _transform,
            icon: _icons,
            palette: _paletteSuccess,
        } as IASyncStateButtonTemplate,
        pending: {
            transform: _transform,
            icon: _icons,
            palette: _palettePending,
        } as IASyncStateButtonTemplate,
        idle: {
            transform: _transform,
            icon: _icons,
            palette: _palette,
        } as IASyncStateButtonTemplate,
    };
};
