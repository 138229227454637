import React from 'react';
import {
	ContentGrid,
	IAction,
	IImage,
	IconType,
	Image,
	StyleGrid,
	Text,
	getCounterTheme,
} from '@fjordkraft/fjordkraft.component.library';
import { MS_ButtonTemplate, LinkButtonTemplate, h2TextPrefab } from '../../Prefabs';
import { ActionButton, LinkButton, MSRichText, ProgressBar } from '../../components';
import { IBlock } from '../../models';
import { createString, getText } from '../../services';
import { Constants } from '../../data';
import classNames from 'classnames';
import './StageBlock.scss';

export interface IStageBlock extends IBlock {
	title: string;
	description: string;
	stage: number;
	stageCount: number;
	translations: any;
	image?: IImage;
	internalRedirect?: IAction;
	desktopView?: boolean;
	onClose: () => void;
	onNext: (nextIndex: number) => void;
	onPrevious: (previousIndex: number) => void;
	onFinish: () => void | Promise<any>;
}

export const StageBlock = (props: IStageBlock) => {

	// ************************************
	// Properties
	// ************************************

	const {
		id,
		className,
		theme = 'Light',
		brand = Constants.uiBrand,
		title,
		description,
		stage,
		stageCount,
		translations,
		image,
		internalRedirect,
		desktopView,
		onClose,
		onNext,
		onPrevious,
		onFinish,
	} = props;
	const classPrefix = 'stage-block';

	// ************************************
	// Helper Functionality
	// ************************************

	const _getNavAlignment = () => {
		if (stage >= stageCount) {
			return 'center-left';
		} else if (stage <= 0) {
			return 'center-right';
		}
		return 'center-right';
	};

	// ************************************
	// Render
	// ************************************

	return (
		<ContentGrid
			id={id}
			className={classNames(classPrefix, {
				[`${className}`]: className,
			})}
			direction="column"
			alignment="top-left"
			tagType="article"
			boxSizing="border-box"
			wrap={false}
			gap={desktopView ? 2 : 4}
		>
			<ContentGrid
				className={`${classPrefix}__header-nav`}
				direction="row"
				alignment="center-right"
				tagType="nav"
				boxSizing="border-box"
			>
				<ActionButton
					brand={brand}
					template={LinkButtonTemplate(theme, false)}
					padding="small"
					iconPlacement="Right"
					action={{
						icon: IconType.Close,
						text: getText('cancel', translations),
						onClick: onClose,
					}}
				/>
			</ContentGrid>
			<StyleGrid
				className={`${classPrefix}__main`}
				direction="row"
				alignment="top-left"
				boxSizing="border-box"
				wrap={false}
				inline={true}
			>
				{image && desktopView && (
					<Image
						className={`${classPrefix}__image`}
						alt={image.alt}
						src={image.src}
						fit={'contain'}
						scalingBy={'fill'}
					/>
				)}
				<StyleGrid
					className={`${classPrefix}__content`}
					direction="column"
					alignment="top-left"
					gap={4}
					boxSizing="border-box"
				>
					<ContentGrid
						className={`${classPrefix}__content__text`}
						direction="column"
						alignment="top-left"
						gap={2}
						tagType="section"
						boxSizing="border-box"
					>
						<Text
							{...h2TextPrefab()}
							theme={getCounterTheme(theme)}
							brand={brand}
						>
							{title}
						</Text>
						<MSRichText
							className={`${classPrefix}__content__text__description`}
							theme={getCounterTheme(theme)}
							brand={brand}
							alignment={'lawful'}
							text={description}
						/>
						{internalRedirect && (
							<LinkButton
								to={internalRedirect.link ?? '/'}
								target="_blank"
								brand={brand}
								theme={theme}
								title={
									internalRedirect.text ??
									createString(getText('defaultTutorialReferenceLinkText', translations), {
										name: title,
									})
								}
							>
								{internalRedirect.text}
							</LinkButton>
						)}
					</ContentGrid>
					<StyleGrid
						className={`${classPrefix}__content__footer`}
						direction="column"
						alignment="top-left"
						gap={2}
					>
						<ProgressBar
							theme={theme}
							brand={brand}
							current={stage}
							max={stageCount}
						/>
						<ContentGrid
							className={`${classPrefix}__content__footer__nav`}
							direction="row"
							alignment={_getNavAlignment()}
							tagType="nav"
							boxSizing="border-box"
							spaceBetween={stage > 0 && stage < stageCount}
						>
							{stage > 0 && (
								<ActionButton
									brand={brand}
									template={LinkButtonTemplate(theme)}
									padding="small"
									action={{
										text: getText('goBack', translations),
										onClick: () => {
											onPrevious(stage - 1);
										},
									}}
								/>
							)}
							{stage + 1 < stageCount && (
								<ActionButton
									brand={brand}
									template={MS_ButtonTemplate(theme, 'primary')}
									padding={desktopView ? "medium" : 'default'}
									action={{
										text: getText('goNext', translations),
										onClick: () => {
											onNext(stage + 1);
										},
									}}
								/>
							)}
							{stage < stageCount && stage > stageCount - 2 && (
								<ActionButton
									brand={brand}
									template={MS_ButtonTemplate(theme, 'primary')}
									padding={desktopView ? "medium" : 'default'}
									action={{
										text: getText('finish', translations),
										onClick: onFinish,
									}}
								/>
							)}
						</ContentGrid>
					</StyleGrid>
				</StyleGrid>
			</StyleGrid>
			{image && !desktopView && (
				<Image
					className={`${classPrefix}__image`}
					alt={image.alt}
					src={image.src}
					fit={'contain'}
					scalingBy={'fill'}
				/>
			)}
		</ContentGrid>
	);
};
