import React from 'react';
import {
    IComponent,
    Text,
    Button,
    Icon,
    BrandColors,
    IAction,
} from '@fjordkraft/fjordkraft.component.library';
import { useApplicationContext, useDefaultPageContext } from '../../../contexts';
import { Link, useNavigate } from 'react-router-dom';
import { mediumTextPrefab } from '../../../Prefabs';
import classNames from 'classnames';
import './CrumbButton.scss';

export interface ICrumbButton extends IComponent {
    action: IAction;
    useHistoryBack?: boolean;
    iconCustomization?: {
        color?: BrandColors;
        location: 'left' | 'right';
    };
}

export const CrumbButton = (props: ICrumbButton) => {
    // ************************************
    // Properties
    // ************************************

    const { activeBrand, activeTheme } = useApplicationContext();
    const { setContentLoading } = useDefaultPageContext();
    const {
        brand,
        theme,
        className,
        action,
        useHistoryBack = false,
        iconCustomization = {
            location: 'left',
            color:
                theme === 'Light'
                    ? BrandColors['icon-shade-light-1']
                    : BrandColors['icon-shade-dark-1'],
        },
    } = props;
    const classPrefix = 'ms-crumb-button';
    const navigate = useNavigate();

    // ************************************
    // Render Functionality
    // ************************************

    const renderIcon = () => {
        if (action.icon) {
            return (
                <Icon
                    brand={brand}
                    className={`${classPrefix}__icon-${iconCustomization.location}`}
                    type={action.icon}
                    color={
                        iconCustomization.color
                            ? iconCustomization.color
                            : BrandColors['icon-shade-light-1']
                    }
                />
            );
        }
    };

    const renderContent = () => {
        return (
            <>
                {iconCustomization.location == 'left' && renderIcon()}
                <Text
                    {...mediumTextPrefab()}
                    className={`${classPrefix}__text`}
                    brand={brand}
                    theme={theme}
                >
                    {action.text}
                </Text>
                {iconCustomization.location == 'right' && renderIcon()}
            </>
        );
    };

    // ************************************
    // Render
    // ************************************

    return (
        <>
            {action.useRouterLink && (
                <Link
                    className={classNames(`${classPrefix}`, {
                        [`${className}`]: className,
                    })}
                    to={'..'}
                    onClick={(e) => {
                        if (useHistoryBack) {
                            e.preventDefault();
                            navigate(-1);
                        } else {
                            e.preventDefault();
                            setContentLoading(true);
                            navigate(action.link ?? '/');
                        }
                    }}
                >
                    {renderContent()}
                </Link>
            )}
            {!action.useRouterLink && (
                <Button
                    className={classNames(`${classPrefix}`, {
                        [`${className}`]: className,
                    })}
                    brand={activeBrand}
                    theme={activeTheme}
                    action={action}
                >
                    {renderContent()}
                </Button>
            )}
        </>
    );
};
