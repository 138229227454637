import React from "react";
import { IComponent, IToast, Toaster, getCounterTheme } from "@fjordkraft/fjordkraft.component.library";
import classNames from "classnames";
import { useApplicationContext } from "../../contexts";
import { IOperatingMessage } from "../../models";
import "./ToasterBlock.scss";

export interface IWebToast extends IToast {
    read: boolean;
    message?: IOperatingMessage;
    desktopView?: boolean;
}

export interface IToasterBlock extends IComponent {
    toasts: IWebToast[];
    coverage?: 'window' | 'relative' | 'absolute';
    onAnimationComplete?: (id: string) => void;
}

export const ToasterBlock = (props: IToasterBlock) => {
    // ************************************
    // Properties
    // ************************************

    const {
        id,
        className,
        theme,
        brand,
        toasts,
        coverage = 'window',
        style,
        onAnimationComplete
    } = props;
    const classPrefix = "toaster-block";
    const { desktopView } = useApplicationContext();

    // ************************************
    // Helper Functionality
    // ************************************

    const _filterOutToasts = (items?: IWebToast[]) => {
        let temp: IWebToast[] = [];

        if (items && items.length > 0) {
            items.forEach((toast: IWebToast) => {
                let localId: string | undefined;
                toast.theme = getCounterTheme(theme);
                toast.className = `${classPrefix}__toast`;
                
                if (onAnimationComplete) {
                    toast.onAnimationComplete = onAnimationComplete;
                }

                if (toast.id) {
                    localId = localStorage.getItem(toast.id) ?? undefined;
                }

                if (!toast.read && !localId) {
                    temp.push(toast);
                }
            })
        }

        return temp;
    }

    // ************************************
    // Render
    // ************************************

    return (
        <Toaster
            id={id}
            theme={theme}
            brand={brand}
            className={classNames(`${classPrefix}`, {
                [`${className}`]: className
            })}
            style={style}
            placement={desktopView ? "top-right" : "bottom-center"}
            duration={500}
            lingerTime={2000}
            toasts={_filterOutToasts(toasts)}
            coverage={coverage}
            loop={false}
            spaced={0}
            gap={desktopView ? 2 : 0}
            onAnimationComplete={() => {
            }}
        />
    );
}