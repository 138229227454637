import {
    BrandColors,
    IComponentTemplate,
    IComponentTransform,
    IThemedColors,
    Theme,
} from '@fjordkraft/fjordkraft.component.library';

// ************************************
// Transform
// ************************************

const _transform = (desktopView: boolean) => {
    return {
        width: '100%',
        border: {
            radiusType: 'curved',
        },
        grid: {
            direction: 'column',
            alignment: 'center',
            tagType: 'div',
            boxSizing: 'border-box',
        },
    } as IComponentTransform;
};

// ************************************
// Palette
// ************************************

const _palette = {
    Light: {
        background: { color: BrandColors['background-shade-light-1'] },
        text: { color: BrandColors['text-shade-dark-3'] },
        border: { color: BrandColors['none'] },
        icon: { color: BrandColors['none'] },
    },
    Dark: {
        background: { color: BrandColors['background-shade-dark-3'] },
        text: { color: BrandColors['text-shade-light-1'] },
        border: { color: BrandColors['none'] },
        icon: { color: BrandColors['none'] },
    },
} as IThemedColors;

// ************************************
// Export
// ************************************

export const GenericDateSelectFilterTemplate = (
    theme: Theme = 'Light',
    desktopView: boolean = true
) => {
    return {
        dontUpdateChildren: true,
        palette: _palette[theme],
        transform: _transform(desktopView),
    } as IComponentTemplate;
};
