import { IComponent } from "@fjordkraft/fjordkraft.component.library";
import React from "react";

export const Mistletoe = (props: IComponent) => {
    return (
        <svg className={props.className} width="294" height="297" viewBox="0 0 294 297" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="148.848" cy="197.854" r="21.1032" transform="rotate(-48.9861 148.848 197.854)" fill="#BB182C" />
            <circle cx="196.76" cy="207.08" r="21.1032" transform="rotate(-48.9861 196.76 207.08)" fill="#E45F5F" />
            <circle cx="180.977" cy="160.911" r="21.1032" transform="rotate(-48.9861 180.977 160.911)" fill="#BB182C" />
            <path d="M128.605 111.098L127.693 110.988C112.717 109.184 99.464 100.434 91.9216 87.3699C90.9572 85.6996 88.6835 85.3676 87.2754 86.6857C75.295 97.9003 58.4316 102.352 42.4874 98.4687L40.6622 98.0241C37.6945 97.3013 35.465 100.709 37.2943 103.155C46.7204 115.76 50.0282 131.97 46.2532 147.25L46.013 148.223C45.6132 149.841 46.7138 151.445 48.3671 151.655C62.1891 153.41 74.396 161.526 81.3624 173.592L82.4428 175.463C83.2874 176.926 85.1581 177.427 86.621 176.583L91.3385 173.859C105.38 165.752 122.004 163.364 137.76 167.189L138.2 167.296C141.629 168.128 144.226 164.223 142.132 161.383C132.417 148.212 128.459 131.664 131.163 115.523L131.3 114.7C131.593 112.95 130.366 111.31 128.605 111.098Z" fill="#46B873" />
            <path d="M58.1592 114.109L121.151 152.469" stroke="white" strokeWidth="6.93374" strokeLinecap="round" />
            <path d="M193.393 119.91L193.288 119.155C191.562 106.749 195.777 94.2557 204.666 85.4319C205.802 84.3037 205.587 82.408 204.23 81.5571C192.688 74.3166 185.54 61.7226 185.278 48.1L185.248 46.5406C185.199 44.005 181.992 42.9374 180.416 44.9244C172.294 55.1632 159.983 61.2543 146.916 61.4627L146.084 61.476C144.701 61.4981 143.646 62.7217 143.828 64.0934C145.349 75.5612 141.421 87.0807 133.211 95.2306L131.938 96.4946C130.943 97.4827 130.937 99.0906 131.925 100.086L135.111 103.296C144.595 112.85 150.036 125.688 150.305 139.147L150.313 139.524C150.371 142.453 154.057 143.71 155.893 141.427C164.407 130.836 176.852 124.152 190.383 122.901L191.073 122.837C192.539 122.701 193.596 121.369 193.393 119.91Z" fill="#46B873" />
            <path d="M176.211 69.2407L159.937 120.72" stroke="white" strokeWidth="6.93374" strokeLinecap="round" />
        </svg>

    );
}