import React from 'react';
import {
    Button,
    Text,
    Icon,
    getCounterTheme,
    BrandColors,
    IComponent,
    IAction,
    IconType,
    StyleGrid,
    ContentGrid,
} from '@fjordkraft/fjordkraft.component.library';
import classnames from 'classnames';
import { LogoutButton } from '../../components';
import { MS_ButtonTemplate, paragraphTextPrefab } from '../../Prefabs';
import './ErrorModal.scss';

interface IProps extends IComponent {
    mode?: 'window' | 'fit';
    text?: string;
    description?: string;
    action?: IAction;
    logOutButtonText?: string;
}
/**
 *   The error modal is a basic screen covering modal used to
 *   display an error message.
 *   @type {string} mode (optional)
 *   @type {string} text (optional)
 *   @type {string} description (optional)
 *   @type {IAction} action (optional)
 *   @type {string} logOutButtonText (optional) - if not set then logout button is not shown
 */

export const ErrorModal = (props: IProps) => {
    // ************************************
    // Properties
    // ************************************

    const {
        id,
        className,
        theme = 'Light',
        brand = 'brand-fjordkraft',
        mode = 'window',
        text,
        description,
        action,
        logOutButtonText,
    } = props;
    const classPrefix = 'ms-error-modal';

    // ************************************
    // Render
    // ************************************

    return (
        <ContentGrid
            id={id}
            className={classnames(classPrefix, {
                [`${classPrefix}--${theme}`]: theme,
                [`${classPrefix}--${mode}`]: mode,
                [`${className}`]: className,
            })}
            direction={'column'}
            alignment={'center'}
            tagType={'section'}
            gap={2}
            brand={brand}
        >
            <StyleGrid
                className={`${classPrefix}__icon-container`}
                direction="row"
                alignment="center"
                brand={brand}
            >
                <Icon
                    brand={brand}
                    type={IconType.ConfusedFace}
                    color={BrandColors['background-shade-light-1']}
                    width={7}
                    height={7}
                />
            </StyleGrid>
            {text && (
                <Text
                    className={`${classPrefix}__title`}
                    type={'h1'}
                    brand={brand}
                    theme={theme}
                    color={BrandColors['primary-shade-light-2']}
                    size={'extra-large'}
                    weight={700}
                >
                    {text}
                </Text>
            )}
            {description && (
                <Text
                    className={`${classPrefix}__subTitle`}
                    type={'h2'}
                    brand={brand}
                    theme={getCounterTheme(theme)}
                    size={'regular'}
                    weight={400}
                    align={'align-center'}
                >
                    {description}
                </Text>
            )}
            {action && (
                <Button
                    className={classnames(`${classPrefix}__button`, {
                        [`${classPrefix}__button--${theme}`]: theme,
                    })}
                    template={MS_ButtonTemplate(theme, 'primary')}
                    brand={brand}
                    action={action}
                >
                    <Icon type={IconType.ArrowLeft} />
                    <Text {...paragraphTextPrefab()}>{action.text}</Text>
                </Button>
            )}
            {logOutButtonText && (
                <LogoutButton
                    className={`${classPrefix}__logOutButton`}
                    text={logOutButtonText}
                />
            )}
        </ContentGrid>
    );
};
