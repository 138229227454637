import React, { useState, useEffect } from 'react';
import {
	IComponent,
	ITabFilterState,
	TabSelectFilter,
	ITabSelectFilterItem,
	ITabSelectDropdown,
	StyleGrid,
} from '@fjordkraft/fjordkraft.component.library';
import { DateResolution } from '../../models/types/Consumption';
import { nb } from 'date-fns/esm/locale';
import { format } from 'date-fns';
import { IConsumptionUsePage, ICustomerInstallation } from '../../models';
import { getDaysInMonthList, getText } from '../../services';
import _ from 'lodash';
import classNames from 'classnames';
import { MS_TabSelectDropdownTemplate } from '../../Prefabs';
import './DateFilter.scss';

export interface IDateFilter extends IComponent {
	installation: ICustomerInstallation;
	desktopView: boolean;
	initialDate: Date;
	resolution: DateResolution;
	translation: IConsumptionUsePage;
	showEstimatedView: boolean;
	onDateChange: (filterDate: IFilterDate) => void;
}

export interface IFilterDate {
	resolution: DateResolution;
	date: Date;
}

export const DateFilter = (props: IDateFilter) => {
	// ************************************
	// Properties
	// ************************************

	const classPrefix = 'ms-date-filter';
	const {
		brand,
		theme,
		id,
		className,
		desktopView,
		initialDate,
		installation,
		resolution,
		translation,
		disabled,
		showEstimatedView,
		onDateChange,
	} = props;

	// ************************************
	// Lifecycle
	// ************************************

	const [dateFilter, setDateFilter] = useState<IFilterDate>();

	useEffect(() => {
		setDateFilter({
			resolution: resolution,
			date: initialDate,
		});
	}, [initialDate, resolution]);

	// ************************************
	// Handling
	// ************************************

	const handleFilterChange = (filterSettings: ITabFilterState) => {
		let newDate: Date = dateFilter?.date ?? new Date();
		let selected: DateResolution = 'day';
		let y = newDate.getFullYear();
		let m = newDate.getMonth();
		let d = newDate.getDate();

		if (filterSettings.states.length > 0) {
			selected = filterSettings.selected?.value ?? 'day';

			filterSettings.states.forEach((state: ITabSelectFilterItem) => {
				if (state.value === 'hour') {
					d = parseInt(state.id) ?? new Date().getDate();
				} else if (state.value === 'day') {
					m = parseInt(state.id) ?? new Date().getMonth();
				} else if (state.value === 'month') {
					y = parseInt(state.id) ?? new Date().getFullYear();
				}
			});

			switch (selected) {
				case 'month':
					newDate = new Date(y, 11, 1);
					break;
				case 'day':
					newDate = new Date(newDate.getFullYear(), m, 1);
					break;
				case 'hour':
					newDate = new Date(newDate.getFullYear(), newDate.getMonth(), d + 1);
					break;
			}
		}

		setDateFilter({ date: newDate, resolution: selected });
		onDateChange({ date: newDate, resolution: selected });
	};

	// ************************************
	// Helper Functionality
	// ************************************

	// Bug:
	// filterDate lags behind if set directly from external source (updating inital date)
	// Need to get filter options with newest (only occurs when assigning directly)
	const getFilterOptions = (f: IFilterDate) => {
		let yearItems: ITabSelectFilterItem[] = [];
		let monthItems: ITabSelectFilterItem[] = [];
		let dayItems: ITabSelectFilterItem[] = [];

		// Setup - years

		let currentDate = new Date();

		if (installation.consumptionYears.length > 0) {
			installation.consumptionYears.forEach((year: number) => {
				yearItems.push({
					id: `${year}`,
					value: `month`,
					text: `${year}`,
					active: f.date.getFullYear() === year,
				});
			});

			yearItems = _.orderBy(yearItems, ['id'], ['desc']);
		} else {
			let pastYear = currentDate.getFullYear() - 5;

			for (let y = pastYear; y <= currentDate.getFullYear(); y++) {
				yearItems.push({
					id: `${y}`,
					value: `month`,
					text: `${y}`,
					active: f.date.getFullYear() === y,
				});
			}
		}

		// Setup - months

		let totalMonths = 11;
		let daysLimit = 40;

		if (currentDate.getFullYear() === f.date.getFullYear()) {
			totalMonths = currentDate.getMonth();

			if (currentDate.getMonth() === f.date.getMonth()) {
				daysLimit = currentDate.getDate();
			}
		}

		for (let m = 0; m <= totalMonths; m++) {
			let month = format(new Date(0, m, 1), 'MMMM', { locale: nb });

			let isActive = false;

			if (resolution !== 'month') {
				isActive = m === f.date.getMonth();
			}

			monthItems.push({
				id: `${m}`,
				value: `day`,
				text: `${_.capitalize(month)}`,
				active: isActive,
			});
		}

		if (resolution === 'month') {
			monthItems.push({
				id: `${12}`,
				value: 'day',
				text: `${getText('month', translation)}`,
				active: true,
			});
		}

		// Setup - days

		let daysTotal: Date[] = getDaysInMonthList(f.date.getMonth(), f.date.getFullYear(), daysLimit);

		daysTotal.forEach((day: Date, i: number) => {
			let dayText = `${day.getDate()}`;
			let isActive = false;

			if (resolution !== 'month' && resolution !== 'day') {
				isActive = day.getDate() === f.date.getDate();
			}

			dayItems.push({
				id: `${i}`,
				value: 'hour',
				text: `${dayText}`,
				active: isActive,
			});
		});

		if (resolution === 'month' || resolution === 'day') {
			dayItems.push({
				id: `${daysTotal.length + 1}`,
				value: 'hour',
				text: `${getText('day', translation)}`,
				active: true,
			});
		}

		// Filters
		let years: ITabSelectDropdown = {
			tabScaling: {
				type: 'percentage',
				width: desktopView ? 20 : 27.5,
			},
			bordered: true,
			items: yearItems,
			highlighted: resolution === 'month' || showEstimatedView,
			disabled: false,
			includeActive: resolution !== 'month',
			template: MS_TabSelectDropdownTemplate(
				'Light',
				{
					type: 'percentage',
					width: desktopView ? 20 : 27.5,
				},
				true,
				'last'
			),
		};
		let months: ITabSelectDropdown = {
			tabScaling: {
				type: 'percentage',
				width: desktopView ? 60 : 45,
			},
			items: monthItems,
			bordered: true,
			disabled: showEstimatedView,
			highlighted: resolution === 'day' && !showEstimatedView,
			includeActive: resolution !== 'day',
			template: MS_TabSelectDropdownTemplate(
				'Light',
				{
					type: 'percentage',
					width: desktopView ? 60 : 45,
				},
				true,
				'middle'
			),
		};
		let days: ITabSelectDropdown = {
			tabScaling: {
				type: 'percentage',
				width: desktopView ? 20 : 27.5,
			},
			items: dayItems,
			bordered: false,
			disabled: showEstimatedView,
			highlighted: resolution === 'hour' && !showEstimatedView,
			includeActive: !(resolution === 'month' || resolution === 'day'),
			template: MS_TabSelectDropdownTemplate(
				'Light',
				{
					type: 'percentage',
					width: desktopView ? 20 : 27.5,
				},
				false,
				'first'
			),
		};

		return [years, months, days];
	};

	// ************************************
	// Render
	// ************************************

	return (
		<StyleGrid
			id={id}
			className={classNames(`${classPrefix}`, {
				[`${className}`]: className,
			})}
			direction="column"
			alignment="center"
			boxSizing="border-box"
		>
			{dateFilter && (
				<TabSelectFilter
					className={`${classPrefix}__filter`}
					brand={brand}
					theme={theme}
					curvy={desktopView}
					desktopView={desktopView}
					filters={getFilterOptions(dateFilter)}
					onChange={(filterSettings: ITabFilterState) => {
						handleFilterChange(filterSettings);
					}}
				/>
			)}
			{disabled && (
				<span
					className={classNames(`${classPrefix}__loading-container`, {
						[`${classPrefix}__loading-container--${theme}`]: theme,
					})}
				/>
			)}
		</StyleGrid>
	);
};
