import { useContext, createContext } from 'react';
import {IAddonData, IServicePage} from '../../models';

export interface IServicesSummary {
    totalAvailableServices: number;
    totalActiveServices: number;
}

interface IApplicationAddonServicesContext {
    addonStates?: IAddonData[];
    updateAddonStates: () => void;
    availableServicePages?: IServicePage[];
    updateAddonStatesAndAvailableServicePages?: () => void;
    servicesSummary?: IServicesSummary;
    setServicesSummary?: () => void
}

export const ApplicationAddonServicesContext =
    createContext<IApplicationAddonServicesContext>({
        addonStates: undefined,
        updateAddonStates: () => {},
        availableServicePages: undefined,
        updateAddonStatesAndAvailableServicePages: () => {},
        servicesSummary: undefined,
        setServicesSummary: () => {}
    });

export const useApplicationAddonServicesContext = () =>
    useContext(ApplicationAddonServicesContext);