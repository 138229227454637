import { BrandColors, NotificationBoxTemplate, Theme } from '@fjordkraft/fjordkraft.component.library';

export const MS_NotificationBoxTemplate = (theme: Theme, type: 'Error' | 'Note' | 'Warning') => {
	let temp = NotificationBoxTemplate(theme);

	switch (type) {
        case 'Error':
            temp.palette.icon = { color: BrandColors['status-shade-light-3'] };
            temp.palette.background = { color: BrandColors['status-shade-light-3'], opacity: 0.2 };
            temp.palette.border = { color: BrandColors['status-shade-light-3'] };
            break;
        case 'Note':
            temp.palette.icon = { color:  BrandColors['action-shade-light-1'] };
            temp.palette.background = { color:  BrandColors['action-shade-light-1'], opacity: 0.2 };
            temp.palette.border = { color:  BrandColors['action-shade-light-1'] };
            break;
        case 'Warning':
            temp.palette.icon = { color: BrandColors['status-shade-light-2'] };
            temp.palette.background = { color: BrandColors['status-shade-light-2'], opacity: 0.2 };
            temp.palette.border = { color: BrandColors['status-shade-light-2'] };
            break;
    }

	return temp;
};
