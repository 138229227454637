import {
	BrandColors,
	IComponentTemplate,
	IComponentTransform,
	IThemedColors,
	Theme,
} from '@fjordkraft/fjordkraft.component.library';

// ************************************
// Transform
// ************************************

const _transform = (useUnderline: boolean) => {
	return {
		width: 'auto',
		margin: '0',
		padding: '0',
		outline: {
			type: 'dashed',
			thickness: 2,
		},
		border: useUnderline
			? {
					type: 'solid',
					sides: 'bottom',
					thickness: 2,
			  }
			: undefined,
		grid: {
			boxSizing: 'border-box',
			direction: 'row',
			alignment: 'center',
			gap: 1,
		},
	} as IComponentTransform;
};

// ************************************
// Palette
// ************************************

const _palette = {
	Light: {
		background: { color: BrandColors['transparent'] },
		text: { color: BrandColors['action-shade-light-1'] },
		icon: { color: BrandColors['action-shade-light-1'] },
		border: { color: BrandColors['action-shade-light-1'] },
		hover: {
			background: { color: BrandColors['transparent'] },
			text: { color: BrandColors['action-shade-light-1'], opacity: 0.8 },
			icon: { color: BrandColors['action-shade-light-1'], opacity: 0.8 },
			border: { color: BrandColors['action-shade-light-1'], opacity: 0.8 },
		},
		focus: {
			background: { color: BrandColors['transparent'] },
			text: { color: BrandColors['action-shade-light-1'], opacity: 0.8 },
			icon: { color: BrandColors['action-shade-light-1'], opacity: 0.8 },
			outline: { color: BrandColors['primary-shade-light-2'] },
		},
		disabled: {
			background: { color: BrandColors['transparent'] },
			text: { color: BrandColors['action-shade-light-1'], opacity: 0.3 },
			icon: { color: BrandColors['action-shade-light-1'], opacity: 0.3 },
		},
	},
	Dark: {
		background: { color: BrandColors['transparent'] },
		text: { color: BrandColors['text-shade-light-1'] },
		icon: { color: BrandColors['text-shade-light-1'] },
		border: { color: BrandColors['text-shade-light-1'] },
		hover: {
			background: { color: BrandColors['transparent'] },
			text: { color: BrandColors['text-shade-light-1'], opacity: 0.8 },
			icon: { color: BrandColors['text-shade-light-1'], opacity: 0.8 },
			border: { color: BrandColors['text-shade-light-1'], opacity: 0.8 },
		},
		focus: {
			background: { color: BrandColors['transparent'] },
			text: { color: BrandColors['text-shade-light-1'], opacity: 0.8 },
			icon: { color: BrandColors['text-shade-light-1'], opacity: 0.8 },
			outline: { color: BrandColors['text-shade-light-1'] },
		},
		disabled: {
			background: { color: BrandColors['transparent'] },
			text: { color: BrandColors['text-shade-light-1'], opacity: 0.3 },
			icon: { color: BrandColors['text-shade-light-1'], opacity: 0.3 },
		},
	},
} as IThemedColors;

export const LinkButtonTemplate = (theme: Theme, useUnderline: boolean = true) => {
	return {
		palette: _palette[theme],
		transform: _transform(useUnderline),
	} as IComponentTemplate;
};
