import React, { useState } from 'react';
import { useApplicationOverlayWrapperContext, useDefaultPageContext } from '../../contexts';
import { getPageContent } from './EnergyAgreementPageData';
import {
	ICustomerElectricityAgreement,
	ICustomerElectricityAgreementDetails,
	ICustomerInstallation,
} from '../../models';
import {
	ChartAccordion,
	DescriptionBlock,
	IChartAccordion,
	IDescriptionBlock,
	IPlankHouse,
	IWebToast,
	PlankHouseBlock,
} from '../../blocks';
import { LineChartCard, MSRichText, Card } from '../../components';
import { capitalizeFirstLetter, createString, getText, tNumber } from '../../services';
import { BrandColors, CallState, StyleGrid, Text, getCounterTheme } from '@fjordkraft/fjordkraft.component.library';
import { CancelManagedModal, MoreAboutEnergyAgreementModal } from '../../modals';
import classNames from 'classnames';
import { Constants } from '../../data';
import { GenericColorCardTemplate, GetToastPrefab, ToastPrefabsType, h4TextPrefab } from '../../Prefabs';
import { format } from 'date-fns';
import { IDefaultViewProps, PageV2 } from '../PageV2';
import './EnergyAgreementPage.scss';

export interface IMoreAboutAgreement {
	house: IPlankHouse;
	top: IDescriptionBlock;
	details: string[];
	translation: any;
	campaignDescription: string;
}

export interface IEnergyAgreementPage extends IDefaultViewProps {
	introduction: IDescriptionBlock;
	campaignDescription: string;
	agreementOptions: IPlankHouse;
	reducedAgreementOptions: IPlankHouse;
	futureAgreementOptions: { [key: string] : IPlankHouse };
	chartData: any;
	chartAccordion?: IChartAccordion;
	installation: ICustomerInstallation;
	agreement: ICustomerElectricityAgreement;
	details: ICustomerElectricityAgreementDetails;
	cancellationNotice?: {
		title: string;
		description: string;
	};
	futureAgreement: {
		translation: any;
		details: ICustomerElectricityAgreementDetails;
		description: string;
	};
}

export interface IShowMoreAboutAgreement {
	show: boolean;
	futureAgreementId?: string;
}

export const EnergyAgreementPage = () => {
	// ************************************
	// Properties
	// ************************************

	const classPrefix = 'energy-agreement-page';
	const { setTempToasts } = useApplicationOverlayWrapperContext();
	const { setMainContentClamping } = useDefaultPageContext();

	// ************************************
	// Lifecycle
	// ************************************

	const [showMoreAboutAgreement, setShowMoreAboutAgreement] = useState<IShowMoreAboutAgreement>({ show: false });
	const [showCancelSpotManaged, setShowCancelSpotManaged] = useState<boolean>(false);

	// ************************************
	// Helper Functionality
	// ************************************

	const _getManagedToastCancellationText = (config: IEnergyAgreementPage, callState: CallState) => {
		const { translations, agreement } = config;

		let epiText: string = getText(`canceledManagedToast${capitalizeFirstLetter(callState)}`, translations);
		let earliestCancelDate: Date = new Date();
		earliestCancelDate.setDate(earliestCancelDate.getDate() + (agreement.minimumTermDays ?? 0));

		return createString(epiText, {
			date: format(earliestCancelDate, 'dd.MM.yyyy'),
			agreementName: agreement.name,
		});
	};

	// ************************************
	// Render Functionality
	// ************************************

	const _renderChartAccordion = (config: IEnergyAgreementPage) => {
		const { chartAccordion, activeBrand, activeTheme } = config;

		if (chartAccordion) {
			return (
				<ChartAccordion
					className={`${classPrefix}__accordion-chart`}
					translations={chartAccordion.translations}
					theme={activeTheme}
					brand={activeBrand}
					overview={chartAccordion.overview}
					header={chartAccordion.header}
					items={chartAccordion.items}
				/>
			);
		}
	};

	const _renderTop = (config: IEnergyAgreementPage, popupView: boolean = false) => {
		const { translations, activeBrand, activeTheme, introduction } = config;

		return (
			<DescriptionBlock
				{...introduction}
				translations={translations}
				className={classNames('', {
					[`${classPrefix}__popup-description-block`]: popupView,
					[`${classPrefix}__description-block`]: !popupView,
				})}
				useExpandableText={false}
				brand={activeBrand}
				theme={activeTheme}
			/>
		);
	};

	const _renderMain = (config: IEnergyAgreementPage, popupView: boolean = false) => {
		const { campaignDescription, agreementOptions, activeBrand, activeTheme } = config;

		return (
			<>
				{campaignDescription && (
					<MSRichText
						className={popupView ? '' : `${classPrefix}__campaign-description`}
						text={campaignDescription}
						theme={getCounterTheme(activeTheme)}
					/>
				)}
				{agreementOptions && (
					<PlankHouseBlock
						{...agreementOptions}
						className={popupView ? '' : `${classPrefix}__house`}
						titleGap={0}
						theme={activeTheme}
						brand={activeBrand}
					/>
				)}
			</>
		);
	};

	// ************************************
	// Render
	// ************************************

	return PageV2({
		setup: {
			pageType: Constants.epiServerPageNames.energyAgreement.type,
			usesSubPage: true,
		},
		dependencies: [
			{ showMoreAboutAgreement },
			{ showCancelSpotManaged },
			{ setShowMoreAboutAgreement },
			{ setShowCancelSpotManaged },
		],
		handleData: getPageContent,
		renderUpdate: () => {
			setMainContentClamping('clamp');
		},
		render: (config: IEnergyAgreementPage) => {
			const {
				introduction,
				chartData,
				activeTheme,
				activeBrand,
				chartAccordion,
				translations,
				agreement,
				cancellationNotice,
				desktopView,
			} = config;

			return (
				<StyleGrid
					className={`${classPrefix}`}
					direction="column"
					alignment={desktopView ? 'top-left' : 'top-center'}
					gap={4}
				>
					{introduction && _renderTop(config)}
					{cancellationNotice && (
						<Card
							template={GenericColorCardTemplate(activeTheme, {
								color: BrandColors['action-shade-light-3'],
							})}
							theme={activeTheme}
							brand={activeBrand}
						>
							<Text
								{...h4TextPrefab()}
								className={`${classPrefix}__cancellatioNotice__text`}
								brand={activeBrand}
								theme={getCounterTheme(activeTheme)}
							>
								{cancellationNotice.title}
							</Text>
							<MSRichText
								className={`${classPrefix}__cancellatioNotice__text`}
								brand={activeBrand}
								theme={getCounterTheme(activeTheme)}
								text={cancellationNotice.description}
								alignment="lawful"
							/>
						</Card>
					)}
					{chartAccordion && _renderChartAccordion(config)}
					{chartData && (
						<LineChartCard
							{...chartData}
							chartTooltip={{
								show: true,
								showMarkers: true,
								x: {
									formatter: (value: any) => {
										let text = '';

										if (chartData.categories) {
											text = `${chartData.categories[value - 1]}`;
										}

										return text;
									},
								},
								y: {
									formatter: (value: any) => {
										return `${tNumber(value, 'no-NO', 2)} ${chartData.chartTooltip?.y?.suffix}`;
									},
									suffix: ` ${chartData.chartTooltip?.y?.suffix}`,
									showOnAxisLabels: false,
								},
							}}
							className={`${classPrefix}__line-chart`}
							brand={activeBrand}
							theme={activeTheme}
						/>
					)}
					{_renderMain(config)}
					{showMoreAboutAgreement.show && (
						<MoreAboutEnergyAgreementModal
							{...config}
							futureAgreementId={showMoreAboutAgreement.futureAgreementId}
							onClose={() => {
								setShowMoreAboutAgreement({ show: false });
							}}
						/>
					)}
					{showCancelSpotManaged && (
						<CancelManagedModal
							agreement={agreement}
							translations={translations}
							onClose={() => {
								setShowCancelSpotManaged(false);
							}}
							onOrderComplete={(callState: CallState) => {
								setTempToasts([
									GetToastPrefab(ToastPrefabsType.CallStateToast, {
										text: _getManagedToastCancellationText(config, callState),
										status: callState,
									}) as IWebToast,
								]);
							}}
							theme={activeTheme}
							brand={activeBrand}
						/>
					)}
				</StyleGrid>
			);
		},
	});
};