import { IRadioPlank } from '@fjordkraft/fjordkraft.component.library';
import { Constants } from '../../../data';
import {
	ConsentStateEnum,
	IConsent,
	ICustomerNotificationSettings,
	IDefaultProps,
	MarketingConsentType
} from '../../../models';
import { getText } from '../../../services';
import { MSCheckInputTemplate, getPlankPrefab } from '../../../Prefabs';
import { ICheckInputPlankPrefab } from '../../../Prefabs/Planks/CheckInputPlankPrefab/CheckInputPlankPrefab';

export interface IMyCommunicationSettingsPageData extends IDefaultProps {
	notification: 'sms' | 'email';
	consents: IConsent[];
}

export const getPageContent = async (config: IMyCommunicationSettingsPageData) => {
	const { translations, services, consents } = config;
	const { GETTYPED} = services;

	if (translations && GETTYPED !== undefined) {
		const oldConsent = consents?.find(e => e.consentName === MarketingConsentType.Legacy1)?.value === ConsentStateEnum.ACCEPTED

		let notificationResp = await GETTYPED<ICustomerNotificationSettings>('Customers/notificationSettings', true);
		let notificationStatus: 'sms' | 'email' = _parseSelectedNotification(notificationResp.data);

		return {
			...config,
			sub: {
				title: getText('title', translations),
			back: {
				text: getText('back', translations),
				link: Constants.paths.userPage,
			},
			subTitle: undefined
			},
			noticesSettings: {
				title: getText('msgNotificationTitle', translations),
				description: getText('msgNotificationDescription', translations),
			},
			consentSettings: {
				title: getText('offerConsentTitle', translations),
				description: getText('offerConsentDescriptionHtml', translations),
				active: oldConsent,
			},
			noticesSettingOptions: _getNotificationOptions(config, notificationStatus),
		};
	}
};

const _parseSelectedNotification = (status?: ICustomerNotificationSettings | null): 'sms' | 'email' => {
	return status?.sms ? 'sms' : 'email';
};

const _getNotificationOptions = (config: IMyCommunicationSettingsPageData, status: 'sms' | 'email') => {
	const { activeTheme, translations } = config;

	let options: IRadioPlank[] = [
		getPlankPrefab('CheckInput', {
			left: {
				title: getText('msgNotificationBoxEmail', translations),
			},
			value: 'email',
			id: '0',
			active: status === 'email',
			radioTemplate: MSCheckInputTemplate(activeTheme, 'radio'),
		} as ICheckInputPlankPrefab) as ICheckInputPlankPrefab,
		getPlankPrefab('CheckInput', {
			left: {
				title: getText('msgNotificationBoxSms', translations),
			},
			value: 'sms',
			id: '1',
			active: status === 'sms',
			radioTemplate: MSCheckInputTemplate(activeTheme, 'radio'),
		} as ICheckInputPlankPrefab) as ICheckInputPlankPrefab,
	];

	return options;
};