import React, { useMemo, useState } from 'react';
import {
	BrandColors,
	CallState,
	IAction,
	IComponent,
	Image,
	LoaderGraphic,
	StyleGrid,
	Text,
	TextInput,
	getCounterTheme,
} from '@fjordkraft/fjordkraft.component.library';
import { PopupCard } from '../PopupCard/PopupCard';
import {
	useApplicationContext,
	useApplicationCoreDataContext,
	useApplicationServicehandlerContext,
} from '../../contexts';
import { FAQ, MSRichText } from '../../components';
import SuccessSVG from '../../assets/art/service-order-success.svg?react';
import FailureSVG from '../../assets/art/service-order-failure.svg?react';
import { ITypedResponse, createString, getText } from '../../services';
import MeterGraphicFKAS from '../../assets/art/PowerMeterNumberFKAS.svg';
import MeterGraphicTKAS from '../../assets/art/PowerMeterNumberTKAS.svg';
import { Constants } from '../../data';
import { ICustomer, ICustomerInstallation } from '../../models';
import { UpdateMeterNumberRequest, updateMeterNumber } from '../../services/collection/InstallationService';
import './NewInstallationModal.scss';
import { paragraphTextPrefab } from '../../Prefabs';

export interface INewInstallationModal extends IComponent {
	translations: any;
	installation: ICustomerInstallation | undefined;
	onClose: () => void;
	onSuccess: () => void;
}

export const NewInstallationModal = (props: INewInstallationModal) => {
	// ************************************
	// Properties
	// ************************************

	const classPrefix = 'new-installation-modal';
	const { updateCustomerData, setInstallation } = useApplicationCoreDataContext();
	const { PUTTYPED } = useApplicationServicehandlerContext();
	const { desktopView } = useApplicationContext();
	const { theme, brand, translations, onClose, onSuccess, installation } = props;

	const {
		minDigits: validateMin,
		maxDigits: validateMax,
		isMeterIdIfFirstDigitsEqual: validateMeterId,
	} = Constants.meterNumberValidation;

	// ************************************
	// Lifecycle
	// ************************************

	const [state, setState] = useState<CallState | 'orderInProgress'>('idle');
	const [valid, setValid] = useState<boolean>(false);
	const [meterNumberIsMeterId, setMeterNumberIsMeterId] = useState<boolean>(false);
	const [meterNumber, setMeterNumber] = useState<string>('');
	const [orderLastUpdatedDate, setOrderLastUpdatedDate] = useState<Date | undefined>(
		installation?.missingMeterNumberOrderLastUpdatedDate
	);

	// ************************************
	// Async handling
	// ************************************

	const _updateNewInstallation = async (meterNumber: string) => {
		setState('pending');
		if (installation?.missingMeterNumberOrderId && installation?.address.streetAddress && orderLastUpdatedDate) {
			const request: UpdateMeterNumberRequest = {
				meterNumber: meterNumber,
				postalCode: installation.address.postalCode ?? '',
				streetAddress: installation.address.streetAddress,
				lastUpdatedDate: orderLastUpdatedDate,
			};
			let resp = await updateMeterNumber(PUTTYPED, installation.missingMeterNumberOrderId, request);
			if (resp.callState === 'success') {
				setTimeout(() => {
					updateCustomerData(true).then((customerResp) => {
						if (installation.missingMeterNumberOrderId) {
							const orderState = _validateNewMeterNumberRequest(
								customerResp,
								installation.missingMeterNumberOrderId
							);
							setState(orderState);
						}
					});
				}, 5000);
			} else {
				setState('error');
			}
		}
	};

	const _validateNewMeterNumberRequest = (
		customerResp: ITypedResponse<ICustomer>,
		orderId: number
	): CallState | 'orderInProgress' => {
		const newCustomer = customerResp.data;

		const newInstallation = newCustomer?.installations.find((inst) => inst.physicalMeterNumber === meterNumber);
		if (newInstallation) {
			setInstallation(newInstallation);
			onSuccess();
			return 'success';
		}

		const failedOrder = newCustomer?.installations.find(
			(inst) => inst.missingMeterNumberOrderId === orderId && inst.missingMeterNumberOrderStatus === 'FAILURE'
		);
		if (failedOrder) {
			setOrderLastUpdatedDate(failedOrder.missingMeterNumberOrderLastUpdatedDate);
			return 'error';
		}

		const cachedInstallation = newCustomer?.installations.find(
			(inst) => inst.missingMeterNumberOrderCachedMeterNumber === meterNumber
		);
		if (cachedInstallation) {
			setInstallation(cachedInstallation);
		}

		return 'orderInProgress';
	};

	const _validateMeterNumber = (value: string): boolean => {
		if (value.startsWith(validateMeterId)) {
			setMeterNumberIsMeterId(true);
			return false;
		} else if (value.length >= validateMin && value.length <= validateMax) {
			setMeterNumberIsMeterId(false);
			return true;
		}
		setMeterNumberIsMeterId(false);
		return false;
	};

	// ************************************
	// Getters
	// ************************************

	const _getTitleFromState = () => {
		switch (state) {
			case 'idle':
				return getText('modalNoInstallationMeterContentTitle', translations);
			case 'pending':
				return getText('modalNoInstallationMeterPendingTitle', translations);
			case 'aborted':
			case 'error':
				return getText('modalNoInstallationMeterFailureTitle', translations);
			case 'success':
			case 'orderInProgress':
				return getText('modalNoInstallationMeterSuccessTitle', translations);
		}
	};

	const _getPrimaryActionFromState = () => {
		let action: IAction | undefined = undefined;
		if (state === 'idle') {
			action = {
				disabled: !valid,
				text: getText('modalNoInstallationMeterAcceptButtonText', translations),
				onClick: () => {
					_updateNewInstallation(meterNumber);
				},
			};
		} else if (state === 'error') {
			action = {
				text: getText('modalNoInstallationMeterRetryButtonText', translations),
				onClick: () => {
					setState('idle');
				},
			};
		}
		return action;
	};

	const _getSecondaryActionFromState = () => {
		let textValue =
			state === 'success' || state === 'error' || state === 'orderInProgress'
				? getText('close', translations)
				: getText('cancel', translations);
		let action: IAction = {
			text: textValue,
			onClick: () => {
				onClose();
			},
		};
		return action;
	};

	const _renderInputText = () => {
		return installation?.address.streetAddress !== undefined
			? createString(getText('modalNoInstallationMeterContentInputText', translations), {
					address: installation?.address.streetAddress,
			  })
			: `${getText('modalNoInstallationMeterContentInputLabel', translations)}`;
	};

	// ************************************
	// Render Functionality
	// ************************************

	const _renderSuccess = () => {
		return (
			<>
				<StyleGrid
					className={`${classPrefix}__response-icon__container`}
					direction={'row'}
					alignment={'center'}
				>
					<SuccessSVG className={`${classPrefix}__modal-response-icon`} />
				</StyleGrid>
				<MSRichText
					theme={getCounterTheme(theme)}
					brand={brand}
					text={
						state === 'orderInProgress'
							? getText('modalNoInstallationSuccessInProgressText', translations)
							: getText('modalNoInstallationMeterSuccessText', translations)
					}
				/>
			</>
		);
	};

	const _renderError = () => {
		return (
			<>
				<StyleGrid
					className={`${classPrefix}__response-icon__container`}
					direction={'row'}
					alignment={'center'}
				>
					<FailureSVG />
				</StyleGrid>
				<MSRichText
					theme={getCounterTheme(theme)}
					brand={brand}
					text={getText('modalNoInstallationMeterFailureText', translations)}
				/>
			</>
		);
	};

	const _renderFaq = useMemo(() => {
		return (
			<FAQ
				theme={theme}
				brand={brand}
				questions={[
					{
						question: getText('modalNoInstallationMeterContentFaqQuestion', translations),
						answerElement: (
							<>
								<Image
									className={`${classPrefix}__content-image`}
									src={brand === 'brand-fjordkraft' ? MeterGraphicFKAS : MeterGraphicTKAS}
									alt={'where-to-find-meter-number-image'}
									fit={'none'}
								/>
								<MSRichText
									text={getText('modalNoInstallationMeterContentFaqAnswer', translations)}
									theme={getCounterTheme(theme)}
									alignment={'lawful'}
								/>
							</>
						),
					},
				]}
			/>
		);
	}, [theme, brand]);

	const _renderLoading = () => {
		return (
			<>
				<StyleGrid
					className={`${classPrefix}__response-icon__container`}
					direction={'row'}
					alignment={'center'}
				>
					<LoaderGraphic
						theme={getCounterTheme(theme)}
						brand={brand}
						thickness={'regular'}
						scaleThickness={0.5}
						size={4}
						colors={{
							backgroudColor: BrandColors['background-shade-light-1'],
							fillColor: BrandColors['primary-shade-dark-2'],
						}}
					/>
				</StyleGrid>
				<StyleGrid
					className={`${classPrefix}__modal-response-icon__container`}
					direction={'row'}
					alignment={'center'}
				>
					<MSRichText
						theme={getCounterTheme(theme)}
						brand={brand}
						text={getText('modalNoInstallationMeterPendingText', translations)}
					/>
				</StyleGrid>
			</>
		);
	};

	const _renderMainContent = () => {
		return (
			<StyleGrid
				direction="column"
				alignment="center-left"
				gap={3}
			>
				<MSRichText
					theme={getCounterTheme(theme)}
					brand={brand}
					text={getText('modalNoInstallationMeterContentDescription', translations)}
				/>
				<StyleGrid
					direction={'row'}
					alignment={'top-left'}
					className={`${classPrefix}__modal-content-input__container`}
					gap={1}
				>
					<MSRichText
						text={_renderInputText()}
						theme={getCounterTheme(theme)}
						className={`${classPrefix}__modal-content-input__label`}
						customization={{ align: 'align-left', type: 'label' }}
					/>

					<TextInput
						className={`${classPrefix}__modal-content-input`}
						value={meterNumber}
						type={'tel'}
						invalidated={meterNumberIsMeterId}
						invalidatedText={createString(
							getText('modalNoInstallationMeterContentInputIsMeterId', translations),
							{ meterIdStartsWith: validateMeterId }
						)}
						title={getText('modalNoInstallationMeterContentInputLabel', translations)}
						onChange={(e) => {
							e.target.value = e.target.value.slice(0, validateMax);
							setValid(_validateMeterNumber(e.target.value));
							setMeterNumber(e.target.value);
						}}
					/>
					<Text
						className={`${classPrefix}__modal-content-input__label`}
						{...paragraphTextPrefab()}
					>
						{createString(getText('modalNoInstallationMeterContentInputGuide', translations), {
							min: validateMin,
							max: validateMax,
						})}
					</Text>
				</StyleGrid>
				{_renderFaq}
			</StyleGrid>
		);
	};

	const _getContentBasedOnState = () => {
		switch (state) {
			case 'idle':
				return _renderMainContent();
			case 'pending':
				return _renderLoading();
			case 'orderInProgress':
			case 'success':
				return _renderSuccess();
			case 'aborted':
			case 'error':
				return _renderError();
		}
	};

	// ************************************
	// Render
	// ************************************

	return (
		<PopupCard
			className={classPrefix}
			theme={theme}
			brand={brand}
			onClose={onClose}
			contentTitle={_getTitleFromState()}
			primaryAction={_getPrimaryActionFromState()}
			secondaryAction={_getSecondaryActionFromState()}
			contentTitleAlignment={'align-left'}
			contentSubTitleAlignment="align-center"
			contentGap={1}
			desktopView={desktopView}
		>
			{_getContentBasedOnState()}
		</PopupCard>
	);
};
