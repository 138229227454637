import React from 'react';
import {
	Text,
	getCounterTheme,
	IconType,
	ITextCustomization,
	IIconAdvanced,
	Icon,
	ContentGrid,
	CheckInput,
	IAction,
	IMasterPlank,
} from '@fjordkraft/fjordkraft.component.library';
import { smallParagraphTextPrefab } from '../../Texts/SmallParagraphTextPrefab';
import { MSCheckInputTemplate } from '../../Templates';
import classNames from 'classnames';
import { v4 as uuid4 } from 'uuid';
import './RadioPlankPrefab.scss';

export interface IRadioPlankPrefabCustomization {
	title?: ITextCustomization;
	description?: ITextCustomization;
	icon?: IIconAdvanced;
}

export interface IRadioPlankPrefab extends Omit<IMasterPlank, 'action'> {
	left?: {
		title?: string;
		description?: string;
		extra?: string;
		icon?: IconType;
		customization?: IRadioPlankPrefabCustomization;
	};
	right: {
		active: boolean;
		value: any;
		onChange: (value?: any, state?: boolean) => void;
	};
	bottom?: {
		title?: string;
		description?: string;
	};
}

export const RadioPlankPrefab = (props: IRadioPlankPrefab) => {
	// ************************************
	// Properties
	// ************************************

	const { id, className, theme = 'Light', brand, left, right, bottom, template } = props;
	const classPrefix = 'ms-radio-plank';

	// ************************************
	// Helpers
	// ************************************

	const _getDynamicScaling = () => {
		return { right: 1, left: 4 };
	};

	// ************************************
	// Render Functionality
	// ************************************

	const _renderLeftIcon = () => {
		if (left?.icon) {
			return (
				[
					<Icon
						brand={brand}
						type={left.icon}
						width={left.customization?.icon?.size ?? 1.5}
						height={left.customization?.icon?.size ?? 1.5}
						{...left.customization?.icon}
					/>
				]
			);
		}
	}

	const _renderLeftTitle = () => {
		if (left?.title) {
			return (
				<Text
					brand={brand}
					weight={500}
					size={'regular'}
					family={'main'}
					type={'h4'}
					align={'align-left'}
					{...left.customization?.title}
				>
					{left.title}
				</Text>
			);
		}
	}

	const _renderLeftDescription = () => {
		if (left?.description) {
			return (
				<Text
					brand={brand}
					weight={400}
					size={'small'}
					family={'main'}
					type={'p'}
					faded
					align={'align-left'}
					{...left.customization?.description}
				>
					{left.description}
				</Text>
			);
		}
	}

	const _renderLeftExtra = () => {
		if (left?.extra) {
			return (
				<Text
					className={`${classPrefix}__left__text__extra`}
					brand={brand}
					{...smallParagraphTextPrefab()}
					size={'tiny'}
					faded
					align={'align-left'}
					{...left.customization?.description}
				>
					{left.extra}
				</Text>
			);
		}
	}

	const _renderLeft = () => {
		if (left?.title || left?.description || left?.extra) {
			return ([
				_renderLeftTitle(),
				_renderLeftDescription(),
				_renderLeftExtra()
			]);
		}
	}

	const _renderRightContent = () => {
		if (right) {
			return (
				[
					<CheckInput
						key={uuid4()}
						type='radio'
						active={right.active}
						value={right.value}
						onChange={right.onChange}
						template={MSCheckInputTemplate(theme, 'radio')}
					/>
				]
			);
		}
	};

	const _renderBottomContent = (bottom?: { title?: string; description?: string }) => {
		if (bottom) {
			return (
				<ContentGrid
					tagType="section"
					alignment="top-left"
					direction="column"
					boxSizing="border-box"
					gap={1}
				>
					{bottom.title && (
						<Text
							brand={brand}
							weight={500}
							size={'regular'}
							family={'main'}
							type={'h4'}
							theme={getCounterTheme(theme)}
							align={'align-right'}
						>
							{bottom.title}
						</Text>
					)}
					{bottom.description && (
						<Text
							brand={brand}
							weight={400}
							size={'small'}
							family={'main'}
							align={'align-right'}
							type={'p'}
							theme={getCounterTheme(theme)}
						>
							{bottom.description}
						</Text>
					)}
				</ContentGrid>
			);
		}
	};

	// ************************************
	// Render
	// ************************************

	return {
		id,
		className: classNames(classPrefix, {
			[`${className}`]: className
		}),
		template,
		leftColumn1: _renderLeftIcon(),
		leftColumn2: _renderLeft(),
		rightColumn2: _renderRightContent(),
		action: {
			onClick: right.onChange
		} as IAction,
		bottomContent: _renderBottomContent(bottom),
		customization: {
			wrapper: {
				alignment: 'center',
				boxSizing: 'border-box',
				wrap: false,
			},
			left: {
				scaling: _getDynamicScaling().left,
				gap: 1,
				direction: 'row',
				alignment: 'center-left',
				wrap: false,
				boxSizing: 'border-box',
			},
			right: {
				scaling: _getDynamicScaling().right,
				gap: 1,
				direction: 'row',
				alignment: 'center-right',
				wrap: false,
				boxSizing: 'border-box',
			},
		},
	} as IMasterPlank;
};
