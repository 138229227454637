import { useContext, createContext } from 'react';
import { ICustomerAccountInformation } from '../../models';

interface IApplicationAccountSelectContext {
    includeAllOption: boolean;
    setIncludeAllOption: (state: boolean) => void;
    useAccountSelectMenu: boolean;
    setUseAccountSelectMenu: (state: boolean) => void;
    activeAccount?: ICustomerAccountInformation;
    setActiveAccount: (account?: ICustomerAccountInformation) => void;
    selectedAccount?: ICustomerAccountInformation;
    setSelectedAccount: (account?: ICustomerAccountInformation) => void;
}

export const ApplicationAccountSelectContext = createContext<IApplicationAccountSelectContext>({
    includeAllOption: false,
    setIncludeAllOption: () => { },
    useAccountSelectMenu: false,
    setUseAccountSelectMenu: () => { },
    activeAccount: undefined,
    setActiveAccount: () => { },
    selectedAccount: undefined,
    setSelectedAccount: () => { }
});

export const useApplicationAccountSelectContext = () => useContext(ApplicationAccountSelectContext);

