import { BrandColors } from '@fjordkraft/fjordkraft.component.library';

export const BUTTON_TEMPLATE_CTA_END = {
	'brand-fjordkraft': {
		Light: {
			background: { color: BrandColors['action-shade-light-2'] },
			text: { color: BrandColors['action-shade-light-1'] },
			border: { color: BrandColors['action-shade-light-2'] },
			icon: { color: BrandColors['action-shade-light-1'] },
			hover: {
				background: { color: BrandColors['secondary-shade-light-2'] },
				text: { color: BrandColors['action-shade-light-1'] },
				border: { color: BrandColors['secondary-shade-light-2'] },
				icon: { color: BrandColors['action-shade-light-1'] },
			},
			focus: {
				background: { color: BrandColors['secondary-shade-light-2'] },
				text: { color: BrandColors['action-shade-light-1'] },
				border: { color: BrandColors['secondary-shade-light-2'] },
				icon: { color: BrandColors['action-shade-light-1'] },
				outline: { color: BrandColors['primary-shade-light-2'] },
			},
			disabled: {
				background: {
					color: BrandColors['action-shade-light-2'],
					opacity: 0.3,
				},
				text: { color: BrandColors['action-shade-light-1'], opacity: 0.3 },
				border: { color: BrandColors['action-shade-light-2'], opacity: 0.3 },
				icon: { color: BrandColors['action-shade-light-1'], opacity: 0.3 },
			},
		},
		Dark: {
			background: { color: BrandColors['action-shade-light-2'] },
			text: { color: BrandColors['action-shade-light-1'] },
			border: { color: BrandColors['action-shade-light-2'] },
			icon: { color: BrandColors['action-shade-light-1'] },
			hover: {
				background: { color: BrandColors['secondary-shade-light-2'] },
				text: { color: BrandColors['action-shade-light-1'] },
				border: { color: BrandColors['secondary-shade-light-2'] },
				icon: { color: BrandColors['action-shade-light-1'] },
			},
			focus: {
				background: { color: BrandColors['secondary-shade-light-2'] },
				text: { color: BrandColors['action-shade-light-1'] },
				border: { color: BrandColors['secondary-shade-light-2'] },
				icon: { color: BrandColors['action-shade-light-1'] },
				outline: { color: BrandColors['primary-shade-light-2'] },
			},
			disabled: {
				background: {
					color: BrandColors['action-shade-light-2'],
					opacity: 0.3,
				},
				text: { color: BrandColors['action-shade-light-1'], opacity: 0.5 },
				border: { color: BrandColors['action-shade-light-2'], opacity: 0.3 },
				icon: { color: BrandColors['action-shade-light-1'], opacity: 0.3 },
			},
		},
	},
	'brand-trondelagkraft': {
		Light: {
			background: { color: BrandColors['action-shade-light-2'] },
			text: { color: BrandColors['text-shade-dark-3'] },
			border: { color: BrandColors['action-shade-light-2'] },
			icon: { color: BrandColors['text-shade-dark-3'] },
			hover: {
				background: { color: BrandColors['secondary-shade-light-2'] },
				text: { color: BrandColors['text-shade-dark-3'] },
				border: { color: BrandColors['secondary-shade-light-2'] },
				icon: { color: BrandColors['text-shade-dark-3'] },
			},
			focus: {
				background: { color: BrandColors['secondary-shade-light-2'] },
				text: { color: BrandColors['text-shade-dark-3'] },
				border: { color: BrandColors['secondary-shade-light-2'] },
				icon: { color: BrandColors['text-shade-dark-3'] },
				outline: { color: BrandColors['primary-shade-light-2'] },
			},
			disabled: {
				background: {
					color: BrandColors['action-shade-light-2'],
					opacity: 0.3,
				},
				text: { color: BrandColors['text-shade-dark-3'], opacity: 0.3 },
				border: { color: BrandColors['action-shade-light-2'], opacity: 0.3 },
				icon: { color: BrandColors['text-shade-dark-3'], opacity: 0.3 },
			},
		},
		Dark: {
			background: { color: BrandColors['action-shade-light-2'] },
			text: { color: BrandColors['text-shade-dark-3'] },
			border: { color: BrandColors['action-shade-light-2'] },
			icon: { color: BrandColors['action-shade-light-1'] },
			hover: {
				background: { color: BrandColors['secondary-shade-light-2'] },
				text: { color: BrandColors['text-shade-dark-3'] },
				border: { color: BrandColors['secondary-shade-light-2'] },
				icon: { color: BrandColors['action-shade-light-1'] },
			},
			focus: {
				background: { color: BrandColors['secondary-shade-light-2'] },
				text: { color: BrandColors['text-shade-dark-3'] },
				border: { color: BrandColors['secondary-shade-light-2'] },
				icon: { color: BrandColors['action-shade-light-1'] },
				outline: { color: BrandColors['primary-shade-light-2'] },
			},
			disabled: {
				background: {
					color: BrandColors['action-shade-light-2'],
					opacity: 0.3,
				},
				text: { color: BrandColors['text-shade-dark-3'], opacity: 0.3 },
				border: { color: BrandColors['action-shade-light-2'], opacity: 0.3 },
				icon: { color: BrandColors['action-shade-light-1'], opacity: 0.3 },
			},
		},
	},
};
