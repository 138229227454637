import React from 'react';
import { BrandColors, IRichText, RichText, Text, getColorCode } from '@fjordkraft/fjordkraft.component.library';
import classNames from 'classnames';
import { Constants } from '../../data';
import { paragraphTextPrefab } from '../../Prefabs';
import './MSRichText.scss';

export interface IMSRichText extends IRichText {
	alignment?: 'lawful' | 'neutral' | 'evil';
	id?: string;
	color?: BrandColors;
}

export const MSRichText = (props: IMSRichText) => {
	// ************************************
	// Properties
	// ************************************

	const {
		id,
		className,
		theme = 'Light',
		brand = Constants.uiBrand,
		text,
		alignment = 'neutral',
		customization,
		color,
		style,
		palette,
		handleOnRender,
	} = props;
	const classPrefix = 'ms-rich-text';

	// ************************************
	// Helpers
	// ************************************

	const _hasHTMLElements = () => {
		let htmlElementRegex = new RegExp(/<\/?[a-z][\s\S]*>/);
		return htmlElementRegex.test(text);
	};

	const _getCustomStyling = () => {
		return customization?.color
			? {
				...style,
				...{
					color: getColorCode({
						color: customization?.color,
					}),
				},
			}
			: style;
	};

	// ************************************
	// Render
	// ************************************

	return (
		<>
			{_hasHTMLElements() && (
				<RichText
					{...props}
					className={classNames(`${classPrefix}`, {
						[`${classPrefix}--${alignment}`]: alignment,
						[`${classPrefix}--${customization?.align}`]: customization?.align,
						[`${classPrefix}--${theme}`]: theme,
						[`${classPrefix}--faded`]: customization?.faded,
						[`${className}`]: className,
					})}
					palette={undefined}
					theme={theme}
					style={_getCustomStyling()}
				/>
			)}
			{!_hasHTMLElements() && (
				<Text
					{...paragraphTextPrefab()}
					className={className}
					id={id}
					theme={theme}
					brand={brand}
					color={color}
					handleOnRender={handleOnRender}
					palette={palette}
					{...customization}
				>
					{text}
				</Text>
			)}
		</>
	);
};
