import { IconType } from '@fjordkraft/fjordkraft.component.library';
import { _getTextPlankPrefabTemplate } from '.';
import { Constants } from '../../../data';
import { AllowFeature, IsActiveCustomer, createString, getText } from '../../../services';
import { fetchKickbackSummary } from '../../../services/collection/BenefitService';
import { IHomePageData } from '../HomePageData';
import { IStatePlank } from '../../../components';
import { format } from 'date-fns';

export const BenefitPlank = (config: IHomePageData): IStatePlank | undefined => {
	const { translations, services, user } = config;
	const { installation } = user;
	const { customerServiceFeature } = services;

	if (
		AllowFeature(Constants.features.benefits, translations, services.user, customerServiceFeature) &&
		IsActiveCustomer(installation)
	) {
		return {
			promise: fetchBenefitsPlank(config),
		};
	}
};

const fetchBenefitsPlank = async (props: IHomePageData) => {
	const { activeBrand, activeTheme, translations, user, services } = props;
	const { userData } = user;
	const { GET } = services;

	let text = '';
	let description: string = getText('plankBenefitsDesc', translations);

	if (userData) {
		const kickbackSummary = await fetchKickbackSummary(GET);

		// Apply negative points if any
		if (kickbackSummary.expiringPoints) {
			description = createString(getText('plankBenefitsDescNegative', translations), {
				amount: kickbackSummary.expiringPoints.points,
				date: format(new Date(kickbackSummary.expiringPoints.date), 'dd.MM.yyyy'),
			});
		}

		if (kickbackSummary) {
			let points = Math.floor(kickbackSummary.amount);
			text = createString(getText('plankBenefitsValue', translations), {
				amount: points,
			});
		} else {
			text = getText('plankBenefitsNoCustomer', translations);
		}

		return _getTextPlankPrefabTemplate({
			theme: activeTheme,
			brand: activeBrand,
			title: getText('plankBenefitsTitle', translations),
			description,
			rightTitle: text,
			link: Constants.paths.benefitsPage,
			iconRight: IconType.ChevronRight,
		});
	}
};
