import { isEmpty } from 'lodash';
import { Constants } from '../../../data';
import { IBenefit, IBenefitData, ILocationNavigationInfoBoxContent, IDefaultProps } from '../../../models';
import { getText } from '../../../services';
import { fetchBenefits, fetchRecruitLink } from '../../../services/collection/BenefitService';
import { getRequest } from '../../../contexts';

export interface IMyBenefitsPageData extends IDefaultProps {}

// ************************************
// PUBLIC
// ************************************

export const getPageContent = async (config: IMyBenefitsPageData) => {
	const { services, translations } = config;
	const { GET } = services;

	if (GET !== undefined && translations) {
		let data = await fetchBenefitsData(GET);

		if (data) {
			return {
				...config,
				sub: {
					title: getText('pageTitle', translations),
					back: {
						text: getText('myBenefitsBack', translations),
						link: Constants.paths.benefitsPage,
					},
				},
				info: {
					hasInfo: true,
					infoTitle: getText('navigationInfoTitle', translations),
					infoText: getText('navigationInfoText', translations),
					buttons: [
						{
							type: 'close',
							text: getText('navigationInfoCloseText', translations),
							buttonStyle: 'secondary',
						},
					],
				} as ILocationNavigationInfoBoxContent,
				recruitLink: data.recruitLink,
				benefits: data.benefitOptions.benefits,
				categories: data.benefitOptions.categories,
			};
		}
	}
};

// ************************************
// Fetch and Parse BenefitData - Benefits, RecruitLink and Kickback Points
// ************************************

export const fetchBenefitsData = async (GET: getRequest) => {
	let data = {} as IBenefitData;

	let res = await fetchBenefits(GET);
	if (!isEmpty(res)) {
		data.benefitOptions = parseBenefits(res);
	}
	res = await fetchRecruitLink(GET);
	if (!isEmpty(res)) {
		data.recruitLink = res;
	}

	return data;
};

const parseBenefits = (data: any) => {
	let benefitOptions = {
		benefits: [] as IBenefit[],
		categories: [] as string[],
	};

	// create array and parse categories to array
	data.benefits.forEach((benefit: any) => {
		benefitOptions.benefits.push({
			...(benefit as IBenefit),
			categories: benefit.categories ? benefit.categories.split(',') : ['Fjordkraft'], // Categories arrive from the Api as: "Faste Fordeler, Tilbud, Mobil" etc. If nothing arrives, give it the "Fjordkraft" category.
		});
	});

	// If category doesnt exist in overall categories menu, add it to the list:
	benefitOptions.benefits.forEach((benefit) => {
		benefit.categories!.forEach((category) => {
			if (!benefitOptions.categories.includes(category)) {
				benefitOptions.categories.push(category);
			}
		});
	});

	return benefitOptions;
};
