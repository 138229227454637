import React from 'react';
import { IBaseComponent, IComponentTemplate, IToast, Icon, IconType, Text } from '@fjordkraft/fjordkraft.component.library';
import { h4TextPrefab } from '../Texts/Header4TextPrefab';
import { MSRichText } from '../../components';

export interface IGenericToastPrefab extends IBaseComponent {
    title?: string;
    description?: string;
    icon?: IconType;
    template: IComponentTemplate;
}

export const GenericToastPrefab = (props: IGenericToastPrefab) => {
    // ************************************
    // Properties
    // ************************************

    const { theme, brand, title, description, icon } = props;

    // ************************************
    // Render Functionality
    // ************************************

    const _renderTitle = () => {
        if (title) {
            return (
                <Text
                    {...h4TextPrefab()}
                    brand={brand}
                    theme={theme}
                >
                    {title}
                </Text>
            );
        }
    }

    const _renderDescription = () => {
        if (description) {
            return (
                <MSRichText
                    brand={brand}
                    theme={theme}
                    text={description}
                />
            );
        }
    }

    const _renderIcon = (allow: boolean) => {
        if (icon && allow) {
            return (
                <Icon
                    type={icon}
                    width={1}
                    height={1}
                    brand={brand}
                />
            );
        }
    }

    // ************************************
    // Render
    // ************************************

    return {
        ...props, ...{
            content: {
                top: (
                    _renderIcon(!!title),
                    _renderTitle()
                ),
                middle: (
                    _renderIcon(!title),
                    _renderDescription()
                )
            }
        }
    } as IToast;
};
