import React from 'react';
import { BaseComponent, CallState, IComponent, IComponentTemplate, IToast, Icon, IconType, Text } from "@fjordkraft/fjordkraft.component.library";
import { MS_StatusToastTemplate, MS_StatusWrapperToastTemplate } from '../Templates';
import { StatusType } from '../../models';
import { h4TextPrefab } from '../Texts/Header4TextPrefab';

export interface ICallStateToastPrefab extends IComponent {
    text: string;
    status: CallState;
    desktopView?: boolean;
}

export const CallStateToastPrefab = (props: ICallStateToastPrefab) => {
    // ************************************
    // Properties
    // ************************************

    const { theme = 'Light', brand, text, status, desktopView } = props;

    // ************************************
    // Helper Functionality
    // ************************************

    const _parseCallStateToStatus = (): StatusType => {
        switch (status) {
            case 'error':
            case 'aborted':
                return 'negative';
            case 'idle':
            case 'pending':
                return 'neutral';
            case 'success':
                return 'positive';
        }
    }

    const _getStatusTemplate = (): IComponentTemplate => {
        return MS_StatusWrapperToastTemplate(theme, _parseCallStateToStatus(), desktopView);
    }

    const _getStatusIcon = (): IconType => {
        switch (status) {
            case 'error':
            case 'aborted':
                return IconType.WarningBadge;
            case 'idle':
            case 'pending':
                return IconType.BellIcon;
            case 'success':
                return IconType.CheckmarkThick;
        }
    }

    // ************************************
    // Render Functionality
    // ************************************

    const _renderText = () => {
        if (text) {
            return (
                <Text
                    {...h4TextPrefab()}
                    brand={brand}
                    theme={theme}
                    palette={_getStatusTemplate().palette}
                >
                    {text}
                </Text>
            );
        }
    }

    const _renderIcon = () => {
        return (
            <Icon
                type={_getStatusIcon()}
                width={1.25}
                height={1.25}
                brand={brand}
                palette={_getStatusTemplate().palette}
            />
        );
    }

    const _renderTopSection = () => {
        if (text && status) {
            return (
                <BaseComponent
                    template={MS_StatusWrapperToastTemplate(theme, _parseCallStateToStatus(), desktopView)}
                >
                    {_renderIcon()}
                    {_renderText()}
                </BaseComponent>
            );
        }
    }

    // ************************************
    // Render
    // ************************************

    return {
        ...props, ...{
            template: MS_StatusToastTemplate(theme, desktopView),
            content: {
                top: _renderTopSection(),
            }
        }
    } as IToast;
}