import React, { useEffect, useMemo, useState } from 'react';
import { ICustomer, IGuestRelationship } from '../../../models';
import { ApplicationGuestsAndHostsContext } from '../../../contexts/variations/ApplicationGuestsAndHostsContext';
import { isGuestCheck } from '../../../services';

export const ApplicationGuestsAndHostsWrapper = (props: any) => {
	// ************************************
	// Lifecycle
	// ************************************

	const [isGuest, setIsGuest] = useState<boolean>(false);
	const [hostIdForCustomerDataRequests, setHostIdForCustomerDataRequests] = useState<string>();
	const [guests, setGuests] = useState<IGuestRelationship[]>();
	const [hosts, setHosts] = useState<IGuestRelationship[]>();
	const [chosenHost, setChosenHost] = useState<IGuestRelationship>();
	const [mainUser, setMainUser] = useState<ICustomer>();

	const context = useMemo(() => {
		return {
			isGuest,
			setIsGuest,
			hostIdForCustomerDataRequests,
			setHostIdForCustomerDataRequests,
			guests,
			setGuests,
			hosts,
			setHosts,
			chosenHost,
			setChosenHost,
			mainUser,
			setMainUser,
		};
	}, [hostIdForCustomerDataRequests, guests, hosts, hostIdForCustomerDataRequests, chosenHost, mainUser, isGuest]);

	useEffect(() => {
		setIsGuest(
			isGuestCheck({
				chosenHost,
				hosts,
				mainUser,
			})
		);
	}, [hostIdForCustomerDataRequests, chosenHost, mainUser]);

	// ************************************
	// Render
	// ************************************

	return (
		<ApplicationGuestsAndHostsContext.Provider value={context}>
			{props.children}
		</ApplicationGuestsAndHostsContext.Provider>
	);
};
