import {ICustomerInstallation, IDefaultProps} from "../../../models";
import {getText, getTextV2} from "../../../services";
import {Constants} from "../../../data";
import {IPlankHouse} from "../../../blocks";
import {getPlankPrefab} from "../../../Prefabs";
import {IconType} from "@fjordkraft/fjordkraft.component.library";
import {IInfoGridRow} from "../../../components/InfoGrid/InfoGrid";
import {ICustomerDataToEdit} from "../../DatahandlerWrappers/ApplicationUserEditWrapper/ApplicationUserEditWrapper";


export type IInstallationContent = {
    rows: IInfoGridRow[],
    installation: ICustomerInstallation
}

type IInstallations = {
    [key: string]: IInstallationContent
}


export interface IResidencePageData extends IDefaultProps {
    house: IPlankHouse
    installations: IInstallations
    isGuest: boolean
    meterId: string
    setCustomerDataToEdit: (data: ICustomerDataToEdit) => void
}

const _getInstallations = (installations: ICustomerInstallation[], translations: any, setCustomerDataToEdit: (data: ICustomerDataToEdit) => void, isGuest: boolean) => {
    return installations.reduce((acc: IInstallations, curr: ICustomerInstallation) => {
        const residenceName = curr?.residenceInfo?.residenceName ?? curr?.address?.streetAddress
        acc[curr.meterId] = {
            installation: curr,
            rows: [
                {
                    title: getTextV2({key: "name", translations}),
                    value: residenceName ?? curr?.address?.streetAddress,
                    buttonRight: {
                        icon: IconType.Edit,
                        onClick: () => setCustomerDataToEdit({
                            title: getTextV2({key: "changeResidenceName", translations}) ?? "",
                            topText: "",
                            bottomText: "",
                            fields: {residenceName}
                        } as ICustomerDataToEdit),
                        title: getTextV2({key: "changeButton", translations}),
                        isDisabled: isGuest,
                        buttonType: "button"
                    }
                },
                {title: getTextV2({key: "address", translations}), value: curr?.address?.streetAddress},
                {title: getTextV2({key: "meterNumber", translations}), value: curr?.meterId},
            ]
        }
        return acc
    }, {})
}


export const getPageContent = async (config: IResidencePageData) => {
    const installations = config?.user?.userData?.installations
    const {setCustomerDataToEdit, translations, isGuest, meterId} = config;
    const currentInstallation = meterId
        ? installations?.find(installation => installation.meterId === meterId)
        : undefined
    return {
        ...config,
        house: await _getPlankHouse(installations),
        installations: _getInstallations(installations, translations, setCustomerDataToEdit, isGuest),
        sub: _getSubData(
            translations,
            meterId,
            currentInstallation?.residenceInfo?.residenceName ?? currentInstallation?.address?.streetAddress
        ),
        description: getTextV2({
            key: 'description',
            translations,
            includeMissing: false
        }),
    }
}

const _getSubData = (translations: any, meterId: string, residenceName: string | undefined) => {
    const hasChosenResidence = !!meterId
    const subpageData = {
        title: getText('pageTitle', translations),
        subTitle: getTextV2({
            key: 'pageSubTitle',
            translations,
            includeMissing: false
        }),
        back: {
            text: getText('backButton', translations),
            link: Constants.paths.userPage
        }
    }
    if (!hasChosenResidence) return subpageData
    return {
        ...subpageData,
        title: residenceName,
        back: {text: getText("pageTitle", translations), link: Constants.paths.userResidences}
    }
}

const _getPlankHouse = async (installations: ICustomerInstallation[]) => {
    const planks = installations.map(installation => {
        const residenceName = installation.residenceInfo?.residenceName ?? installation.address.streetAddress
        return getPlankPrefab('Text', {
            action: {
                link: `${Constants?.paths?.userResidences}?meterId=${encodeURIComponent(installation.meterId)}`,
            },
            left: {
                title: residenceName,
                icon: IconType.DetailedHouseIcon,
            },
            right: {
                icon: IconType.ChevronRight,
            },
        });
    })
    return {plankWalls: [{planks}]}
}