import React, { useState } from 'react';
import {
	IComponent,
	getCounterTheme,
	LoaderGraphic,
	StyleGrid,
	Image,
	ContentGrid,
	CheckInput,
} from '@fjordkraft/fjordkraft.component.library';
import { format } from 'date-fns';
import { MSRichText } from '../../components';
import { ICustomerInvoice, CallState, IInvoicePage } from '../../models';
import { capitalizeFirstLetter, createString, getText } from '../../services';
import { MSCheckInputTemplate } from '../../Prefabs';
import { PopupCard } from '../PopupCard/PopupCard';
import classNames from 'classnames';
import SuccessSVG from '../../assets/art/service-order-success.svg';
import FailureSVG from '../../assets/art/service-order-failure.svg';
import { Constants } from '../../data';
import { useApplicationContext, useApplicationServicehandlerContext } from '../../contexts';
import { v4 as uuid4 } from 'uuid';
import './InvoiceDueDateModal.scss';

export interface IInvoiceDueDateModal extends IComponent {
	translation: IInvoicePage;
	title?: string;
	preface?: string;
	description?: string;
	delayButtonText: string;
	abortButtonText: string;
	invoice: ICustomerInvoice;
	callStateChange: (message: string, state: CallState) => void;
	close: () => void;
}

/**
 *   The loading invoice due date modal is used to set a new due date
 *   the invoice page.
 *   @type {IInvoicePage} translation (translations)
 *   @type {string} title (optional)
 *   @type {string} preface (optional)
 *   @type {string} description (optional)
 *   @type {IAction} delay
 *   @type {IAction} abort
 *   @type {ICustomerInvoice} invoice
 *   @type {function} callStateChange
 *   @type {function} close
 */
export const InvoiceDueDateModal = (props: IInvoiceDueDateModal) => {
	// ************************************
	// Properties
	// ************************************

	const {
		id,
		className,
		translation,
		title,
		preface,
		description,
		theme = 'Light',
		brand = Constants.uiBrand,
		delayButtonText,
		abortButtonText,
		invoice,
		callStateChange,
		close,
	} = props;
	const classPrefix = 'ms-invoice-due-date-modal';
	const { POST } = useApplicationServicehandlerContext();
	const { desktopView } = useApplicationContext();

	// ************************************
	// Initial setup
	// ************************************

	const _getInitialOptionIndex = (): number => {
		if (translation.delayPopup.delayOptions.length > 1) {
			return 0;
		}
		return -1;
	};

	const _getInitialActiveState = (): boolean => {
		if (translation.delayPopup.delayOptions.length > 1) {
			return true;
		}

		return false;
	};

	// ************************************
	// Lifecycle
	// ************************************

	const [activeOption, setActiveOption] = useState<number>(_getInitialOptionIndex());
	const [callState, setCallState] = useState<CallState>('idle');
	const [canProceed, setCanProceed] = useState<boolean>(_getInitialActiveState());

	// ************************************
	// Async Functionality
	// ************************************

	const _setNewInvoiceDueDate = async () => {
		let selectedDelay: any = _usesMoreOptions() ? _getOption(activeOption) : _getOption(0);

		let additionalDays: number = selectedDelay.value;
		let currentDueDate: Date = new Date(invoice.dueDate);
		let accountNr: string = invoice.electricityAccountNumber;
		let serialNr: number = invoice.serialNumber;

		currentDueDate.setDate(currentDueDate.getDate() + additionalDays);
		setCallState('pending');

		if (invoice?.canBeDelayed) {
			let resp = await POST(
				`Invoices/electricity/${accountNr}/${serialNr}/newduedate/${currentDueDate.toDateString()}`
			);

			setCallState(resp.callState);

			if (resp.callState === 'success' || resp.callState === 'error' || resp.callState === 'aborted') {
				callStateChange(
					createString(
						getText(`invoiceDueDateExtended${capitalizeFirstLetter(resp.callState)}`, translation),
						{
							date: format(currentDueDate, 'dd.MM.yyyy'),
						}
					),
					resp.callState
				);
			}
		}
	};

	// ************************************
	// Helpers
	// ************************************

	const _usesMoreOptions = (): boolean => {
		return translation.delayPopup.delayOptions.length > 1;
	};

	const _getOption = (index: number) => {
		return translation.delayPopup.delayOptions[index];
	};

	// ************************************
	// Getters
	// ************************************

	const _getStateImage = (callState: CallState) => {
		switch (callState) {
			case 'aborted':
			case 'error':
				return { src: FailureSVG, alt: 'failed image' };
			case 'pending':
			case 'idle':
			case 'success':
				return { src: SuccessSVG, alt: 'success image' };
		}
	};

	const _getTitle = (callState: CallState) => {
		switch (callState) {
			case 'aborted':
			case 'error':
				return getText('extendDueDateErrorTitle', translation);
			case 'pending':
				return getText('extendDueDateLoadingTitle', translation);
			case 'idle':
				return title;
			case 'success':
				return getText('extendDueDateSuccessTitle', translation);
		}
	};

	const _getSubTitle = (callState: CallState) => {
		switch (callState) {
			case 'aborted':
			case 'error':
			case 'pending':
			case 'success':
				return '';
			case 'idle':
				return preface;
		}
	};

	const _getDescription = (callState: CallState) => {
		switch (callState) {
			case 'aborted':
			case 'error':
				return getText('extendDueDateErrorText', translation);
			case 'pending':
				return getText('extendDueDateLoadingText', translation);
			case 'success':
				return getText('extendDueDateSuccessText', translation);
			case 'idle':
				return description;
		}
	};

	const _getSecondaryActionText = (callState: CallState) => {
		switch (callState) {
			case 'aborted':
			case 'error':
			case 'success':
			case 'pending':
				return getText('close', translation);
			case 'idle':
				return abortButtonText;
		}
	};

	// ************************************
	// Render Functionality
	// ************************************

	const _renderStatus = (callState: CallState) => {
		return (
			<StyleGrid
				className={`${classPrefix}__loader`}
				alignment="center"
				direction="column"
			>
				{callState === 'pending' && (
					<LoaderGraphic
						brand={brand}
						theme={theme}
						scaleThickness={0.5}
						size={4}
						thickness={'regular'}
					/>
				)}
				{callState !== 'pending' && <Image {..._getStateImage(callState)} />}
			</StyleGrid>
		);
	};

	const _renderSelection = () => {
		if (translation?.delayPopup?.delayOptions && translation.delayPopup.delayOptions.length > 0) {
			return translation.delayPopup.delayOptions.map((option: any, index: number) => {
				let moreOptions: boolean = translation.delayPopup.delayOptions.length > 1;
				let nextDate: Date = new Date(invoice.dueDate);
				let checkType: 'radio' | 'checkbox' = moreOptions ? 'radio' : 'checkbox';
				nextDate.setDate(nextDate.getDate() + option.value);

				return (
					<ContentGrid
						className={`${classPrefix}__option`}
						key={uuid4()}
						direction="row"
						alignment="top-left"
						gap={2}
						boxSizing={'border-box'}
						tagType="section"
					>
						<CheckInput
							className={`${classPrefix}__option__checkbox`}
							template={MSCheckInputTemplate(theme, checkType)}
							theme={theme}
							brand={brand}
							type={checkType}
							active={index === activeOption}
							value={canProceed}
							onChange={(value) => {
								if (checkType === 'radio') {
									setActiveOption(index);
								} else {
									setActiveOption(activeOption !== index ? index : -1);
								}

								if (!moreOptions) {
									setCanProceed(!value);
								}
							}}
						/>
						<MSRichText
							className={`${classPrefix}__option__description`}
							brand={brand}
							theme={getCounterTheme(theme)}
							text={createString(getText('extendDueDateCheckboxDescription', translation), {
								date: format(nextDate, 'dd.MM.yyyy'),
								days: option.value,
								cost: option.cost,
							})}
							alignment="lawful"
							style={{
								width: '80%',
							}}
						/>
					</ContentGrid>
				);
			});
		}
	};

	const _renderSelectionGrid = () => {
		return (
			<StyleGrid
				className={`${classPrefix}__options-grid`}
				direction="column"
				alignment="top-left"
				boxSizing="border-box"
				gap={2}
			>
				{_renderSelection()}
			</StyleGrid>
		);
	};

	// ************************************
	// Render
	// ************************************

	return (
		<PopupCard
			id={id}
			className={classNames(classPrefix, {
				[`${className}`]: className,
			})}
			contentTitle={_getTitle(callState)}
			contentTitleAlignment={callState === 'idle' ? 'align-left' : 'align-center'}
			contentSubTitle={_getSubTitle(callState)}
			contentGap={4}
			onClose={() => {
				close();
			}}
			primaryAction={
				callState === 'idle'
					? {
							onClick: () => {
								_setNewInvoiceDueDate();
							},
							disabled: !canProceed,
							text: delayButtonText,
					  }
					: undefined
			}
			secondaryAction={{
				onClick: () => {
					close();
				},
				text: _getSecondaryActionText(callState),
			}}
			desktopView={desktopView}
		>
			{callState === 'idle' && _renderSelectionGrid()}
			{callState !== 'idle' && _renderStatus(callState)}
			<MSRichText
				className={`${classPrefix}__description`}
				theme={getCounterTheme(theme)}
				brand={brand}
				text={_getDescription(callState)}
				alignment="lawful"
				customization={{
					type: 'p',
					align: callState === 'idle' ? 'align-left' : 'align-center',
				}}
			/>
		</PopupCard>
	);
};
