import { IMoveAddress } from '../../../contexts';
import { Constants } from '../../../data';
import { ICustomerInstallation, IDefaultProps } from '../../../models';
import { GetInstallationStatus, getText } from '../../../services';

interface IMovePageData extends IDefaultProps {}

// ************************************
// PUBLIC
// ************************************

export const getPageContent = async (config: IMovePageData) => {
	const { translations, user } = config;
	const { userData } = user;

	if (translations && userData?.installations)
		return {
			...config,
			sub: {
				title: getText('moveOutPageTitle', translations),
				back: {
					text: getText('moveOutPageBack', translations),
					link: Constants.paths.movePage,
				},
			},
			addressesData: getAddressesData(userData.installations),
			accordionData: getAccordionData(translations),
		};
};

// ************************************
// PRIVATE
// ************************************

const getAddressesData = (installations: ICustomerInstallation[]) => {
	let addressList: IMoveAddress[] = [];

	installations.forEach((installation) => {
		let status = GetInstallationStatus(installation.validFrom, installation.validTo);
		if (installation.address && installation.meterId) {
			addressList.push({
				address: `${installation.address.streetAddress ?? ''}, ${installation.address.postalCode ?? ''}`,
				physicalMeterNumber: installation.physicalMeterNumber,
				meterId: installation.meterId,
				meteringPointType: '',
				status: status,
			});
		}
	});

	return addressList;
};

const getAccordionData = (translation: any) => {
	let accordionData: any = {
		elements: [],
	};

	if (translation.addressPage.faqAccordion) {
		translation.addressPage.faqAccordion.forEach((question: any) => {
			accordionData.elements.push({
				title: question.question,
				content: question.answer,
			});
		});
	}

	return accordionData;
};
