import { IInvoiceCard } from './../../../components/Cards/InvoiceCard/InvoiceCard';
import {
	fetchInvoiceDemands,
	fetchParsedInvoices,
	getCorrectInvoicePeriodDate,
	parseInvoicesIntoCards,
} from './../../../services/collection/InvoiceService';
import { isExternalUrl, getText, createString, sortInvoiceByPeriodDate } from '../../../services';
import {
	ICustomerInvoice,
	IInvoiceDemand,
	IInvoicePage,
	IEstimatedInvoice,
	INextInvoice,
	ICustomer,
	IAccountOption,
	ICustomerAccountInformation,
	IDefaultProps,
} from '../../../models';
import { IMSPlankWall, IPlankHouse } from '../../../blocks';
import { BrandColors, IAction, IconType, Theme } from '@fjordkraft/fjordkraft.component.library';
import { getPlankPrefab, ITextPlankPrefab } from '../../../Prefabs';
import { Constants } from '../../../data';
import format from 'date-fns/format';
import { getRequest } from '../../../contexts';

export interface ICurrentInvoicePageData extends IDefaultProps {}

// ************************************
// PUBLIC
// ************************************

export const getPageContent = async (config: ICurrentInvoicePageData) => {
	const { user, translations, desktopView, services } = config;
	const { userData } = user;
	const { GET, GETTYPED, token } = services;

	if (translations && GETTYPED !== undefined && userData) {
		let invoiceData = await fetchParsedInvoices({ userData, token, GETTYPED, hidePaid: true });
		let cards: IInvoiceCard[] | undefined = undefined;

		let filteredInvoicesDemands = await _getInvoicesDemands(invoiceData.invoices, GET);

		cards = parseInvoicesIntoCards({
			invoices: invoiceData.invoices,
			invoicesDemands: filteredInvoicesDemands,
			translations,
			desktopView,
			GET,
		});
		let navigation: IPlankHouse = await _getPlankHouseNavigation(invoiceData.noPreviousInvoices, config);

		return {
			...config,
			sub: {
				title: getText('pageTitle', translations),
				subTitle: getText('subTitle', translations),
				back: {
					text: getText('back', translations),
					link: Constants.paths.energyPage,
				} as IAction,
				statusToast: _getStatusToast(config, invoiceData.noPreviousInvoices, cards),
			},
			invoiceBlockData: cards,
			invoiceNavigationData: navigation,
			nextAndPreviousInvoices: _getNextAndPreviousInvoicePlankHouse(
				translations,
				__getMostRecentInvoices(invoiceData.invoices),
				_createNextInvoice(translations, invoiceData.estimatedElectricityInvoices)
			),
			fixedDueDateOptions: _getFixedDueDateOptions(userData, translations),
			noPreviousInvoices: invoiceData.noPreviousInvoices,
		};
	}
};

export interface IUpdateFilterState {
	translation: IInvoicePage;
	theme: Theme;
}

const _getStatusToast = (config: ICurrentInvoicePageData, noPreviousInvoices: boolean, cards: IInvoiceCard[]) => {
	const { translations } = config;

	if (noPreviousInvoices) {
		return {
			text: getText('noInvoices', config.translations),
			icon: IconType.InfoBubble,
			status: 'neutral',
		};
	} else if (cards && cards?.length === 0 && translations) {
		return {
			text: getText('allPaid', config.translations),
			icon: IconType.InfoBubble,
			status: 'positive',
		};
	}
};

const _getFixedDueDateOptions = (userData: ICustomer, translations: any) => {
	if (userData.accounts && userData.accounts.length > 0) {
		let infos: ICustomerAccountInformation[] = [];
		let options: IAccountOption[] = [];

		userData.accounts.forEach((acc) => {
			infos.push(acc);
			options.push({
				title: createString(getText('selectOptionAccount', translations), {
					accountNumber: acc.accountId,
				}),
				value: acc.accountId,
			});
		});

		return { infos, options };
	}
	return undefined;
};

const _getPlankHouseNavigation = async (disabledHistoryPlank: boolean, config: ICurrentInvoicePageData) => {
	const { translations, user } = config;
	const { userData } = user;

	let group1: IMSPlankWall = {
		planks: [_getHistoryPlank(translations, disabledHistoryPlank)],
	};

	if (userData.accounts && userData.accounts.length > 0) {
		group1.planks.push(_getSettingsPlank(translations));
	}

	return {
		plankWalls: [group1],
	} as IPlankHouse;
};

const __getMostRecentInvoices = (invoices: ICustomerInvoice[]) => {
	let mostRecent: ICustomerInvoice[] = [];

	if (invoices && invoices.length > 0) {
		let sorted: ICustomerInvoice[] = sortInvoiceByPeriodDate(invoices);

		sorted.forEach((invoice: ICustomerInvoice) => {
			let mostRecentMonth = getCorrectInvoicePeriodDate(sorted[0].invoicePeriod);

			if (invoice.paymentStatus !== 'Paid') {
				mostRecentMonth.setMonth(mostRecentMonth.getMonth() - 1);
			}

			let previousMonth: number = mostRecentMonth.getMonth();

			let invoicePeriod = getCorrectInvoicePeriodDate(invoice.invoicePeriod);
			let year: number = mostRecentMonth.getFullYear();

			if (
				invoice.paymentStatus === 'Paid' &&
				invoicePeriod.getMonth() === previousMonth &&
				invoicePeriod.getFullYear() === year
			) {
				mostRecent.push(invoice);
			}
		});
	}

	return mostRecent;
};

// ************************************
// PRIVATE
// ************************************

const _getNextAndPreviousInvoicePlankHouse = (
	translations: any,
	previousInvoices: ICustomerInvoice[],
	nextInvoices?: INextInvoice[]
) => {
	let house: IPlankHouse = {
		plankWalls: [],
	};

	let wall: IMSPlankWall = {
		planks: [],
	};

	if (previousInvoices) {
		previousInvoices.forEach((invoice: ICustomerInvoice) => {
			wall.planks.push(
				getPlankPrefab('Invoice', {
					action: {
						link: `${Constants.paths.invoiceHistoryPage}/${invoice.invoiceNumber}`,
					},
					invoice,
					translation: translations,
				})
			);
		});
	}

	if (nextInvoices) {
		nextInvoices.forEach((invoice) => {
			wall.planks.push(
				getPlankPrefab('Text', {
					left: {
						icon: invoice?.icon,
						title: invoice?.title,
						description: invoice?.subtitle,
						customization: {
							icon: {
								type: IconType.NextInvoice,
								color: BrandColors['primary-shade-light-2'],
							},
						},
					},
					right: {
						title: invoice?.cost,
						description: invoice?.costDescription,
					},
				})
			);
		});
	}

	if (wall.planks.length > 0) {
		house.plankWalls.push(wall);
	}

	return house;
};

const _getInvoicesDemands = async (filtered: ICustomerInvoice[], GET: getRequest) => {
	let allInvoicesDemands: IInvoiceDemand[][] = [];

	for (const invoice of filtered) {
		let invoiceDemands: IInvoiceDemand[] = [];
		if (invoice.collectionCase) {
			invoiceDemands = await fetchInvoiceDemands(GET, invoice.invoiceNumber);
		}
		allInvoicesDemands.push(invoiceDemands);
	}

	return allInvoicesDemands;
};

const _createNextInvoice = (translation: IInvoicePage, invoices: IEstimatedInvoice[]) => {
	let nextInvoices: INextInvoice[] = [];

	if (invoices && invoices.length > 0) {
		invoices.forEach((invoice) => {
			const accountInfo =
				invoices.length > 1 ? `\n${getText('estimatedInvoiceAccount', translation)} ${invoice.accountId}` : ``;

			let estimatedDateString;
			let costDescriptionString;
			// Latest date with cost is used when accounts are brand new and the cost is not estimated for the entire last month,
			// but only until last date with cost calculated.
			const latestCostDateForNewAccount = invoice.estimatedConsumptionSummary.latestDateWithCostForAllMeterIds;

			if (invoice.isNewAccount) {
				estimatedDateString = createString(
					getText('estimatedInvoiceDateIfNoPreviousInvoicesOrNewAccount', translation),
					{
						month: format(new Date().setMonth(new Date().getMonth() + 1), 'MM'),
					}
				);
				if (latestCostDateForNewAccount) {
					costDescriptionString = createString(
						getText('estimatedInvoiceCostDescriptionWhenNewAccount', translation),
						{
							date: format(new Date(latestCostDateForNewAccount), 'dd.MM'),
						}
					);
				} else {
					costDescriptionString = getText('estimatedInvoiceCostDescription', translation);
				}
			} else {
				// If the estimatedDate is null on estimatedInvoices, it's because it could not be calculated,
				// and we default to date specified in episerver.
				estimatedDateString = invoice?.estimatedDate
					? createString(getText('estimatedInvoiceDate', translation), {
							minDate: format(new Date(invoice.estimatedDate.minDate), 'dd.MM'),
							maxDate: format(new Date(invoice.estimatedDate.maxDate), 'dd.MM'),
					  })
					: createString(getText('estimatedInvoiceDateIfNoPreviousInvoicesOrNewAccount', translation), {
							month: format(new Date(), 'MM'),
					  });
				costDescriptionString = getText('estimatedInvoiceCostDescription', translation);
			}

			nextInvoices.push({
				title: `${getText('estimatedInvoiceTitle', translation)}`,
				subtitle: `${estimatedDateString}${accountInfo}`,
				cost: `${invoice.estimatedTotalCost.toFixed(0)} ${getText('currency', translation)}`,
				costDescription: costDescriptionString,
				icon: IconType.NextInvoice,
			});
		});
	}

	return nextInvoices;
};

const _getHistoryPlank = (translation: IInvoicePage, disabled: boolean): ITextPlankPrefab => {
	return getPlankPrefab('Text', {
		left: {
			title: getText('plankHistoryTitle', translation),
			description: !disabled
				? getText('plankHistoryDesc', translation)
				: getText('plankHistoryDescNoInvoices', translation),
			icon: IconType.HistoryLowDetail,
			customization: {
				icon: {
					type: IconType.HistoryLowDetail,
					color: BrandColors['primary-shade-light-2'],
				},
			},
		},
		right: {
			icon: IconType.ChevronRight,
		},
		action: {
			useRouterLink: !isExternalUrl(Constants.paths.invoiceHistoryPage),
			link: Constants.paths.invoiceHistoryPage,
			disabled: disabled,
		},
	});
};

const _getSettingsPlank = (translation: IInvoicePage) => {
	return getPlankPrefab('Text', {
		left: {
			title: getText('plankSettingsTitle', translation),
			description: getText('plankSettingsDesc', translation),
			icon: IconType.CogLowDetail,
			customization: {
				icon: {
					color: BrandColors['primary-shade-light-2'],
				},
			},
		},
		right: {
			icon: IconType.ChevronRight,
		},
		action: {
			link: `${Constants.paths.invoicePage}${Constants.paths.invoiceSettings}`,
		},
	} as ITextPlankPrefab);
};
