import React from 'react';
import {
	IComponent,
	IconType,
	IComponentTemplate,
	IIcon,
	ITextCustomization,
} from '@fjordkraft/fjordkraft.component.library';
import classnames from 'classnames';
import { useAuth } from 'react-oidc-context';
import { TextAndIconButtonTemplate } from '../../../Prefabs/Templates';
import { ActionButton } from '../ActionButton';

interface ILogoutButton extends IComponent {
	text?: string;
	icon?: IconType;
	template?: IComponentTemplate;
	flipped?: boolean;
	customization?: {
        icon?: Omit<IIcon, 'type'>;
        text?: ITextCustomization;
    }
}

export const LogoutButton = (props: ILogoutButton) => {
	// ************************************
	// Properties
	// ************************************

	const classPrefix = 'ms-logout-button';
	const { signoutRedirect } = useAuth();
	const {
		id,
		className,
		text,
		theme = 'Light',
		brand,
		icon,
		template = TextAndIconButtonTemplate(theme),
		flipped = false,
		customization
	} = props;

	// ************************************
	// Render
	// ************************************

	return (
		<ActionButton
			id={id}
			className={classnames(classPrefix, {
				[`${className}`]: className,
			})}
			brand={brand}
			action={{
				icon,
				text,
				onClick: () => signoutRedirect(),
			}}
			template={template}
			iconPlacement={flipped ? 'Left' : 'Right'}
			customization={customization}
		/>
	);
};
