import {
    BrandColors,
    IComponentTemplate,
    IComponentTransform,
    IThemedColors,
    Theme,
} from '@fjordkraft/fjordkraft.component.library';

export interface IServiceArticleTemplate extends IComponentTemplate {
    label: IComponentTemplate;
    image: IComponentTemplate;
}

// ************************************
// Transform
// ************************************

const _transform = (desktopView: boolean) => {
    return {
        width: desktopView ? '65%' : '100%',
        height: 'auto',
        padding: '2rem',
        border: {
            radiusType: 'curved',
            radiusLocation: desktopView ? undefined : 'bottom',
        },
        grid: {
            tagType: 'article',
            boxSizing: 'border-box',
            direction: 'column',
            alignment: 'top-left',
            gap: 1,
            spaceBetween: true,
        },
    } as IComponentTransform;
};

const _transformImage = (desktopView: boolean) => {
    return {
        width: desktopView ? '70%' : '100%',
        height: desktopView ? '100%' : '9rem',
        border: {
            radiusType: 'curved',
            radiusLocation: desktopView ? undefined : 'top',
        },
        grid: {
            boxSizing: 'border-box',
            direction: 'column',
            alignment: desktopView ? 'top-left' : 'top-center',
        },
    } as IComponentTransform;
};

const _transformLabel = {
    height: 'auto',
    width: 'auto',
    border: {
        radiusType: 'roundedOff',
    },
    padding: '0.25rem 1rem',
    margin: undefined,
    grid: {
        direction: 'row',
        alignment: 'center',
        gap: 1,
        tagType: 'section',
        boxSizing: 'border-box',
        wrap: false,
    },
} as IComponentTransform;

// ************************************
// Palette
// ************************************

const _palette = {
    Light: {
        background: { color: BrandColors['background-shade-light-1'] },
        text: { color: BrandColors['text-shade-dark-3'] },
        icon: { color: BrandColors['icon-shade-light-1'] },
        extra: [
            {
                key: 'loaderFill',
                value: { color: BrandColors['action-shade-light-1'] },
            },
            {
                key: 'loaderBackground',
                value: { color: BrandColors['action-shade-light-1'] },
            },
        ],
    },
    Dark: {
        background: { color: BrandColors['background-shade-dark-3'] },
        text: { color: BrandColors['text-shade-light-1'] },
        border: { color: BrandColors['none'] },
        icon: { color: BrandColors['none'] },
    },
} as IThemedColors;

const _paletteLabel = {
    Light: {
        background: {
            color: BrandColors['background-shade-dark-3'],
            opacity: 0.6,
        },
        text: { color: BrandColors['text-shade-light-1'] },
    },
    Dark: {
        background: { color: BrandColors['background-shade-light-1'], opacity: 0.6 },
        text: { color: BrandColors['text-shade-dark-3'] },
    },
} as IThemedColors;

// ************************************
// Export
// ************************************

export const ServiceArticleTemplate = (
    theme: Theme,
    desktopView: boolean = true
) => {
    return {
        palette: _palette[theme],
        transform: _transform(desktopView),
        image: {
            transform: _transformImage(desktopView),
            dontUpdateChildren: true,
        },
        label: {
            transform: _transformLabel,
            palette: _paletteLabel[theme],
        },
    } as IServiceArticleTemplate;
};
