import { format } from 'date-fns';
import { IMSPlankWall, IPlankHouse } from '../../../blocks';
import {
	IAgreementMinimumTermTypeDefinition,
	ICustomerElectricityAgreement,
	ICustomerElectricityAgreementDetails,
	ICustomerFutureElectricityAgreementDetails,
	ICustomerInstallation,
	ICustomerPriceElement,
} from '../../../models';
import { createString, getPlankIcon, getPlankText, getText, getTextV2, tNumber } from '../../../services';
import { IAgreementGetData } from '../EnergyAgreementPageData';
import { ITextPlankPrefab, MS_ButtonTemplate, PrimaryPlankTemplate, getPlankPrefab } from '../../../Prefabs';
import { BrandColors, IMasterPlank, IconType } from '@fjordkraft/fjordkraft.component.library';
import { Constants } from '../../../data';
import _ from 'lodash';

export const getEnergyAgreementOptions = (config: IAgreementGetData) => {
	const { agreement, translations, benefits, reducedOptions = false } = config;

	let agreementOptions: IPlankHouse = {
		plankWalls: [],
	};

	if (agreement) {
		let group1: IMSPlankWall = { planks: [] };
		let group2: IMSPlankWall = { planks: [] };
		let group3: IMSPlankWall = { planks: [] };
		let group4: IMSPlankWall = { planks: [] };
		//let group5: IMSPlankWall = {
		//	planks: [],
		//	title: getText('futureProductsTitle', translations),
		//};
		let group6: IMSPlankWall = { planks: [] };

		group1.planks = group1.planks.concat(_handlePriceElements(config).planks);
		group1.planks = group1.planks.concat(_handleTerminationPlanks(agreement, translations).planks);
		group2.planks = _handleAdditionalBindingPeriod(config);
		group3.planks = _handleBenefits(benefits, config).planks;
		group4.planks = _getMoreOptions(config);
		//group5.planks = _getCancelSpotManagedPlanks(config);
		group6.planks = _handleFutureAgreements(config).planks;

		agreementOptions.plankWalls.push(group1);

		if (group2.planks.length > 0) {
			agreementOptions.plankWalls.push(group2);
		}

		if (!reducedOptions) {
			agreementOptions.plankWalls.push(group3);

			agreementOptions.plankWalls.push(group4);

			//if (group5.planks.length > 0) {
			//	agreementOptions.plankWalls.push(group5);
			//}

			if (agreement.futureProducts?.length > 0) {
				agreementOptions.plankWalls.push(group6);
			}
		}
	}

	return agreementOptions;
};

// ************************************
// Price Elements
// ************************************

const _handlePriceElements = (config: IAgreementGetData) => {
	const { agreement, user, translations } = config;
	const { installation } = user;

	let plankWall: IMSPlankWall = { planks: [] };
	let priceAsString: string = '';

	if (agreement.priceElements && installation) {
		agreement.priceElements.forEach((priceElement: ICustomerPriceElement) => {
			priceAsString = tNumber(
				installation.address.priceArea === 'NO4'
					? priceElement.vatExclusivePrice
					: priceElement.vatInclusivePrice,
				'no-NO',
				2
			);

			plankWall.planks.push(
				_getPriceElementPlank(
					priceElement,
					_sortFuturePrices(priceElement, installation, translations),
					priceAsString,
					translations
				)
			);
		});
	}

	return plankWall;
};

// ************************************
// Price Elements :: Helpers
// ************************************

const _getPriceElementPlank = (
	priceElement: ICustomerPriceElement,
	futurePrices: any,
	price: string,
	translation: any
) => {
	const descriptions =
		futurePrices.labels && futurePrices.values
			? { leftDescription: futurePrices.labels, rightDescription: futurePrices.values }
			: undefined;

	return getPlankPrefab('Text', {
		left: {
			title: priceElement.name,
			description: descriptions ? descriptions.leftDescription : '',
		},
		right: {
			title: `${price} ${priceElement.unit}`,
			description: descriptions ? descriptions.rightDescription : '',
		},
	} as ITextPlankPrefab);
};

const _sortFuturePrices = (
	priceElement: ICustomerPriceElement,
	installation: ICustomerInstallation,
	translation: any
) => {
	const futurePrices: {
		labels?: string;
		values?: string;
	} = {};
	let values: string[] = [];
	let labels: string[] = [];

	if (priceElement.futurePrices && priceElement.futurePrices.length > 0) {
		priceElement.futurePrices.forEach((futurePrice) => {
			if (futurePrice.from) {
				labels.push(
					createString(getText('plankFuturePriceDateLabel', translation), {
						date: format(new Date(futurePrice.from), 'dd.MM.yyyy'),
					})
				);
				values.push(
					`${tNumber(
						installation.address.priceArea === 'NO4'
							? futurePrice.vatExclusivePrice
							: futurePrice.vatInclusivePrice,
						'no-NO',
						2
					)} ${priceElement.unit}`
				);
			}
		});
	}

	futurePrices.labels = labels.join('\n');
	futurePrices.values = values.join('\n');

	return futurePrices;
};

// ************************************
// Termination planks
// ************************************

const _handleTerminationPlanks = (agreement: ICustomerElectricityAgreement, translation: any) => {
	let plankWall: IMSPlankWall = { planks: [] };

	if (agreement.minimumTermDefinitions && agreement.minimumTermDefinitions.length > 0) {
		agreement.minimumTermDefinitions.forEach((def) => {
			if (def.type === 'TERMINATION') {
				plankWall.planks.push(_getAgreementMinimumTermDefinitionPlank(translation, def));
			}
		});
	} else {
		plankWall.planks.push(_getAgreementNoTermType(translation));
	}

	return plankWall;
};

// ************************************
// Termination planks :: Helpers
// ************************************

const _getAgreementMinimumTermDefinitionPlank = (translation: any, definition: IAgreementMinimumTermTypeDefinition) => {
	let { leftTitle, rightTitle } = _getMinimumTermPlankValues(translation, definition);

	return getPlankPrefab('Text', {
		left: {
			title: leftTitle,
		},
		right: {
			title: rightTitle,
		},
		bottom: {
			description: createString(getText(`plankMinimumTermDefinitionBottomText`, translation), {
				amount: definition.breachFee,
			}),
			customization: {
				description: {
					align: 'align-left',
				},
			},
		},
	} as ITextPlankPrefab);
};

const _getMinimumTermPlankValues = (translation: any, definition: IAgreementMinimumTermTypeDefinition) => {
	let leftTitle, leftDescription, rightTitle, rightDescription;

	switch (definition.type) {
		case 'START':
			leftTitle = getText('plankStartTermType', translation);
			break;
		case 'TERMINATION':
			leftTitle = getText('plankTerminationTermType', translation);
	}
	if (definition.expiresAt) leftTitle = `${leftTitle} ${getText('plankMinimumTermExpires', translation)}`;

	if (definition.expiresAt) {
		rightTitle = format(new Date(definition.expiresAt), 'dd.MM.yyyy');
	} else if (definition.termDays) {
		rightTitle = createString(getText('plankMinimumTermDaysTotal', translation), { days: definition.termDays });
	}

	if (definition.breachFee && definition.breachFeeDenomination) {
		rightDescription = `${definition.breachFee} ${definition.breachFeeDenomination}`;
		leftDescription = getText('plankMinimumTermBreachFee', translation);
	}

	return {
		leftTitle,
		leftDescription,
		rightTitle,
		rightDescription,
	};
};

const _getAgreementNoTermType = (translation: any) => {
	return getPlankPrefab('Text', {
		left: {
			title: getText('plankNoTermTypeTitle', translation),
		},
		right: {
			title: getText('plankNoTermTypeDays', translation),
		},
	} as ITextPlankPrefab);
};

// ************************************
// Additional binding period
// ************************************

const _handleAdditionalBindingPeriod = (config: IAgreementGetData) => {
	const { translations, agreement, activeTheme } = config;

	let planks: IMasterPlank[] = [];

	if (agreement.minimumTermDefinitions && agreement.minimumTermDefinitions?.length > 0) {
		let additionaBindingTime: IAgreementMinimumTermTypeDefinition | undefined = _.find(
			agreement.minimumTermDefinitions,
			(def: IAgreementMinimumTermTypeDefinition) => {
				return def.type === 'START';
			}
		);

		if (additionaBindingTime) {
			planks.push(
				getPlankPrefab('Text', {
					left: {
						title: getText('plankAdditionalBindingHighlightTitle', translations),
						description: getText('plankAdditionalBindingHighlightDesc', translations),
					},
					template: PrimaryPlankTemplate(activeTheme, 'top'),
				} as ITextPlankPrefab)
			);
		}

		agreement.minimumTermDefinitions.forEach((def: IAgreementMinimumTermTypeDefinition) => {
			if (def.type === 'START') {
				// Title priority:
				// 1. The specified title for the product id in EpiServer '_productId:{productId}'.
				// 2. If the product is the "extra binding period product", often included in the product order by a salesperson
				//		in the combination with a gift card or similar, select the default title.
				// 3. The product name in ProductHub
				// 4. The default title.
				const startTypeLeftTitle =
					getTextV2({
						key: `plankAdditionalBindingTitle_productId:${def.associatedProductId}`,
						includeMissing: false,
						translations: translations,
					}) ??
					(!!def.associatedProductName &&
					def.associatedProductId !== Constants.minimumBindingPeriodGiftcardProductId
						? def.associatedProductName
						: getText('plankAdditionalBindingTitle', translations));

				planks.push(
					getPlankPrefab('Text', {
						left: {
							title: startTypeLeftTitle,
							description: createString(getText('plankAdditionalBindingDesc', translations), {
								amount: def.breachFee,
							}),
						},
						right: {
							title: getText('plankAdditionalBindingRightTitle', translations),
							description: format(new Date(def.expiresAt), 'dd.MM.yyyy'),
						},
					} as ITextPlankPrefab)
				);
			}
		});
	}

	return planks;
};

// ************************************
// Benefits
// ************************************

const _handleBenefits = (benefits: any[], config: IAgreementGetData) => {
	let plankWall: IMSPlankWall = { planks: [] };

	if (benefits?.length > 0) {
		benefits.forEach((plank: any) => {
			plankWall.planks.push(_getBenefitPlankOption(plank, config));
		});
	}

	return plankWall;
};

// ************************************
// Benefits :: Helpers
// ************************************

const _getBenefitPlankOption = (plank: any, config: IAgreementGetData) => {
	return getPlankPrefab('Text', {
		left: {
			title: getPlankText({
				key: 'title',
				plank,
			}),
			description: getPlankText({
				key: 'description',
				plank,
			}),
			icon: config.desktopView
				? getPlankIcon({
						key: 'leftIcon',
						plank,
				  })
				: undefined,
			customization: {
				icon: {
					type: getPlankIcon({ key: 'leftIcon', plank }),
					color: BrandColors['primary-shade-light-2'],
					size: 1.5,
				},
			},
		},
	} as ITextPlankPrefab);
};

// ************************************
// More Options
// ************************************

const _getMoreOptions = (config: IAgreementGetData) => {
	const { translations, setShowMoreAboutAgreement, activeTheme, desktopView } = config;

	return [
		getPlankPrefab('Action', {
			left: {
				title: getText('plankMoreAboutAgreement', translations),
				icon: IconType.MoreInfo,
				customization: {
					icon: {
						type: IconType.MoreInfo,
						color: BrandColors['primary-shade-light-2'],
					},
				},
			},
			right: {
				template: MS_ButtonTemplate(activeTheme, 'link'),
			},
			desktopView,
			action: {
				text: getText('plankMoreAboutAgreementValue', translations),
				onClick: () => {
					if (setShowMoreAboutAgreement) {
						setShowMoreAboutAgreement({ show: true });
					}
				},
			},
		}),
	];
};

// ************************************
// Cancel Agreement
// ************************************

const _getCancelSpotManagedPlanks = (config: IAgreementGetData) => {
	const { translations, activeTheme, agreement, setShowCancelSpotManaged, desktopView } = config;

	if (agreement.agreementCategory === 'Managed') {
		let earliestCancelDate: Date = new Date();
		earliestCancelDate.setDate(earliestCancelDate.getDate() + (agreement.minimumTermDays ?? 0));

		if (setShowCancelSpotManaged) {
			return [
				getPlankPrefab('Action', {
					left: {
						title: createString(getText('plankCancelManagedTitle', translations), {
							agreementName: agreement.name.toLocaleLowerCase(),
						}),
						description: createString(getText('plankCancelManagedDesc', translations), {
							date: format(earliestCancelDate, 'dd.MM.yyyy'),
						}),
					},
					right: {
						template: MS_ButtonTemplate(activeTheme, 'secondary'),
					},
					action: {
						text: getText('plankCancelManagedValue', translations),
						onClick: () => {
							setShowCancelSpotManaged(true);
						},
					},
					desktopView,
					actionButtonPadding: 'small',
				}),
			];
		}
	}

	return [];
};

// ************************************
// Future Agreement
// ************************************

const _handleFutureAgreements = (config: IAgreementGetData) => {
	const { agreement, details } = config;

	let plankWall: IMSPlankWall = {
		planks: [],
	};

	if (agreement?.futureProducts?.length > 0) {
		agreement.futureProducts.forEach((futureProduct: any) => {
			plankWall.planks.push(
				_getFutureProduct({
					...config,
					...{
						agreement: futureProduct,
						detailsList: _getAgreementDetails(details, futureProduct.id, true),
					},
				})
			);
		});
	}

	return plankWall;
};

// ************************************
// Future Agreement :: Helpers
// ************************************

const _getFutureProduct = (config: IAgreementGetData) => {
	const { agreement, translations, setShowMoreAboutAgreement } = config;

	return getPlankPrefab('Text', {
		left: {
			title: agreement.name,
			description: createString(getText('plankFutureProductDesc', translations), {
				date: format(new Date(agreement.from), 'dd.MM.yyyy'),
			}),
			icon: IconType.Calendar,
			customization: {
				icon: {
					type: IconType.Calendar,
					color: BrandColors['primary-shade-light-2'],
				},
			},
		},
		right: {
			title: getText('plankFutureProductValue', translations),
			customization: {
				title: {
					color: BrandColors['action-shade-light-1'],
					underline: true,
				},
			},
		},
		action: {
			onClick: () => {
				if (setShowMoreAboutAgreement) {
					setShowMoreAboutAgreement({ show: true, futureAgreementId: `${agreement.id}` });
				}
			},
		},
		actionButtonPadding: 'none',
	});
};

const _getAgreementDetails = (
	electricityDetails: ICustomerElectricityAgreementDetails,
	id: number,
	futureProduct: boolean
) => {
	let details: string[] = [];

	if (futureProduct) {
		if (electricityDetails?.futureAgreementTexts) {
			electricityDetails.futureAgreementTexts.forEach(
				(agreementDetails: ICustomerFutureElectricityAgreementDetails) => {
					if (agreementDetails.id === id) {
						details = agreementDetails.agreementTexts;
					}
				}
			);
		}
	} else {
		details = electricityDetails.activeAgreementTexts;
	}

	return details;
};
