import { IconType } from '@fjordkraft/fjordkraft.component.library';
import { _getTextPlankPrefabTemplate } from '.';
import { Constants } from '../../../data';
import { AllowFeature, IsActiveCustomer, getText } from '../../../services';
import { IHomePageData } from '../HomePageData';
import { IStatePlank } from '../../../components';

export const ElectricityAgreementPlank = (config: IHomePageData): IStatePlank | undefined => {
	const { translations, services, user } = config;
	const { installation } = user;
	const { customerServiceFeature } = services;

	if (
		AllowFeature(Constants.features.agreement, translations, services.user, customerServiceFeature) &&
		IsActiveCustomer(installation)
	) {
		return {
			promise: fetchAgreementPlank(config),
		};
	}
};

const fetchAgreementPlank = async (config: IHomePageData) => {
	const { activeTheme, activeBrand, translations, user, services } = config;
	const { installation } = user;
	const { GET } = services;

	if (installation) {
		let resp = await GET(`Agreements/${installation.meterId}`);

		return _getTextPlankPrefabTemplate({
			theme: activeTheme,
			brand: activeBrand,
			title: getText('plankElectricityAgreementTitle', translations),
			description: resp?.data?.name ?? '',
			rightTitle: getText('plankElectricityAgreementDesc', translations),
			link: `${Constants.paths.powerAgreementPage}`,
			iconRight: IconType.ChevronRight,
		});
	}
};
