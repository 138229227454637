import { IComponent, IImage } from '@fjordkraft/fjordkraft.component.library';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useApplicationContext, useDefaultPageContext } from '../../../contexts';
import { Mistletoe } from '../../../assets/christmas/Mistletoe';
import './HeaderLogoButton.scss';

export interface IHeaderLogoButton extends IComponent {
	link: string;
	image: IImage;
}

export const HeaderLogoButton = (props: IHeaderLogoButton) => {
	const { id, className, link, image } = props;
	const classPrefix = 'header-logo-button';
	const { seasonalMode } = useApplicationContext();
	const { setContentLoading } = useDefaultPageContext();
	const navigate = useNavigate();

	return (
		<Link
			id={id}
			className={classNames(classPrefix, {
				[`${className}`]: className,
			})}
			to={'..'}
			onClick={(e) => {
				e.preventDefault();
				setContentLoading(true);
				navigate(link);
			}}
		>
			{seasonalMode === 'Christmas' && <Mistletoe className={`${classPrefix}__mistletoe`} />}
			<img
				className={classNames(`${classPrefix}__image`, {
					[`${className}__image`]: className,
				})}
				{...image}
			/>
		</Link>
	);
};
