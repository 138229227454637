/* This function converts a json array to a csv.
   If specific columns are required, an object can be written like so:
   {
      nameOfColumn: (item) => return item.desiredValue,
      nameOfColumn2: (item) => return item.subItem.desiredValue2 
   }
   and so on.
   If not, then the columns are extracted from the keys of the first object.
*/
export function jsonToCsv(
    jsonArray: any[],
    columns?: any,
    delimiter: string = ';'
) {
    let headers: string[] = [];

    if (columns) {
        Object.keys(columns).forEach((key: any) => {
            headers.push(key);
        });
    } else {
        Object.keys(jsonArray[0]).forEach((key: any) => {
            headers.push(key);
        });
    }
    let csv = `sep=${delimiter}\r\n`;
    csv += headers.join(delimiter) + '\n';

    jsonArray.forEach((item: any) => {
        let values: string[] = [];
        if (columns) {
            headers.forEach((header: string) => {
                values.push(columns[header](item));
            });
        } else {
            headers.forEach((header) => {
                values.push(item[header]);
            });
        }
        csv += values.join(delimiter) + '\n';
    });

    return csv;
}

export interface CSVData {
    data: any;
    fileName: string;
}

export function downloadCsv(csv: CSVData) {
    const filename = csv.fileName + '.csv';
    const blob = new Blob([csv.data], {
        type: 'text/csv;charset=utf-8;',
    });

    // For other browsers
    const link = document.createElement('a');

    if (link.download !== undefined) {
        // HTML5 approach
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } else {
        // Legacy approach
        const csvData =
            'data:text/csv;charset=utf-8,' + encodeURIComponent(csv.data);
        window.open(csvData);
    }
}
