import {
	BrandColors,
	IComponentTemplate,
	IComponentTransform,
	IModalTemplate,
	IThemedColors,
	Theme,
} from '@fjordkraft/fjordkraft.component.library';

// ************************************
// Transform
// ************************************

const _transform = (desktopView: boolean) => {
	return {
		height: '100vh',
		width: '100vw',
		grid: {
			direction: 'column',
			alignment: desktopView ? 'center' : 'bottom-center',
			tagType: 'div',
			boxSizing: 'border-box',
		},
	} as IComponentTransform;
};

const _transformContent = (desktopView: boolean) => {
	return {
		height: desktopView ? 'auto' : '90vh',
		width: '100%',
		maxWidth: 'var(--max-content-width)',
		maxHeight: desktopView ? '70vh' : '90vh',
		border: {
			radiusType: 'curved',
		},
		padding: '2rem 0',
		grid: {
			direction: 'column',
			alignment: 'top-center',
			tagType: 'article',
			boxSizing: 'border-box',
			inline: true,
			wrap: false,
		},
	} as IComponentTransform;
};

// ************************************
// Palette
// ************************************

const _palette = {
	Light: {
		background: {
			color: BrandColors['background-shade-dark-1'],
			opacity: 0.8,
		},
	},
	Dark: {
		background: { color: BrandColors['background-shade-light-1'], opacity: 0.8 },
	},
} as IThemedColors;

const _paletteContent = {
	Light: {
		background: { color: BrandColors['background-shade-light-2'] },
		text: { color: BrandColors['text-shade-dark-3'] },
		border: { color: BrandColors['none'] },
		icon: { color: BrandColors['none'] },
	},
	Dark: {
		background: { color: BrandColors['background-shade-dark-2'] },
		text: { color: BrandColors['text-shade-light-1'] },
		border: { color: BrandColors['none'] },
		icon: { color: BrandColors['none'] },
	},
	dontUpdateChildren: true,
} as IThemedColors;

// ************************************
// Export
// ************************************

export const PopupCardTemplate = (theme: Theme = 'Light', desktopView: boolean = true) => {
	return {
		dontUpdateChildren: true,
		palette: _palette[theme],
		transform: _transform(desktopView),
		content: {
			palette: _paletteContent[theme],
			transform: _transformContent(desktopView),
			dontUpdateChildren: true,
		},
	} as IModalTemplate;
};

export const PopupModalTemplate = (theme: Theme = 'Light', desktopView: boolean = true) => {
	return {
		palette: _paletteContent[theme],
		transform: _transformContent(desktopView),
		dontUpdateChildren: true,
	} as IComponentTemplate;
};
