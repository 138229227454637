import { useContext, createContext } from 'react';
import { IWebToast } from '../../blocks';

interface IApplicationOverlayWrapperContext {
    globalLoading: boolean;
    setGlobalLoading: (boolean: boolean) => void;
    tempToasts?: IWebToast[];
    setTempToasts: (toasts: IWebToast[] | undefined) => void;
    toasts?: IWebToast[];
    setToasts: (toasts: IWebToast[] | undefined) => void;
}

export const ApplicationOverlayWrapperContext =
    createContext<IApplicationOverlayWrapperContext>({
        globalLoading: false,
        setGlobalLoading: () => { },
        tempToasts: undefined,
        setTempToasts: () => { },
        toasts: undefined,
        setToasts: () => { },
    });

export const useApplicationOverlayWrapperContext = () =>
    useContext(ApplicationOverlayWrapperContext);
