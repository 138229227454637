import React, { useState } from 'react';
import { StyleGrid, ContentGrid, CallState, IImage } from '@fjordkraft/fjordkraft.component.library';
import { getPageContent } from './OnboardingPageData';
import { Constants } from '../../data';
import { IStageBlock, StageBlock } from '../../blocks';
import { useApplicationCoreDataContext, useApplicationServicehandlerContext } from '../../contexts';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { HeaderLogoButton } from '../../components';
import { IDefaultViewProps, PageV2 } from '../PageV2';
import './OnboardingPage.scss';

export interface IOnboardingPage extends IDefaultViewProps {
	logo: {
		image: IImage;
		link: string;
	};
	onboardingStageData?: IStageBlock;
}

export interface IEpiOnboardingStageBlockData {
	title?: string;
	description?: string;
	tutorialReferenceLinkText?: string;
	image?: string;
	blockId?: string;
	active?: boolean;
	modelType?: string;
}

export const OnboardingPage = () => {
	// ************************************
	// Lifecycle
	// ************************************

	const classPrefix = 'onboarding-page';
	const { POST } = useApplicationServicehandlerContext();
	const { userData, updateCustomerData } = useApplicationCoreDataContext();
	const [paramProperties, setParamProperties] = useSearchParams();
	const navigate = useNavigate();

	// ************************************
	// Lifecycle
	// ************************************

	const [finishStatus, setFinishStatus] = useState<CallState>('idle');

	// ************************************
	// Async Functionality
	// ************************************

	const _handleFinish = async () => {
		if (finishStatus !== 'pending') {
			if (!userData?.hasOnboarded) {
				setFinishStatus('pending');
				await POST('Customers/setHasOnboarded');
				await updateCustomerData(true);
				setFinishStatus('idle');
				navigate(Constants.paths.energyPage ?? '/');
			} else {
				navigate(Constants.paths.energyPage ?? '/');
			}
		}
	};

	const _handleOnNext = (maxAmount: number) => {
		let currentPage: number = _clampPageIntValue();
		currentPage++;
		currentPage = currentPage > maxAmount ? maxAmount : currentPage;
		setParamProperties({ side: `${currentPage}` });
	};

	const _handleOnPrevious = () => {
		let currentPage: number = _clampPageIntValue();
		currentPage--;
		currentPage = currentPage < 0 ? 0 : currentPage;
		setParamProperties({ side: `${currentPage}` });
	};

	// ************************************
	// Helpers
	// ************************************

	const _clampPageIntValue = (): number => {
		return parseInt(paramProperties.get('side') ?? '0') ?? 0;
	};

	// ************************************
	// Render
	// ************************************

	return PageV2({
		setup: {
			pageType: Constants.epiServerPageNames.onboardingPage.type,
		},
		dependencies: [{ paramProperties }],
		handleData: getPageContent,
		render: (config: IOnboardingPage) => {
			const { activeTheme, activeBrand, desktopView, logo, onboardingStageData } = config;

			return (
				<StyleGrid
					className={classPrefix}
					direction="column"
					alignment="top-left"
					boxSizing="border-box"
					wrap={false}
				>
					{desktopView && logo && (
						<ContentGrid
							tagType="header"
							direction="row"
							alignment="center-left"
							boxSizing="border-box"
						>
							<HeaderLogoButton
								link={logo.link}
								image={logo.image}
							/>
						</ContentGrid>
					)}
					<ContentGrid
						className={`${classPrefix}__main`}
						tagType="main"
						direction="column"
						alignment={desktopView ? 'center' : 'top-left'}
						boxSizing="border-box"
					>
						{onboardingStageData && (
							<StageBlock
								{...onboardingStageData}
								desktopView={desktopView}
								theme={activeTheme}
								brand={activeBrand}
								onClose={() => {
									navigate(Constants.paths.energyPage ?? '/');
								}}
								onNext={_handleOnNext}
								onPrevious={_handleOnPrevious}
								onFinish={() => {
									_handleFinish();
								}}
							/>
						)}
					</ContentGrid>
				</StyleGrid>
			);
		},
	});
};
