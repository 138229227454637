import { IComponent } from '@fjordkraft/fjordkraft.component.library';
import React from 'react';

export const ChristmasTree = (props: IComponent) => {
	return (
		<svg
			className={props.className}
			width="90"
			height="90"
			viewBox="0 0 250 250"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M121.414 146.154C123.413 143.749 127.104 143.749 129.104 146.154L196.724 227.489C202.141 234.005 197.508 243.882 189.035 243.882H61.4825C53.0095 243.882 48.3762 234.005 53.7929 227.489L121.414 146.154Z"
				fill="#46B873"
			/>
			<path
				d="M72.6543 193.213C94.5922 216.434 185.901 220.305 196.574 210.629"
				stroke="white"
				strokeWidth="8"
				strokeLinecap="round"
			/>
			<path
				d="M121.414 50.6246C123.413 48.2199 127.104 48.2199 129.104 50.6246L196.724 131.96C202.141 138.475 197.508 148.353 189.035 148.353H61.4825C53.0095 148.353 48.3762 138.475 53.7929 131.96L121.414 50.6246Z"
				fill="#46B873"
			/>
			<path
				d="M121.414 98.3892C123.413 95.9845 127.104 95.9845 129.104 98.3892L196.724 179.725C202.141 186.24 197.508 196.118 189.035 196.118H61.4825C53.0095 196.118 48.3762 186.24 53.7929 179.725L121.414 98.3892Z"
				fill="#46B873"
			/>
			<path
				d="M81.5059 83.7354C98.7428 106.097 170.486 109.824 178.871 100.506"
				stroke="white"
				strokeWidth="8"
				strokeLinecap="round"
			/>
			<ellipse
				cx="90.5966"
				cy="115.476"
				rx="7.45382"
				ry="7.91968"
				transform="rotate(-18.9861 90.5966 115.476)"
				fill="#FFD700"
			/>
			<circle
				cx="125.919"
				cy="74.1077"
				r="7.45382"
				transform="rotate(-18.9861 125.919 74.1077)"
				fill="#BB182C"
			/>
			<circle
				cx="162.257"
				cy="121.626"
				r="7.45382"
				transform="rotate(-18.9861 162.257 121.626)"
				fill="#BB182C"
			/>
			<path
				d="M192.382 115.414C186.791 132.185 120.173 165.727 56.8154 164.796"
				stroke="white"
				strokeWidth="8"
				strokeLinecap="round"
			/>
			<circle
				cx="159.462"
				cy="170.075"
				r="7.45382"
				transform="rotate(-18.9861 159.462 170.075)"
				fill="white"
			/>
			<circle
				cx="141.139"
				cy="230.571"
				r="7.45382"
				transform="rotate(-18.9861 141.139 230.571)"
				fill="white"
			/>
			<circle
				cx="90.5134"
				cy="182.189"
				r="7.45382"
				transform="rotate(-18.9861 90.5134 182.189)"
				fill="white"
			/>
			<circle
				cx="121.26"
				cy="133.739"
				r="7.45382"
				transform="rotate(-18.9861 121.26 133.739)"
				fill="white"
			/>
			<circle
				cx="129.987"
				cy="190.847"
				r="7.45382"
				transform="rotate(-18.9861 129.987 190.847)"
				fill="#FFD700"
			/>
			<circle
				cx="80.6061"
				cy="225.843"
				r="7.45382"
				transform="rotate(-18.9861 80.6061 225.843)"
				fill="#BB182C"
			/>
			<path
				d="M122.147 7.78116C123.045 5.01723 126.955 5.01722 127.853 7.78115L130.837 16.9656C131.239 18.2016 132.391 19.0385 133.691 19.0385L143.348 19.0385C146.254 19.0385 147.462 22.7574 145.111 24.4656L137.298 30.1418C136.247 30.9058 135.807 32.2599 136.208 33.4959L139.193 42.6803C140.091 45.4443 136.927 47.7426 134.576 46.0344L126.763 40.3582C125.712 39.5942 124.288 39.5942 123.237 40.3582L115.424 46.0344C113.073 47.7426 109.909 45.4443 110.807 42.6803L113.792 33.4959C114.193 32.2599 113.753 30.9058 112.702 30.1418L104.889 24.4656C102.538 22.7573 103.746 19.0385 106.652 19.0385L116.309 19.0385C117.609 19.0385 118.761 18.2016 119.163 16.9656L122.147 7.78116Z"
				fill="#FFD700"
			/>
		</svg>
	);
};
