import { ContentGrid, IComponent, IComponentTemplate, IconType, StyleGrid } from "@fjordkraft/fjordkraft.component.library";
import React, { useState } from "react";
import { ActionButton } from "../Buttons/ActionButton";
import { MS_ButtonTemplate } from "../../Prefabs";
import classNames from "classnames";
import './BareAccordion.scss';

export interface IBareAccordion extends IComponent {
    textOpen: string;
    textClosed: string;
}

export const BareAccordion = (props: IBareAccordion) => {

    // ************************************
	// Properties
	// ************************************

    const { id, className, theme, brand, children, textOpen, textClosed } = props;
    const classPrefix = 'ms-bare-accordion';

    // ************************************
	// Lifecycle
	// ************************************

    const [show, setShow] = useState<boolean>();

    // ************************************
	// Render
	// ************************************

    return(
        <ContentGrid
            id={id}
            className={classNames(classPrefix, {
                [`${className}`]: className
            })}
            direction="column"
            alignment="top-left"
            boxSizing='border-box'
            tagType="nav"
        >
            <ActionButton
                className={`${classPrefix}__action`}
                brand={brand}
                template={MS_ButtonTemplate(theme, 'link')}
                action={{
                    icon: show ? IconType.ChevronUp : IconType.ChevronDown,
                    text: show ? textOpen : textClosed,
                    onClick: () => {
                        setShow(!show);
                    }
                }}
                iconPlacement="Right"
                padding="medium"
            />
            {show &&
                <StyleGrid
                    className={`${classPrefix}__container`}
                    direction="column"
                    alignment="top-left"
                    boxSizing='border-box'
                >
                    {children}
                </StyleGrid>
            }
        </ContentGrid>
    );
}