import React from 'react';
import classnames from 'classnames';
import {
    getCounterTheme,
    StyleLoopLimit,
    Text,
    ContentGrid,
    TooltipIcon,
    IComponent,
    BrandColors,
    getColorCode,
    IText,
} from '@fjordkraft/fjordkraft.component.library';
import { MSRichText } from '../../components';
import { h4TextPrefab } from '../../Prefabs';
import './OptimizedPlankWallBase.scss';

export interface IOptimizedPlankWallBase extends IComponent {
    title?: string;
    titleCustomization?: Omit<IText, 'type'>;
    customization?: {
        titleGap?: StyleLoopLimit;
        backgroundColor: {
            Light: BrandColors;
            Dark: BrandColors;
        }
    }
    optimizationSettings?: {
        plankAverageHeightInRem: number;
        plankCount: number;
    }
}

export const OptimizedPlankWallBase = (props: IOptimizedPlankWallBase) => {
    // ************************************
    // Properties
    // ************************************

    const {
        id,
        className,
        brand,
        theme = 'Light',
        title,
        titleCustomization = {
            size: 'medium',
            weight: 600,
        },
        render = true,
        customization = {
            titleGap: 2,
            backgroundColor: {
                Light: BrandColors["background-shade-light-2"],
                Dark: BrandColors["background-shade-dark-3"]
            }
        },
        tooltip,
        children,
        optimizationSettings = {
            plankAverageHeightInRem: 4,
            plankCount: 0
        }
    } = props;
    const classPrefix = 'plank-wall-optimized';

    const averagePlankHeightInRem = 4;
    const gapInPx = 0.125;

    // ************************************
    // Helper Functionality
    // ************************************

    // TODO: Figure out a way to better predefine correct height before implementation
    const _predefineBounds = (): number => {
        return (averagePlankHeightInRem + gapInPx) * optimizationSettings.plankCount;
    }

    // ************************************
    // Render
    // ************************************

    return (
        <ContentGrid
            id={id}
            className={classnames(`${classPrefix}`, {
                [`${className}`]: className,
            })}
            brand={brand}
            theme={theme}
            alignment={'top-left'}
            direction={'column'}
            render={render}
            gap={customization.titleGap}
            tagType={'section'}
        >
            {title && (
                <ContentGrid
                    className={`${classPrefix}__header`}
                    tagType={'section'}
                    alignment={'center-left'}
                    direction={'row'}
                    boxSizing="border-box"
                    spaceBetween
                >
                    <Text
                        {...h4TextPrefab()}
                        brand={brand}
                        theme={getCounterTheme(theme)}
                        {...titleCustomization}
                    >
                        {title}
                    </Text>
                    {tooltip && (
                        <TooltipIcon
                            brand={brand}
                            theme={getCounterTheme(theme)}
                            alignment='center-left'
                        >
                            <MSRichText
                                className={`${classPrefix}__tooltip`}
                                text={tooltip}
                                theme={theme}
                            />
                        </TooltipIcon>
                    )}
                </ContentGrid>
            )}
            <ContentGrid
                className={`${classPrefix}__content`}
                wrap={false}
                boxSizing='border-box'
                direction='column'
                alignment='top-left'
                gap={2}
                gapType='px'
                tagType='nav'
                style={optimizationSettings ? {
                    backgroundColor: getColorCode({ color: customization.backgroundColor[theme] })
                } : undefined}
            >
                {children}
            </ContentGrid>
        </ContentGrid>
    );
};
