import { Constants } from '../../../data';
import { ICustomerAccountInformation, IPage, IServicePage, ISteddiTransaction, ServiceStatus } from '../../../models';
import { createString, getText, tNumber } from '../../../services';
import { getSteddiStatus, ITrumfTransaction, ITrumfTransactions } from '../ServicePage/Datahandling/UniqueServices';
import { SteddiPlankTemplate, TrumfPlankTemplate, getPlankPrefab } from '../../../Prefabs';
import { format } from 'date-fns';
import { nb } from 'date-fns/locale';
import { IMSPlankWall, IPlankHouse } from '../../../blocks';
import { IServiceTransactionPage } from './ServiceTransactionsPage';
import { BrandColors } from '@fjordkraft/fjordkraft.component.library';
import { typedGetRequest } from '../../../contexts';

interface ITrumfTransactionsDataProps {
	servicePage: IServicePage;
	trumfData: ITrumfTransactions;
}

// ************************************
// Public / Main fetch
// ************************************

export const getTrumfTransactionsPageContent = (props: ITrumfTransactionsDataProps) => {
	const { servicePage, trumfData } = props;

	return {
		translation: servicePage,
		title: getText('transactionsPageTitle', servicePage),
		back: {
			text: getText('pageTitle', servicePage),
			link: `${servicePage.servicePageId}`,
		},
		overviewData: {
			title: getText('plankOverviewTitle', servicePage),
			description: `${format(new Date(trumfData.periodStart), 'dd.MM.yyyy')} - ${format(
				new Date(trumfData.periodEnd),
				'dd.MM.yyyy'
			)}`,
			extra: '',
			amount: trumfData.sumPoints,
			suffix: getText('points', servicePage),
			brand: 'brand-trumf',
			theme: 'Dark',
			template: TrumfPlankTemplate('Dark', 'single'),
			dontUseCounterTheme: false,
		},
	} as IServiceTransactionPage;
};

interface IPredictablePaymentPageDataProps {
	account: ICustomerAccountInformation;
	servicePage: IServicePage;
	GETTYPED: typedGetRequest;
}

export const getPredictablePaymentTransactionsPageContent = async (props: IPredictablePaymentPageDataProps) => {
	const { account, servicePage, GETTYPED } = props;

	const steddiStatus = await getSteddiStatus([account], account.accountId, GETTYPED);

	if (!account.steddiInfo || steddiStatus !== 'INACTIVE') {
		return {
			translation: servicePage,
			title: getText('transactionsPageTitle', servicePage),
			back: {
				text: getText('pageTitle', servicePage),
				link: Constants.paths.predictablePayment,
			},
			overviewData: {
				title: getText('plankOverviewTitle', servicePage),
				description:
					steddiStatus == 'ACTIVE' || steddiStatus == 'ACTIVE_FUTURE'
						? createString(getText('plankOverviewDesc', servicePage), {
								amount: `${tNumber(account.steddiInfo!.monthlyAmount!, 'no-NO')}`,
						  })
						: undefined,
				amount: account.steddiInfo!.balance,
				extra: createString(getText('plankOverviewTitleValue', servicePage), {
					accountNumber: account.accountId,
				}),
				suffix: getText('currency', servicePage),
				brand: 'brand-steddi',
				template: SteddiPlankTemplate('Light', 'single'),
			},
		} as IServiceTransactionPage;
	}
};

export interface ITransactionsHouse {
	paid: ISteddiTransaction[];
	unpaid: ISteddiTransaction[];
	translation: any;
	loadAmount: number;
}

export const getPredictablePaymentTransactionsHouse = (config: ITransactionsHouse) => {
	const { paid, unpaid, translation, loadAmount } = config;
	let plankHouse: IPlankHouse = {
		plankWalls: [],
	};

	if (unpaid?.length > 0) {
		plankHouse.plankWalls.push(_parseTransactions(unpaid, translation, 'unpaid', unpaid.length));
	}

	if (paid?.length > 0) {
		plankHouse.plankWalls.push(_parseTransactions(paid, translation, 'paid', loadAmount));
	}

	return plankHouse;
};

export const getParsedTrumfTransactions = (
	transactions: ITrumfTransaction[],
	servicePage: IServicePage,
	limit: number
) => {
	let plankWall: IMSPlankWall = {
		planks: [],
		title: getText('transactionsWallTitle', servicePage),
		tooltip: getText('transactionsWallTooltip', servicePage),
	};

	if (transactions.length > 0) {
		transactions.forEach((transaction: ITrumfTransaction, index: number) => {
			if (index < limit) {
				plankWall.planks.push(_getParsedTransaction(transaction, servicePage));
			}
		});
	}

	return plankWall;
};

// ************************************
// Private
// ************************************

const _getParsedTransaction = (transaction: ITrumfTransaction, servicePage: IServicePage) => {
	let gained: boolean = transaction.points > 0;

	return getPlankPrefab('Text', {
		left: {
			title: getText(gained ? 'plankTransactionItemTitle' : 'plankTransactionItemTitleUsed', servicePage),
			description: format(new Date(transaction.date), 'dd.MM.yyyy', {
				locale: nb,
			}),
		},
		right: {
			title: createString(
				getText(gained ? 'plankTransactionItemValue' : 'plankTransactionItemValueUsed', servicePage),
				{
					amount: tNumber(transaction.points, 'no-NO'),
				}
			),
		},
	});
};

const _parseTransactions = (
	transactions: ISteddiTransaction[],
	translation: any,
	status: 'paid' | 'unpaid',
	limit: number
) => {
	let plankWall: IMSPlankWall = {
		planks: [],
		title:
			status === 'unpaid'
				? getText('transactionsTitle', translation)
				: getText('completeTransactionTitle', translation),
		tooltip: status === 'paid' ? getText('tooltipPaid', translation) : getText('tooltipUnpaid', translation),
	};

	if (transactions.length > 0) {
		transactions.forEach((transaction: ISteddiTransaction, index: number) => {
			if (index < limit) {
				plankWall.planks.push(_getTextPlankPrefab(transaction, translation));
			}
		});
	}

	return plankWall;
};

const _getTextPlankPrefab = (transaction: ISteddiTransaction, translation: IPage) => {
	return getPlankPrefab('Text', {
		left: {
			title: transaction.text ?? getText('unknownText', translation),
			description: format(new Date(transaction.date), 'dd.MM.yyyy', {
				locale: nb,
			}),
		},
		right: {
			title: createString(getText('plankTransactionValue', translation), {
				amount: tNumber(transaction.amount, 'no-NO'),
			}),
			customization: {
				title:
					transaction.amount > 0
						? {
								color: BrandColors['status-shade-light-1'],
						  }
						: undefined,
			},
		},
	});
};
