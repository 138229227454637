import React from 'react';
import {
    IAction,
    Text,
    Icon,
    IComponentTemplate,
    ITextCustomization,
    IIcon,
    StyleGrid
} from '@fjordkraft/fjordkraft.component.library';
import {paragraphTextPrefab} from '../../../Prefabs';
import {MsButton} from '../Button';
import classNames from 'classnames';
import './ActionButton.scss';

export interface IActionButton {
    id?: string;
    className?: string;
    action: IAction;
    template: IComponentTemplate;
    brand?: string;
    iconPlacement?: 'Left' | 'Right' | 'End';
    padding?: 'default' | 'small' | 'medium' | 'large';
    children?: any;
    disabled?: boolean;
    preventRouterUse?: boolean;
    customization?: {
        icon?: Omit<IIcon, 'type'>;
        text?: ITextCustomization;
    }
}

export const ActionButton = (props: IActionButton) => {
    // ************************************
    // Properties
    // ************************************

    const {
        id,
        action,
        template,
        iconPlacement = 'Left',
        padding,
        brand = import.meta.env.REACT_APP_UI_BRAND,
        className,
        disabled,
        customization,
    } = props;
    const classPrefix = 'ms-action-button';

    // ************************************
    // Render Functionality
    // ************************************

    const _renderIcon = (iconPlacement: string) => {
        if (action?.icon) {
            return (
                <Icon
                    className={`${classPrefix}__icon-${iconPlacement}`}
                    {...customization?.icon}
                    type={action.icon}
                />
            );
        }
    };

    // ************************************
    // Render
    // ************************************

    return (
        <MsButton
            id={id}
            className={classNames(classPrefix, {
                [`${classPrefix}--${padding}`]: action.text && padding,
                [`${classPrefix}--${padding}__icon-only`]:
                !action.text && padding,
                [`${className}`]: className,
                [`${classPrefix}--icon-end`]: iconPlacement === "End",
            })}
            active={action.active}
            disabled={disabled}
            action={action}
            brand={brand}
            template={template}
        >
            {iconPlacement === 'Left' && _renderIcon(iconPlacement)}
            {action.text && !action.value && (
                <Text
                    {...paragraphTextPrefab()}
                    weight={500}
                    {...customization?.text}
                    wrap="nowrap"
                >
                    {action.text}
                </Text>
            )}
            {action.value && action.text && (
                <StyleGrid direction={"row"} className={`${classPrefix}__two-texts`} alignment={"center"}>
                    <Text
                        {...paragraphTextPrefab()}
                        weight={500}
                        {...customization?.text}
                        wrap="nowrap"
                    >
                        {action.text}
                    </Text>
                    <Text
                        {...paragraphTextPrefab()}
                        weight={500}
                        {...customization?.text}
                        wrap="nowrap"
                    >
                        {action.value}
                    </Text>
                </StyleGrid>
            )}
            {["Right", "End"].includes(iconPlacement) && _renderIcon(iconPlacement)}
        </MsButton>
    );
};