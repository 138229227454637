import { IconType } from '@fjordkraft/fjordkraft.component.library';
import { IPointPlankPrefab, SteddiPlankTemplate, getPlankPrefab } from '../../../../../Prefabs';
import { IMSPlankWall } from '../../../../../blocks';
import { ICustomerAccountInformation, ServiceStatus } from '../../../../../models';
import {
	AllowFeature,
	HomePageData,
	createString,
	getAddressesBasedOnAgreement,
	getText,
	redirectToSteddiPaymentFreeUrl,
	tNumber,
} from '../../../../../services';
import { Constants } from '../../../../../data';
import { IServiceBasePageData } from '../../ServiceBasePageData';
import { typedGetRequest } from '../../../../../contexts';
import { addMonths, format } from 'date-fns';
import { IActionPlankPrefab } from '../../../../../Prefabs/Planks/ActionPlankPrefab/ActionPlankPrefab';
import { IStatePlank } from '../../../../../components';
import { IServiceOrderOrCancel } from '../../../../../modals';
import { getActionPlank } from '../ServicePageDataHouse';

// ************************************
// Predictable Payment planks
// ************************************

export const getSteddiWalls = async (config: IServiceBasePageData): Promise<IMSPlankWall[]> => {
	const { user } = config;
	const { userData } = user;

	let walls: IMSPlankWall[] = [];

	let steddiWalls: IMSPlankWall[] | undefined = await getSteddiPlanks(config);

	if (steddiWalls) {
		walls = walls.concat(steddiWalls);
	}

	if (userData.accounts?.length > 0) {
		walls.push({ planks: await _getSteddiAccountPlanks(config) });
	}

	return walls;
};

export const getSteddiPlanks = async (config: IServiceBasePageData): Promise<IMSPlankWall[]> => {
	const { user, services, translations, activeTheme, epiChildren } = config;
	const { userData } = user;
	const { GET, GETTYPED, customerServiceFeature } = services;

	let walls: IMSPlankWall[] = [];

	if (userData.accounts.length > 0) {
		for (const account of userData.accounts) {
			let accountWall: IMSPlankWall = {
				planks: [],
			};

			const steddiStatus = await getSteddiStatus(userData.accounts, account.accountId, GETTYPED);

			if (steddiStatus == 'INACTIVE') {
				continue;
			}

			if (account.steddiInfo) {
				accountWall.planks.push(
					getPlankPrefab('Point', {
						brand: 'brand-steddi',
						template: SteddiPlankTemplate(activeTheme ?? 'Light', 'top'),
						useDecimals: true,
						left: {
							title: getText('plankOverviewTitle', translations),
							description:
								steddiStatus == 'ACTIVE' || steddiStatus == 'ACTIVE_FUTURE'
									? createString(getText('plankOverviewDesc', translations), {
											amount: tNumber(account.steddiInfo.monthlyAmount!, 'no-NO'),
									  })
									: undefined,
						},
						right: {
							description: createString(getText('plankOverviewTitleValue', translations), {
								accountNumber: account.accountId,
							}),
						},
						points: account.steddiInfo.balance,
						pointsLabel: getText('currency', translations),
					} as IPointPlankPrefab)
				);
				accountWall.planks.push(
					getPlankPrefab('Text', {
						left: {
							title: getText('plankLastTransactionsTitle', translations),
							description: getText('plankLastTransactionsDesc', translations),
						},
						right: {
							icon: IconType.ChevronRight,
						},
						action: {
							link: `${Constants.paths.predictablePaymentTransactions}/${account.accountId}`,
						},
					})
				);

				if (
					account.steddiInfo?.active &&
					AllowFeature(
						Constants.features.steddiPaymentFreeMonth,
						HomePageData(epiChildren),
						user,
						customerServiceFeature
					)
				) {
					accountWall.planks.push(
						getPlankPrefab('Text', {
							left: {
								title: getText('plankPaymentFreeTitle', translations),
							},
							right: {
								icon: IconType.ExternalLinkThick,
							},
							action: {
								onClick: async () => {
									await redirectToSteddiPaymentFreeUrl(account.accountId, GET);
								},
							},
						})
					);
				}
			}

			if (accountWall.planks.length > 0) {
				walls.push(accountWall);
			}
		}
	}

	return walls;
};

const _getSteddiAccountPlanks = async (config: IServiceBasePageData): Promise<IStatePlank[]> => {
	const { user, translations, desktopView, onClickServiceHandling, relationship, services } = config;
	const { userData } = user;
	const { isGuest } = relationship;

	let planks: IStatePlank[] = [];

	if (userData && translations) {
		for (const account of userData.accounts) {
			const desc: string = getAddressesBasedOnAgreement({ userData, accountId: account.accountId });
			const title: string = createString(getText('plankAccountTitle', translations), {
				accountNumber: account.accountId,
			});
			const status: ServiceStatus = await getSteddiStatus(
				userData.accounts,
				account.accountId,
				services.GETTYPED
			);

			let bottom: IActionPlankPrefab['bottom'];

			switch (status) {
				case 'ACTIVE_FUTURE':
					bottom = {
						description: createString(getText('plankAccountActivating', translations), {
							date: format(getSteddiStartDate(), 'dd.MM.yyyy'),
						}),
					};
					break;
				case 'TERMINATING':
					bottom = {
						description: getText('plankAccountTerminating', translations),
					};
					break;
			}

			const actionPlank = getActionPlank({
				title,
				desc,
				bottom,
				servicePage: translations,
				status,
				desktopView,
				isGuest,
				onClick: () => {
					onClickServiceHandling({
						account,
						page: translations,
						status,
					} as IServiceOrderOrCancel);
				},
			});

			if (actionPlank) planks.push(actionPlank);
		}
	}

	return planks;
};

/**
 * Returns the Steddi (Forutsigbar Betaling) status for a given account.
 * @param accounts - List of customer accounts.
 * @param accountId - Optional specific account ID to check.
 * @param GETTYPED - Function to fetch typed data from the backend.
 */
export const getSteddiStatus = async (
	accounts: ICustomerAccountInformation[],
	accountId?: string,
	GETTYPED?: typedGetRequest
): Promise<'ACTIVE' | 'ACTIVE_FUTURE' | 'ORDER_IN_PROGRESS' | 'TERMINATING' | 'INACTIVE'> => {
	// Check if any account includes steddiInfo, and find the specified account if provided.
	const steddiAccount = accounts.find((acc) => acc.steddiInfo && (accountId ? accountId == acc.accountId : true));

	const accountIsClosed = steddiAccount?.steddiInfo?.closed; // The account is closed if its enddate is in the past.
	const accountBalance = steddiAccount?.steddiInfo?.balance ?? 0;
	const accountIsActive = steddiAccount?.steddiInfo?.active; // The account is active if passthroughbilling is activated.

	if (steddiAccount && !accountIsClosed) {
		if (accountIsActive) {
			return 'ACTIVE';
		} else {
			return 'ACTIVE_FUTURE';
		}
	}

	// If the account is closed, return 'TERMINATING' if the balance is not zero.
	if (accountIsClosed && accountBalance !== 0) {
		return 'TERMINATING';
	}

	if (!steddiAccount || accountIsClosed) {
		// If no account is found or it is closed, fetch new orders
		if (GETTYPED) {
			const steddiOrderStatus = await _getSteddiOrderStatus(GETTYPED, accountId);

			// If the order failed, we return 'ORDER_IN_PROGRESS' because Elmera is actively working on fixing it
			if (steddiOrderStatus == 'ORDER_FAILED' || steddiOrderStatus == 'ORDER_IN_PROGRESS') {
				return 'ORDER_IN_PROGRESS';
			}
		}
	}

	// If no conditions are met, return 'INACTIVE'.
	return 'INACTIVE';
};

/**
 * Fetches the Steddi order status from the backend.
 * @param GETTYPED - Function to fetch typed data from the backend.
 * @param accountId - Optional specific account ID to check.
 * @returns The Steddi order status.
 */
const _getSteddiOrderStatus = async (
	GETTYPED: typedGetRequest,
	accountId?: string
): Promise<FetchableSteddiStatuses | undefined> => {
	const resp = await GETTYPED<{ accountId: string; status: FetchableSteddiStatuses }[]>('steddi/accountOrderStatus');

	if (resp.callState == 'success' && resp.data?.length) {
		const steddiOrders = resp.data;

		const fetchedSteddiStatus = steddiOrders?.find((order) =>
			accountId ? accountId == order.accountId : true
		)?.status;

		return fetchedSteddiStatus;
	}
};

/**
 * Possible statuses from the "api/steddi/orders" endpoint.
 * Will always be a subset of ServiceStatus.
 */
export type FetchableSteddiStatuses = null | 'ORDER_COMPLETED' | 'ORDER_IN_PROGRESS' | 'ORDER_FAILED';

/**
 * If the steddi status is "ACTIVE_FUTURE" it *should always* be activated on the first of next month:
 * @returns
 */
export const getSteddiStartDate = () => {
	const today = new Date();
	const firstOfNextMonth = addMonths(new Date(today.getFullYear(), today.getMonth(), 1), 1);
	return firstOfNextMonth;
};
