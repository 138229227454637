import React, { useState, useEffect, useMemo } from 'react';
import {
	Text,
	IAction,
	IconType,
	IComponent,
	getCounterTheme,
	BrandColors,
	ContentGrid,
	StyleGrid,
	Toast,
} from '@fjordkraft/fjordkraft.component.library';
import {
	SubPageLayoutContext,
	useDefaultPageContext,
	useApplicationOverlayWrapperContext,
	useApplicationDefaultContext,
} from '../../../contexts';
import { ICustomerInstallation, ILocationNavigationInfoBoxContent } from '../../../models';
import classnames from 'classnames';
import { LocationNavigationInfoBox, CrumbButton, ActionButton, MSRichText, MSTooltip } from '../../../components';
import { Outlet } from 'react-router-dom';
import { isExternalUrl } from '../../../services';
import {
	GetToastPrefab,
	MSStaticStatusToastTemplate,
	MS_ButtonTemplate,
	ToastPrefabsType,
	h1TextPrefab,
	h3TextPrefab,
	paragraphTextPrefab,
} from '../../../Prefabs';
import { SliderSelector } from '../../../components/SliderSelector/SliderSelector';
import { ApplicationAccountSelectWrapper } from '../../DatahandlerWrappers/ApplicationAccountSelectWrapper/ApplicationAccountSelectWrapper';
import { v4 as uuid4 } from 'uuid';
import { DynamicEpiContentBlock } from '../../../blocks';
import './SubPageLayout.scss';
import { ISliderItemData } from '../../../components/SliderSelector/SliderItem';

export interface ISubPageLayout extends IComponent {
	useRouteOutlet?: boolean;
	autoAdjust?: boolean;
}

export interface IStatusToast {
	text: string;
	icon: IconType;
	status: 'positive' | 'negative' | 'neutral';
}

export const SubPageLayout = (props: ISubPageLayout) => {
	// ************************************
	// Properties
	// ************************************

	const classPrefix = 'sub-page-layout';
	const { children, useRouteOutlet = false, autoAdjust = true } = props;
	const { defaultProps } = useApplicationDefaultContext();
	const { mainContentClamping, setMainContentClamping } = useDefaultPageContext();
	const { globalLoading } = useApplicationOverlayWrapperContext();

	const infoBoxGap: number = 25;
	const fadeTimeInSeconds = 0.5;

	// ************************************
	// Helper Functionality
	// ************************************

	const toggleInfoBox = (instant: boolean = false) => {
		// This function sets the fadingIn and hide classes to the infobox.
		// We need to "hide" it, after the animation is done, so that the buttons can't be clicked.
		if (displayingInfoBox) {
			if (instant) setDisplayingInfoBox(false);
			else setTimeout(() => setDisplayingInfoBox(false), fadeTimeInSeconds * 1000);
		} else setDisplayingInfoBox(true);
		setFadingInInfoBox(!fadingInInfoBox);
	};

	let infoBoxHeight = 0;
	let infoBox = document.querySelector('.location-navigation-info-box');

	if (infoBox) {
		infoBoxHeight = infoBox.clientHeight + infoBoxGap;
	}

	// ************************************
	// Lifecycle
	// ************************************

	const [back, setBack] = useState<IAction>();
	const [title, setTitle] = useState<string>();
	const [subTitle, setSubTitle] = useState<string>();
	const [description, setDescription] = useState<string>();
	const [descriptionTooltip, setDescriptionTooltip] = useState<string>();

	const [showInstallationSelector, setShowInstallationSelector] = useState<boolean>(false);
	const [infoBoxContent, setInfoBoxContent] = useState<ILocationNavigationInfoBoxContent | null>(null);
	const [fadingInInfoBox, setFadingInInfoBox] = useState<boolean>(false);
	const [displayingInfoBox, setDisplayingInfoBox] = useState<boolean>(false);
	const [statusToast, setStatusToast] = useState<IStatusToast>();

	useEffect(() => {
		if (autoAdjust) {
			if (defaultProps?.desktopView) {
				setMainContentClamping('clamp');
			} else {
				setMainContentClamping('stretch');
			}
		} else {
			setMainContentClamping('stretch');
		}
	}, [defaultProps]);

	useEffect(() => {
		if (displayingInfoBox) {
			toggleInfoBox(true);
		}
	}, [infoBoxContent]);

	useEffect(() => {
		setStatusToast(undefined);
	}, [defaultProps]);

	const contextHandler = useMemo(() => {
		return {
			title,
			setTitle,
			subTitle,
			setSubTitle,
			back,
			setBack,
			infoBoxContent,
			setInfoBoxContent,
			showInstallationSelector,
			setShowInstallationSelector,
			statusToast,
			setStatusToast,
			description,
			setDescription,
			descriptionTooltip,
			setDescriptionTooltip,
		};
	}, [title, subTitle, back, infoBoxContent, showInstallationSelector, statusToast, description, descriptionTooltip]);

	// ************************************
	// Helper Functionality
	// ************************************

	const _handleBackButtonFunctionality = (back: IAction) => {
		return {
			...back,
			...({
				icon: IconType.ArrowLeft,
				useRouterLink: !isExternalUrl(back.link),
			} as IAction),
		};
	};

	const _showDescriptionBox = () => {
		if (!globalLoading && (description || descriptionTooltip)) {
			return true;
		} else {
			return false;
		}
	};

	// ************************************
	// Render Functionality
	// ************************************

	const _renderHeader = () => {
		if (title && defaultProps) {
			return (
				<ContentGrid
					className={classnames(`${classPrefix}__header`, {
						[`${classPrefix}__header--showingInfoBox`]: fadingInInfoBox,
					})}
					direction="column"
					alignment={defaultProps.desktopView ? 'center' : 'center-left'}
					boxSizing="border-box"
					gap={2}
					tagType="nav"
					style={{
						marginBottom: fadingInInfoBox ? infoBoxHeight + infoBoxGap + 'px' : `${infoBoxGap}px`,
					}}
				>
					{back?.text && back?.link && (
						<CrumbButton
							className={`${classPrefix}__header__back-button`}
							theme={getCounterTheme(defaultProps.activeTheme)}
							brand={defaultProps.activeBrand}
							action={_handleBackButtonFunctionality(back)}
						/>
					)}
					<ContentGrid
						alignment={defaultProps.desktopView ? 'center' : 'center-left'}
						tagType={infoBoxContent?.hasInfo ? 'nav' : 'section'}
						direction={'row'}
						boxSizing="border-box"
						gap={1}
					>
						<Text
							className={`${classPrefix}__title__text`}
							{...h1TextPrefab()}
							size={defaultProps.desktopView ? 'huge' : 'extra-large'}
							brand={defaultProps.activeBrand}
							color={BrandColors['primary-shade-light-2']}
							align={defaultProps.desktopView ? 'align-center' : 'align-left'}
						>
							{title}
						</Text>
						{infoBoxContent?.hasInfo && (
							<ActionButton
								className={`${classPrefix}__title__icon`}
								template={MS_ButtonTemplate(defaultProps.activeTheme, 'icon')}
								brand={defaultProps.activeBrand}
								action={{
									onClick: () => {
										toggleInfoBox();
									},
									icon: IconType.MoreInfo,
								}}
							/>
						)}
					</ContentGrid>
					{subTitle && subTitle.length > 0 && (
						<Text
							className={`${classPrefix}__sub-title`}
							{...h3TextPrefab()}
							size="medium"
							brand={defaultProps.activeBrand}
							theme={getCounterTheme(defaultProps.activeTheme)}
							align={defaultProps.desktopView ? 'align-center' : 'align-left'}
						>
							{subTitle}
						</Text>
					)}
					{_showDescriptionBox() && (
						<ContentGrid
							className={`${classPrefix}__description-container`}
							tagType="section"
							direction="row"
							alignment="center"
							boxSizing="border-box"
							wrap={false}
							gap={1}
							spaceBetween={!defaultProps.desktopView}
						>
							{description && description.length > 0 && (
								<MSRichText
									className={`${classPrefix}__description-container__description`}
									brand={defaultProps.activeBrand}
									theme={getCounterTheme(defaultProps.activeTheme)}
									text={description}
									alignment="lawful"
									customization={{
										type: 'p',
										align: 'align-center',
										weight: 500,
									}}
								/>
							)}
							{descriptionTooltip && (
								<MSTooltip
									className={`${classPrefix}__description-container__info`}
									brand={defaultProps.activeBrand}
									theme={getCounterTheme(defaultProps.activeTheme)}
									text={descriptionTooltip}
									alignment={defaultProps.desktopView ? 'bottom-center' : 'bottom-left'}
								/>
							)}
						</ContentGrid>
					)}
				</ContentGrid>
			);
		}
	};

	const _getInstallationSelection = (
		inst: ICustomerInstallation[],
		activeInstallation?: ICustomerInstallation | null
	) => {
		let selection: any[] = [];

		if (defaultProps) {
			inst.forEach((installation: ICustomerInstallation) => {
				selection.push({
					children: (
						<ContentGrid
							alignment={'center'}
							direction={'row'}
							wrap={false}
							gap={2}
							tagType="section"
							boxSizing="border-box"
						>
							<Text
								className={`${classPrefix}__slider-selector-text`}
								type={'label'}
								weight={500}
								size={'regular'}
								brand={defaultProps.activeBrand}
								theme={getCounterTheme(defaultProps.activeTheme)}
								wrap={'nowrap'}
								faded={installation !== activeInstallation}
							>
								{installation.address.streetAddress}
							</Text>
						</ContentGrid>
					),
					value: installation,
				} as ISliderItemData);
			});
		}

		return selection;
	};

	const _renderAddressSlider = () => {
		if (defaultProps) {
			const { user, activeTheme } = defaultProps;
			const { installation, userData, setInstallation } = user;

			let installations: ICustomerInstallation[] | undefined = userData?.installations;

			if (showInstallationSelector) {
				if (installations && installations.length > 1) {
					return (
						<SliderSelector
							className={`${classPrefix}__slider-selector`}
							uniqueId={`${classPrefix}__slider-selector`}
							data={_getInstallationSelection(installations, installation)}
							defaultOption={installation}
							slideToSelect={false}
							width={550}
							mobileWidth={document.body.clientWidth * 0.9}
							onValueChange={(value) => {
								if (value) {
									setInstallation(value);
								}
							}}
						/>
					);
				} else {
					if (installations && installations[0]) {
						return (
							<Text
								className={`${classPrefix}__top__address`}
								{...paragraphTextPrefab()}
								weight={500}
								theme={getCounterTheme(activeTheme)}
								wrap={'nowrap'}
							>
								{installations[0].address.streetAddress}
							</Text>
						);
					}
				}
			}
		}
	};

	const _renderInfoBox = () => {
		if (infoBoxContent?.hasInfo) {
			return (
				<LocationNavigationInfoBox
					className={`${classPrefix}__info-box`}
					text={infoBoxContent.infoText}
					title={infoBoxContent.infoTitle}
					buttons={infoBoxContent.buttons}
					closeButtonOnClickFunc={toggleInfoBox}
					fadeTiming={fadeTimeInSeconds}
					displayingInfoBox={displayingInfoBox}
					fadingInInfoBox={fadingInInfoBox}
				/>
			);
		}
	};

	const _renderStatusToast = () => {
		if (statusToast && defaultProps) {
			return (
				<Toast
					{...GetToastPrefab(ToastPrefabsType.Generic, {
						id: uuid4(),
						className: `${classPrefix}__status-toast`,
						description: statusToast.text,
						icon: statusToast.icon,
						brand: defaultProps.activeBrand,
						theme: getCounterTheme(defaultProps.activeTheme),
						template: MSStaticStatusToastTemplate(
							defaultProps.activeTheme,
							statusToast.status,
							defaultProps.desktopView
						),
					})}
					staticToast={true}
				/>
			);
		}
	};

	const _renderLocationNavHeader = () => {
		if (defaultProps) {
			return (
				<ContentGrid
					className={classnames(`${classPrefix}__header`, {
						[`${classPrefix}__header--${mainContentClamping}`]: mainContentClamping,
					})}
					tagType="nav"
					alignment={defaultProps.desktopView ? 'center' : 'center-left'}
					direction="column"
					gap={2}
					boxSizing="border-box"
				>
					{_renderHeader()}
					{_renderAddressSlider()}
				</ContentGrid>
			);
		}
	};

	// ************************************
	// Render
	// ************************************

	return (
		<SubPageLayoutContext.Provider value={contextHandler}>
			<StyleGrid
				id={`${classPrefix}-id`}
				className={`${classPrefix}`}
				direction="column"
				alignment={globalLoading ? 'center' : 'top-center'}
			>
				{!globalLoading && (
					<>
						{_renderLocationNavHeader()}
						{_renderInfoBox()}
						{_renderStatusToast()}
					</>
				)}
				{!globalLoading && defaultProps?.translations?.topEditorialContent?.length > 0 && (
					<DynamicEpiContentBlock
						theme={defaultProps?.activeTheme}
						brand={defaultProps?.activeBrand}
						epiItems={defaultProps?.translations?.topEditorialContent}
					/>
				)}
				<ApplicationAccountSelectWrapper>
					{useRouteOutlet && <Outlet />}
					{children}
				</ApplicationAccountSelectWrapper>
			</StyleGrid>
		</SubPageLayoutContext.Provider>
	);
};
