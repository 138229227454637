import React from 'react';
import { IComponent } from '@fjordkraft/fjordkraft.component.library';
import { useApplicationContext } from '../../contexts';
import { BenefitListItemModalMobile } from './mobile/BenefitListItemModalMobile';
import { BenefitListItemModalDesktop } from './desktop/BenefitListItemModalDesktop';
import { IBenefit } from '../../models';

export interface IBenefitModal extends IComponent {
	data: IBenefit | null;
	recruitLink?: string;
	open: boolean;
	close: () => void;
}

/**
 *    The BenefitListItemModal is used as a container for desktop and mobile modals, which are vastly different
 *    @type {IBenefit} data
 *    @type {string} recruitLink - recruitment URL grabbed from API
 *    @type {Function} close - function to call to close the modal
 */
export const BenefitListItemModal = (props: IBenefitModal) => {
	const { desktopView } = useApplicationContext();

	// ************************************
	// Render
	// ************************************
	return <>{desktopView ? <BenefitListItemModalDesktop {...props} /> : <BenefitListItemModalMobile {...props} />}</>;
};
