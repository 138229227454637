import { createContext, useContext } from 'react';
import { ICustomer, IGuestRelationship } from '../../models';

interface IApplicationGuestsAndHostsContext {
	isGuest: boolean;
	hostIdForCustomerDataRequests?: string;
	hosts?: IGuestRelationship[];
	guests?: IGuestRelationship[];
	chosenHost?: IGuestRelationship;
	mainUser?: ICustomer;
	setIsGuest: (isGuest: boolean) => void;
	setHostIdForCustomerDataRequests: (id?: string) => void;
	setHosts: (hosts?: IGuestRelationship[]) => void;
	setGuests: (guests?: IGuestRelationship[]) => void;
	setChosenHost: (user?: IGuestRelationship) => void;
	setMainUser: (user?: ICustomer) => void;
}

export const ApplicationGuestsAndHostsContext = createContext<IApplicationGuestsAndHostsContext>({
	isGuest: false,
	hostIdForCustomerDataRequests: undefined,
	guests: undefined,
	hosts: undefined,
	chosenHost: undefined,
	mainUser: undefined,
	setIsGuest: () => {},
	setHostIdForCustomerDataRequests: () => {},
	setHosts: () => {},
	setGuests: () => {},
	setChosenHost: () => {},
	setMainUser: () => {},
});

export const useApplicationGuestsAndHostsContext = () => useContext(ApplicationGuestsAndHostsContext);
