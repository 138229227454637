import React from 'react';
import { IComponent, FlexGridAligner, Text, getCounterTheme } from '@fjordkraft/fjordkraft.component.library';
import classnames from 'classnames';
import { useApplicationContext, useApplicationCoreDataContext } from '../../../../contexts';
import './CouponCodeModal.scss';
import { getText } from '../../../../services';

export interface ICouponCodeModal extends IComponent {
	couponCode?: string;
	couponQRCodeUrl?: string;
}

/**
 *    The CouponCodeModal is used to show a modal for the QR code benefitype.
 *    @type {string} text
 */
export const CouponCodeModal = (props: ICouponCodeModal) => {
	// ************************************
	// Properties
	// ************************************
	const { translation } = useApplicationCoreDataContext();
	const { activeBrand, activeTheme } = useApplicationContext();
	const { id, couponCode, couponQRCodeUrl } = props;
	const classPrefix = 'coupon-code-modal';

	// ************************************
	// Render
	// ************************************
	return (
		<FlexGridAligner
			id={id}
			className={`${classPrefix}`}
			brand={activeBrand}
			theme={activeTheme}
			alignment={'center'}
			direction={'column'}
		>
			<FlexGridAligner
				className={`${classPrefix}__content`}
				alignment={'center'}
				direction={'column'}
			>
				<FlexGridAligner
					className={`${classPrefix}__content__title`}
					alignment={'center-left'}
					direction={'column'}
				>
					<Text
						type={'h3'}
						weight={500}
						brand={activeBrand}
						theme={getCounterTheme(activeTheme)}
					>
						{getText('couponTitle', translation)}
					</Text>
				</FlexGridAligner>

				<FlexGridAligner
					className={`${classPrefix}__content__code`}
					alignment={'center'}
					direction={'column'}
				>
					{couponCode && (
						<Text
							className={classnames(`${classPrefix}__content__code__text`, {
								[`${classPrefix}--${activeTheme}`]: getCounterTheme(activeTheme),
							})}
							type={'h2'}
							style={'italic'}
							brand={activeBrand}
							theme={getCounterTheme(activeTheme)}
							align={'align-center'}
						>
							{couponCode}
						</Text>
					)}
					{couponQRCodeUrl && (
						<img
							className={`${classPrefix}__content__code__qr`}
							src={couponQRCodeUrl}
							alt={'QR kode'}
						/>
					)}
				</FlexGridAligner>
			</FlexGridAligner>
		</FlexGridAligner>
	);
};
