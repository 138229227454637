import React from 'react';
import { BaseComponent, CheckInput, ContentGrid, getCounterTheme, IComponent, StyleGrid, Text } from "@fjordkraft/fjordkraft.component.library";
import { DetailCheckboxItemTemplate, IDetailCheckboxItemTemplate } from "./DetailCheckboxItemTemplate";
import { h3TextPrefab, h4TextPrefab, MSCheckInputTemplate, paragraphTextPrefab } from '../../../Prefabs';
import { v4 as uuid4 } from 'uuid';
import { MSRichText } from '../../../components';
import classNames from 'classnames';
import { ConsentState } from '../../../models';
import './DetailCheckboxItem.scss';

export interface IDetailCheckboxListItem {
    name?: string;
    description?: string;
}

export interface IDetailCheckboxListData {
    title?: string;
    items?: IDetailCheckboxListItem[];
}

export interface IDetailCheckboxItemData extends Omit<IComponent, 'active'> {
    title?: string;
    description?: string;
    listData?: IDetailCheckboxListData;
    template?: IDetailCheckboxItemTemplate;
    location?: 'top' | 'bottom' | 'single';
    state: ConsentState;
}

interface IDetailCheckboxItem extends IDetailCheckboxItemData {
    onChange: (value: any, state: boolean) => void;
}

export const DetailCheckboxItem = (props: IDetailCheckboxItem) => {
    // ************************************
    // Properties
    // ************************************

    const {
        id,
        theme = "Light",
        brand,
        location,
        template = DetailCheckboxItemTemplate(theme, location),
        listData,
        title,
        description,
        disabled,
        state,
        onChange,
    } = props;
    const classPrefix = "detail-checkbox-item";

    // ************************************
    // Helper functionality
    // ************************************

    const _isChecked = () : boolean => {
        return state === 'ACCEPTED';
    }


    // ************************************
    // Render Functionality
    // ************************************

    const _renderListItems = (items: IDetailCheckboxListItem[]) => {
        return items.map((item: IDetailCheckboxListItem) => {
            return (
                <ContentGrid
                    key={uuid4()}
                    direction='column'
                    alignment='top-left'
                    tagType='section'
                    boxSizing='border-box'
                >
                    <Text {...h4TextPrefab()} theme={getCounterTheme(theme)}>
                        {item.name}
                    </Text>
                    <Text {...paragraphTextPrefab()} theme={getCounterTheme(theme)} faded>
                        {item.description}
                    </Text>
                </ContentGrid>
            );
        })
    }

    const _renderListContent = () => {
        if (listData) {
            return (
                <BaseComponent
                    className={`${classPrefix}__list`}
                    template={template.list}
                >
                    {listData.title &&
                        <Text {...h3TextPrefab()} size='medium'>
                            {listData.title}
                        </Text>
                    }
                    {listData.items &&
                        <StyleGrid
                            className={`${classPrefix}__list__view`}
                            direction='column'
                            alignment='top-left'
                            boxSizing='border-box'
                        >
                            <ContentGrid
                                className={`${classPrefix}__list__view__container`}
                                tagType='section'
                                alignment='top-left'
                                direction='column'
                                boxSizing='border-box'
                                gap={3}
                            >
                                {_renderListItems(listData.items)}
                            </ContentGrid>
                        </StyleGrid>
                    }
                </BaseComponent>
            );
        }
    }

    // ************************************
    // Render
    // ************************************

    return (
        <BaseComponent
            className={classNames(`${classPrefix}`, {
                [`${classPrefix}--disabled`]: disabled
            })}
            template={template}
            disabled={disabled}
        >
            <StyleGrid
                className={`${classPrefix}__top`}
                direction='row'
                alignment='top-left'
                boxSizing='border-box'
                wrap={false}
            >
                <ContentGrid
                    className={`${classPrefix}__top__left`}
                    direction='column'
                    alignment='top-left'
                    tagType='section'
                    boxSizing='border-box'
                    wrap={false}
                    gap={2}
                    spaceBetween
                >
                    {title &&
                        <Text
                            {...h3TextPrefab()}
                            className={`${classPrefix}__top__left__title`}
                            size='medium'
                        >
                            {title}
                        </Text>
                    }
                    {description &&
                        <MSRichText
                            {...h3TextPrefab()}
                            className={`${classPrefix}__top__left__description`}
                            brand={brand}
                            theme={getCounterTheme(theme)}
                            text={description}
                            alignment='lawful'
                        />
                    }
                </ContentGrid>
                <ContentGrid
                    className={`${classPrefix}__top__right`}
                    direction='column'
                    alignment='top-right'
                    boxSizing='border-box'
                    tagType='section'
                >
                    <CheckInput
                        template={MSCheckInputTemplate(theme, 'checkbox')}
                        disabled={disabled}
                        active={_isChecked()}
                        value={id}
                        type='checkbox'
                        onChange={(value, state) => {
                            onChange(value, state ?? false);
                        }}
                    />
                </ContentGrid>
            </StyleGrid>
            {_renderListContent()}
        </BaseComponent>
    );
}