import React from 'react';
import { StyleGrid } from '@fjordkraft/fjordkraft.component.library';
import {
    IMoveAddress,
} from '../../../contexts';
import { getPageContent } from './MoveOutPageData';
import { MoveFormBlock } from '../../../blocks/MoveFormBlock/MoveFormBlock';
import { Constants } from '../../../data';
import { IDefaultViewProps, PageV2 } from '../../PageV2';
import './MoveOutPage.scss';

interface IMoveOutPage extends IDefaultViewProps {
    addressesData: IMoveAddress[];
    accordionData: any;
}

export const MoveOutPage = () => {
    // ************************************
    // Properties
    // ************************************

    const classPrefix = 'move-page';

    // ************************************
    // Render
    // ************************************

    return (
        PageV2({
            setup: {
                pageType: Constants.epiServerPageNames.move.type,
                usesSubPage: true
            },
            handleData: getPageContent,
            render: (config: IMoveOutPage) => {
                const { addressesData, accordionData, activeBrand, activeTheme } = config;

                return (
                    <StyleGrid
                        className={`${classPrefix}`}
                        alignment={'center-left'}
                        direction={'row'}
                        brand={activeBrand}
                        theme={activeTheme}
                    >
                        <MoveFormBlock
                            addressesData={addressesData}
                            accordionData={accordionData}
                        />
                    </StyleGrid>
                )
            }
        })
    );
};