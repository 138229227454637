import {
	BrandColors,
	IComponentTransform,
	IMasterPlankTemplate,
	IThemedColors,
	LocationType,
	MasterPlankTemplate,
	Theme,
} from '@fjordkraft/fjordkraft.component.library';

// ************************************
// Transform
// ************************************

const _transformMiddle = () => {
	return {
		padding: '1rem 0',
		grid: {
			direction: 'row',
			alignment: 'bottom-center',
			boxSizing: 'border-box',
			gap: 1,
		},
	} as IComponentTransform;
};

// ************************************
// Palette
// ************************************

const _palette = () => {
	return {
		Light: {
			background: { color: BrandColors['background-shade-light-1'] },
			text: { color: BrandColors['secondary-shade-light-1'] },
			border: { color: BrandColors['none'] },
			icon: { color: BrandColors['none'] },
			hover: {
				background: {
					color: BrandColors['background-shade-light-1'],
					opacity: 0.8,
				},
				text: { color: BrandColors['text-shade-light-1'] },
				border: { color: BrandColors['none'] },
				icon: { color: BrandColors['none'] },
			},
			focus: {
				background: {
					color: BrandColors['background-shade-light-1'],
					opacity: 0.8,
				},
				text: { color: BrandColors['text-shade-light-1'] },
				border: { color: BrandColors['none'] },
				icon: { color: BrandColors['none'] },
			},
			disabled: {
				background: {
					color: BrandColors['background-shade-light-1'],
					opacity: 0.5,
				},
				text: { color: BrandColors['text-shade-light-1'], opacity: 0.5 },
				border: { color: BrandColors['none'] },
				icon: { color: BrandColors['none'] },
			},
		},
		Dark: {
			background: { color: BrandColors['background-shade-dark-3'] },
			text: { color: BrandColors['text-shade-light-1'] },
			border: { color: BrandColors['none'] },
			icon: { color: BrandColors['none'] },
		},
	} as IThemedColors;
};

export const PointPlankTemplate = (theme: Theme, location: LocationType) => {
	let temp: IMasterPlankTemplate = structuredClone(MasterPlankTemplate(theme, location));

	if (temp?.transform?.grid?.alignment) {
		temp.transform.grid.alignment = 'top-center';
	}

	temp.palette = _palette()[theme];

	if (temp.rightColumn1?.transform.grid?.alignment) {
		temp.rightColumn1.transform.grid.alignment = 'top-right';
	}

	if (temp.leftColumn2?.transform?.grid?.gap) {
		temp.leftColumn2.transform.grid.gap = 1;
		temp.leftColumn2.transform.grid.gapType = 'rem';
	}

	if (temp?.leftColumn1?.palette) {
		temp.leftColumn1.palette.text = _palette()[theme].text;
		temp.leftColumn1.palette.icon = _palette()[theme].icon;
	}

	if (temp?.leftColumn2?.palette) {
		temp.leftColumn2.palette.text = _palette()[theme].text;
		temp.leftColumn2.palette.text = _palette()[theme].text;
	}

	if (temp?.rightColumn1?.palette) {
		temp.rightColumn1.palette.text = _palette()[theme].text;
		temp.rightColumn1.palette.text = _palette()[theme].text;
	}

	if (temp?.rightColumn2?.palette) {
		temp.rightColumn2.palette.text = _palette()[theme].text;
		temp.rightColumn2.palette.text = _palette()[theme].text;
	}

	return temp;
};
