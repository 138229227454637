import {
	BrandColors,
	IComponentColors,
	IComponentTemplate,
	IComponentTransform,
	IThemedColors,
	Theme,
} from '@fjordkraft/fjordkraft.component.library';

// ************************************
// Transform
// ************************************

const _transform = (desktopView: boolean) => {
	return {
		width: 'auto',
		height: 'auto',
		grid: {
			direction: 'row',
			alignment: 'center',
			gap: 2,
			boxSizing: 'border-box',
			wrap: false,
		},
	} as IComponentTransform;
};

const _containerTransform = (desktopView: boolean) => {
	return {
		height: 'auto',
		width: '100%',
		minWidth: desktopView ? '100%' : '87vw',
		shadow: {
			box: '0rem 0.5rem 4rem rgba(0, 0, 0, 0.15)',
		},
		grid: {
			direction: 'column',
			alignment: 'top-left',
			gap: 1,
			gapType: 'rem',
			tagType: 'nav',
		},
	} as IComponentTransform;
};

// ************************************
// Palette
// ************************************

const _palette = {
	Light: {
		text: { color: BrandColors['primary-shade-light-2'] },
		icon: { color: BrandColors['text-shade-light-1'] },
		focus: {
			text: { color: BrandColors['primary-shade-light-3'] },
			icon: { color: BrandColors['text-shade-light-1'] },
		},
		hover: {
			text: { color: BrandColors['primary-shade-light-3'] },
			icon: { color: BrandColors['text-shade-light-1'] },
		},
		disabled: {
			text: { color: BrandColors['primary-shade-light-2'], opacity: 0.3 },
			icon: { color: BrandColors['text-shade-light-1'], opacity: 0.3 },
		},
	} as IComponentColors,
	Dark: {} as IComponentColors,
} as IThemedColors;

const _containerPalette = {
	Light: {
		background: { color: BrandColors['background-shade-light-2'] },
	},
	Dark: {},
} as IThemedColors;

// ************************************
// Export
// ************************************

export const MS_PlankDropdownTemplate = (theme: Theme, desktopView: boolean) => {
	return {
		dropdown: {
			transform: _transform(desktopView),
			palette: _palette[theme],
		} as IComponentTemplate,
		container: {
			dontUpdateChildren: true,
			transform: _containerTransform(desktopView),
			palette: _containerPalette[theme],
		} as IComponentTemplate,
	};
};
