import React, { useState } from 'react';
import { getPageContent } from './EnergyConsumptionUsePageData';
import {
    StyleGrid,
} from '@fjordkraft/fjordkraft.component.library';
import {
    ICustomerInstallation,
    IDateState,
} from '../../../models';
import { v4 as uuid4 } from 'uuid';
import { Constants } from '../../../data';
import { ConsumptionBlock } from '../../../blocks/ConsumptionBlock/ConsumptionBlock';
import { IDefaultViewProps, PageV2 } from '../../PageV2';
import '../EnergyConsumptionPage.scss';
import {CommercialBlock} from "../../../blocks";

export interface IEnergyConsumptionUsePage extends IDefaultViewProps {
    chartData: any;
    installation: ICustomerInstallation;
    showEstimatedView: boolean;
}

export const EnergyConsumptionUsePage = () => {
    // ************************************
    // Properties
    // ************************************

    const classPrefix = 'energy-consumption-page';
    const pageId = uuid4();

    // ************************************
    // Lifecycle
    // ************************************

    const [state, setState] = useState<IDateState>();
    const [selectedYears, setSelectedYears] = useState<number[]>();

    // ************************************
    // Render
    // ************************************

    return PageV2({
        setup: {
            pageType: Constants.epiServerPageNames.consumptionUse.type,
            usesSubPage: true
        },
        dependenciesOptional: [
            { state },
            { selectedYears }
        ],
        handleData: getPageContent,
        render: (config: IEnergyConsumptionUsePage) => {
            const {
                activeBrand,
                activeTheme,
                chartData,
                showEstimatedView
            } = config;

            return (
                <StyleGrid
                    className={classPrefix}
                    direction="column"
                    alignment="top-center"
                    id={pageId}
                    gap={7}
                >
                    <ConsumptionBlock
                        {...config}
                        brand={activeBrand}
                        theme={activeTheme}
                        chartData={chartData}
                        showEstimatedView={showEstimatedView}
                        onDateChange={(state: IDateState) => {
                            setState(state);
                        }}
                        onSelectedYearsChange={(years: number[]) => {
                            setSelectedYears(years);
                        }}
                    />
                    <CommercialBlock/>
                </StyleGrid>
            );
        }
    });
};