import React from 'react';
import {
    IComponent,
    Text,
    StyleGrid,
    ContentGrid,
} from '@fjordkraft/fjordkraft.component.library';
import { paragraphTextPrefab } from '../../Prefabs';
import './ListItemSponsor.scss';

export interface IListItemSponsor extends IComponent {
    logoUrl?: string;
    name?: string;
    text?: string;
}

export const ListItemSponsor = (props: IListItemSponsor) => {
    // ************************************
    // Properties
    // ************************************
    const { logoUrl, name, text } = props;
    const classPrefix = 'list-item-sponsor';

    // ************************************
    // Render
    // ************************************
    return (
        <StyleGrid
            className={`${classPrefix}`}
            alignment={'center-left'}
            direction={'row'}
            spaceBetween
        >
            <ContentGrid
                className={`${classPrefix}__sponsor`}
                alignment={'center-left'}
                direction={'row'}
                tagType={'section'}
            >
                {/* SPONSOR LOGO */}
                {logoUrl && (
                    <img
                        className={`${classPrefix}__sponsor__icon`}
                        src={logoUrl}
                        alt={'Sponsor logo'}
                    />
                )}

                {/* SPONSOR */}
                <ContentGrid
                    className={`${classPrefix}__sponsor__info`}
                    alignment={'center-left'}
                    direction={'column'}
                    tagType={'section'}
                >
                    {name && (
                        <Text {...paragraphTextPrefab()} weight={500}>
                            {name}
                        </Text>
                    )}
                    {text && (
                        <Text
                            className={`${classPrefix}__sponsor__info__text`}
                            {...paragraphTextPrefab()}
                        >
                            {text}
                        </Text>
                    )}
                </ContentGrid>
            </ContentGrid>
        </StyleGrid>
    );
};
