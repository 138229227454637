import React, { useState, useMemo } from 'react';
import { IWebToast, ToasterBlock } from '../../../blocks';
import { LoadingModal } from '../../../modals';
import { ApplicationOverlayWrapperContext, useApplicationContext } from '../../../contexts';
import classNames from 'classnames';
import './ApplicationOverlayWrapper.scss';

export const ApplicationOverlayWrapper = (props: any) => {
	// ************************************
	// Properties
	// ************************************

	const classPrefix = 'application-overlay-wrapper';
	const { activeBrand, activeTheme, desktopView } = useApplicationContext();
	const spacingOffsetDesktop = 80;

	// ************************************
	// Lifecycle
	// ************************************

	const [tempToasts, setTempToasts] = useState<IWebToast[]>();
	const [toasts, setToasts] = useState<IWebToast[]>();
	const [globalLoading, setGlobalLoading] = useState<boolean>(false);

	const context = useMemo(() => {
		return {
			tempToasts,
			setTempToasts,
			toasts,
			setToasts,
			globalLoading,
			setGlobalLoading,
		};
	}, [tempToasts, globalLoading, toasts]);

	// ************************************
	// Helper Functionality
	// ************************************

	const _parseStaticToasts = () => {
		let toasties: IWebToast[] = [];

		if (toasts && toasts.length > 0) {
			toasts.forEach((translation: IWebToast) => {
				let foundClosedItem = localStorage.getItem(translation.id);
				translation.staticToast = true;

				if (!foundClosedItem) {
					toasties.push(translation);
				}
			});
		}

		return toasties;
	};

	const _parseTempToasts = () => {
		let toasties: IWebToast[] = [];

		if (tempToasts && tempToasts.length > 0) {
			toasties = tempToasts.map((translation: IWebToast) => {
				translation.staticToast = false;
				return translation;
			});
		}

		return toasties;
	};

	const _getTempStylePlacement = (offset: number) => {
		if (toasts && toasts.length > 0) {
			return desktopView ? { top: `${offset}px` } : { bottom: `${offset}px` };
		} else {
			return undefined;
		}
	};

	// ************************************
	// Render Functionality
	// ************************************

	const _renderStaticToast = () => {
		if (toasts && toasts.length > 0) {
			return (
				<ToasterBlock
					id={`${classPrefix}__toaster__static-id`}
					className={classNames(`${classPrefix}__toaster`, {
						[`${classPrefix}__toaster__top`]: desktopView,
					})}
					coverage="relative"
					theme={activeTheme}
					brand={activeBrand}
					toasts={_parseStaticToasts()}
				/>
			);
		}
	};

	const _renderTempToast = () => {
		if (tempToasts && tempToasts.length > 0) {
			let staticToastEle: any = document.getElementById(`${classPrefix}__toaster__static-id`);
			let offset: number = 0;

			if (staticToastEle) {
				let bounds = staticToastEle.getBoundingClientRect();
				offset = bounds.height + (desktopView ? spacingOffsetDesktop : 0);
			}

			return (
				<ToasterBlock
					id={`${classPrefix}__toaster__temp-id`}
					className={classNames(`${classPrefix}__toaster`, {
						[`${classPrefix}__toaster__top`]: desktopView,
					})}
					style={_getTempStylePlacement(offset)}
					theme={activeTheme}
					brand={activeBrand}
					coverage="relative"
					toasts={_parseTempToasts()}
					onAnimationComplete={() => {
						if (tempToasts) {
							setTempToasts(undefined);
						}
					}}
				/>
			);
		}
	};

	const _renderGlobalLoader = useMemo(() => {
		if (globalLoading) {
			return (
				<LoadingModal
					className={`${classPrefix}__loader`}
					mode={'window'}
					brand={activeBrand}
					theme={activeTheme}
				/>
			);
		}
	}, [globalLoading]);

	// ************************************
	// Render
	// ************************************

	return (
		<ApplicationOverlayWrapperContext.Provider value={context}>
			{props.children}
			{desktopView && (
				<>
					{_renderStaticToast()}
					{_renderTempToast()}
				</>
			)}
			{!desktopView && (
				<>
					{_renderTempToast()}
					{_renderStaticToast()}
				</>
			)}
			{_renderGlobalLoader}
		</ApplicationOverlayWrapperContext.Provider>
	);
};
