import {
	BrandColors,
	IMasterPlankTemplate,
	IThemedColors,
	LocationType,
	Theme,
} from '@fjordkraft/fjordkraft.component.library';
import { PointPlankTemplate } from './PointPlankTemplate';

const _palette = {
	Light: {
		background: { color: BrandColors['primary-shade-light-1'] },
		text: { color: BrandColors['text-shade-dark-3'] },
		border: { color: BrandColors['none'] },
		icon: { color: BrandColors['primary-shade-light-3'] },
		hover: {
			background: {
				color: BrandColors['primary-shade-light-1'],
				opacity: 0.8,
			},
			text: { color: BrandColors['text-shade-light-1'] },
			border: { color: BrandColors['none'] },
			icon: { color: BrandColors['primary-shade-light-3'] },
		},
		focus: {
			background: {
				color: BrandColors['primary-shade-light-1'],
				opacity: 0.8,
			},
			text: { color: BrandColors['text-shade-light-1'] },
			border: { color: BrandColors['none'] },
			icon: { color: BrandColors['primary-shade-light-3'] },
		},
		disabled: {
			background: {
				color: BrandColors['primary-shade-light-1'],
				opacity: 0.5,
			},
			text: { color: BrandColors['text-shade-light-1'], opacity: 0.5 },
			border: { color: BrandColors['none'] },
			icon: { color: BrandColors['primary-shade-light-3'] },
		},
	},
	Dark: {
		background: { color: BrandColors['secondary-shade-light-1'] },
		text: { color: BrandColors['text-shade-light-1'] },
		border: { color: BrandColors['none'] },
		icon: { color: BrandColors['none'] },
	},
} as IThemedColors;

export const SteddiPlankTemplate = (theme: Theme, location: LocationType) => {
	let temp: IMasterPlankTemplate = structuredClone(PointPlankTemplate(theme, location));

	temp.palette = _palette[theme];

	return temp;
};
