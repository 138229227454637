import {
	BrandColors,
	IComponentTemplate,
	IComponentTransform,
	IThemedColors,
	Theme,
} from '@fjordkraft/fjordkraft.component.library';

const _transform = {
	width: '100%',
	height: 'auto',
	grid: {
		boxSizing: 'border-box',
		direction: 'column',
		tagType: 'div',
	},
} as IComponentTransform;

const _palette = {
	Light: {
		background: { color: BrandColors['tertiary-shade-light-2']},
		text: { color: BrandColors['app-shade-light-1'] },
		icon: { color: BrandColors['app-shade-light-1'] },

	},
	Dark: {
		background: { color: BrandColors['tertiary-shade-dark-2'] },
		text: { color: BrandColors['app-shade-dark-1'] },
		icon: { color: BrandColors['app-shade-dark-1'] },
	},
} as IThemedColors;

export const CommercialTemplate = (theme: Theme) => {
	return {
		palette: _palette[theme],
		transform: _transform,
	} as IComponentTemplate;
};