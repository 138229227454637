import { WebStorageStateStore } from 'oidc-client-ts';
import { AuthProviderProps } from 'react-oidc-context';

const useB2C = import.meta.env.REACT_APP_USE_B2C == 'true';

export const authConfig: AuthProviderProps = {
	client_id: (useB2C ? import.meta.env.REACT_APP_B2C_CLIENT_ID : import.meta.env.REACT_APP_CLIENT_ID) ?? '',
	metadataUrl: useB2C ? import.meta.env.REACT_APP_B2C_AUTHORITY : undefined,
	authority: (useB2C ? 'auth://metadata' : import.meta.env.REACT_APP_AUTHORITY) ?? '',
	redirect_uri:
		import.meta.env.REACT_APP_AUTH_REDIRECT_URL ?? (window as any).authRedirectUrl ?? import.meta.env.REACT_APP_URL,
	automaticSilentRenew: true,
	onSigninCallback(user) {
		const loggedInuser = user as any;
		if (loggedInuser.state) {
			const url = loggedInuser.state.redirectUrl;
			if (url && window.location.href != url) {
				const parsedUrl = new URL(url);
				parsedUrl.searchParams.delete('li');
				parsedUrl.searchParams.delete('fkId');
				parsedUrl.searchParams.delete('sub');
				window.location.href = parsedUrl.toString();
			}
		}
	},
	popup_post_logout_redirect_uri: window.location.origin,
	post_logout_redirect_uri: window.location.origin,
	scope: useB2C ? import.meta.env.REACT_APP_B2C_AUTH_SCOPE : import.meta.env.REACT_APP_AUTH_SCOPE,
	acr_values: useB2C ? undefined : createAcrValues(),
	userStore: new WebStorageStateStore({ store: localStorage }),
	loadUserInfo: false,
	extraQueryParams: useB2C ? createExtraparams() : undefined,
};
function createAcrValues(): string {
	let acrValues = 'context:userData';
	const params = new URLSearchParams(window.location.search);
	if (params.get('li')) {
		acrValues = `${acrValues} li:${params.get('li')}`;
	}
	if (params.get('fkId')) {
		acrValues = `${acrValues} fk_code:${params.get('fkId')}`;
	}
	return acrValues;
}
function createExtraparams() {
	const params = new URLSearchParams(window.location.search);
	const base: Record<string, string> = {
		uiTemplate: import.meta.env.REACT_APP_B2C_UIDOMAIN ?? 'WHITE',
		context: 'web',
	};
	if(import.meta.env.REACT_APP_B2C_UIDOMAIN){
		base.brand = import.meta.env.REACT_APP_B2C_UIDOMAIN;
	}
	if (params.get('li')) {
		base.li = params.get('li') ?? '';
		base.prompt = 'login';
	}
	return base;
}
