import React from 'react';
import {
    Button,
    IComponent,
    Icon,
    Text,
    IconType,
    IAction,
    BrandColors,
    ITextCustomization,
} from '@fjordkraft/fjordkraft.component.library';
import classnames from 'classnames';
import './BackButton.scss';

export interface IBackButtonCustomization {
    text: ITextCustomization;
}

export interface IBackButton extends IComponent {
    action: IAction;
    customization?: IBackButtonCustomization;
}

export const BackButton = (props: IBackButton) => {
    // ************************************
    // Properties
    // ************************************

    const { id, className, theme, brand, action, customization } = props;
    const classPrefix = 'ms-back-button';

    // ************************************
    // Render
    // ************************************

    return (
        <Button
            id={id}
            className={classnames(classPrefix, {
                [`${className}`]: className,
            })}
            brand={brand}
            action={action}
        >
            <Icon
                className={`${classPrefix}__icon`}
                brand={brand}
                theme={undefined}
                color={
                    theme === 'Light'
                        ? BrandColors['icon-shade-light-1']
                        : undefined
                        ? BrandColors['primary-shade-light-2']
                        : BrandColors['icon-shade-dark-1']
                }
                type={IconType.ArrowLeft}
                width={1}
                height={1}
            />
            <Text
                className={`${classPrefix}__text`}
                type={'label'}
                theme={theme}
                weight={500}
                size={'medium'}
                family={'main'}
                brand={brand}
                {...customization?.text}
            >
                {action.text}
            </Text>
        </Button>
    );
};
