import { BrandColors, IComponentTransform } from '@fjordkraft/fjordkraft.component.library';

const BUTTON_TRANSFORM_LINK = {
	width: 'auto',
	height: 'auto',
	padding: '0',
	margin: '0',
	outline: {
		type: 'dashed',
		thickness: 2,
	},
	border: {
		thickness: 2,
		sides: 'bottom',
		type: 'solid',
	},
	grid: {
		direction: 'row',
		alignment: 'center',
		gap: 1,
		boxSizing: 'border-box',
		wrap: false,
	},
} as IComponentTransform;

const BUTTON_PALETTE_LINK = {
	'brand-fjordkraft': {
		Light: {
			background: { color: BrandColors['transparent'] },
			text: { color: BrandColors['action-shade-light-1'] },
			border: { color: BrandColors['action-shade-light-1'] },
			icon: { color: BrandColors['action-shade-light-1'] },
			hover: {
				background: { color: BrandColors['transparent'], opacity: 0.8 },
				text: { color: BrandColors['action-shade-light-1'], opacity: 0.8 },
				border: { color: BrandColors['action-shade-light-1'], opacity: 0.8 },
				icon: { color: BrandColors['action-shade-light-1'], opacity: 0.8 },
			},
			focus: {
				background: { color: BrandColors['transparent'], opacity: 0.8 },
				text: { color: BrandColors['action-shade-light-1'], opacity: 0.8 },
				border: { color: BrandColors['action-shade-light-1'], opacity: 0.8 },
				icon: { color: BrandColors['action-shade-light-1'], opacity: 0.8 },
				outline: { color: BrandColors['primary-shade-light-2'] },
			},
			disabled: {
				background: { color: BrandColors['transparent'], opacity: 0.3 },
				text: { color: BrandColors['action-shade-light-1'], opacity: 0.3 },
				border: { color: BrandColors['action-shade-light-1'], opacity: 0.3 },
				icon: { color: BrandColors['action-shade-light-1'], opacity: 0.3 },
			},
		},
		Dark: {
			background: { color: BrandColors['transparent'] },
			text: { color: BrandColors['text-shade-light-1'] },
			border: { color: BrandColors['text-shade-light-1'] },
			icon: { color: BrandColors['text-shade-light-1'] },
			hover: {
				background: { color: BrandColors['transparent'], opacity: 0.8 },
				text: { color: BrandColors['text-shade-light-1'], opacity: 0.8 },
				border: { color: BrandColors['text-shade-light-1'], opacity: 0.8 },
				icon: { color: BrandColors['text-shade-light-1'], opacity: 0.8 },
			},
			focus: {
				background: { color: BrandColors['transparent'], opacity: 0.8 },
				text: { color: BrandColors['text-shade-light-1'], opacity: 0.8 },
				border: { color: BrandColors['text-shade-light-1'], opacity: 0.8 },
				icon: { color: BrandColors['text-shade-light-1'], opacity: 0.8 },
				outline: { color: BrandColors['primary-shade-light-2'] },
			},
			disabled: {
				background: { color: BrandColors['transparent'], opacity: 0.3 },
				text: { color: BrandColors['text-shade-light-1'], opacity: 0.3 },
				border: { color: BrandColors['text-shade-light-1'], opacity: 0.3 },
				icon: { color: BrandColors['text-shade-light-1'], opacity: 0.3 },
			},
		},
	},
	'brand-trondelagkraft': {
		Light: {
			background: { color: BrandColors['transparent'] },
			text: { color: BrandColors['action-shade-light-1'] },
			border: { color: BrandColors['action-shade-light-1'] },
			icon: { color: BrandColors['action-shade-light-1'] },
			hover: {
				background: { color: BrandColors['transparent'], opacity: 0.8 },
				text: { color: BrandColors['action-shade-light-1'], opacity: 0.8 },
				border: { color: BrandColors['action-shade-light-1'], opacity: 0.8 },
				icon: { color: BrandColors['action-shade-light-1'], opacity: 0.8 },
			},
			focus: {
				background: { color: BrandColors['transparent'], opacity: 0.8 },
				text: { color: BrandColors['action-shade-light-1'], opacity: 0.8 },
				border: { color: BrandColors['action-shade-light-1'], opacity: 0.8 },
				icon: { color: BrandColors['action-shade-light-1'], opacity: 0.8 },
				outline: { color: BrandColors['primary-shade-light-2'] },
			},
			disabled: {
				background: { color: BrandColors['transparent'], opacity: 0.3 },
				text: { color: BrandColors['action-shade-light-1'], opacity: 0.3 },
				border: { color: BrandColors['transparent'], opacity: 0.3 },
				icon: { color: BrandColors['action-shade-light-1'], opacity: 0.3 },
			},
		},
		Dark: {
			background: { color: BrandColors['transparent'] },
			text: { color: BrandColors['text-shade-light-1'] },
			border: { color: BrandColors['text-shade-light-1'] },
			icon: { color: BrandColors['text-shade-light-1'] },
			hover: {
				background: { color: BrandColors['transparent'], opacity: 0.8 },
				text: { color: BrandColors['text-shade-light-1'], opacity: 0.8 },
				border: { color: BrandColors['text-shade-light-1'], opacity: 0.8 },
				icon: { color: BrandColors['text-shade-light-1'], opacity: 0.8 },
			},
			focus: {
				background: { color: BrandColors['transparent'], opacity: 0.8 },
				text: { color: BrandColors['text-shade-light-1'], opacity: 0.8 },
				border: { color: BrandColors['text-shade-light-1'], opacity: 0.8 },
				icon: { color: BrandColors['text-shade-light-1'], opacity: 0.8 },
				outline: { color: BrandColors['primary-shade-light-2'] },
			},
			disabled: {
				background: { color: BrandColors['transparent'], opacity: 0.3 },
				text: { color: BrandColors['text-shade-light-1'], opacity: 0.3 },
				border: { color: BrandColors['text-shade-light-1'], opacity: 0.3 },
				icon: { color: BrandColors['text-shade-light-1'], opacity: 0.3 },
			},
		},
	},
} as any;

export const BUTTON_TEMPLATE_LINK = {
	themedPalette: BUTTON_PALETTE_LINK,
	transform: BUTTON_TRANSFORM_LINK,
};
